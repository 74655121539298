myApp.controller('YesnoModalController', function($scope, $uibModalInstance, $http, param, SharedData) {

    $scope.sharedData = SharedData.data;

    $scope.text = param.text;

    $scope.ok = function() {
        $uibModalInstance.close("close");

    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
});
