(function () {
    angular
        .module('Order4meClient')
        .factory('KeyWordService', KeyWordService);

    KeyWordService.$inject = [];

    function KeyWordService() {
        const KEY_WORD_LINK = 'LINK';
        const SEPARATOR_URL = 'URL';

        return {
            replaceLinks: replaceLinks
        };

        function replaceLinks(link) {
            if (link === null || link === undefined) {
                return "";
            }

            let htmlLink = link;
            let replacedLink;

            let limit = 50; // Backup for infinity loop
            while (limit-- > 0) {
                replacedLink = replaceLink(htmlLink);

                if (replacedLink !== null) {
                    htmlLink = replacedLink;
                } else {
                    break;
                }
            }

            return htmlLink;
        }

        function replaceLink(text) {
            const linkStartPhrase = buidKeyWordStart(KEY_WORD_LINK);
            const linkEndPhrase = buidKeyWordEnd(KEY_WORD_LINK);
            const urlStartPhrase = buildSeparator(SEPARATOR_URL);

            const linkStartPos = indexOfIgnoreCase(linkStartPhrase, text);
            const linkEndPos = indexOfIgnoreCase(linkEndPhrase, text);
            const urlStartPos = indexOfIgnoreCase(urlStartPhrase, text);

            if (linkStartPos < 0 || linkEndPos < 0 || urlStartPos < 0) {
                return null;
            }

            // Build required data
            const url = text.substring(urlStartPos + urlStartPhrase.length, linkEndPos);
            const linkStructure = '<a class="a_orig" onclick=\"externalPageLoader(\'' + url + '\')\">';

            let htmlLink = text;
            htmlLink = replace(linkStartPhrase, linkStructure, htmlLink);
            htmlLink = replace(linkEndPhrase, '</a>', htmlLink);
            htmlLink = replace(urlStartPhrase + url, ' ', htmlLink);

            return htmlLink;
        }

        function replace(phrase, replacePhrase, original) {
            let pos = indexOfIgnoreCase(phrase, original);
            return original.substring(0, pos) + replacePhrase + original.substring(pos + phrase.length);
        }

        function buidKeyWordStart(keyword) {
            return '$' + keyword + '_start$';
        }

        function buidKeyWordEnd(keyword) {
            return '$' + keyword + '_end$';
        }

        function buildSeparator(separator) {
            return '$' + separator + '$';
        }

        function indexOfIgnoreCase(phrase, text) {
            return text.toLowerCase().indexOf(phrase.toLowerCase());
        }
    }
})();
