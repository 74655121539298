myApp.config(function ($translateProvider) {
    $translateProvider.translations('ro', {
        ADDRESS_HEADER: 'Adresa',
        ARRIVAL_LABEL: 'Sosire',
        ASK_FOR_CHECK_OUT_LABEL: 'Vă puteți pregăti detaliile de facturare în avans',
        BACK: 'înapoi',
        CHANNELS_LABEL: 'canal',
        CHARGE: 'Taxa de serviciu',
        CHECK_IN_BUTTON: 'Check-in in procesare',
        CHECK_IN_CARD_INFO_LABEL: 'Vă rugăm să completați întregul formular',
        CHECK_IN_CARD_LABEL: 'Informatii personale',
        CHECK_IN_FINISH_THANK_YOU_LABEL: 'Vă mulțumim! Detaliile de facturare au fost salvate. La sosire, puteți ridica cheia de la recepție. Călătorie placuta',
        CHECK_IN_THANK_YOU_LABEL: 'Vă mulțumesc pentru efectuarea check-in ului on-line.',
        CHECK_OUT_BUTTON: 'Salveaza adresa de facturare',
        CHECK_OUT_CARD_INFO_LABEL: 'Doriti o adresa de facturare diferita?',
        CHECK_OUT_CARD_LABEL: 'Adresa de facturare',
        CHECK_OUT_THANK_YOU_LABEL: 'Vă mulțumim! Detaliile de facturare au fost salvate. La sosire, puteți ridica cheia de la recepție. Călătorie placuta!',
        CITY_LABEL: 'Oras',
        COMMENT: 'Comentariu...',
        COMPANY_NAME1_LABEL: 'Numele companiei',
        COMPANY_NAME2_LABEL: 'Numele companiei',
        COUNTRY_LABEL: 'Tara',
        DATE_OF_BIRTH_LABEL: 'Data de nastere',
        DEPARTURE_LABEL: 'Plecare',
        DO_CHECK_OUT_BUTTON: 'Pregătiți detaliile de facturare',
        EMAIL_LABEL: 'E-mail',
        ERROR_MESSAGE: 'Mesaj de eroare',
        FINISH_CHECK_IN_BUTTON: 'Nu acum',
        FIRST_NAME_LABEL: 'Prenume',
        INPUT_PROMPT_COMMENT: 'Comentariu',
        LANGUAGE_LABEL: 'limbă',
        LAST_NAME_LABEL: 'Nume de familie',
        LOADING_LABEL: 'Incarca',
        LOGOFFDIALOG: 'Doriți cu adevărat să vă deconectați?',
        MANDATORY_FIELD: 'Câmp obligatoriu',
        MESSAGE_TO_RECEPTION: 'Contactati receptia',
        MONTH_APRIL: 'Aprilie',
        MONTH_AUGUST: 'August',
        MONTH_DECEMBER: 'Decembrie',
        MONTH_FEBRUARY: 'Februarie',
        MONTH_JANUARY: 'Ianuarie',
        MONTH_JULY: 'Iulie',
        MONTH_JUNE: 'Iunie',
        MONTH_MARCH: 'Martie',
        MONTH_MAY: 'Mai ',
        MONTH_NOVEMBER: 'Noiembrie',
        MONTH_OCTOBER: 'Octombrie',
        MONTH_SEPTEMBER: 'Septembrie',
        NATIONALITY_LABEL: 'Nationalitate',
        NAV_HOME: 'Acasa',
        NAV_MOBILE_CHECK_IN: 'Mobile check in',
        NO_DATA_FOUND: 'Nu sunt disponibile detalii despre rezervare. Vă rugăm să verificați informațiile.',
        NO_ROOM: 'Camera nu a fost gasita',
        NO: 'Nu',
        ORDER: 'Comanda',
        PASSPORT_LABEL: 'Numar de pasaport',
        PLEASE_ENTER: 'Vă rugăm să introduceți numărul camerei și numele dumneavoastra',
        PRICE_LABEL: 'preț',
        PROCEED: 'Continua',
        REPLY: 'Raspunde',
        RESERVATION_COMMENT: 'Note',
        ROOM_NAME: 'Nume',
        ROOM_NUMBER: 'Camera',
        SALUTATION_LABEL: 'Titlu',
        SEARCH: 'Cauta',
        SEND_MESSAGE: 'Trimite',
        STREET_LABEL: 'Adresa',
        STREET_LINE1_LABEL: 'Strada (Numar 1)',
        STREET_LINE2_LABEL: 'Strada (Numar 2)',
        USE_CREDIT_CARD_LABEL: 'Foloseste cartea de credit urmatoare',
        USE_PRIVATE_ADDRESS_LABEL: 'Foloseste adresa ta privata',
        VALUE_LABEL: 'valoare',
        YES: 'Da',
        YOUR_MESSAGE: 'Mesajul tau',
        YOUR_ORDER: 'comandă',
        ZIP_CODE_LABEL: 'Cod Postal',
        EMAIL_NAME: 'E-Mail',
        VALID_VALUE: 'Va rugam sa introduceti o valoare valida',
        NOT_AVAILABLE_WIFI: 'Pentru a utiliza aceasta functie trebuie fiti conectat la reteaua WI-FI!',
        NOT_AVAILABLE_LOCATION: 'Pentru a utiliza aceasta functie trebuie sa va aflati in incinta hotelului!',
        NOT_AVAILABLE: 'Functia nu este disponibila',
        CHECK_LOCATION: 'Verificare locatie...',
        DESCRIPTION_LABEL: 'Descriere',
        CONTACT_RECEPTION_PERMANENT: 'Aveti o intrebare?Personalul receptiei va fi bucuros sa va ajute...',
        CHECK_NEARBY_HOTELS_LABEL: 'Se cauta hoteluri in apropiere,va rugam asteptati...',
        NO_GEO_LOCATION_FOUND: 'Nu s-a putut determina locatia',
        NO_GEO_LOCATION_SERVICE_FOUND: 'Va rugam sa activati serviciile de localizare ale dispozitivului dumneavastra',
        // checkout
        ACCEPT_AGB: '*<a class="a_orig" href=\'{{agb}}\' target=\'_blank\'>Condiții generale</a> citite și acceptate.&nbsp;&nbsp;',
        CANCEL_LABEL: 'Anulare',
        CHECK_OUT_VALIDATION: 'Vă rugăm să vă validați identitatea',
        CHECKOUT: 'Check-out',
        CHECKOUT_DONE_MESSAGE: 'Aproape ați terminat! Vă rugăm să lăsați cheia la recepție.',
        CHECKOUT_DONE_MESSAGE_WITH_INVOICE: 'Aproape ați terminat! Factura vă va fi trimisă prin e-mail. Vă rugăm să lăsați cheia la recepție.',

        CHECKINOUT_ERROR_MESSAGE: 'Ups! Ceva nu a funcționat conform așteptărilor. Vă rugăm să mergeți la recepție. Echipa noastră va rezolva totul.',
        CONSUMATE_QUESTION: 'Doriți ceva de la minibar? Vă rugăm să adăugați produsele.',
        EDIT_LABEL: 'Editare',
        FOLIO_DETAILS: 'Detalii factură',
        FOLIO_SUM: 'Sumă',
        INVOICE_ADDRESS: 'Adresă de facturare',
        INVOICE_INFORMATION: 'Informații despre detaliile facturii. Serviciul de check-out va fi disponibil în ziua plecării.',
        INVOICE_MESSAGE_ON_CONDITION: 'Sunt incluse toate cheltuielile de până în acest moment. Pot apărea modificări.',
        ITEM_DESCRIPTION_LABEL: 'Produs',
        ITEM_QUANTITY_LABEL: 'Cantitate',
        ITEM_TRANSACTION_DATE_LABEL: 'Data tranzacției',
        MANDATORY_MAIL_MESSAGE: 'Este suficient să specificați adresa de e-mail pentru facturare.',
        NOT_FOUND_NAME_ROOM_DIALOG: 'Ups! Ceva nu a funcționat conform așteptărilor. Încercați din nou sau contactați recepția.',
        PAY_DIFFERENCE: 'Efectuare plată acum',
        PAYED_AMOUNT: 'Valoare plătită',
        PAYMENT_FAILED_MESSAGE: 'Ups! Ceva nu a funcționat conform așteptărilor. Vă rugăm să efectuați plata din nou.',
        PAYMENT_SUCCESS_MESSAGE: 'Succes! Etapa următoare este check-out-ul final.',
        PROCESS_CART: 'Rezervare produse',
        REMAINING_AMOUNT: 'Valoare datorată',
        REQUIRED_LABEL: 'Acest câmp nu poate fi lăsat necompletat!',
        ROOM_NUMBER_CHECKOUT: 'Număr cameră',
        SAVE_LABEL: 'Salvare',
        SKIP_CART: 'Fără produse din minibar',
        SUBMIT_CONFIRMATION_MESSAGE: 'Confirmați trimiterea?',
        SUBMIT_LABEL: 'Rezervare produse',
        SUM_AMOUNT: 'Valoare totală',
        YOUR_ORDER_CHECKOUT: 'Consumul dvs.'
    });
});
