(function () {
    'use strict';

    angular
        .module('Order4meBase')
        .factory('EventDispatcher', EventDispatcher);

    EventDispatcher.$inject = [ '$log', '$timeout' ];

    function EventDispatcher($log, $timeout) {
        const listeners = {};
        const lockedEvents = [];

        const dispatcher = {
            addEventListener: addUniqueEventListener,
            removeEventListener: removeEventListener,
            dispatchEvent: dispatchEvent,
            dispatchAndLockEvent: dispatchAndLockEvent
        };
        return dispatcher;


        /**
         * Adds a listener on the object.
         * @param {string} type - Event type
         * @param {Object} callback - Listener callback
         */
        function addEventListener(type, callback) {
            $timeout(function () {
                if (!listeners.hasOwnProperty(type))
                    listeners[ type ] = [];

                listeners[ type ].push(callback);

                // Execute callback immediately if the event has been locked
                let event = getLockedEvent(type);
                if (event !== null) {
                    try {
                        callback.apply(this, event.args);
                    } catch (e) {
                        $log.error("Error in callback of event '" + type + "'", e);
                    }
                }
            });
        }

        /**
         * Removes all listeners with the given function name.
         * @param {string} type - Event type
         * @param {Object} callback - Listener callback
         */
        function removeEventListener(type, callback) {
            $timeout(function () {
                if (listeners.hasOwnProperty(type)) {
                    let name = extractFunctionName(callback);

                    for (let i = 0; i < listeners[ type ].length; i++) {
                        let funcName = extractFunctionName(listeners[ type ][ i ]);
                        if (name === funcName) {
                            listeners[ type ].splice(i--, 1);
                        }
                    }
                }
            });
        }

        /**
         * Extracts the original function name of a function or bound function object.
         * @param {Object} listener - Listener
         * @return {string} function name
         */
        function extractFunctionName(listener) {
            // bind() function creates a new bound function with the name <bound funcName>
            let funcName = (listener.name) ? listener.name.split(" ") : null;
            if (funcName) {
                funcName = (funcName.length === 1) ? funcName[ 0 ] : funcName[ funcName.length - 1 ];
            }
            return funcName;
        }

        /**
         * Checks if a listener with the given name is already assigned to a event with the given type.
         * @param {string} type - Event type
         * @param {Object} callback  - Listener callback
         * @return {boolean} true if a matching name was found, otherwise false
         */
        function containsListener(type, callback) {
            if (listeners.hasOwnProperty(type)) {
                let name = extractFunctionName(callback);

                for (let i = 0; i < listeners[ type ].length; i++) {
                    let funcName = extractFunctionName(listeners[ type ][ i ]);
                    if (name && name === funcName) {
                        return true;
                    }
                }
            }

            return false;
        }

        /**
         * Adds the listener to the event with the given name if no other listener with the same function-name is already bound.
         * @param {string} type - Event type
         * @param {Object} callback - Listener callback
         */
        function addUniqueEventListener(type, callback) {
            // Return and contains are not working correctly if the callback-name is empty
            if (!callback.name || callback.name.length === 0) {
                console.warn("No Listener-Name is set for this callback - this could lead to further problems!");
            }

            if (!containsListener(type, callback)) {
                addEventListener(type, callback);
            }
        }

        /**
         * Dispatch an event to all registered listener.
         * @param {...*} _ - event type (must be string) followed by the callback's parameters
         */
        function dispatchEvent(_) {
            let listener = listeners[ arguments[ 0 ] ];

            for (let key in listener) {
                // Copy the argments object because editing it directly can prevent optimizations of JS-Enginge
                const callbackArgs = argumentsToArry.apply(this, arguments);
                callbackArgs.splice(0, 1);
                listener[ key ].apply(this, callbackArgs);
            }
        }

        /**
         * Dispatch an event to all registered listener and locks the event. Whenever a callback is added
         * to a locked event, the callback is immediately executed.
         * @param {...*} _ - event type (must be string) followed by the callback's parameters
         */
        function dispatchAndLockEvent(_) {
            dispatchEvent.apply(this, arguments);
            lockEvent.apply(this, arguments);
        }

        /**
         * Locks the event with the given type.
         * @param {...*} _ - event type (must be string) followed by the callback's parameters
         */
        function lockEvent(_) {
            let event = getLockedEvent(arguments[ 0 ]) || {};
            let args = argumentsToArry.apply(this, arguments);
            event.name = args[ 0 ];
            args.splice(0, 1);
            event.args = args;
            lockedEvents.push(event);
        }

        /**
         * Unlocks the event with the given type.
         * @param {string} type - Event type
         */
        function unlockEvent(type) {
            let event = getLockedEvent(arguments[ 0 ]);
            let index = lockedEvents.indexOf(event);
            if (index >= 0) {
                lockedEvents.splice(index, 1);
            }
        }

        /**
         * Returns the locked event by the specified type.
         * @param {string} type - Event type
         * @returns {(JSON|null)} The locked event or null
         */
        function getLockedEvent(type) {
            for (let i = 0; i < lockedEvents.length; i++) {
                if (type === lockedEvents[ i ].name) {
                    return lockedEvents[ i ];
                }
            }
            return null;
        }

        /**
         * Creates an array object from the arguments object, to enable further editing.
         * (Direct editing of the arguments obejct can prevent optimizations of JS-Enginge!).
         * @param {*...} _ - the arguments object
         * @return {Array} The array containing the argument values
         */
        function argumentsToArry(_) {
            return (arguments.length === 1 ? [ arguments[ 0 ] ] : Array.apply(null, arguments));
        }
    }
})();
