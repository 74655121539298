/*
 function initialiseUI() {
 // Set the initial subscription value
 swRegistration.pushManager.getSubscription()
 .then(function (subscription) {
 isSubscribed = !(subscription === null);
 if (isSubscribed) {
 console.log('User IS subscribed to Push');
 } else {
 console.log('User is NOT subscribed to Push');
 subscribeUser();
 }

 });
 }
 */
