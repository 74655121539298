(function() {
    'use strict';

    myApp.directive('o4mFillToBtm', FillToBtm);
    
    FillToBtm.$inject = ['$window','$timeout'];

    function FillToBtm($window,$timeout) {
        var directive = {
            restrict: 'A',
            link: link,
            scope: {
                excludeIds : '=?',
                reduceBy : '=?'
            }
        };
        return directive;


        function link($scope, $element, $attrs) {
            
            $scope.excludeIds = $scope.excludeIds || [];
            $scope.reduceBy = $scope.reduceBy || 0;

            $scope.setSize = function() {
                $timeout(function() {	// delay to allow dom-update before execution
                    let target = angular.element($element);
                    let excludeSize = 0;
                    
                    $scope.excludeIds.forEach(function(id) {
                        let exclude = angular.element(document.querySelector('#' + id))[0];
                        if (exclude)
                            excludeSize += exclude.clientHeight;
                    }, this);
                    
                    target.css('height', $window.innerHeight - target[0].offsetTop - excludeSize - $scope.reduceBy + 'px');
                }, 0);
            }

            angular.element($window).bind('resize', function() {
                $scope.setSize();
            });            

            $scope.$on('betRecalcFillToBtn', function(event) {
                $scope.setSize();
            });

            $scope.setSize();
        }
    }
})();