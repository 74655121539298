(function() {
	angular
		.module('Order4meBase')
		.factory('HttpClient', HttpClient);

	HttpClient.$inject = ['$http', '$q'];

	function HttpClient($http, $q) {
        var urlPrefix = "";

        var http = {
            init: init,
            send: send,
            getParams: getParams,
            getBaseUrl: getBaseUrl
        };
        return http;

        function init(prefix) {
            urlPrefix = prefix;
        }

        function send(url, data) {
            let def = $q.defer();
            $http({
                method : 'POST',
                url : urlPrefix + '/' + url,
                headers : { 'Content-Type' : 'application/json; charset=UTF-8' },
                data: data
            }).then(function success(response) {
                def.resolve(response.data);
            }, function error(response) {
                console.error("Error in HTTP-Request", data, response);
                def.reject("Error");
            });
            return def.promise;
        }

        function getParams() {
            let params = {};
            if (window.location.href.split('?').length === 2) {
                let gets = window.location.href.split('?')[1].split("&");
                for (let i = 0; i < gets.length; i++) {
                    let temp = gets[i].split("=");
                    params[temp[0]] = temp[1];
                }
            }
            return params;
        }

        function getBaseUrl() {
            let pathArray = window.location.href.split('/');
            return pathArray[0] + '//' + pathArray[2];
        }
	}
})();