(function () {
    angular
        .module('Order4meClient')
        .factory('Sentry', SentryService);

    SentryService.$inject = ['$raven'];

    function SentryService($raven) {
        var service = {
            log: log
        };
        return service;

        function log(msg) {
        }
    }
})();