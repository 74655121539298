var createTwitter = function () {
    console.log('TWITTER STARTS');

    var d  = document,
        s  = 'script',
        id = 'twitter-wjs';

    var js, fjs = d.getElementsByTagName(s)[ 0 ],
        t       = window.twttr || {};
    if (d.getElementById(id)) {
        return t;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://platform.twitter.com/widgets.js';
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function (f) {
        t._e.push(f);
    };

    window.twttr = t;
};
