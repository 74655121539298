myApp.controller('ClockCustomerModalCtrl', function ($scope, $uibModalInstance, $translate, $http, param, SharedData, $filter, EmailProviderService) {
    $scope.countries = param.countries;
    $scope.salutations = param.salutations;
    $scope.invoiceDto = param.invoiceDto;
    $scope.isCompany = param.isInvoiceDtoCompany;
    $scope.isPrivate = !param.isInvoiceDtoCompany;
    $scope.currency = param.currency;
    $scope.callback = param.callback;

    $scope.ok = function() {
        $scope.callback($scope.invoiceDto);
        $uibModalInstance.close('ok');
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.transaction = function() {
        return $scope.invoiceDto && $scope.invoiceDto.transactionDtos && $scope.invoiceDto.transactionDtos.length > 0 &&
            $scope.invoiceDto.transactionDtos[0].transactionDetailsDtos && $scope.invoiceDto.transactionDtos[0].transactionDetailsDtos .length > 0 ?
            $scope.invoiceDto.transactionDtos[0].transactionDetailsDtos[0] : {};
    };

    $scope.billing = function() {
        return $scope.invoiceDto && $scope.invoiceDto.billingDto ? $scope.invoiceDto.billingDto : {};
    };

    $scope.billingAddress = function() {
        let billing = $scope.billing();
        if (!angular.equals(billing, {}) && billing.addresses && billing.addresses.length > 0) {
            return billing.addresses[0];
        }
        return {};
    };

    $scope.isInvoiceNew = function(){
        return !$scope.invoiceDto.identifier;
    };

    $scope.isBillingAddressEmpty = function() {
        let billingAddress = $scope.billingAddress();
        return angular.equals(billingAddress, {});
    };

    $scope.hasTransaction = function() {
        let transaction = $scope.transaction();
        return !angular.equals(transaction, {});
    };

    function init() {
        $scope.personalDetailsExpanded = true;
        $scope.companyDetailsExpanded = true;
        $scope.addressDetailsExpanded = $scope.isBillingAddressEmpty();
        $scope.transactionDetailsExpanded = $scope.isInvoiceNew() && $scope.hasTransaction();
    }

    init();
});
