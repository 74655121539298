/*
function updateSubscriptionOnServer(subscription) {
    // TODO: Send subscription to application server

    //subscriptionJson = document.querySelector('.js-subscription-json');
    //subscriptionDetails =
    //      document.querySelector('.js-subscription-details');
    if (subscription) {
        //  subscriptionJson.textContent = JSON.stringify(subscription);
        //  subscriptionDetails.classList.remove('is-invisible');
        console.log(JSON.stringify(subscription));
        var hot = angular.element(document.getElementById('mainBody')).scope().sharedData.hotelID;
        console.log("Reporting push, Hotel would be " + hot);
        angular.element(document.getElementById('mainBody')).scope().sharedData.sendLog("pushSubscription", 0, hot);
        // this is to be sent to server!
        var rawKey = subscription.getKey ? subscription.getKey('p256dh') : '';
        key = rawKey ?
            btoa(String.fromCharCode.apply(null, new Uint8Array(rawKey))) :
            '';
        var rawAuthSecret = subscription.getKey ? subscription.getKey('auth') : '';
        authSecret = rawAuthSecret ?
            btoa(String.fromCharCode.apply(null, new Uint8Array(rawAuthSecret))) :
            '';

        endpoint = subscription.endpoint;
        angular.element(document.getElementById('mainBody')).scope().sharedData.sendPushRegister(endpoint, key, authSecret);

    } else {
        //    subscriptionDetails.classList.add('is-invisible');
    }
}

function subscribeUser() {
    applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
    swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
        })
        .then(function(subscription) {
            console.log('User is subscribed.', subscription);
            updateSubscriptionOnServer(subscription);
            isSubscribed = true;
        })
        .catch(function(err) {
            console.log('Failed to subscribe the user: ', err);
        });
}
*/
