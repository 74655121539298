(function () {
    angular
        .module('Order4meBase')
        .factory('LocalStorage', LocalStorage);

    LocalStorage.$inject = [ '$window', '$q', '$localStorage', '$timeout' ];

    function LocalStorage($window, $q, $localStorage, $timeout) {
        const WIDGET_ID = 'gustaffo-local-storage';
        let widget;
        let widgetInitialized = false;

        return {
            init: init,
            sync: sync,
            read: read,
            write: write,
            increment: increment
        };

        function init() {
            let def = $q.defer();

            if ($window.document.getElementById(WIDGET_ID)) {
                console.warn(WIDGET_ID + ' already exists.');
                def.resolve('ALREADY_EXISTING');
            }

            console.log('Waiting for cross origin local storage widget to be loaded...');

            let timer = $timeout(function () {
                def.resolve('TIMEOUT');
            }, 10000);

            // Wait until widget has been initialized
            $window.addEventListener('message', function (event) {
                let data = event.data;

                if (data && data.substr(WIDGET_ID.length).startsWith('.ready')) {
                    widgetInitialized = true;
                    console.log('Cross origin local storage widget initialized.');
                    $timeout.cancel(timer);

                    // Synchronize cross origin storage with local storage
                    sync().then(function (state) {
                        def.resolve(state);
                    });
                }
            });

            // Create iframe for cross origin local storage operations
            widget = $window.document.createElement('iframe');
            widget.setAttribute('title', WIDGET_ID);
            widget.setAttribute('name', WIDGET_ID);
            widget.setAttribute('id', WIDGET_ID);
            widget.setAttribute('src', 'https://order4mepeu.appspot.com/v2/frontend/widgets/crossOriginLocalStorage/crossOriginLocalStorage.html?id=' + WIDGET_ID);
            widget.style.display = 'none';
            $window.document.body.appendChild(widget);

            return def.promise;
        }

        function sync() {
            let def = $q.defer();

            if (widgetInitialized && testLocalStorage()) {
                // Send read request
                $window.frames[ WIDGET_ID ].postMessage(WIDGET_ID + '.allGet', '*');

                // Wait for answer
                $window.addEventListener('message', function readListener(event) {
                    let data = event.data;

                    if (data && data.substr(WIDGET_ID.length).startsWith('.readAll')) {
                        $window.removeEventListener('message', readListener);

                        let obj = JSON.parse(data.substr(WIDGET_ID.length + 9));
                        for (var key in obj) {
                            var value = obj[ key ];

                            // All incoming values are of type string!
                            // Replace "undefined" and "null" values with actual primitive/object value
                            switch (value) {
                                case 'undefined':
                                    value = undefined;
                                    break;
                                case 'null':
                                    value = null;
                                    break;
                                default:
                                    value = value.replace(/"/g, '');
                            }

                            $localStorage[ key ] = value;
                        }

                        console.log('Local storage is synchronized.');

                        def.resolve('SUCCESS');
                    }
                }, false);
            } else {
                console.warn('Could not synchronize cross origin starge with local storage.');
                def.resolve('NOT_SUPPORTED');
            }

            return def.promise;
        }

        function write(param, data) {
            if (testLocalStorage()) {
                // Always write also in actual local storage
                $localStorage[ param ] = data;
                console.log('Written to local storage ' + param + ' - ' + data);
                if (widgetInitialized) {
                    window.frames[ WIDGET_ID ].postMessage(WIDGET_ID + '.set.' + param + '.' + data, '*');
                } else {
                    console.warn('Cross origin storage has not been initialized and synced, loading \'' + param + '\' from local storage.');
                }


            } else {
                console.error('Could not write to local storage!');
            }
        }

        function read(param) {
            let data;

            if (testLocalStorage()) {
                if ($localStorage[ param ]) {
                    data = $localStorage[ param ];
                    console.log('Found \'' + param + '\' in local storage', data);
                }
            } else {
                console.error('Could not read from local storage!');
            }

            return data;
        }

        function increment(param) {
            let counter = read(param);
            counter = counter || 0;
            write(param, ++counter);
            return counter;
        }

        function testLocalStorage() {
            try {
                return window.localStorage;
            } catch (e) {
                return false;
            }
        }
    }
})();
