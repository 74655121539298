/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

myApp.controller('NewsCtrl', function($rootScope, $scope, $location, $translate, $http, $sce, $filter, SharedData) {
    $scope.sharedData = SharedData.data;



    $scope.getHtml = function(html) {
        return $sce.trustAsHtml(html);
    };

    $scope.goHome = function() {
		$location.path("#/?" + $scope.hid);
		$scope.sharedData.trackScreen("home", $scope.hotel.shortcut, $scope.tracker);

	};
    $scope.loadNews = function()
    {

        $scope.sharedData.trackScreen("news", $scope.hotel.shortcut, $scope.tracker);

        $scope.sharedData.sendLog("news", 0, $scope.sharedData.hotelID);


    };
});

