var showCookiePopup = function (text, button, privacy, url, declineText) {

    console.log('SHOWING COOKIE POPUP');
    if (!declineText) {
        window.cookieconsent.initialise({
            'palette': {
                'popup': {
                    'background': '#000'
                },
                'button': {
                    'background': '#f1d600'
                }
            },
            'theme': 'edgeless',
            'position': 'top',
            'content': {
                'message': text,
                'dismiss': button,
                'link': privacy,
                'href': url
            }
        });
    }
    else {
        window.cookieconsent.initialise({
            'palette': {
                'popup': {
                    'background': '#000'
                },
                'button': {
                    'background': '#f1d600'
                }
            },
            'theme': 'edgeless',
            'position': 'top',
            'type': 'opt-out',
            'content': {
                'message': text,
                'dismiss': button,
                'link': privacy,
                'href': url,
                'deny': declineText
            },

            onInitialise: function (status) {
                var type = this.options.type;
                var didConsent = this.hasConsented();
                if (type === 'opt-in' && didConsent) {
                    enableCookies();
                }
                if (type === 'opt-out' && !didConsent) {
                    disableCookies();
                }
            },

            onStatusChange: function (status, chosenBefore) {
                var type = this.options.type;
                var didConsent = this.hasConsented();
                if (type === 'opt-in' && didConsent) {
                    enableCookies();
                }
                if (type === 'opt-out' && !didConsent) {
                    disableCookies();
                }
            },

            onRevokeChoice: function () {
                var type = this.options.type;
                if (type === 'opt-in') {
                    disableCookies();
                }
                if (type === 'opt-out') {
                    enableCookies();
                }
            }

        });
    }
};

function enableCookies() {
    console.log('Enable Cookies');
}

function disableCookies() {
    console.log('Disable Cookies');
}
