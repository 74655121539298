var createUbimet = function (contentid) {
    console.log("UBIMET STARTS with id " + contentid);

    var oldScript = document.getElementById('ubiwidget-loader-script');
    if (oldScript)
        oldScript.parentNode.removeChild(oldScript);
    var script = document.createElement("script"),
            tag = document.getElementById('ubiwidget-script');
    script.id = 'ubiwidget-loader-script';
    script.src = "https://ubiwidget.ubimet.com/static/widgets/js/widget-script.18edc6571722.js";
    script.setAttribute('data-url', "https://ubiwidget.ubimet.com/widgets/content/" + contentid);
    script.setAttribute('data-styles', "https://ubiwidget.ubimet.com/static/widgets/css/300.721393053c06.css");
    tag.parentNode.insertBefore(script, tag);
};
