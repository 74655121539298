(function () {
    angular
        .module('Order4meClient')
        .factory('ChatWidget', ChatWidget);

    ChatWidget.$inject = ['$window', '$q', '$timeout', 'EventDispatcher'];

    function ChatWidget($window, $q, $timeout, EventDispatcher) {
        let widgetInitialized = false;
        let widgetEnabled = true;

        const chatWidget = {
            init: init,
            open: openWidget,
            isEnabled: isEnabled,
            changeLang: changeLang,
            logon: login,
            logout: logout,
            reloadHistory: reloadHistory
        };
        return chatWidget;

        function init(hotelId, isVisible, lang, forceLogin) {
            console.log("Initializing chat widget", hotelId, isVisible, lang, forceLogin);

            if (isVisible !== undefined)
                widgetEnabled = isVisible;

            $window.gustaffoChatAsyncInit = function () {
                $window.GustaffoChat.init({
                    hotelId: hotelId,
                    isVisible: widgetEnabled,
                    lang: lang,
                    forceLogin: forceLogin
                });

                $window.GustaffoChat.setUserCallback(userUpdated);

                widgetInitialized = true;
                EventDispatcher.dispatchAndLockEvent("widget-initialized");
            };

            // If chat widget is already loaded, call init function right away
            if ($window.GustaffoChat !== undefined) {
                $window.gustaffoChatAsyncInit();
            }
        }

        function openWidget() {
            $window.GustaffoChat.openWidget();
        }

        function isEnabled() {
            return widgetEnabled;
        }

        function changeLang(lang) {
            $window.GustaffoChat.changeLang(lang);
        }

        function userUpdated(user) {
            // User is null when login was canceled
            if (user !== null) {
                // The lock allows a "get-user-method" behaviour of this event
                EventDispatcher.dispatchAndLockEvent("widget-userchanged", user);
            }

            EventDispatcher.dispatchEvent("widget-finalizeloginlogout", user);
        }

        function login() {
            let def = $q.defer();

            function getLoginUserData(userData) {
                EventDispatcher.removeEventListener("widget-finalizeloginlogout", getLoginUserData);

                if (userData !== null) {
                    def.resolve(userData);
                } else {
                    def.reject();
                }
            }

            if (widgetInitialized) {
                EventDispatcher.addEventListener("widget-finalizeloginlogout", getLoginUserData.bind(this));
                $window.GustaffoChat.login();
            } else {
                def.reject();
            }

            return def.promise;
        }

        function logout() {
            let def = $q.defer();

            function getLogoutUser() {
                EventDispatcher.removeEventListener("widget-finalizeloginlogout", getLogoutUser);
                def.resolve();
            }

            if (widgetInitialized) {
                EventDispatcher.addEventListener("widget-finalizeloginlogout", getLogoutUser.bind(this));
                $window.GustaffoChat.logout();
            } else {
                def.reject();
            }

            return def.promise;
        }

        function reloadHistory() {
            $window.GustaffoChat.reloadHistory();
        }
    }
})();
