(function () {
    'use strict';

    angular
        .module('Order4meClient')
        .directive('compile', Compile);

    Compile.$inject = [ '$compile' ];

    function Compile($compile) {
        return {
            restrict: 'EA',
            link: link,
            controller: controller,
            scope: {
                content: '=',
                action: '&'
            }
        };

        // If the problem is an empty value, set the oldValue in a variable and only recompile (and set variable) is value is not empty.

        function link($scope, $element, $attrs) {
            $scope.$watch(
                function ($scope) {
                    return $scope.$eval($attrs.compile);
                },
                function (value) {
                    if (value !== null && value !== undefined && value !== '') {
                        $element.html(value);
                        $compile($element.contents())($scope);
                    }
                }
            );
        }

        function controller($scope) {
            $scope.name = $scope.content;
        }
    }
})();
