/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
function guid() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
               .toString(16)
               .substring(1);
}

function iOS() {
    var iDevices = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ];

    if (!!navigator.platform) {
        while (iDevices.length) {
            if (navigator.platform === iDevices.pop()) {
                return true;
            }
        }
    }

    return false;
}

function generateUsername() {
    var ret = '';
    var ua = navigator.userAgent;
    var checker = {
        iphone: ua.match(/iPhone/),
        ipad: ua.match(/iPad/),
        ipod: ua.match(/iPod/),
        blackberry: ua.match(/BlackBerry/),
        windowsphone: ua.match(/Windows Phone/),
        android: ua.match(/Android/)
    };
    var date = new Date();
    var dateString = showTwoDigits(date.getDate()) + ''
        + showTwoDigits(date.getMonth() + 1) + ''
        + date.getFullYear().toString().substring(2) + ''
        + showTwoDigits(date.getHours()) + ''
        + showTwoDigits(date.getMinutes()) + ''
        + showTwoDigits(date.getSeconds());

    function showTwoDigits(Value) {
        return (Value > 9) ? '' + Value : '0' + Value;
    }

    if (checker.android) {
        ret = 'AN_' + dateString;
    } else if (checker.iphone) {
        ret = 'IPO_' + dateString;
    } else if (checker.ipad) {
        ret = 'IPA_' + dateString;
    } else if (checker.ipod) {
        ret = 'IPOD_' + dateString;
    } else if (checker.blackberry) {
        ret = 'BLA_' + dateString;
    } else if (checker.windowsphone) {
        ret = 'WP_' + dateString;
    } else {
        ret = 'OT_' + dateString;
    }
    return ret;
}

myApp.factory('SharedData', function ($rootScope, $window, $timeout, $anchorScroll, PubNub, $sessionStorage, $location, $http, $translate, TranslationFactory, $uibModal, $geolocation, EventDispatcher, ChatWidget, LocalStorage, Sentry) {
    var clientData = {};
    var loadedGuestMaps = [];
    var loadedLists = [];
    var loadedHistory = {};
    var menuData = {};
    var newsData = {};
    var globalData = {};
    var serviceData = {};
    var messageCallbacks = [];
    var currentScreen = '';
    var splashScreen = {};
    var currentPosition = {};

    PubNub.init({
        subscribe_key: 'sub-c-251daa5c-aab9-11e2-821b-12313f022c90',
        ssl: ('https:' === document.location.protocol)
    });

    function putMessageCallback(id, callback) {
        messageCallbacks[ id ] = callback;
    }

    function toTop() {
        console.log('To Top');
        $window.scrollTo(0, 0);
    }

    function checkPubnub() {
        if (globalData.channelPrefix && clientData.userID) {
            var channel = globalData.channelPrefix + '_' + clientData.userID;
            console.log('Pubnub Channel found, it would be ' + channel);
            PubNub.ngSubscribe({channel: channel});

            $rootScope.$on(PubNub.ngMsgEv(channel), function (event, payload) {
                // payload contains message, channel, env...

                console.log(JSON.stringify(payload));
                refreshHistory(globalData.hotelID);
            });
        } else {
            console.log('Pubnub not yet received!' + globalData.channelPrefix + ' - ' + clientData.userID);
        }
    }

    function yesNoDialog(callback, $text) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'yesnoModal',
            controller: 'YesnoModalController',
            windowClass: 'yesnoModal',
            resolve: {
                param: function () {
                    var ret = {};
                    ret.text = $text;
                    return ret;
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            if (callback) {
                callback(true);
            }
        }, function () {
            if (callback) {
                callback(false);
            }
        });
    }

    function logonDialog(callback, location, logon, pwd) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'logonModal',
            controller: 'LogonModalController',
            windowClass: 'logonModal',
            resolve: {
                param: function () {
                    var ret = {};
                    ret.data = clientData;
                    return ret;
                },
                location: location,
                logon: logon,
                password: pwd
            }
        });
        modalInstance.result.then(function (result) {
            console.log('Modal closed with result \'' + result + '\'');

            if (result === 'chatWidgetLogin') {
                ChatWidget.logon().then(function () {
                    console.log('User logged in over chat-widget.');

                    if (callback) {
                        callback(true);
                    }
                }, function (reason) {
                    console.log('User canceled login over chat-widget.');
                    if (callback) {
                        callback(false);
                    }
                });
            } else {
                console.log('User logged in over modal.');
                if (callback) {
                    callback(true);
                }
            }
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
            if (callback) {
                callback(false);
            }
        });
    }

    function showLogon(callback, location, logon, pwd) {
        var bypassAuthentication = false;
        if (serviceData.currentService) {
            bypassAuthentication = serviceData.currentService.bypassAuthentication;
        }

        var logonOK = (clientData.lastname && clientData.lastname.length > 0) || bypassAuthentication;

        var bOK = checkLocation(location);

        if (logon && !logonOK) {
            bOK = false;
        } else if (pwd) {
            bOK = false;
        }

        if (bOK) {
            callback(true);
        } else {
            logonDialog(callback, location, logon, pwd);
        }
    }

    function checkLocation(location) {
        var bypassLocationCheck = false;

        if (serviceData.currentService) {
            bypassLocationCheck = serviceData.currentService.bypassLocationCheck;
        }

        var locationOK = globalData.ipok || globalData.locationOK || bypassLocationCheck;
        return location && locationOK;
    }

    function refreshHistory(hid) {
        if (ChatWidget.isEnabled()) {
            console.log('Sending history reload to chat widget ...');
            ChatWidget.reloadHistory();
        } else {
            console.log('Loading History ...');
            $http({
                method: 'POST',
                url: urlPrefix + '/history',
                headers: {'Content-Type': 'application/json'},
                data: {
                    hid: hid,
                    id: clientData.userID,
                    lang: TranslationFactory.getLanguage()
                }
            }).success(function (data) {
                // console.log("History done " + JSON.stringify(data));
                loadedHistory.chat = data.chat;
                loadedHistory.history = data.history;
                for (var x in messageCallbacks) {
                    console.log('Calling callback ' + x);
                    var callback = messageCallbacks[ x ];
                    callback('history');
                }
            });
        }
    }

    function refreshList(hid, lid) {
        console.log('loading list ' + lid + ' for hotel ' + hid);
        var requestData = {
            action: 'client',
            lang: TranslationFactory.getLanguage(),
            guestMapId: lid,
            hid: hid
        };

        $http({
            method: 'POST',
            url: urlPrefix + '/guestMapLetterData',
            headers: {'Content-Type': 'application/json'},
            data: requestData
        }).success(function (data) {
            loadedLists[ lid ] = data;
            console.log('loaded list ' + lid + ' for hotel ' + hid);
        });
    }

    function refreshGuestmaps(id) {
        $http({
            method: 'POST',
            url: urlPrefix + '/guestMapGroupData',
            headers: {'Content-Type': 'application/json'},
            data: {
                action: 'client',
                lang: TranslationFactory.getLanguage(),
                hid: id
            }
        }).success(function (data) {
            loadedGuestMaps.length = 0; // do not recreate as reference is used in return statement
            for (var i = 0; i < data.length; i++) {
                var obj = data[ i ];
                loadedGuestMaps.push(obj);
                refreshList(id, obj.id);
            }
        });
    }

    function refreshMenu(id) {
        $http({
            method: 'POST',
            url: urlPrefix + '/menu',
            headers: {'Content-Type': 'application/json'},
            data: {
                action: 'client',
                lang: TranslationFactory.getLanguage(),
                hid: id
            }
        }).success(function (data) {
            menuData.categories = data.categories;
            //menuData.roomServiceAllergenList = data.roomServiceAllergenList;
        });

        $http({
            method: 'POST',
            url: urlPrefix + '/allergen',
            headers: {'Content-Type': 'application/json'},
            data: {
                action: 'read',
                lang: TranslationFactory.getLanguage(),
                hid: id
            }
        }).then(function onSuccess(response) {
            menuData.roomServiceAllergenList = response.data;
        });
    }

    function refreshServices(id) {
        $http({
            method: 'POST',
            url: urlPrefix + '/serviceData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                action: 'client',
                lang: TranslationFactory.getLanguage(),
                hid: id
            }
        }).success(function (data) {
            serviceData.services = data.sort((a, b) => a.serviceOrder < b.serviceOrder ? a.serviceOrder > b.serviceOrder ? 1 : 0 : 0 );
        });
    }

    function refreshNews(id) {
        $http({
            method: 'POST',
            url: urlPrefix + '/listNews',
            headers: {'Content-Type': 'application/json'},
            data: {
                action: 'client',
                lang: TranslationFactory.getLanguage(),
                hid: id,
                all: false
            }
        }).success(function (data) {
            newsData.news = data;
        });
    }

    function refreshSplashScreen(id, mainMenu) {
        $http({
            method: 'POST',
            url: urlPrefix + '/splashScreenServlet',
            headers: {'Content-Type': 'application/json'},
            data: {
                action: 'client',
                hid: id,
                user: clientData.userID,
                lang: TranslationFactory.getLanguage()
            }
        }).then(function onSuccess(response) {
            if (response.data.hasOwnProperty('id')) {
                splashScreen.id = response.data.id;
                splashScreen.callToAction = response.data.callToAction;
                splashScreen.toHomeScreen = response.data.toHomeScreen;
                splashScreen.landscape = response.data.landscape;
                splashScreen.portrait = response.data.portrait;
                splashScreen.showButtons = response.data.showButtons;
                splashScreen.immediateSend = response.data.immediateSend;

                for (let i = 0; i < mainMenu.length; i++) {
                    if (mainMenu[ i ].menuid === response.data.menu) {
                        splashScreen.menu = angular.copy(mainMenu[ i ]);
                        break;
                    }
                }

                console.log('Splash screen loaded');
                if (splashScreen.menu === undefined) {
                    console.error('Could not find menu item of splash screen!');
                    splashScreen.show = false;
                } else {
                    // Preload splash screen images
                    var images = [];

                    function preload() {
                        for (i = 0; i < preload.arguments.length; i++) {
                            images[ i ] = new Image();
                            images[ i ].src = preload.arguments[ i ];
                        }
                    }

                    preload(
                        splashScreen.landscape,
                        splashScreen.portrait
                    );

                    // Show splash screen
                    splashScreen.show = true;
                }
            } else {
                console.log('No splash screen found');
                splashScreen.show = false;
            }
        });
    }

    function doSelectItem(item) {
        if (item.type === 'service') {
            console.log('Item type service');
            doSelectService(item);
        }
        if (item.type === 'guestmap') {
            console.log('Item type guestmap');
            for (var i = 0; i < loadedGuestMaps.length; i++) {
                if (loadedGuestMaps[ i ].id === item.id) {
                    console.log('Guestmap with id ' + item.id + ' selected');
                    menuData.currentGuestmap = loadedGuestMaps[ i ];
                    if (item.trackingcode !== undefined) {
                        menuData.currentGuestmap.trackingcode = item.trackingcode;
                    } else {
                        menuData.currentGuestmap.trackingcode = '';
                    }
                }
            }
        }
    }

    function doSelectService(service) {
        for (var i = 0; i < serviceData.services.length; i++) {
            if (serviceData.services[ i ].id === service.id) {
                console.log('Service with id ' + service.id + ' selected', serviceData.services[ i ]);
                serviceData.currentService = serviceData.services[ i ];
                if (service.trackingcode !== undefined) {
                    serviceData.currentService.trackingcode = service.trackingcode;
                } else {
                    serviceData.currentService.trackingcode = '';
                }
            }
        }
    }

    function doSendPushRegister(token) {
        function runPushRegister(token) {
            EventDispatcher.removeEventListener('userSynced', runPushRegister);

            $http.post(
                urlPrefix + '/pushRegister',
                {uid: clientData.userID, token: token, backend: false, hotel: globalData.hotelID},
                {'Content-Type': 'application/json; charset=UTF-8'}
            ).success(function (data) {
                // nothing to to yet at log success
            });
        }

        EventDispatcher.addEventListener('userSynced', runPushRegister.bind(this, token));
    }

    function contentPath(type, hid, callback) {
        $http({
            method: 'POST',
            url: urlPrefix + '/contentLink',
            headers: {'Content-Type': 'application/json'},
            data: {type: type, user: clientData.userID, hotel: hid, lang: TranslationFactory.getLanguage()}
        }).success(function (data) {
            callback(data.link);
        });
    }

    function doSendLog(page, id, hotel) {
        $http({
            method: 'POST',
            url: urlPrefix + '/log',
            headers: {'Content-Type': 'application/json'},
            data: {action: page, target: id, lang: TranslationFactory.getLanguage(), uid: clientData.userID, hid: hotel}
        }).success(function (data) {
            // nothing to to yet at log success
        });
    }

    function refreshHotel(id, $callback) {
        console.log('LOADING HOTEL...' + id);

        $http({
            method: 'POST',
            url: urlPrefix + '/wirtData',
            headers: {'Content-Type': 'application/json'},
            data: {action: 'client', id: id, lang: TranslationFactory.getLanguage()}
        }).success(function (data) {
           
            
            if(data.wirt.deleted==true) {
            		$window.location.replace("https://www.gustaffo.com");
            } else  {
            	 if (typeof data.wirt.languages === 'undefined') {
                     data.wirt.languages = [];
                 }

                // language that comes from Server from /wirtData request needs to be used initially for client!
                TranslationFactory.use(data.language, id);
                console.log('Language to be used is ' + $translate.use());

                console.log('Loading Guest Map data');
                refreshGuestmaps(id);
                refreshMenu(id);
                refreshNews(id);
                refreshServices(id);
                globalData.channelPrefix = data.channelPrefix;
                globalData.ipok = data.ipok;
                globalData.alwaysShowMessage = data.alwaysShowMessage;
                globalData.orderpay = data.orderpay;
                globalData.homePath = data.homePath;

                globalData.locationOK = false;
                globalData.hotelID = id;
                globalData.defaultRoom = data.defaultRoom ? data.defaultRoom : '';
                globalData.defaultName = data.defaultName ? data.defaultName : '';
                globalData.expandMenu = data.expandMenu;
                globalData.poweredBy = data.poweredBy;
                globalData.chargeMin = data.wirt.roomServiceFeeMin;
                globalData.charge = data.wirt.roomServiceFee;
                globalData.gastro = (data.wirt.domain === 2);
                globalData.proximityAllowed = data.wirt.proximityAllowed;
                globalData.wirt = data.wirt;
                globalData.version = data.version;
                $callback(data);
                checkPubnub();

                let widgetForceLogon = (globalData.wirt.hasOwnProperty('widgetForceAuthentication')) ? globalData.wirt.widgetForceAuthentication : false;

                EventDispatcher.addEventListener('widget-userchanged', syncUser.bind(this));

                if (!data.wirt.chatWidgetInHotelOnly || (data.wirt.chatWidgetInHotelOnly && checkLocation(true))) {
                    ChatWidget.init(globalData.wirt.id, globalData.alwaysShowMessage, data.language, widgetForceLogon);
                } else {
                    getCurrentPosition(globalData.wirt.id, function(success) {
                        if (success) {
                            ChatWidget.init(globalData.wirt.id, globalData.alwaysShowMessage, data.language, widgetForceLogon);
                        }
                    })
                }

                activeLanguageCallback(ChatWidget.changeLang);
                EventDispatcher.dispatchAndLockEvent('hotel-loaded');
            }
        });
    }

    function initUser($callback) {
        function runInitUser() {
            EventDispatcher.removeEventListener('userSynced', runInitUser);

            clientData.pubNubChannel = 'PUBNUB_' + clientData.userID;
            checkPubnub();

            $callback(clientData);
        }

        EventDispatcher.addEventListener('userSynced', runInitUser.bind(this));
    }

    function syncUser(user) {
        console.log('Received user update from chat widget', user, clientData);

        $timeout(function () {
            clientData.clientUUID = user.clientUUID;
            clientData.userID = user.userID;
            clientData.lastname = user.lastname;
            clientData.lastroom = user.lastroom;
            clientData.forceLogonRoom = user.forceLogonRoom;
            clientData.forceLogonName = user.forceLogonName;
            clientData.forceStartPage = user.forceStartPage;

            LocalStorage.sync();

            EventDispatcher.dispatchAndLockEvent('userSynced');
        }, 0);
    }

    function trackTheScreen(screen, shortcut, tracker)//TODO finish tracker
    {
        console.log('changing screen from ' + globalData.currentScreen + ' to ' + screen);
        if (tracker != null && tracker !== undefined) {
            ga(tracker.aggregatedTracker + '.send', 'pageview', {
                'page': '/' + screen,
                'title': screen
            });
            ga(tracker.granularTracker + '.send', 'pageview', {
                'page': '/' + shortcut + '/' + screen,
                'title': screen
            });
            if (tracker.clientTracker != null && tracker.clientTracker !== '') {
                ga(tracker.clientTracker + '.send', 'pageview', {
                    'page': '/' + shortcut + '/' + screen,
                    'title': screen
                });
            }
        } else {
            ga('send', 'pageview', {
                'page': '/' + screen,
                'title': screen
            });
        }
        globalData.currentScreen = screen;
    }

    function checkNearbyHotels(id, callback) {

        if (LocalStorage.read('userID')) {
            clientData.userID = LocalStorage.read('userID');
            console.log('userID found in local storage:' + clientData.userID);
        }


        var cameBack = false;

        $geolocation.getCurrentPosition({
            timeout: 15000
        }).then(function (position) {
            cameBack = true;
            console.log(position);
            currentPosition.latitude = position.coords.latitude;
            currentPosition.longitude = position.coords.longitude;

            $http({
                method: 'POST',
                url: urlPrefix + '/checkDistance',
                headers: {'Content-Type': 'application/json'},
                data: {
                    domainid: id,
                    wid: 0,
                    uid: clientData.userID,
                    lat: currentPosition.latitude,
                    lon: currentPosition.longitude,
                    uid: clientData.userID
                }
            }).success(function (data) {
                callback(data);
            });
        }, function (positionError) {
            cameBack = true;
            console.log(positionError);
            callback(null);
        });

        var noGeoFound = function () {
            if (!cameBack) {
                console.log('NO Geolocation found after timeout');
                callback(null);
            }
        };

        $timeout(noGeoFound, 15000);
    }

    function getCurrentPosition(hotel, callback) {
        var cameBack = false;

        $geolocation.getCurrentPosition({
            timeout: 15000
        }).then(function (position) {
            console.log(position);
            currentPosition.latitude = position.coords.latitude;
            currentPosition.longitude = position.coords.longitude;

            $http({
                method: 'POST',
                url: urlPrefix + '/checkDistance',
                headers: {'Content-Type': 'application/json'},
                data: {
                    wid: hotel,
                    uid: clientData.userID,
                    lat: currentPosition.latitude,
                    lon: currentPosition.longitude
                }
            }).success(function (data) {
                cameBack = true;

                if (data.success && data.distok) {
                    globalData.locationOK = true;
                    callback(true, currentPosition.latitude, currentPosition.longitude);
                } else {
                    callback(false, currentPosition.latitude, currentPosition.longitude);
                    globalData.locationOK = false;
                }
            });

        }, function (positionError) {
            cameBack = true;
            console.log(positionError);
            callback(false, 0, 0);
        });

        var noGeoFound = function () {
            if (!cameBack) {
                console.log('NO Geolocation found after timeout');
                callback(false, 0, 0);
            }
        };

        $timeout(noGeoFound, 15000);
    }

    var loadAutomaticUser = function(hotelNumber, roomNumber, clientId, callback) {
        var requestData = {
            rn: roomNumber,
            clientId: clientId,
            hid: hotelNumber
        };

        $http({
            method: 'POST',
            url: urlPrefix + '/autoClientLogOn',
            headers: {'Content-Type': 'application/json'},
            data: requestData
        }).success(function (data) {
            callback(data)
        });
    }

    function showMessages() {
        if (globalData.alwaysShowMessage) {
            trackTheScreen('home', globalData.wirt.shortcut, null);
            $location.path('#/');
            ChatWidget.open();
        } else {
            $location.path('/send');
        }
    }

    function activeLanguageCallback(callback) {
        EventDispatcher.addEventListener('lang-changed', callback.bind(this));
    }

    function getRandomId() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 12; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }


    return {
        data: {
            hotelId: 0,
            clientHistory: loadedHistory,
            uuid: '',
            lists: loadedLists,
            guestmaps: loadedGuestMaps,
            user: clientData,
            menu: menuData,
            services: serviceData,
            news: newsData,
            currency: 'EUR',
            currentScreen: currentScreen,
            globals: globalData,
            currentParent: 0,
            gpsPos: currentPosition,
            splashScreen: splashScreen,
            trackScreen: function (screen, shortcut, tracker) {
                trackTheScreen(screen, shortcut, tracker);
            },
            loadHotel: function (id, $callback) {
                refreshHotel(id, $callback);
            },
            initUser: function ($callback) {
                initUser($callback);
            },
            scrollTop: function () {
                toTop();
            },
            sendLog: function (page, id, hotel) {
                doSendLog(page, id, hotel);
            },
            loadHistory: function (hotel) {
                refreshHistory(hotel);
            },
            showLogonDialog: function (callback, location, logon, pwd) {
                showLogon(callback, location, logon, pwd);
            },
            sendPushRegister: function (token) {
                EventDispatcher.addEventListener('hotel-loaded', doSendPushRegister.bind(this, token));
                // doSendPushRegister(token);
            },
            showYesnoDialog: function (callback, text) {
                yesNoDialog(callback, text);
            },
            addMessageCallback: function (id, callback) {
                putMessageCallback(id, callback);
            },
            selectItem: function (item) {
                doSelectItem(item);
            },
            selectService: function (service) {
                doSelectService(service);
            },
            showLogonDialogForce: function (callback, location, logon, pwd) {
                logonDialog(callback, location, logon, pwd);
            },
            getPosition: function (hotel, callback) {
                getCurrentPosition(hotel, callback);
            },
            checkNearby: function (id, callback) {
                checkNearbyHotels(id, callback);
            },
            getContentPath: function (type, hid, callback) {
                contentPath(type, hid, callback);
            },
            loadSplashScreen: function (id, mainMenu) {
                refreshSplashScreen(id, mainMenu);
            },
            showMessages: function () {
                showMessages();
            },
            activeLanguageCallback: function (callback) {
                activeLanguageCallback(callback);
            },
            getRandomId: function () {
                return getRandomId();
            },
            checkLocation: function(location) {
                return checkLocation(location)
            },
            loadAutomaticUser: function(hotelNumber, roomNumber, clientId, callback) {
                loadAutomaticUser(hotelNumber, roomNumber, clientId, callback)
            }
        }
        // Other methods or objects can go here
    };
});
