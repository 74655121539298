(function () {
    angular
        .module('Order4meClient')
        .factory('Calendar', Calendar);

    Calendar.$inject = [];

    function Calendar() {
        return {
            days: getFilledArray(31),
            months: getAvailableMonths(),
            years: getAvailableYears(),
            selectedDay: 1,
            selectedMonth: getAvailableMonths()[ 0 ],
            selectedYear: getAvailableYears()[ 0 ],
            changedMonth: changedMonth,
            changedYear: changedYear,
            selectDateByReservationDate: selectDateByReservationDate,
            getInReservationDateForm: getInReservationDateForm
        };

        function getAvailableMonths() {
            return [ 'MONTH_JANUARY', 'MONTH_FEBRUARY', 'MONTH_MARCH',
                'MONTH_APRIL', 'MONTH_MAY', 'MONTH_JUNE',
                'MONTH_JULY', 'MONTH_AUGUST', 'MONTH_SEPTEMBER',
                'MONTH_OCTOBER', 'MONTH_NOVEMBER', 'MONTH_DECEMBER'
            ];
        }

        function getAvailableYears() {
            const range = 100;
            const thisYear = new Date().getFullYear();
            return getFilledArrayWithOffset(range, thisYear - range + 1);
        }

        function getFilledArray(length) {
            return getFilledArrayWithOffset(length, 1);
        }

        function getFilledArrayWithOffset(length, offset) {
            return Array.apply(null, Array(length)).map(function (_, i) {
                return i + offset;
            });
        }

        function changedMonth(item) {
            if (item && this.selectedYear) {
                let n_days;

                switch (item) {
                    case 'MONTH_JANUARY':
                    case 'MONTH_MARCH':
                    case 'MONTH_MAY':
                    case 'MONTH_JULY':
                    case 'MONTH_AUGUST':
                    case 'MONTH_OCTOBER':
                    case 'MONTH_DECEMBER':
                        n_days = 31;
                        break;
                    case 'MONTH_APRIL':
                    case 'MONTH_JUNE':
                    case 'MONTH_SEPTEMBER':
                    case 'MONTH_NOVEMBER':
                        n_days = 30;
                        break;
                    case 'MONTH_FEBRUARY':
                        n_days = isLeapYear(this.selectedYear) ? 29 : 28;
                        break;
                }

                days = getFilledArray(n_days);
            }
        }

        function changedYear(item) {
            if (this.selectedYear === 'MONTH_FEBRUARY') {
                if (isLeapYear(item)) {
                    days = getFilledArray(29);
                } else {
                    days = getFilledArray(28);
                }
            }
        }

        function isLeapYear(year) {
            return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
        }

        function selectDateByReservationDate(reservationDate) {
            if (reservationDate == null) {
                return;
            }
            
            const dateArr = reservationDate.split('-');

            this.selectedYear = parseInt(dateArr[ 2 ]);
            changedYear(this.selectedYear);

            this.selectedMonth = this.months[ parseInt(dateArr[ 1 ]) - 1 ];
            changedMonth(this.selectedMonth);

            this.selectedDay = parseInt(dateArr[ 0 ]);
        }

        function getInReservationDateForm() {
            return pad2(this.selectedDay) + '-' + pad2(this.months.indexOf(this.selectedMonth) + 1) + '-' + this.selectedYear;
        }

        function pad2(number) {
            return (number < 10 ? '0' : '') + number;
        }
    }
})();
