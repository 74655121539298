function getParameter(paramName) {
    var searchString = window.location.search.substring(1),
            i, val, params = searchString.split("&");

    for (i = 0; i < params.length; i++) {
        val = params[i].split("=");
        if (val[0] == paramName) {
            return val[1];
        }
    }
    return null;
}

function getBase64Image(img) {
    // Create an empty canvas element
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    // Copy the image contents to the canvas
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Get the data-URL formatted image
    // Firefox supports PNG and JPEG. You could check img.src to
    // guess the original format, but be aware the using "image/jpg"
    // will re-encode the image.
    var dataURL = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

function reportAngular(event)
{
    var hot = angular.element(document.getElementById('mainBody')).scope().sharedData.hotelID;
    console.log("Reporting " + event + " Hotel would be " + hot);
    angular.element(document.getElementById('mainBody')).scope().sharedData.sendLog(event, 0, hot);
}
;
function urlB64ToUint8Array(base64String) {
    padding = '='.repeat((4 - base64String.length % 4) % 4);
    base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
    rawData = window.atob(base64);
    outputArray = new Uint8Array(rawData.length);
    for (i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function is_touch_device() {
    return 'ontouchstart' in window // works on most browsers
            || navigator.maxTouchPoints; // works on IE10/11 and
    // Surface
}
;
var touch = is_touch_device();

if (touch) { // remove all :hover stylesheets
    console.log("this is a touch device");
    try { // prevent crash on browsers not supporting DOM styleSheets
        // properly
        for (var si in document.styleSheets) {
            var styleSheet = document.styleSheets[si];
            if (!styleSheet.rules)
                continue;
            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                if (!styleSheet.rules[ri].selectorText)
                    continue;
                if (styleSheet.rules[ri].selectorText.match(':hover')) {
                    console.log("deleting hover " + ri);
                    styleSheet.deleteRule(ri);
                }
            }
        }
    } catch (ex) {
    }
} else {
    console.log("NO Touch device");
}
