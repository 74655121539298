 /* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

myApp.controller('HistoryCtrl', function($rootScope, $scope, $location, $translate, $http, $sce, $filter, SharedData) {
    $scope.sharedData = SharedData.data;


    $scope.history = [];

    $scope.goHome = function() {
		$location.path("#/?" + $scope.hid);
		$scope.sharedData.trackScreen("home", $scope.hotel.shortcut, $scope.tracker);

	};
  
});

