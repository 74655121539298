myApp.config(function ($translateProvider) {
    $translateProvider.translations('sk', {
        ADDRESS_HEADER: 'Adresa',
        ARRIVAL_LABEL: 'Príchod',
        ASK_FOR_CHECK_OUT_LABEL: 'Môžete pripraviť Vaše fakturačné údaje vopred.',
        BACK: 'späť',
        CHANNELS_LABEL: 'channel',
        CHARGE: 'Poplatok za službu',
        CHECK_IN_BUTTON: 'Potvrdiť Check-in',
        CHECK_IN_CARD_INFO_LABEL: 'Žiadam Vás o vyplnenie celého formulára.',
        CHECK_IN_CARD_LABEL: 'Osobné informácie',
        CHECK_IN_FINISH_THANK_YOU_LABEL: 'Ďakujeme Vám! Fakturačné údaje boli uložené. Kľúč od izby si môžete pri príchode vyzdvihnúť na recepcii. Šťastnú cestu',
        CHECK_IN_THANK_YOU_LABEL: 'Ďakujeme za Vaše prihlásenie online',
        CHECK_OUT_BUTTON: 'Uložiť fakturačnú adresu',
        CHECK_OUT_CARD_INFO_LABEL: 'Prajete si inú fakturačnú adresu?',
        CHECK_OUT_CARD_LABEL: 'Fakturačná adresa',
        CHECK_OUT_THANK_YOU_LABEL: 'Ďakujeme Vám! Fakturačné údaje boli uložené. Kľúč od izby si môžete pri príchode vyzdvihnúť na recepcii. Šťastnú cestu.',
        CITY_LABEL: 'Mesto',
        COMMENT: 'Komentár...',
        COMPANY_NAME1_LABEL: 'Názov firmy',
        COMPANY_NAME2_LABEL: 'Názov firmy',
        COUNTRY_LABEL: 'Krajina',
        DATE_OF_BIRTH_LABEL: 'Dátum narodenia',
        DEPARTURE_LABEL: 'Odchod',
        DO_CHECK_OUT_BUTTON: 'Pripravit fakturačné údaje',
        EMAIL_LABEL: 'E-mail',
        ERROR_MESSAGE: 'Chybná správa',
        FINISH_CHECK_IN_BUTTON: 'Nie teraz',
        FIRST_NAME_LABEL: 'Meno',
        INPUT_PROMPT_COMMENT: 'Komentár',
        LANGUAGE_LABEL: 'jazyk',
        LAST_NAME_LABEL: 'Priezvisko',
        LOADING_LABEL: 'Nahrávanie',
        LOGOFFDIALOG: 'Chcete odhlásiť?',
        MANDATORY_FIELD: '* Povinné ',
        MESSAGE_TO_RECEPTION: 'Kontaktujte recepciu',
        MONTH_APRIL: 'Apríl',
        MONTH_AUGUST: 'August',
        MONTH_DECEMBER: 'December',
        MONTH_FEBRUARY: 'Február',
        MONTH_JANUARY: 'Január',
        MONTH_JULY: 'Júl',
        MONTH_JUNE: 'Jún',
        MONTH_MARCH: 'Marec',
        MONTH_MAY: 'Máj',
        MONTH_NOVEMBER: 'November',
        MONTH_OCTOBER: 'Október',
        MONTH_SEPTEMBER: 'September',
        NATIONALITY_LABEL: 'Národnosť',
        NAV_HOME: 'Domov',
        NAV_MOBILE_CHECK_IN: 'Mobilný Check-in',
        NO_DATA_FOUND: 'Žiadne detaily rezervácie nie sú dostupné. Prosím skontrolujte informácie.',
        NO_ROOM: 'Izba nenájdená',
        NO: 'Nie',
        ORDER: 'Objednávka',
        PASSPORT_LABEL: 'Číslo pasu',
        PLEASE_ENTER: 'Prosím , zadajte svoje číslo izby a meno',
        PRICE_LABEL: 'cena',
        PROCEED: 'Pokračovať',
        REPLY: 'Odpovedať',
        RESERVATION_COMMENT: 'Poznámka',
        ROOM_NAME: 'Názov',
        ROOM_NUMBER: 'Izba',
        SALUTATION_LABEL: 'nadpis',
        SEARCH: 'Hľadanie',
        SEND_MESSAGE: 'Odoslať',
        STREET_LABEL: 'Adresa',
        STREET_LINE1_LABEL: 'Ulica (Číslo domu 1)',
        STREET_LINE2_LABEL: 'Ulica (Číslo domu 2)',
        USE_CREDIT_CARD_LABEL: 'Použiť nasledujúcu kreditnú kartu',
        USE_PRIVATE_ADDRESS_LABEL: 'Použiť súkromú adresu?',
        VALUE_LABEL: 'hodnota',
        YES: 'Áno',
        YOUR_MESSAGE: 'Vaša spáva',
        YOUR_ORDER: 'objednávka',
        ZIP_CODE_LABEL: 'PSČ',
        // checkout
        ACCEPT_AGB: '*<a class="a_orig" href=\'{{agb}}\' target=\'_blank\'>Všeobecné podmienky a požiadavky</a> som si prečítal(a) a súhlasím s nimi.&nbsp;&nbsp;',
        CANCEL_LABEL: 'Zrušiť',
        CHECK_OUT_VALIDATION: 'Potvrďte svoju totožnosť',
        CHECKOUT: 'Check-out',
        CHECKOUT_DONE_MESSAGE: 'Ste takmer na konci! Kľúč odovzdajte na recepcii.',
        CHECKOUT_DONE_MESSAGE_WITH_INVOICE: 'Ste takmer na konci! Vaša faktúra bude zaslaná e-mailom. Kľúč odovzdajte na recepcii.',
        CHECKINOUT_ERROR_MESSAGE: 'Stala sa nejaká chyba. Niečo sa nepodarilo. Choďte na recepciu a o ostatné sa postará tím.',
        CONSUMATE_QUESTION: 'Konzumovali ste niečo z minibaru? Pridajte položky, prosím.',
        EDIT_LABEL: 'Upraviť',
        FOLIO_DETAILS: 'Fakturačné údaje',
        FOLIO_SUM: 'Suma',
        INVOICE_ADDRESS: 'Fakturačná adresa',
        INVOICE_INFORMATION: 'Informácie o fakturačných údajoch. Služba check-out bude dostupná v deň odchodu.',
        INVOICE_MESSAGE_ON_CONDITION: 'Všetky poplatky sú zahrnuté. Podlieha možnosti zmeny.',
        ITEM_DESCRIPTION_LABEL: 'Položka',
        ITEM_QUANTITY_LABEL: 'Množstvo',
        ITEM_TRANSACTION_DATE_LABEL: 'Dátum transakcie',
        MANDATORY_MAIL_MESSAGE: 'Uveďte svoju e-mailovú adresu na účely fakturácie.',
        NOT_FOUND_NAME_ROOM_DIALOG: 'Stala sa nejaká chyba. Niečo sa nepodarilo. Skúste znova alebo kontaktujte recepciu.',
        PAY_DIFFERENCE: 'Uhradiť teraz',
        PAYED_AMOUNT: 'Uhradená čiastka',
        PAYMENT_FAILED_MESSAGE: 'Stala sa nejaká chyba. Niečo sa nepodarilo. Zopakujte proces platby.',
        PAYMENT_SUCCESS_MESSAGE: 'Prebehlo úspešne! Ďalším krokom je konečný check-out!',
        PROCESS_CART: 'Zaúčtovať položky',
        REMAINING_AMOUNT: 'Neuhradená čiastka',
        REQUIRED_LABEL: 'Toto pole nesmie zostať nevyplnené!',
        ROOM_NUMBER_CHECKOUT: 'Č. izby',
        SAVE_LABEL: 'Uložiť',
        SKIP_CART: 'Preskočiť položky z minibaru',
        SUBMIT_CONFIRMATION_MESSAGE: 'Naozaj chcete odoslať?',
        SUBMIT_LABEL: 'Zaúčtovať položky',
        SUM_AMOUNT: 'Celková čiastka',
        YOUR_ORDER_CHECKOUT: 'Vaša konzumácia'
    });
});
