myApp.config(function ($translateProvider) {
    $translateProvider.translations('it', {
        ADDRESS_HEADER: 'Indirizzo ',
        ARRIVAL_LABEL: 'Arrivo',
        ASK_FOR_CHECK_OUT_LABEL: 'Impostare già ora i dati di fatturazione',
        BACK: 'Indietro ',
        CHANNELS_LABEL: 'Canale',
        CHARGE: 'Quote di servizio ',
        CHECK_IN_BUTTON: 'Effettuare check-in!',
        CHECK_IN_CARD_INFO_LABEL: 'Per favore compilare tutto il modulo ',
        CHECK_IN_CARD_LABEL: 'Informazioni personali ',
        CHECK_IN_FINISH_THANK_YOU_LABEL: 'Grazie! Potete ritirare la chiave quando arrivate alla reception. Buon viaggio',
        CHECK_IN_THANK_YOU_LABEL: 'Grazie per aver effettuato il check-in online.',
        CHECK_OUT_BUTTON: 'Salva indirizzo di fatturazione',
        CHECK_OUT_CARD_INFO_LABEL: 'Volete un indirizzo di fatturazione diverso?',
        CHECK_OUT_CARD_LABEL: 'Indirizzo di fatturazione',
        CHECK_OUT_THANK_YOU_LABEL: 'Grazie! I dati per la fatturazione sono salvati. Potete ritirare la chiave quando arrivate alla reception. Buon viaggio.',
        CITY_LABEL: 'Città',
        COMMENT: 'Commento ...',
        COMPANY_NAME1_LABEL: 'Denominazione azienda',
        COMPANY_NAME2_LABEL: 'Denominazione azienda',
        COUNTRY_LABEL: 'Nazione',
        DATE_OF_BIRTH_LABEL: 'Data di nascita',
        DEPARTURE_LABEL: 'Partenza',
        DO_CHECK_OUT_BUTTON: 'Impostare i dati di fatturazione',
        EMAIL_LABEL: 'E-mail',
        ERROR_MESSAGE: 'Errore ',
        FINISH_CHECK_IN_BUTTON: 'Non ora',
        FIRST_NAME_LABEL: 'Nome',
        INPUT_PROMPT_COMMENT: 'Commento ',
        LANGUAGE_LABEL: 'Lingua ',
        LAST_NAME_LABEL: 'Cognome',
        LOADING_LABEL: 'Caricando….',
        LOGOFFDIALOG: 'Confermare log-out?',
        MANDATORY_FIELD: '*Campo obbligatorio ',
        MESSAGE_TO_RECEPTION: 'Messaggio alla reception ',
        MONTH_APRIL: 'Aprile',
        MONTH_AUGUST: 'Agosto',
        MONTH_DECEMBER: 'Dicembre',
        MONTH_FEBRUARY: 'Febbraio ',
        MONTH_JANUARY: 'Gennaio ',
        MONTH_JULY: 'Luglio ',
        MONTH_JUNE: 'Giugno ',
        MONTH_MARCH: 'Marzo ',
        MONTH_MAY: 'Maggio ',
        MONTH_NOVEMBER: 'Novembre',
        MONTH_OCTOBER: 'Ottobre',
        MONTH_SEPTEMBER: 'Settembre',
        NATIONALITY_LABEL: 'Cittadinanza',
        NAV_HOME: 'Start',
        NAV_MOBILE_CHECK_IN: 'Mobile check in',
        NO_DATA_FOUND: 'Non vi è alcuna prenotazione. Si prega di verificare i dati.',
        NO_ROOM: 'Camera non trovata!',
        NO: 'No',
        ORDER: 'ordinare',
        PASSPORT_LABEL: 'Passaporto Nr.',
        PLEASE_ENTER: 'Per favore inserire numero camera e nome',
        PRICE_LABEL: 'Prezzo ',
        PROCEED: 'procedi',
        REPLY: 'Rispondi',
        RESERVATION_COMMENT: 'Annotazioni',
        ROOM_NAME: 'Nome',
        ROOM_NUMBER: 'Camera',
        SALUTATION_LABEL: 'Titolo ',
        SEARCH: 'Cerca….',
        SEND_MESSAGE: 'Invia',
        STREET_LABEL: 'Via',
        STREET_LINE1_LABEL: 'Via (riga 1)',
        STREET_LINE2_LABEL: 'Via (riga 2)',
        USE_CREDIT_CARD_LABEL: 'Utilizzare la seguente carta di credito',
        USE_PRIVATE_ADDRESS_LABEL: 'Usare indirizzo privato ?',
        VALUE_LABEL: 'Valore',
        YES: 'Si',
        YOUR_MESSAGE: 'Il vs. messaggio',
        YOUR_ORDER: 'Il vs. ordine',
        ZIP_CODE_LABEL: 'CAP'
    });
});
