(function() {
    'use strict';

    myApp.directive('o4mTriggerBtnEnter', TriggerBtnEnter);
    
    TriggerBtnEnter.$inject = [];

    function TriggerBtnEnter() {
        var directive = {
            restrict: 'A',
            link: link,
            scope: true
        };
        return directive;


        function link($scope, $element, $attrs) {
            
            $element.bind("keydown keypress", function (event) {
                console.log("KEYDOWN");
                if(event.which === 13) {
                    $scope.$apply(function (){
                        console.log("FIRE");
                        $scope.$eval($attrs.cbEnter);
                    });

                    event.preventDefault();
                }
            });
        }
    }
})();