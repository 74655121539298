(function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o), m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

ga('create', 'UA-59807234-3', 'auto', 'aggregatedTracker');
ga('create', 'UA-59807234-4', 'auto', 'granularTracker');
// ga('aggregatedTracker.send', 'pageview');
ga(function (tracker) {
    // Logs the tracker created above to the console.
    console.log(tracker);
});
