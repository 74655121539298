(function () {
    angular
        .module('Order4meClient')
        .directive('splashScreen', splashScreen);

    splashScreen.$inject = [ '$window', '$translate' ];

    function splashScreen($window, $translate) {
        return {
            restrict: 'A',
            link: link,
            scope: {
                data: '=',
                callback: '&'
            },
            template:
            '<div class=\'splashScreenBtnBox\' ng-show=\'data.showButtons\'>' +
            '<!-- CUSTOMIZE BUTTON WITH CSS-CLASS \'splashScreenActionBtn_custom\' -->' +
            '<button type=\'text\' class=\'splashScreenBtn splashScreenActionBtn_custom\' ng-click=\'toMenu()\'>{{data.callToAction}}</button>' +
            '<!-- CUSTOMIZE BUTTON WITH CSS-CLASS \'splashScreenBackBtn_custom\' -->' +
            '<button type=\'text\' class=\'splashScreenBtn splashScreenBackBtn_custom\' ng-click=\'back()\'>{{data.toHomeScreen}}</button></div>' +
            '<!-- CUSTOMIZE BUTTON WITH CSS-CLASS \'splashScreenCloseBtn_custom\' -->' +
            '<button type=\'button\' class=\'close splashScreenCloseBtn splashScreenCloseBtn_custom\' ng-hide=\'data.showButtons\' ng-click=\'back()\'><span aria-hidden=\'true\'>&times;</span></button>' +
            '<div class=\'close splashScreenClickableBg\' ng-hide=\'data.showButtons\' ng-click=\'toMenu()\'></div>'
        };

        function link($scope, $element, $attrs) {
            var landscape, portrait;

            $scope.menu = undefined;
            $scope.invoke = false;

            $element.css({
                'height': '100%',
                'width': '100%',
                'color': 'black'
            });

            $scope.toMenu = function () {
                $scope.callback({item: $scope.menu, invoke: $scope.invoke});
                $scope.back();
            };

            $scope.back = function () {
                $scope.data.show = false;
            };

            function setBackground() {
                if (landscape === undefined || portrait === undefined) {
                    return;
                }

                let img;
                if ($window.matchMedia('(orientation:portrait)').matches) {
                    img = portrait;
                } else {
                    img = landscape;
                }

                $element.css({
                    'background-image': 'url(' + urlPrefix + '/serveAsset?id=' + img + ')',
                    'background-size': 'cover'
                });
            }

            angular.element($window).bind('orientationchange load resize', function () {
                setBackground();
            });

            $scope.$watch('data', function (newData) {
                if (angular.equals({}, newData)) {
                    return;
                }

                $scope.menu = newData.menu;
                $scope.invoke = newData.immediateSend;

                landscape = newData.landscape;
                portrait = newData.portrait;

                newData.toHomeScreen = (newData.toHomeScreen !== undefined && newData.toHomeScreen.length > 0) ? newData.toHomeScreen : $translate.instant('SPLASHSCREEN_BACK');

                setBackground();
            }, true);
        }
    }
})();
