myApp.controller('externalPageModalController', function ($scope, $uibModalInstance, $sce, param) {

    $scope.url = param.url;

    $scope.ok = function () {
        $uibModalInstance.close('close');
    };

    $scope.trustSrc = function (src) {
        return $sce.trustAsResourceUrl(src);
    };
});
