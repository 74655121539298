// Initialize Firebase
var messaging;
$(function() {
    var firebaseConfiguration = {
        apiKey: "AIzaSyCEaBkKNySBf10jaCHu-OoBUDlnUGa7gbE",
        authDomain: "order4mepeu.firebaseapp.com",
        databaseURL: "https://order4mepeu.firebaseio.com",
        projectId: "order4mepeu",
        storageBucket: "order4mepeu.appspot.com",
        messagingSenderId: "279039352072"
    };

    firebase.initializeApp(firebaseConfiguration);

    messaging = firebase.messaging();
    messaging.onMessage(function(payload) {
        console.log("FCM: Message received. ", payload);
    });

    messaging.onTokenRefresh(function() {
        messaging.getToken()
            .then(function(refreshedToken) {
                console.log('FCM: Token refreshed.' + refreshedToken);
                // Indicate that the new Instance ID token has not yet been sent to the
                // app server.
                angular.element(document.getElementById('mainBody')).scope().sharedData.sendPushRegister(refreshedToken);

                // ...
            })
            .catch(function(err) {
                console.log('FCM: Unable to retrieve refreshed token ', err);
            });
    });
});
