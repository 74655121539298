/**
 * Route configuration for the  module.
 */
myApp.config([ '$stateProvider', '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        // For unmatched routes
        $urlRouterProvider.otherwise('/');

        // Application routes
        $stateProvider
            .state('index', {
                url: '/',
                templateUrl: 'templates/startpage.html'
            })
            .state('checkin', {
                url: '/checkin',
                templateUrl: 'templates/checkin.html'
            })
            .state('checkout', {
                url: '/checkout',
                templateUrl: 'templates/checkout.html'
            })
            .state('pay', {
                url: '/pay',
                templateUrl: 'templates/pay.html'
            })
            .state('guestmap', {
                url: '/guestmap',
                templateUrl: 'templates/guestmap.html'
            })
            .state('service', {
                url: '/service',
                templateUrl: 'templates/service.html'
            })
            .state('feedback', {
                url: '/feedback',
                templateUrl: 'templates/feedback.html'
            })
            .state('menu', {
                url: '/menu',
                templateUrl: 'templates/menu.html'
            })
            .state('history', {
                url: '/history',
                templateUrl: 'templates/history.html'
            })
            .state('send', {
                url: '/send',
                templateUrl: 'templates/send.html'
            })
            .state('news', {
                url: '/news',
                templateUrl: 'templates/news.html'
            })
            .state('embed', {
                url: '/embed',
                templateUrl: 'templates/embed.html'
            })
            .state('index2', {
                url: '/index2',
                templateUrl: 'templates/startpage.html'
            })
            .state('hotelchat', {
                url: '/hotelchat',
                templateUrl: 'templates/hotelchat.html'
            })
            .state('selfCheckin', {
                url: '/selfCheckin',
                templateUrl: 'templates/selfCheckin.html'
            })
            .state('elevator', {
                url: '/elevator',
                templateUrl: 'templates/elevator.html'
            });

    }
]);
