myApp.controller('ElevatorCtrl', function ($uibModal, $scope, $location, $http, SharedData, $filter, $window, EmailProviderService, EventDispatcher, $translate, TranslationFactory, KeyWordService) {
    $scope.cart = [];
    $scope.details = [];
    $scope.userdata = [];
    $scope.cartSize = 0;
    $scope.totalItems = 0;
    $scope.showInvoiceInformation = false;
    $scope.firstNamePlaceHolder = 'FIRST_NAME_LABEL';
    $scope.lastNamePlaceHolder = 'LAST_NAME_LABEL';
    $scope.roomPlaceHolder = 'ROOM_NUMBER';
    $scope.acceptAgb = false;
    $scope.showInvoiceOnly = false;
    $scope.today = '13-09-2017';
    $scope.personalDetails = [];
    $scope.companyDetails = [];
    $scope.addressDetails = [];
    $scope.articles = [];
    $scope.mobileCheckin = [];
    $scope.transactions = [];
    $scope.invoicesDto = {};
    $scope.displayInvoiceInformation = '';
    $scope.elevator = {};
    $scope.elevator.availability = {};
    $scope.elevator.availableHours = [];
    $scope.elevator.day = 'TODAY';
    $scope.elevator.hour = '';
    $scope.elevator.minute = '';
    $scope.elevator.blocked = {};
    $scope.loading = true;
    $scope.bookingCancelled = false;
    $scope.auth = {
      errorMessage: "",
      error: false
    };

    $scope.formatToday = function () {
        const date = new Date();
        console.log(date);
        $scope.today = $scope.paddLeft(date.getDate()) + '-' + $scope.paddLeft(date.getMonth() + 1) + '-' + date.getFullYear();
        console.log('today is:' + $scope.today);
    };

    $scope.paddLeft = function (string) {
        if (string < 10) {
            return '0' + string;
        }
        return string;
    };

    $scope.formatToday();

    $scope.switch = function (div) {
        $scope.showDiv = div;
    };

    $scope.sharedData = SharedData.data;

    $scope.$watch(function () {
        return $scope.sharedData.currency;
    }, function (currency) {
        if (currency) {
            $scope.currency = currency + ' ';
        }
    });

    $scope.reservationNumber = '';

    $scope.hotelLoaded = false;
    $scope.gtcText = '';
    $scope.elevator.error = false;

    EventDispatcher.addEventListener('hotel-loaded', function () {
        $scope.hotelLoaded = true;
    });

    // Workaround to activate angular event loop
    $scope.$watch('hotelLoaded', function () {
        if ($scope.hotelLoaded) {
            $scope.gtcText = KeyWordService.replaceLinks($scope.sharedData.hotel.wirt.gtcText);
            $scope.initWithSearchParameter();
        }
    });

    $scope.initWithSearchParameter = function () {
        const reservationNumber = $location.search().r;
        const hotelNumber = $location.search().h;
        const roomNumber = $location.search().rn;
        const clientId = $location.search().client;
        $scope.loadOptions()
        if (reservationNumber && hotelNumber && roomNumber != null) {
            $scope.switch('loading-data');
            $scope.reservationNumber = reservationNumber;
            $scope.hotelNumber = hotelNumber;
            $scope.roomNumber = roomNumber;

            if (!$scope.userdata) {
                $scope.userdata = {}
            }
            $scope.userdata.room = roomNumber;

            $scope.switch("elevator-timetable")
        } else if (roomNumber && hotelNumber && clientId) {
            $scope.sharedData.loadAutomaticUser(hotelNumber, roomNumber, clientId, user => {
                if (user.success == "false") {
                    $scope.switch('login');
                    return;
                }

                if (!$scope.userdata) {
                    $scope.userdata = {}
                }

                $scope.mobileCheckin = user.mobileCheckin;
                $scope.reservationNumber = user.mobileCheckin.reservationNumber;
                $scope.userdata.room = roomNumber;
                $scope.userdata.firstname = user.firstname;
                $scope.userdata.lastname = user.lastname;

                $scope.loadElevatorAvailability();
                $scope.switch("elevator-timetable")
            })
        } else {
            $scope.sharedData.sendLog('elevator-kachel', 0, $scope.sharedData.hotelID);
            $scope.switch('login');
        }
    };

    $scope.checkForUser = function () {
        console.log('username is: ' + $scope.sharedData.user.lastname);
    };

    $scope.loadOptions = function () {
        $http({
            method: 'POST',
            url: urlPrefix + '/options',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                id: $scope.sharedData.hotelID
            }
        }).then(function onSuccess(response) {
            $scope.adminData = response.data;
        });
    };

    $scope.randomId = function () {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 12; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    };

    $scope.loadCredentials = function () {
        const r = $scope.userdata.room;
        const f = $scope.userdata.firstname;
        const l = $scope.userdata.lastname;
        $scope.noRoom = $scope.noFirstName = $scope.noLastName = 'black';
        $scope.firstNamePlaceHolder = 'FIRST_NAME_LABEL';
        $scope.lastNamePlaceHolder = 'LAST_NAME_LABEL';
        $scope.roomPlaceHolder = 'ROOM_NUMBER';

        if (r != null && f != null && l != null) {
            $scope.switch('loading-data');
            $scope.validate();
        } else {
            if (r == null || r === undefined) {
                $scope.noRoom = 'red';
                $scope.roomPlaceHolder = $filter('translate')('REQUIRED_LABEL');
            }
            if (f == null || f === undefined) {
                $scope.noFirstName = 'red';
                $scope.firstNamePlaceHolder = $filter('translate')('REQUIRED_LABEL');
            }
            if (l == null || l === undefined) {
                $scope.noLastName = 'red';
                $scope.lastNamePlaceHolder = $filter('translate')('REQUIRED_LABEL');
            }
        }
        document.activeElement.blur();
    };

    $scope.returnTrueIfExists = function (label, array) {
        if (array) {
            const ret = array[ label ];
            return !!ret;
        }
    };

    $scope.validate = function () {
        const mobileCheckin = {
            room: $scope.userdata.room,
            firstname: $scope.userdata.firstname,
            lastname: $scope.userdata.lastname
        };

        const requestData = {
            mobileCheckin: mobileCheckin,
            hid: $scope.hid
        };

        $http({
            method: 'POST',
            url: urlPrefix + '/checkOutLogin',
            headers: {
                'Content-Type': 'application/json'
            },
            data: requestData
        }).success(function (data) {
            if (data.success === 'true') {
                $scope.hotelNumber = data.mobileCheckin.hotelNumber;
                $scope.roomNumber = data.mobileCheckin.room;
                $scope.reservationNumber = data.mobileCheckin.reservationNumber;
                $scope.mobileCheckin = data.mobileCheckin;
                $scope.loadElevatorAvailability();
                $scope.auth.error = false;
            } else {
                $scope.showError();
            }
        });
    };

    $scope.toggleCollapse = function (item) {
        item.expanded = !item.expanded;
    };

    $scope.formatDate = function (date) {
        return new Date(date);
    };

    $scope.showError = function(message) {
        $scope.switch("login")
        $scope.loading = false;
        $scope.auth.errorMessage = message || 'NOT_FOUND_NAME_ROOM_DIALOG';
        $scope.auth.error = true;
    }

    $scope.loadElevatorAvailability = function() {
        const requestData = {
            rn: $scope.userdata.room,
            hid: $scope.hid
        };

        $http({
            method: 'GET',
            url: urlPrefix + "/elevator?hid=" + requestData.hid + "&rn=" + requestData.rn + "&r=" + $scope.reservationNumber,
            headers: {
                'Content-Type': 'application/json'
            }
        }).success(function (data) {

            if (data.success === false) {
                $scope.switch("elevator-booking-not-available")
                $scope.loading = false;
                return;
            }

            if (data.blocked) {
                $scope.elevator.blocked = data.blocked;
                delete data.blocked;
            }

            $scope.elevator.availability = data;
            $scope.resetDayField()
            $scope.getAvailableHours($scope.elevator.day)

            if ($scope.elevator.availableHours.length >= 0) {
                $scope.elevator.hour = $scope.elevator.availableHours[0];
            }

            $scope.getAvailableMinutes($scope.elevator.day, $scope.elevator.hour);

            if ($scope.elevator.availableMinutes.length >= 0) {
                $scope.elevator.minute = $scope.elevator.availableMinutes[0];
            }

            $scope.loading = false;
            $scope.switch("elevator-timetable")
        });
    };

    $scope.resetDayField = function() {
        $scope.elevator.availableDays = Object.keys($scope.elevator.availability);
        if ($scope.elevator.availableDays.length > 0) {
            $scope.elevator.day = $scope.elevator.availableDays[0]
        }
    }

    $scope.getAvailableMinutes = function(day, hour) {
        $scope.elevator.availableMinutes = [];

        if (!day || !hour) {
            return $scope.elevator.availableMinutes
        }

        let fullDay = $scope.elevator.availability[day]

        if (!fullDay) {
            return $scope.elevator.availableMinutes;
        }

        $scope.elevator.availableMinutes = fullDay[hour].sort((a, b) => Number(a.minute) - Number(b.minute));

        return $scope.elevator.availableMinutes;
    }

    $scope.getAvailableHours = function(day) {
        $scope.elevator.availableHours = [];

        if (!day) {
            return $scope.elevator.availableHours
        }

        let fullDay = $scope.elevator.availability[day]

        if (!fullDay) {
            return $scope.elevator.availableHours;
        }

        $scope.elevator.availableHours = Object.keys(fullDay).sort((a, b) => Number(a) - Number(b))

        return $scope.elevator.availableHours;
    }

    $scope.submitElevatorBooking = function() {

        $scope.switch('default');

        $scope.elevator.error = false;
        const requestData = {
            rn: $scope.userdata.room,
            hid: $scope.hid,
            day: $scope.elevator.day,
            time: $scope.elevator.hour + ":" + $scope.elevator.minute.minute,
            r: $scope.reservationNumber
        };

        $http({
            method: 'POST',
            url: urlPrefix + "/elevator",
            headers: {
                'Content-Type': 'application/json'
            },
            data: requestData
        }).success(function (data) {
            console.warn(data);

            if (!data.success || data.success === 'false') {
                $scope.elevator.error = true
                $scope.switch("elevator-timetable")
            } else {
                $scope.switch('elevator-booking-ok');
            }
        });
    };

    $scope.cancelElevatorBooking = function(slot) {

        $scope.switch('default');

        var slotDate = new Date(slot.time);

        $scope.elevator.error = false;
        const requestData = {
            rn: $scope.userdata.room,
            hid: $scope.hid,
            day: $scope.isToday(slotDate) ? "TODAY" : "TOMORROW",
            time: slotDate.getHours() + ":" + slotDate.getMinutes(),
            r: $scope.reservationNumber,
            action: "cancel"
        };

        $http({
            method: 'DELETE',
            url: urlPrefix + "/elevator",
            headers: {
                'Content-Type': 'application/json'
            },
            data: requestData
        }).success(function (data) {
            console.warn(data);

            if (data.success) {

                $scope.bookingCancelled = true
                $scope.loadElevatorAvailability()
            }

            $scope.switch("elevator-timetable")
        });
    }

    $scope.isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    };

    $scope.getElevatorDayTranslated = function(day) {
        return $translate.instant(day)
    };

    $scope.parseSlotDateTime = function(slot) {
        moment.locale($scope.sharedData.activeLanguageCode);
        return moment(slot).calendar()
    }
});
