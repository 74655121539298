myApp.controller('CustomerModalCtrl', function ($scope, $uibModalInstance, $translate, $http, param, SharedData, $filter, EmailProviderService) {
    $scope.form = {};
    $scope.noMail = $scope.noFirstName = $scope.noLastName = "black";
    $scope.firstNamePlaceHolder = "FIRST_NAME_LABEL";
    $scope.lastNamePlaceHolder = "LAST_NAME_LABEL";
    $scope.mailPlaceHolder = "EMAIL_LABEL";

    $scope.isBlacklisted = function (email) {
        return EmailProviderService.isBlacklisted(email);
    };

    $scope.initInstances = function () {
        $scope.instances = {};
        $scope.instances.title = {
            "edit": false
        };
        $scope.instances.firstname = {
            "edit": false
        };
        $scope.instances.lastname = {
            "edit": false
        };
        $scope.instances.company = {
            "edit": false
        };
        $scope.instances.city = {
            "edit": false
        };
        $scope.instances.address = {
            "edit": false
        };
        $scope.instances.mail = {
            "edit": false
        };
        $scope.instances.phone = {
            "edit": false
        };
    };
    $scope.initInstances();

    $scope.toggleEdit = function (item) {
        item.edit = !item.edit;
    };
    $scope.save = function () {
        Object.keys($scope.instances).forEach(function (item) {
            $scope.instances[ item ].edit = false;
        });
        console.log("ok, save entity and checkout");
        $uibModalInstance.close('ok');
    };

    $scope.personalDetails = {
        expanded: false
    };
    $scope.addressDetails = {
        expanded: false
    };
    $scope.companyDetails = {
        expanded: false
    };

    $scope.countries = param.countries;

    $scope.toggleCollapse = function (item) {
        if (!item.expanded) {
            item.expanded = true;
        } else
            item.expanded = false;
        console.log(" expanded:" + item.expanded);
    };
    $scope.sharedData = SharedData.data;

    $scope.mobileCheckin = angular.copy(param.mobileCheckin);

    $scope.checkForBlacklistedEmailAddresses = function () {
        if (EmailProviderService.isBlacklisted($scope.mobileCheckin.email)) {
            $scope.mobileCheckin.email = null;
        }
    };

    $scope.checkForBlacklistedEmailAddresses();

    $scope.callback = param.callback;

    $scope.salutations = param.salutations;

    $scope.text = 'SUBMIT_MESSAGE';
    $scope.showQuestion = function () {
        var cust = $scope.mobileCheckin;
        console.debug(JSON.stringify(cust));

        if (cust.firstname !== "" && cust.firstname != null && cust.firstname !== undefined &&
            cust.lastname !== "" && cust.lastname != null && cust.lastname !== undefined &&
            cust.email !== "" && cust.email != null && cust.email !== undefined && !EmailProviderService.isBlacklisted(cust.email)) {
            $scope.question = true;
        } else {
            $scope.validateRequiredFields(cust);
        }
    };

    $scope.hideQuestion = function () {
        $scope.question = false;
    };

    $scope.ok = function () {
        console.log("ok, commence chargepost");
        $scope.callback($scope.mobileCheckin);
        $uibModalInstance.close('ok');
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.expandCompany = function (title) {
        current = $filter('filter')(
            $scope.salutations, {
                title: title
            })[ 0 ];
        if (current.isCompanyTitle === true) {
            $scope.companyDetails.expanded = true;
            document.getElementById("companyDetails").classList.add('in');
        } else {
            $scope.companyDetails.expanded = false;
            document.getElementById("companyDetails").classList.remove('in');
        }
    };

    $scope.continue = function () {
        var cust = $scope.mobileCheckin;
        console.debug(JSON.stringify(cust));

        if (cust.email !== "" && cust.email != null && cust.email !== undefined && !EmailProviderService.isBlacklisted(cust.email)) {
            // $scope.save();
            $scope.ok();
        } else {
            $scope.validateRequiredFields(cust);
        }
    };

    $scope.validateRequiredFields = function (customer) {
        if (customer.email == null || customer.email === undefined || customer.email === "" || EmailProviderService.isBlacklisted(customer.email)) {
            $scope.instances.mail.edit = true;
            $scope.mobileCheckin.email = null;
            $scope.noMail = "red";
            $scope.mailPlaceHolder = $filter('translate')('REQUIRED_LABEL');
        }
        if (customer.firstname == null || customer.firstname === undefined || customer.firstname === "") {
            $scope.instances.firstname.edit = true;
            $scope.noFirstName = "red";
            $scope.firstNamePlaceHolder = $filter('translate')('REQUIRED_LABEL');
        }
        if (customer.lastname == null || customer.lastname === undefined || customer.lastname === "") {
            $scope.instances.lastname.edit = true;
            $scope.noLastName = "red";
            $scope.lastNamePlaceHolder = $filter('translate')('REQUIRED_LABEL');
        }
    }

    $scope.calcInputLimit = function () {
        if ($scope.mobileCheckin.reservationType === 'HMS') {
            return 100;
        } else {
            return 26;
        }
    };
});
