/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
//var urlPrefix="http://order4mepeu.appspot.com";

myApp.controller('MainCtrl', function ($rootScope, $document, $scope, $location, $translate, TranslationFactory, $http,
    SharedData, $css, $timeout, $state, $stateParams, $window, ChatWidget, EventDispatcher, LocalStorage, Sentry) {
    $scope.images = [];

    $scope.tracker = {
        'aggregatedTracker': 'aggregatedTracker',
        'granularTracker': 'granularTracker',
        'clientTracker': ''
    };
    $scope.fixedTop = {
        'margin-top': '22px'
    };
    $scope.senddata = {};
    $scope.setBackground = function (url) {
        var img = new Image();
        console.log('PRELOADING ' + url);
        img.onload = function () {
            console.log('Image Loaded: ' + url);
            var same = false;
            if ($scope.currentImage) {
                var a_base64 = getBase64Image(img);
                var b_base64 = getBase64Image($scope.currentImage);
                if (a_base64 === b_base64) {
                    same = true;
                }
            }
            if (same) {
                console.log('Images are the SAME!');
            } else {
                var el = document.getElementById('mainBody');
                if (el != null) {
                    el.style.backgroundImage = 'url(' + url + ')';
                    $scope.currentImage = img;
                    setTimeout(function () {
                        var offset = 750;
                        if (iOS()) {
                            offset = 10;
                        }
                        var rnd = Math.round(Math.random() * 10) + offset;
                        var kt = document.getElementById('kte');
                        if (kt) {
                            kt.style.paddingBottom = rnd + 'px';
                        }
                        console.log('set set' + rnd);
                    }, 500);
                }
            }
        };
        img.src = url;
    };

    $scope.pwaIntegrationInIframe = false;

    $scope.getStyle = function () {
        var ret = 'url(\'' + urlPrefix + '/serveImageAsset?hid=0&type=background\');';
        return ret;

    };

    $scope.setPageStyles = function (hid) {
        var cssURL = 'assets/css/vi.css';
        var imgURL = 'assets/img/backgrounds/2.jpg';
        var iconURL = '/assets/ico/gustaffo_favicon_for_t2.png';
        if (hid > 0) {
            cssURL = urlPrefix + '/serveAsset?hid=' + hid + '&type=css';
            imgURL = urlPrefix + '/serveImageAsset?hid=' + hid + '&type=background';
            iconURL = urlPrefix + '/serveImageAsset?hid=' + hid + '&type=favicon';
        } else if (hid == 0) // generic geo inbound with hotel
        {
            imgURL = 'assets/img/backgrounds/1-old.jpg';
        } else if (hid < 0) {
            var did = hid * -1;
            cssURL = urlPrefix + '/serveAsset?did=' + did + '&type=css';
            imgURL = urlPrefix + '/serveImageAsset?did=' + did + '&type=geobackground';
            iconURL = urlPrefix + '/serveImageAsset?did=' + did + '&type=favicon';
        }
        iconURL += addVersionParameter();

        $css.bind({
            href: cssURL
        }, $scope);
        $scope.setBackground(imgURL);
        if (iconURL) {
            createFaviconElement(iconURL);
        }

        createAllFavicons(urlPrefix, hid);
    };

    function addVersionParameter() {
        // This ensures icon will refresh
        return '&v=' + Date.now();
    }

    function createAllFavicons(urlPrefix, hotelId) {
        var faviconTypeNumber = 14;
        var id_type = 'hid';
        if (hotelId < 0) {
            id_type = 'did';
            hotelId = hotelId * -1;
        }

        var faviconCodes = sendRequestForFaviconUrls(urlPrefix, hotelId);
        faviconCodes.forEach(function (code) {
            var iconURL = urlPrefix + '/serveImageAsset?';
            iconURL += id_type + '=' + hotelId;
            iconURL += '&type=' + faviconTypeNumber;
            iconURL += '&code=' + code;
            iconURL += addVersionParameter();
            createFaviconElement(iconURL);
        });
    }

    function sendRequestForFaviconUrls(urlPrefix, hotelId) {
        var xhttp = new XMLHttpRequest();
        var mainUrl = urlPrefix + '/favicons?hid=' + hotelId + '&action=getCodes';

        console.log('Sending favicon request to ' + mainUrl);
        xhttp.open('GET', mainUrl, false);
        xhttp.setRequestHeader('Content-type', 'application/json');
        xhttp.send();

        var faviconCodes = [];
        console.log('Favixcon response with status: ', xhttp.status);
        if (xhttp.status === 200 && xhttp.responseText !== null) {
            console.log('Favixcon response with text:', xhttp.responseText);
            var response = JSON.parse(xhttp.responseText);
            faviconCodes = response.faviconCodes;
        }
        return faviconCodes;
    }

    function createFaviconElement(iconURL) {
        var link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = iconURL;
        document.getElementsByTagName('head')[ 0 ].appendChild(link);
    }

    $scope.checkHid = function () {
        console.log('checking hid ...');
        console.log($location.search());
        console.log($window.location.href);
        console.log(getParameter('hid'));
        var hid = getParameter('hid');
        if ($location.search().hid) {
            hid = $location.search().hid;
        }

        if (hid) {
            if (hid > 0) {
                $scope.sharedData.hotelID = hid;
                console.log('hotel is ' + $scope.sharedData.hotelID);
                LocalStorage.write('hotelID', $scope.sharedData.hotelID);
            }
        } else {
            $scope.sharedData.hotelID = LocalStorage.read('hotelID');
            console.log('HotelID from local storage is ' + $scope.sharedData.hotelID);
            hid = $scope.sharedData.hotelID;
        }
        if ($location.search().lang) {
            LocalStorage.write('language', $location.search().lang);
            console.log('Language from Parameter is ' + LocalStorage.read('language'));
            TranslationFactory.use(LocalStorage.read('language'));
        } else if (LocalStorage.read('language')) {
            console.log('Language from Storage is ' + LocalStorage.read('language'));
            TranslationFactory.use(LocalStorage.read('language'));
        }

        $scope.hid = hid;
    };

    $scope.setStyleForHotelId = function () {
        $scope.checkHid();
        $scope.setPageStyles($scope.hid);
    };

    $scope.disabled = false;
    $scope.hotel = {};
    $scope.sharedData = SharedData.data;
    $scope.setStyleForHotelId();
    $scope.disableTilesOnNavbar = function () {
        $(document).on('click', function () {
            $(document).click(function (event) {
                var clickover = $(event.target);
                var _opened = $('#myNavbar').hasClass('navbar-collapse collapse in');
                if (_opened === true && !clickover.hasClass('navbar-toggle')) {
                    $('#buttonNav').click();
                    $scope.disabled = false;
                }
                $scope.checkDevice();
            });
        });
    };

    $scope.checkDevice = function () {
        function is_touch_device() {
            return 'ontouchstart' in window // works on most browsers
                ||
                navigator.maxTouchPoints; // works on IE10/11 and
            // Surface
        }

        $scope.touch = is_touch_device();
    };


    $scope.disableTilesOnNavbar();
    $scope.checkDevice();
    $scope.searchDone = false;
    $scope.searchSuccess = false;
    $scope.splashScreen = false;
    $scope.customSplashScreen = false;
    $scope.locationFound = false;
    $scope.nearbyHotels = [];
    $scope.langFilterNone = '';
    $scope.langFilterYes = '';
    $scope.newMessages = 0;
    $scope.navbar = [ {
        name: 'Home',
        faicon: 'fa-home',
        href: '#/'
    }
    ];
    $scope.mainMenu = [ {
        name: 'Loading, please Wait ...',
        faicon: 'fa-cog fa-spin',
        href: '/',
        position: 1,
        active: true
    }
    ];
    $scope.services = [ {
        name: 'Late Check Out',
        faicon: 'fa-clock-o',
        href: '/guestmap',
        position: 1,
        active: false
    }, {
        name: 'Laundry Service',
        faicon: 'fa-suitcase',
        href: '/guestmap',
        position: 2,
        active: false
    }, {
        name: 'Get a Taxi',
        faicon: 'fa-cab',
        href: '/guestmap',
        position: 3,
        active: false
    }, {
        name: 'Conferences Package',
        faicon: 'fa-group',
        href: '/feedback',
        position: 4,
        active: false
    } ];
    $scope.orderitems = [ {
        name: 'Soft Drinks',
        faicon: 'fa-coffee',
        href: '/guestmap',
        position: 1,
        active: false
    }, {
        name: 'Alcoholic Drinks',
        faicon: 'fa-glass',
        href: '/guestmap',
        position: 2,
        active: false
    }, {
        name: 'Snacks',
        faicon: 'fa-spoon',
        href: '/guestmap',
        position: 3,
        active: false
    }, {
        name: 'Meals',
        faicon: 'fa-cutlery',
        href: '/feedback',
        position: 4,
        active: false
    } ];


    $scope.flagImage = function (lang) {
        if (lang === 'en') {
            return 'gb';
        } else {
            return lang;
        }
    };

    $scope.refreshHotel = function () {
        $scope.splashScreen = false;

        console.log('Using language ' + TranslationFactory.getLanguage());
        $scope.langFilterNone = '!' + TranslationFactory.getLanguage();
        $scope.langFilterYes = TranslationFactory.getLanguage();
        // Load the hotel
        $scope.sharedData.loadHotel($scope.sharedData.hotelID, function ($data) {
            function loadSplashScreen() {
                EventDispatcher.removeEventListener('userSynced', loadSplashScreen);
                $scope.sharedData.loadSplashScreen($scope.sharedData.hotelID, $data.mainMenu);
            }

            // Load SpashScreen when hotel-id and user-id are available
            EventDispatcher.addEventListener('userSynced', loadSplashScreen.bind(this));

            $scope.sharedData.hotel = $data;
            $scope.sharedData.currency = ' ' + $data.wirt.currency + ' ';
            $scope.navbar = $data.navbar;
            $scope.mainMenu = $data.mainMenu;
            $scope.allowCheckout = $data.allowCheckout;
            $scope.homePath = $data.homePath;

            for (var i = 0; i < $scope.mainMenu.length; i++) {
                var item = $scope.mainMenu[ i ];
                if (item.type === 'folder') {
                    var img = urlPrefix + '/serveImageAsset?kid=' + item.id + '&type=background';
                    console.log('PRELOADING ' + img);
                    var prel = new Image();
                    prel.src = img;
                    $scope.images[ img ] = prel;
                }
            }
            $scope.langFilterNone = '!' + TranslationFactory.getLanguage();
            $scope.langFilterYes = TranslationFactory.getLanguage();

            $scope.hotel = $data.wirt;
            $scope.title = $data.title;
            $document[ 0 ].title = $scope.title;

            if ($scope.hotel.trackingid !== null && $scope.hotel.trackingid !== undefined &&
                $scope.hotel.trackingid !== '') {
                ga('create', $scope.hotel.trackerId, 'auto', 'clientTracker');
                $scope.tracker.clientTracker = 'clientTracker';
            }

            $scope.sharedData.trackScreen('home', $scope.hotel.shortcut, $scope.tracker);
            $scope.initVoyat($scope.hotel.voyatid);

            if ($data.wirt.privacyShowCookie) {
                const cookieText = $translate.instant('COOKIE_TEXT');
                const cookieButton = $translate.instant('COOKIE_BUTTON');
                const cookieTitle = $translate.instant('COOKIE_PRIVACY_TITLE');
                const cookieDisable = ($data.wirt.privacyCookieChoice) ? $translate.instant('COOKIE_DISABLE') : undefined;

                showCookiePopup(cookieText, cookieButton, cookieTitle, $data.wirt.privacyUrl, cookieDisable);
            }
            console.log('Home path is ' + $scope.homePath);
            if ($scope.homePath) {
                $location.path($scope.homePath);
            }
        });

        $scope.sharedData.initUser(function ($data) {
            console.log('Logon done!');
            $scope.sharedData.sendLog('logon', 0, $scope.sharedData.hotelID);
            $scope.sharedData.loadHistory($scope.sharedData.hotelID);
            if ($data.forceStartPage) {
                console.log('Forcing start page ' + $data.forceStartPage);
                $location.path('/' + $data.forceStartPage);
            }
        });

        $scope.sharedData.addMessageCallback('main', function (message) {
            console.log('MAIN: received callback for ' + message);
        });
    };

    $scope.refreshData = function () {

        console.log('Scope hid:' + $scope.hid);
        $location.path('#/?' + $scope.hid);
        $scope.disabled = false;

        $scope.sharedData.sendLog('preLocalStorageInit', 0, $scope.sharedData.hotelID);

        // Initialize cross orig storage service and try login
        LocalStorage.init().then(function (state) {
            $scope.sharedData.sendLog('postLocalStorageInit - ' + state, 0, $scope.sharedData.hotelID);
            if ($scope.hid <= 0) {
                console.log('ZERO HOTEL, showing Splash screen!');
                $scope.splashScreen = true;
                $scope.customSplashScreen = ($scope.hid < 0);
                var did = $scope.hid * -1;
                $scope.sharedData.checkNearby(did, function (data) {
                    $scope.searchDone = true;
                    $scope.domain = data.domain;
                    if (!data) {
                        $scope.locationFound = false;
                        console.log('NO LOCATION SERVICE FOUND!');
                        $scope.searchSuccess = false;
                    } else {

                        $scope.locationFound = true;
                        if (!data.hotels) {
                            $scope.searchSuccess = false;
                        } else {
                            $scope.searchSuccess = data.success && data.hotels.length > 0;
                            if (data.hotels.length > 0) {
                                $scope.nearbyHotels = data.hotels;
                            }
                            if ($scope.domain) {
                                if (data.hotels.length === 1 && $scope.domain.autoHotelForward == true) {
                                    console.log('AUTO HOTEL FORWARD!!!');
                                    if (data.hotelsMap[data.hotels[0].id]) {
                                        $window.location.href = data.hotelsMap[data.hotels[0].id];
                                    } else {
                                        $window.location.href = '/v2/frontend/client.html?hid=' + data.hotels[ 0 ].id + '#/';
                                    }
                                }
                                if (data.hotels.length === 0 && $scope.domain.autoDefaultForward == true) {
                                    console.log('AUTO DEFAULT FORWARD!!!');
                                    $window.location.href = $scope.domain.defaultPage;
                                }
                            }
                        }
                    }
                    console.log('Success of Check nearby: ' + data.success + ' locationFound:' + $scope.locationFound);
                });
            } else {
                $scope.refreshHotel();
            }
        });
    };

    $scope.initVoyat = function (voyatid) {
        if (voyatid != null && voyatid != '' && voyatid != undefined) {
            voyatInit(voyatid);
        }
    };
    $scope.showMessages = function () {
        // $location.path("/send");
        $scope.sharedData.showMessages();
    };
    $scope.gotoPowered = function () {
        $window.open('http://www.gustaffo.com');
    };
    $scope.goHome = function () {
        $scope.sharedData.currentParent = 0;
        $scope.setBackground(urlPrefix + '/serveImageAsset?hid=' + $scope.sharedData.hotelID + '&type=background');
        if ($scope.homePath) {
            console.log('Going to Home Path ' + $scope.homePath);
            $location.path($scope.homePath);
        } else {
            $location.path('#/?' + $scope.hid);
        }


        $scope.sharedData.trackScreen('home', $scope.hotel.shortcut, $scope.tracker);
        $scope.disabled = false;
        $scope.fixedTop = {
            'margin-top': '22px'
        };
    };
    $scope.setFixedTop = function () {
        $scope.fixedTop = {
            'margin-top': '22px',
            'position': 'fixed',
            'z-index': '20'
        };
    };
    $scope.filterMenu = function (item) {
        if ($scope.sharedData.currentParent === 0) {
            if (!item.parentitem) {
                return true;
            } else {
                return (item.parentitem === null || item.parentitem === 0);
            }
        } else {
            if (!item.parentitem) {
                return false;
            } else {
                return item.parentitem === $scope.sharedData.currentParent;
            }
        }
    };

    $scope.shouldShowMessages = function () {
        $scope.newMessages = 0;
        if ($scope.sharedData.clientHistory.chat) {
            for (var i = 0; i < $scope.sharedData.clientHistory.chat.length; i++) {
                var data = $scope.sharedData.clientHistory.chat[ i ];
                if (!data.read && !data.me) {
                    $scope.newMessages++;
                }
            }
        }

        if ($scope.sharedData.globals.currentScreen === 'send') {
            return false;
        } else if (ChatWidget.isEnabled) {
            return false;
        } else if ($scope.newMessages > 0) {
            return true;
        } else {
            return false;
        }
    };

    $scope.changeUser = function () {
        $scope.sharedData.globals.defaultRoom = $scope.sharedData.user.lastroom;
        $scope.sharedData.globals.defaultName = $scope.sharedData.user.lastname;
        LocalStorage.write('lastname', null);
        $scope.sharedData.showLogonDialogForce(function (success) {
            if (success === true) {
                console.log('User changed');
            }
        }, false);
    };

    $scope.logOffUser = function () {
        $scope.sharedData.showYesnoDialog(function (response) {
            if (response === true) {
                ChatWidget.logout().then(function () {
                    $scope.goHome();
                });
            }
        }, 'LOGOFFDIALOG');
    };

    $scope.checkDisabled = function (item) {
        $scope.checkDevice();
        if ($scope.disabled === false || $scope.touch === 0 || $scope.touch === false) {
            if (item.type !== 'phone' && item.type !== 'whatsapp') {
                $scope.checkRoute(item);
            }
        }
    };

    $scope.invokeSplashScreenMenu = function (item, invoke) {
        console.log((invoke ? 'Invoking' : 'Going to') + ' splashscreen-menu with type ' + item.type);

        if (item.type === 'service' && invoke) {
            $scope.invokeServiceWithoutInputs(item);
        } else {
            $scope.checkDisabled(item);
        }
    };

    $scope.invokeServiceWithoutInputs = function (service) {
        console.log('Invoking service \'' + service.name + '\' ...');

        $scope.sharedData.showLogonDialog(function (success) {
            $scope.sharedData.selectService(service);
            $scope.sharedData.sendLog('sendService', $scope.sharedData.services.currentService.id, $scope.sharedData.hotelID);

            if (success === true) {
                let senddata = {};
                senddata.hid = $scope.sharedData.hotelID;
                senddata.id = $scope.sharedData.user.userID;
                senddata.room = $scope.sharedData.user.lastroom;
                senddata.name = $scope.sharedData.user.lastname;
                senddata.lang = TranslationFactory.getLanguage();
                senddata.event = $scope.sharedData.services.currentService.id;

                if ($scope.sharedData.gpsPos.latitude && $scope.sharedData.gpsPos.longitude) {
                    senddata.lat = $scope.sharedData.gpsPos.latitude;
                    senddata.lon = $scope.sharedData.gpsPos.longitude;
                }

                $http({
                    method: 'POST',
                    url: urlPrefix + '/sendClientMessage',
                    headers: {'Content-Type': 'application/json; charset=UTF-8'},
                    data: senddata
                }).success(function (data) {
                    console.log('Send done ' + JSON.stringify(data));
                    if (data.chat) {
                        $scope.sharedData.clientHistory.chat = data.chat;
                    }
                    $scope.sharedData.loadHistory($scope.sharedData.hotelID);
                    // $location.path("/send");
                    $scope.sharedData.showMessages();
                });
            } else {
                console.log('Logon failed - service not invoked!');
            }
        }, true, true, 0);
    };

    $scope.goBack = function () {
        $scope.sharedData.currentParent = 0;
        $scope.setBackground(urlPrefix + '/serveImageAsset?hid=' + $scope.sharedData.hotelID + '&type=background');
    };


    $scope.isCheckout = function (item) {
        if (item.altHref != null && item.altHref.indexOf('checkout.html') >= 0) {
            return true;
        }
        if (item.href != null && item.href.indexOf('checkout.html') >= 0) {
            return true;
        }
        return false;
    };

    $scope.isGeneralMenu = function (type) {
        return (type !== 'phone' && type !== 'whatsapp');
    };

    $scope.processPhoneNumberForWhatsapp = function (number) {
        if (number) {
            var formatted = number.replace(/\D/g, '');

            while (formatted.length > 1 && formatted.startsWith('0')) {
                formatted = formatted.substring(1, formatted.length - 1);
            }

            return formatted;
        } else {
            return number;
        }
    };

    $scope.gotoItem = function (item) {
        var href = '';
        if (($scope.touch === 0 || $scope.touch === false) && item.altHref != null && item.altHref !== 'null') {
            href = item.altHref;
        } else {
            href = item.href;
        }
        console.log('going to ' + href + ' with type ' + item.menutype + ' and logon ' + item.logon);

        $scope.sharedData.sendLog('kachel', item.id, $scope.sharedData.hotelID);
        if (item.type === 'folder') {
            $scope.setBackground(urlPrefix + '/serveImageAsset?kid=' + item.id + '&type=background');
            $scope.sharedData.currentParent = item.id;
            $scope.sharedData.trackScreen(item.trackingcode, $scope.hotel.shortcut, $scope.tracker);
            $scope.sharedData.sendLog('folder', item.id, $scope.sharedData.hotelID);
        } else if (item.type === "iframe") {
            var embedPath = "iframe://" + href;
            $scope.sharedData.embedPath = embedPath;
            console.log('iframe found, opening!');
            $scope.sharedData.scrollTop();
            $location.path('/embed');
            $scope.sharedData.sendLog('iframe', item.id, $scope.sharedData.hotelID);
        } else if (href.lastIndexOf('http', 0) === 0) {
            console.log('http found, opening!');
            var target = '_blank';
            if (href.lastIndexOf(',') > 0) {
                target = href.substring(href.lastIndexOf(',') + 1);
                href = href.substring(0, href.lastIndexOf(','));
            }
            $window.open(href, target);
            $scope.sharedData.sendLog('href', item.id, $scope.sharedData.hotelID);
            $scope.sharedData.trackScreen(item.trackingcode, $scope.hotel.shortcut, $scope.tracker);
        } else if (href.lastIndexOf('twitter', 0) === 0) {
            $scope.sharedData.embedPath = href;
            console.log('twitter found, opening!');
            $scope.sharedData.scrollTop();
            $location.path('/embed');
            $scope.sharedData.sendLog('twitter', item.id, $scope.sharedData.hotelID);
        } else if (href.lastIndexOf('feedback', 0) === 0) {
            $scope.sharedData.embedPath = href;
            console.log('feedback found, opening!');
            $scope.sharedData.scrollTop();
            $location.path('/feedback');
        } else if (href.lastIndexOf('hotelchat', 0) === 0) {
            $scope.sharedData.embedPath = href;
            console.log('hotelchat found, opening!');
            $scope.sharedData.scrollTop();
            $location.path('/hotelchat');
        } else if (href.lastIndexOf('ubimet', 0) === 0) {
            $scope.sharedData.embedPath = href;
            console.log('ubimet found, opening!');
            $scope.sharedData.scrollTop();
            $location.path('/embed');
            $scope.sharedData.sendLog('ubimet', item.id, $scope.sharedData.hotelID);
        } else if (href.lastIndexOf('content', 0) === 0) {
            var type = href.substring(8);
            $scope.sharedData.sendLog('content', item.id, $scope.sharedData.hotelID);
            console.log('checking content for type ' + type);

            $scope.sharedData.getContentPath(type, $scope.hotel.id, function (link) {
                if (link) {
                    console.log('content link is ' + link);
                    var w = $window.open(link);
                    if (w == null) {
                        console.log('Popup was blocked!');
                    }

                }
            });
        } else {
            console.log('Going there!');
            if (href === '#/') {
                $scope.setBackground(urlPrefix + '/serveImageAsset?hid=' + $scope.sharedData.hotelID + '&type=background');
                $scope.sharedData.trackScreen('home', $scope.hotel.shortcut, $scope.tracker);
                $scope.fixedTop = {
                    'margin-top': '22px'
                };
                $scope.sharedData.currentParent = 0;
            }

            if (href === '/send') {
                // $scope.sharedData.sendLog("contact", item.id, $scope.sharedData.hotelID);
                $scope.sharedData.showMessages();
            } else {
                $scope.sharedData.selectItem(item);
                $scope.sharedData.scrollTop();
                $location.path(href);
            }
        }

        $scope.disabled = false;
    };

    $scope.checkRoute = function (item) {
        console.log('going to ' + item.name + ' with type ' + item.menutype + ' and logon ' + item.logon + '  and password ' + item.password + ' and ipgeo ' + item.ipgeo);

        var pwd = 0;
        if (item.password) {
            pwd = item.password;
        }

        if (item.logon || item.ipgeo || item.password) {
            $scope.sharedData.showLogonDialog(function (success) {
                if (success === true) {
                    $scope.gotoItem(item);
                }
            }, item.ipgeo || item.logon, item.logon, pwd);
        } else {
            $scope.gotoItem(item);
        }
    };

    $scope.changeCheckoutLang = function (lang, te) {
        TranslationFactory.use(lang, $scope.hotel.id);
        console.log('Language changed to ' + TranslationFactory.getLanguage());
        LocalStorage.write('language', TranslationFactory.getLanguage());
        $location.path($location.path());
        $scope.loadCountryList();
        $scope.refreshHotel();

        $scope.$broadcast('lang-changed', TranslationFactory.getLanguage());
    };

    $scope.changeCheckInLang = function (lang, te) {
        TranslationFactory.use(lang, $scope.hotel.id);
        console.log('Language changed to ' + TranslationFactory.getLanguage());
        LocalStorage.write('language', TranslationFactory.getLanguage());
        $location.path($location.path());
        $scope.loadCountryList();
        $scope.refreshHotel();

        $scope.$broadcast('lang-changed', TranslationFactory.getLanguage());
    };

    $scope.loadCountryList = function loadCountryList(lang) {

        var requestData = {
            lang: TranslationFactory.getLanguage()
        };

        if (lang) {
            requestData.lang = lang;
        }

        // TODO: should be replaced by Angular's $http call to get rid of
        // $scope.$apply
        $.ajax({
            type: 'POST',
            url: urlPrefix + '/retrieveCountries',
            data: JSON.stringify(requestData),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: function (data) {
                $scope.$apply(function () {
                    console.log('loading country list done');
                    $scope.countries = data;
                });
            }
        });
    };

    $scope.initPage = function (path) {
        $scope.refreshHotel();
        $scope.loadCountryList();

        if ($location.search().pwaIntegrationInIframe) {
            $scope.pwaIntegrationInIframe = true;
        }

        $location.path(path);
    };

    $scope.changeLang = function (lang) {
        TranslationFactory.use(lang, $scope.hotel.id);
        LocalStorage.write('language', TranslationFactory.getLanguage());

        $location.path('#' + $location.path());
        //$location.path($location.path());
        $scope.refreshData();
        EventDispatcher.dispatchEvent('lang-changed', TranslationFactory.getLanguage());
        console.log('Language changed to ' + TranslationFactory.getLanguage());
    };

    $scope.submitSend = function () {
        console.log('Submit send');
        if ($scope.senddata.message && $scope.senddata.message.length > 0) {

            $scope.sharedData.showLogonDialog(function (success) {
                if (success) {
                    $scope.sharedData.sendLog('sendMainMenu', 0, $scope.sharedData.hotelID);
                    $scope.sharedData.globals.sendText = $scope.senddata.message;
                    $scope.senddata.message = '';
                    // $location.path("/send");
                    $scope.sharedData.showMessages();
                } else {
                    console.log('Login canceled - Not sending reception message over starting page.');
                }
            }, true, true, 0);
        }
    };

    $scope.itemClasses = function (item) {
        var className = '';
        var returnVal = '';
        if ($scope.isBig(item)) {
            className = 'kachel_big';
        } else {
            className = 'kachel_small';
        }
        if (item.active) {
            returnVal = className + ' ' + 'kachel_aktiv';
        } else {
            returnVal = className + ' ' + 'kachel_grau';
        }
        if ($scope.touch === 0 || $scope.touch === undefined) {
            returnVal = returnVal + ' ' + className + '_allow_hover';
        }
        return returnVal;
    };

    $scope.isBig = function (item) {
        if ($scope.mainMenu.length % 2 === 0) {
            return false;
        } else if ($scope.mainMenu[ 0 ] === item) {
            return true;
        }

        return false;
    };

    $scope.returnFullClass = function (className) {
        var faSubString = 'fa-';
        if (className.indexOf(faSubString) === -1) {
            return '';
        } else {
            return 'fa fa-lg ';
        }
    };

    $scope.getColor = function (iconColor) {
        if (iconColor !== undefined && iconColor !== '' && iconColor != null) {
            return iconColor;
        } else {
            return 'black';
        }
    };

    $scope.getCheckoutStyle = function () {
        return 'url(\'' + urlPrefix + '/serveImageAsset?hid=' + $scope.hid + '&type=background\');';

    };

    $scope.getSelfCheckinBackground = function () {
        return 'url(\'' + urlPrefix + '/serveImageAsset?hid=' + $scope.hid + '&type=background\');';
    };

    $scope.loadClient = function () {
        $window.location.href = location.origin + location.pathname.replace('checkinout', 'client').replace('checkout', 'client') + location.search;
    };

    $scope.redirectToBmineWordpressPage = function (hotelId) {
        if (hotelId === 6722864168304640) {
            $window.location.href = "https://pwa.gustaffo.com/bminedd/de/startseite/";
        } else if (hotelId === 6734243755458560) {
            $window.location.href = "https://pwa.gustaffo.com/bminefra/de/startseite/";
        }

    };
});
