/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

myApp.controller('SendCtrl', function ($rootScope, $timeout, $document, $scope, $anchorScroll, $location, $translate, $http, $sce, $filter, SharedData, TranslationFactory) {
    $scope.sharedData = SharedData.data;


    $scope.sendAllowed = true;

    $scope.senddata = {};

    $scope.scrollDown = function () {

        $timeout(function () {
            $location.hash('finale');
            $anchorScroll();
        }, 200);


    };
    $scope.clearHistory = function () {
        console.log("Deleting History");
        $scope.sharedData.showYesnoDialog(function (success) {
            if (success)
                console.log("YES pushed");
            else
                console.log("No pushed");


        }, "YES OR NO?");
    };
    $scope.initSend = function () {
        $scope.setFixedTop();
        $scope.sharedData.trackScreen("send", $scope.hotel.shortcut, $scope.tracker);

        $scope.sharedData.sendLog("send", 0, $scope.sharedData.hotelID);


        $scope.setRead();

        if ($scope.sharedData.user) {
            if ($scope.sharedData.user.lastname)
                $scope.senddata.name = $scope.sharedData.user.lastname;
            if ($scope.sharedData.user.lastroom)
                $scope.senddata.room = $scope.sharedData.user.lastroom;
        }
        $scope.scrollDown();

        $scope.sharedData.addMessageCallback("send", function (message) {

            console.log("received callback for " + message);
            if ($scope.sharedData.globals.currentScreen === "send") {
                $scope.setRead();
            } else
                console.log("Not on Send Screen");


            $scope.scrollDown();
        });

        if ($scope.sharedData.globals.sendText && $scope.sharedData.globals.sendText.length > 0) {
            console.log("Immediately sending " + $scope.sharedData.globals.sendText);
            $scope.senddata.message = $scope.sharedData.globals.sendText;
            $scope.sharedData.globals.sendText = "";
            $scope.submitSend();
            // $scope.senddata.message = "";


        }
    };

    $scope.setRead = function () {
        $http({
            method: 'POST',
            url: urlPrefix + '/setRead',
            headers: {'Content-Type': 'application/json; charset=UTF-8'},
            data: {wid: $scope.sharedData.hotelID, user: $scope.sharedData.user.userID, client: true}
        }).success(function (data) {
            if ($scope.sharedData.clientHistory.chat) {
                for (var i = 0; i < $scope.sharedData.clientHistory.chat.length; i++) {
                    var data = $scope.sharedData.clientHistory.chat[i];
                    if (!data.me)
                        data.read = true;
                }
            }
            console.log("Read set!");
        });
    };

    $scope.historyClass = function (item) {
        if (item.me)
            return "chatMe";
        else if (item.bot)
            return "chatBot";
        else
            return "chatOther";
    };

    $scope.submitSend = function () {


        if ($scope.sendAllowed) {


            $scope.scrollDown();

            if ($scope.senddata.message && $scope.senddata.message.length > 0) {
                $scope.sharedData.sendLog("sendMessage", 0, $scope.sharedData.hotelID);

                // should be replaced by Angular's $http call

                $scope.senddata.hid = $scope.sharedData.hotelID;
                $scope.senddata.id = $scope.sharedData.user.userID;

                $scope.senddata.room = $scope.sharedData.user.lastroom;

                $scope.senddata.name = $scope.sharedData.user.lastname;

                $scope.senddata.lang = TranslationFactory.getLanguage();

                $scope.senddata.event = 1;

                if ($scope.sharedData.gpsPos.latitude && $scope.sharedData.gpsPos.longitude) {
                    $scope.senddata.lat = $scope.sharedData.gpsPos.latitude;
                    $scope.senddata.lon = $scope.sharedData.gpsPos.longitude;
                }


                $scope.sendAllowed = false;
                theTimer = $timeout(function () {
                    $scope.sendAllowed = true;
                    console.log("Send Timeout");
                }, 2500);


                $http({
                    method: 'POST',
                    url: urlPrefix + '/sendClientMessage',
                    headers: {'Content-Type': 'application/json; charset=UTF-8'},
                    data: $scope.senddata
                }).success(function (data) {

                    $scope.sendAllowed = true;
                    $timeout.cancel(theTimer);
                    $scope.senddata.message = "";
                    console.log("Send done " + JSON.stringify(data));
                    if (data.chat) {
                        $scope.sharedData.clientHistory.chat = data.chat;
                    }
                    $scope.scrollDown();


                });
            } else
                console.log("Message too short!");
        } else {
            console.log("not allowed");
        }
    };

    $scope.inputKeys = function (event) {
        // Enter-key
        if (event.keyCode === 13) {
            if (!event.shiftKey) {
                $scope.submitSend();
                event.preventDefault();
            }
        }
    };
});

