/**
 * The Code is adopted form "angular-eonasdan-datetimepicker"
 *   Link: https://github.com/atais/angular-eonasdan-datetimepicker
 * 
 * Requirements: eonasdan-bootstrap-datetimepicker
 *   Link: http://eonasdan.github.io/bootstrap-datetimepicker/Installing/
 * 
 * Installation:
 *   bower install eonasdan-bootstrap-datetimepicker#latest --save
 */
(function () {
    'use strict';

    angular
        .module('Order4meClient')
        .directive('datetimepicker', Datetimepicker);
        
        Datetimepicker.$inject = ['$timeout'];

        function Datetimepicker($timeout) {
            var directive = {
                restrict: 'EA',
                require: 'ngModel',
                link: link,
                scope: {
                    options: '=?',
                    onChange: '&?',
                    onClick: '&?'
                }
            };
            return directive;
    
    
            function link($scope, $element, $attrs, ngModel) {
                var dpElement = $element.parent().hasClass('input-group') ? $element.parent() : $element;

                $scope.options = $scope.options || {};

                $scope.$watch('options', function (newValue) {
                    var dtp = dpElement.data('DateTimePicker');
                    $.map(newValue, function (value, key) {
                        dtp[key](value);
                    });
                }, true);

                ngModel.$render = function () {
                    // if value is undefined/null do not do anything, unless some date was set before
                    var currentDate = dpElement.data('DateTimePicker').date();
                    if (!ngModel.$viewValue && currentDate) {
                        dpElement.data('DateTimePicker').clear();
                    } else if (ngModel.$viewValue) {
                        // otherwise make sure it is moment object
                        if (!moment.isMoment(ngModel.$viewValue)) {
                            ngModel.$setViewValue(moment(ngModel.$viewValue));
                        }
                        dpElement.data('DateTimePicker').date(ngModel.$viewValue);
                    }
                };

                var isDateEqual = function (d1, d2) {
                    return moment.isMoment(d1) && moment.isMoment(d2) && d1.valueOf() === d2.valueOf();
                };

                dpElement.on('dp.change', function (e) {
                    if (!isDateEqual(e.date, ngModel.$viewValue)) {
                        var newValue = e.date === false ? null : e.date;
                        ngModel.$setViewValue(newValue);

                        var formattedDate = formatter(e.date._d);

                        $timeout(function () {
                            if (typeof $scope.onChange === 'function') {
                                $scope.onChange({dateTime: formattedDate});
                            }
                        });
                    }
                });

                dpElement.on('click', function () {
                    $timeout(function () {
                        if (typeof $scope.onClick === 'function') {
                            $scope.onClick();
                        }
                    });
                });

                dpElement.datetimepicker($scope.options);
            }

            function formatter(date) {
                var obj = new Date(date);
                var day = obj.getDate();
                day = day < 10 ? "0" + day : day;
                var month = obj.getMonth() + 1;
                month = month < 10 ? "0" + month : month;
                var hours = obj.getHours();
                hours = hours < 10 ? "0" + hours : hours;
                var minutes = obj.getMinutes();
                minutes = minutes < 10 ? "0" + minutes : minutes;

                var ret = {};
                ret.full = date;
                ret.date = obj.getFullYear() + "-" + month + "-" + day;
                ret.time = hours + ":" + minutes;
                return ret;
            }
        }
})();