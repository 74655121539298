var externalPageLoader;
myApp.controller('CheckoutCtrl', function ($uibModal, $scope, $location, $http, SharedData, $filter, $window, EmailProviderService, EventDispatcher, TranslationFactory, KeyWordService, $sce) {
    $scope.cart = [];
    $scope.details = [];
    $scope.userdata = [];
    $scope.cartSize = 0;
    $scope.totalItems = 0;
    $scope.showInvoiceInformation = false;
    $scope.firstNamePlaceHolder = 'FIRST_NAME_LABEL';
    $scope.lastNamePlaceHolder = 'LAST_NAME_LABEL';
    $scope.roomPlaceHolder = 'ROOM_NUMBER';
    $scope.acceptAgb = false;
    $scope.showInvoiceOnly = false;
    $scope.today = '13-09-2017';
    $scope.personalDetails = [];
    $scope.companyDetails = [];
    $scope.addressDetails = [];
    $scope.articles = [];
    $scope.mobileCheckin = [];
    $scope.transactions = [];
    $scope.invoicesDto = {};
    $scope.displayInvoiceInformation = '';

    $scope.setAgb = function () {
        $scope.acceptAgb = !$scope.acceptAgb;
    };

    $scope.formatToday = function () {
        var date = new Date();
        console.log(date);
        $scope.today = $scope.paddLeft(date.getDate()) + '-' + $scope.paddLeft(date.getMonth() + 1) + '-' + date.getFullYear();
        console.log('today is:' + $scope.today);
    };

    $scope.paddLeft = function (string) {
        if (string < 10) {
            return '0' + string;
        }
        return string;
    };

    $scope.formatToday();

    function resetInvoicesDto() {
        $scope.invoicesDto = {
            'openAmount': 0,
            'paidAmount': 0,
            'totalAmount': 0,
            'expanded': false
        };
    }

    resetInvoicesDto();

    $scope.resetExpanded = function () {
        $scope.personalDetails.expanded = false;
        $scope.companyDetails.expanded = false;
        $scope.addressDetails.expanded = false;
        $scope.articles.expanded = false;
        $scope.mobileCheckin.expanded = false;
        $scope.transactions.expanded = false;
        $scope.invoicesDto.expanded = false;
    };

    $scope.pagination = {
        currentPage: 1,
        numPerPage: 16
    };

    $scope.skipAllowed = true;
    $scope.gtcText = '';

    $scope.switch = function (div) {
        $scope.resetExpanded();
        $scope.showDiv = div;
    };

    $scope.paginate = function (value) {
        var begin = void 0,
            end = void 0,
            index = void 0;
        begin = ($scope.pagination.currentPage - 1) * $scope.pagination.numPerPage;
        end = begin + $scope.pagination.numPerPage;
        index = $scope.transactions.indexOf(value);
        return begin <= index && index < end;
    };

    $scope.sharedData = SharedData.data;

    $scope.$watch(function () {
        return $scope.sharedData.currency;
    }, function (currency) {
        if (currency) {
            $scope.currency = currency + ' ';
        }
    });

    $scope.reservationNumber = '';

    $scope.hotelLoaded = false;
    $scope.gtcText = '';

    EventDispatcher.addEventListener('hotel-loaded', function () {
        $scope.hotelLoaded = true;
    });

    // Workaround to activate angular event loop
    $scope.$watch('hotelLoaded', function () {
        if ($scope.hotelLoaded) {
            $scope.gtcText = $sce.trustAsHtml(KeyWordService.replaceLinks($scope.sharedData.hotel.wirt.gtcText));
            $scope.initWithSearchParameter();
        }
    });

    $scope.checkForUser = function () {
        console.log('username is: ' + $scope.sharedData.user.lastname);
    };

    $scope.preparePayment = function () {
        if ($scope.acceptAgb) {

            var nominalOrderId = $scope.invoicesDto.invoiceNumber ? $scope.invoicesDto.invoiceNumber.replace(/[^0-9]*/gi, '') : $scope.invoicesDto.identifier;
            var invoiceNumber = $scope.invoicesDto.invoiceNumber ? $scope.invoicesDto.invoiceNumber.replace(/[^0-9]*/gi, '') : $scope.invoicesDto.identifier;

            var requestData = {
                openAmount: $scope.invoicesDto.openAmount,
                currency: $scope.currency.substring(0, $scope.currency.length - 1),
                description: 'Payment of invoice ' + invoiceNumber,
                nominalOrderId: nominalOrderId,
                orderId: $scope.invoicesDto.identifier,
                lang: 'de',
                requestId: 'REQ_' + invoiceNumber + '_' + $scope.randomId(),
                reservationNumber: $scope.reservationNumber,
                hotelId: $scope.hotelNumber,
                source: 'checkout'
            };

            $http({
                method: 'POST',
                url: urlPrefix + '/payment',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: requestData
            }).success(function (data) {
                window.open(data.RedirectUrl, '_self');
            });
        }
    };

    $scope.loadOptions = function () {
        $http({
            method: 'POST',
            url: urlPrefix + '/options',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                id: $scope.sharedData.hotelID
            }
        }).then(function onSuccess(response) {
            $scope.adminData = response.data;
        });
    };

    $scope.randomId = function () {
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (var i = 0; i < 12; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    };

    $scope.checkout = function () {
        var requestData = {
            hotelId: $scope.hotelNumber,
            reservationNumber: $scope.reservationNumber,
            roomNumber: $scope.roomNumber
        };

        $http({
            method: 'PUT',
            url: urlPrefix + '/checkout',
            headers: {
                'Content-Type': 'application/json'
            },
            data: requestData
        }).success(function (data) {
            $scope.mobileCheckin = data.mobileCheckin;
            if (data.success !== 'EXCEPTION') {
                console.log('checkout was successful');
                $scope.sharedData.sendLog('checkout-success', 0, $scope.sharedData.hotelID);

                if ($scope.sharedData.hotel.wirt.group == 6029646313291776) {
                    $scope.switch("checkout-completed");
                    return;
                }

                var checkoutMessageKey = $scope.sharedData.hotel.wirt.group == 5746235935817728 || $scope.sharedData.hotel.wirt.group == 5661845167800320 ? 'AFTER_CHECKOUT_GREETINGS_PROVENT' : $scope.paymentSuccess === 't' ? 'CHECKOUT_DONE_MESSAGE_WITH_INVOICE' : 'CHECKOUT_DONE_MESSAGE';

                $scope.sharedData.showYesnoDialog(function (response) {
                    if ($scope.sharedData.hotelID == "6201157261197312") {
                        // For provent hotels
                        $window.location.href = "https://parkinn-hotel-koeln-city-west.suitepad.io/?js=post";
                    } else if ($scope.sharedData.hotelID == "5684932489773056") {
                        // For provent hotels
                        $window.location.href = "https://parkinn-hotel-neumarkt.suitepad.io/?js=post";
                    } else if ($scope.sharedData.hotelID == "4852783666692096") {
                        // For provent hotels
                        $window.location.href = "https://parkinn-hotel-goettingen.suitepad.io/?js=post";
                    } else if ($scope.sharedData.hotelID == "5368223815958528") {
                        // For provent hotels
                        $window.location.href = "https://parkinn-hotel-dresden.suitepad.io/?js=post";
                    } else if ($scope.sharedData.hotelID == "4861292718325760") {
                        // For provent hotels
                        $window.location.href = "https://parkinn-hotel-papenburg.suitepad.io/?js=post";
                    } else {
                        $scope.loadClient();
                    }
                }, checkoutMessageKey);
            } else {
                $scope.sharedData.sendLog('checkout-fail', 0, $scope.sharedData.hotelID);
                $scope.sharedData.showYesnoDialog(function (response) {
                    if ($scope.sharedData.hotelID == "6201157261197312") {
                        // For provent hotels
                        $window.location.href = "https://parkinn-hotel-koeln-city-west.suitepad.io/?js=post";
                    } else if ($scope.sharedData.hotelID == "5684932489773056") {
                        // For provent hotels
                        $window.location.href = "https://parkinn-hotel-neumarkt.suitepad.io/?js=post";
                    } else if ($scope.sharedData.hotelID == "4852783666692096") {
                        // For provent hotels
                        $window.location.href = "https://parkinn-hotel-goettingen.suitepad.io/?js=post";
                    } else if ($scope.sharedData.hotelID == "5368223815958528") {
                        // For provent hotels
                        $window.location.href = "https://parkinn-hotel-dresden.suitepad.io/?js=post";
                    } else if ($scope.sharedData.hotelID == "4861292718325760") {
                        // For provent hotels
                        $window.location.href = "https://parkinn-hotel-papenburg.suitepad.io/?js=post";
                    } else {
                        $scope.loadClient();
                    }
                }, 'CHECKINOUT_ERROR_MESSAGE');
            }
        });
    };

    $scope.payDiff = function () {
        if ($scope.payedAmount != null && $scope.payedAmount > 0) {
            var requestData = {
                identifier: {
                    hotelId: $scope.hotelNumber,
                    reservationNumber: $scope.reservationNumber,
                    roomNumber: $scope.roomNumber
                },
                transactionDto: {
                    transactionDetailsDtos: [{
                        amount: $scope.payedAmount,
                        productCode: 1
                    }],
                    paymentType: '82',
                    id: $scope.invoicesDto.identifier
                }
            };

            $http({
                method: 'POST',
                url: urlPrefix + '/rest/folio',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: requestData
            }).success(function (data) {
                if (data && data.success === true) {
                    $scope.loadMobileCheckOutData();
                } else {
                    $scope.showErrorModal();
                }
            });
        } else {
            $scope.switch('checkoutform');
        }
    };

    $scope.goToCheckout = function () {
        $scope.switch('loading-data');
        $scope.loadMinibar();
    };

    $scope.loadCredentials = function () {
        var r = $scope.userdata.room;
        var f = $scope.userdata.firstname;
        var l = $scope.userdata.lastname;
        $scope.noRoom = $scope.noFirstName = $scope.noLastName = 'black';
        $scope.firstNamePlaceHolder = 'FIRST_NAME_LABEL';
        $scope.lastNamePlaceHolder = 'LAST_NAME_LABEL';
        $scope.roomPlaceHolder = 'ROOM_NUMBER';

        if (r != null && f != null && l != null) {
            $scope.switch('loading-data');
            $scope.validate();
        } else {
            if (r == null || r === undefined) {
                $scope.noRoom = 'red';
                $scope.roomPlaceHolder = $filter('translate')('REQUIRED_LABEL');
            }
            if (f == null || f === undefined) {
                $scope.noFirstName = 'red';
                $scope.firstNamePlaceHolder = $filter('translate')('REQUIRED_LABEL');
            }
            if (l == null || l === undefined) {
                $scope.noLastName = 'red';
                $scope.lastNamePlaceHolder = $filter('translate')('REQUIRED_LABEL');
            }
        }
        document.activeElement.blur();
    };

    $scope.editCustomer = function () {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'customerModal.html',
            controller: 'CustomerModalCtrl',
            windowClass: 'customerModal',
            resolve: {
                param: function param() {
                    var ret = [];
                    ret.countries = $scope.countries;
                    ret.salutations = $scope.salutations;
                    ret.mobileCheckin = $scope.mobileCheckin;
                    ret.callback = $scope.updateMobileCheckin;
                    return ret;
                }
            }
        });

        modalInstance.result.then(function (selectedItem) {
            console.log('Modal closed with result ' + selectedItem);
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
        });
        document.activeElement.blur();
    };

    $scope.showInvoiceAddressOverview = function () {

        if ($scope.invoicesDto && $scope.invoicesDto.invoiceDtos) {
            var invoices = $scope.invoicesDto.invoiceDtos;
            var openInvioces = invoices.filter(function (el) {
                return el.openAmount > 0;
            });
            if (openInvioces.length > 0) {
                return;
            }
        }

        if (!EmailProviderService.isBlacklisted($scope.mobileCheckin.email)) {
            $scope.checkout();
        } else {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'customerOverviewModal.html',
                controller: 'CustomerModalCtrl',
                windowClass: 'customerModal',
                resolve: {
                    param: function param() {
                        var ret = [];
                        ret.countries = $scope.countries;
                        ret.salutations = $scope.salutations;
                        ret.mobileCheckin = $scope.mobileCheckin;
                        ret.callback = $scope.updateMobileCheckin;
                        return ret;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                console.log('Modal closed with result ' + selectedItem);
                $scope.checkout();
            }, function () {
                console.log('Modal dismissed at: ' + new Date());
            });
            document.activeElement.blur();
        }
    };

    $scope.updateMobileCheckin = function (mobileCheckin) {

        if ((mobileCheckin.companyName1 || mobileCheckin.companyName2) && !mobileCheckin.invoiceAddress) {
            mobileCheckin.invoiceAddress = mobileCheckin.privateAddress;
        }

        $scope.mobileCheckin = mobileCheckin;
        var requestData = $scope.mobileCheckin;

        console.warn(JSON.stringify(mobileCheckin));

        $http({
            method: 'PUT',
            url: urlPrefix + '/updateCustomer',
            headers: {
                'Content-Type': 'application/json'
            },
            data: requestData
        });
    };

    $scope.isBlacklisted = function (email) {
        return EmailProviderService.isBlacklisted(email);
    };

    $scope.postCharges = function () {
        var requestData = {
            identifier: {
                hotelId: $scope.hotelNumber,
                reservationNumber: $scope.reservationNumber,
                roomNumber: $scope.roomNumber
            },
            transactionDto: {
                transactionDetailsDtos: $scope.details,
                paymentType: 'room',
                date: new Date()
            }
        };

        $http({
            method: 'POST',
            url: urlPrefix + '/rest/transactions',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: requestData
        }).success(function (data) {
            if (data) {
                $scope.details = [];
                $scope.cart = [];
                $scope.cartSize = 0;
            }
            $scope.loadMobileCheckOutData();
            $scope.sharedData.sendLog('checkout-book-minibar', 0, $scope.sharedData.hotelID);
        });
    };

    $scope.skip = function () {
        if ($scope.positionsDebt != null) {
            $scope.switch('checkoutform');
        } else {
            $scope.sharedData.sendLog('checkout-skip-minibar', 0, $scope.sharedData.hotelID);
            $scope.switch('loading-data');
            $scope.loadMobileCheckOutData();
        }
    };

    $scope.openInvoice = function () {
        $scope.switch("loading-data");
        $scope.showInvoiceInformation = true;
        $scope.displayInvoiceInformation = {
            'padding-bottom': 'inherit'
        };

        $scope.showInvoiceOnly = true;
        $scope.loadMobileCheckOutData();
    };

    $scope.goBackToGreetingView = function () {
        $scope.showInvoiceInformation = false;
        $scope.showInvoiceOnly = false;
        $scope.displayInvoiceInformation = {
            'padding-bottom': 'inherit'
        };

        $scope.switch("greeting-view");
    };

    $scope.loadMinibar = function () {
        var reservationNumber = $scope.reservationNumber.replace('/', '%2F');
        var restUrl = '/rest/reservations/' + $scope.hotelNumber + '/' + reservationNumber + '?rn=' + $scope.roomNumber;

        $http({
            method: 'GET',
            url: urlPrefix + restUrl,
            headers: {
                'Content-Type': 'application/json'
            }
        }).success(function (data) {
            if (!data || data.hasOwnProperty('error')) {
                if (data.error === 'NOT_ALLOWED') {
                    $scope.showErrorModal('CHECKOUT_NOT_ALLOWED');
                } else {
                    $scope.showErrorModal();
                }
                return null;
            }

            $scope.mobileCheckin = data.mobileCheckin;
            if (($scope.mobileCheckin.checkOutError == null || $scope.mobileCheckin.checkOutError === false) && ($scope.mobileCheckin.checkOutDone == null || $scope.mobileCheckin.checkOutDone === false) && $scope.mobileCheckin.departure === $scope.today) {
                var requestData = {
                    hid: $scope.sharedData.hotelID,
                    lang: TranslationFactory.getLanguage(),
                    action: 'read'
                };

                $http({
                    method: 'POST',
                    url: urlPrefix + '/minibar',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: requestData
                }).success(function (data) {
                    if (data != null && data.length > 1) {
                        $scope.articles = data;
                        $scope.switch('minibar');
                        // $scope.loadMobileCheckOutData();
                    } else {
                        $scope.loadMobileCheckOutData();
                    }
                });
            } else {
                if ($scope.mobileCheckin.departure > $scope.today) {
                    $scope.showInvoiceInformation = true;
                    $scope.displayInvoiceInformation = {
                        'padding-bottom': 'inherit'
                    };
                }
                $scope.loadMobileCheckOutData();
            }
        });
    };

    $scope.loadMobileCheckOutData = function (callback) {
        var reservationNumber = $scope.reservationNumber.replace('/', '%2F');
        var restUrl = '/rest/folio/' + $scope.hotelNumber + '/' + reservationNumber + '?lang=' + TranslationFactory.getLanguage() + '&rn=' + $scope.roomNumber;

        $http({
            method: 'GET',
            url: urlPrefix + restUrl,
            headers: {
                'Content-Type': 'application/json'
            }
        }).success(function (data) {
            if (data && data.success === true) {
                $scope.fillFolioData(data);
                $scope.switch('checkoutform');
                callback && callback();
            } else {
                $scope.showErrorModal();
            }
        });
    };

    $scope.updateFolioData = function (invoicesDto, callback) {
        var requestData = {
            identifier: {
                hotelId: $scope.hotelNumber,
                reservationNumber: $scope.reservationNumber,
                roomNumber: $scope.roomNumber
            },
            invoicesDto: invoicesDto
        };

        $http({
            method: 'PUT',
            url: urlPrefix + '/rest/folio',
            headers: {
                'Content-Type': 'application/json'
            },
            data: requestData
        }).success(function (data) {
            if (data && data.success === true) {
                $scope.loadMobileCheckOutData(callback);
            } else {
                $scope.showErrorModal();
            }
        });
    };

    $scope.fillFolioData = function (data) {
        if (data && data !== '') {
            $scope.mobileCheckin = data.mobileCheckin;
            $scope.salutations = data.salutations;
            $scope.agb = data.agb;
            $scope.incomingCurrency = 'EUR';
            resetInvoicesDto();

            if (data.invoicesDto) {
                switch ($scope.mobileCheckin.managementSystemType) {
                    case "CLOCK":
                        $scope.invoicesDto = data.invoicesDto;
                        break;
                    default:
                        processInvoicesData(data.invoicesDto);
                        flattenTransactions();
                        break;
                }
            }
        }
    };

    function processInvoicesData(invoicesDto) {
        resetInvoicesDto();

        $scope.invoicesDto.invoiceDtos = invoicesDto.invoiceDtos;
        $scope.invoicesDto.identifier = convertInvoiceIdentifier($scope.invoicesDto.invoiceDtos[0].identifier);

        $scope.invoicesDto.invoiceDtos.forEach(function (invoiceDto) {
            $scope.invoicesDto.openAmount += invoiceDto.openAmount ? invoiceDto.openAmount : 0;
            $scope.invoicesDto.paidAmount += invoiceDto.paidAmount ? invoiceDto.paidAmount : 0;
            $scope.invoicesDto.totalAmount += invoiceDto.totalAmount ? invoiceDto.totalAmount : 0;
        });
    }

    function convertInvoiceIdentifier(identifier) {
        var separator = identifier.indexOf('|');

        if (separator >= 0) {
            return identifier.substr(0, separator);
        }

        return identifier;
    }

    function flattenTransactions() {
        $scope.transactions = [];
        $scope.totalItems = 0;

        $scope.invoicesDto.invoiceDtos.forEach(function (invoiceDto) {
            invoiceDto.transactionDtos.forEach(function (transactionDto, i) {
                transactionDto.transactionDetailsDtos.forEach(function (transactionDetailsDto, j) {
                    transactionDetailsDto.date = transactionDto.date;
                    transactionDetailsDto.index = i + j;
                    $scope.transactions.push(transactionDetailsDto);
                });
                $scope.totalItems = $scope.totalItems + transactionDto.transactionDetailsDtos.length;
            });
        });

        $scope.transactions.sort(function (a, b) {
            a = new Date(a.date);
            b = new Date(b.date);
            return a > b ? -1 : a < b ? 1 : 0;
        });
    }

    $scope.returnTrueIfExists = function (label, array) {
        if (array) {
            var ret = array[label];
            return !!ret;
        }
    };

    $scope.validate = function () {
        var mobileCheckin = {
            room: $scope.userdata.room,
            firstname: $scope.userdata.firstname,
            lastname: $scope.userdata.lastname
        };

        var requestData = {
            mobileCheckin: mobileCheckin,
            hid: $scope.hid
        };

        $http({
            method: 'POST',
            url: urlPrefix + '/checkOutLogin',
            headers: {
                'Content-Type': 'application/json'
            },
            data: requestData
        }).success(function (data) {
            if (data.success === 'true') {
                $scope.hotelNumber = data.mobileCheckin.hotelNumber;
                $scope.roomNumber = data.mobileCheckin.room;
                $scope.reservationNumber = data.mobileCheckin.reservationNumber;
                $scope.mobileCheckin = data.mobileCheckin;
                //$scope.loadMinibar();
                $scope.switch("greeting-view");
            } else {
                $scope.showErrorModal();
            }
        });
    };

    $scope.addToCart = function (article) {
        console.log('added item ' + (article.localTexts ? article.localTexts.localizedTitle : article.name) + ' with price ' + (article.columns ? article.columns.PRICE_LABEL : article.value));
        var articleFound = false;
        for (index = 0; index < $scope.cart.length; index++) {
            if ($scope.cart[index].id === article.id) {
                $scope.cart[index].unitCnt++;
                articleFound = true;
                break;
            }
        }

        if (!articleFound) {
            article.unitCnt = 1;

            if (article.columns && article.columns.PRICE_LABEL) {
                article.unitPrice = article.columns.PRICE_LABEL;
            }

            if (article.columns && article.columns.VALUE_LABEL) {
                article.productCode = article.columns.VALUE_LABEL;
            }

            if (!article.columns && article.value) {
                article.unitPrice = article.value;
            }

            article.description = article.localTexts && article.localTexts.localizedTitle ? article.localTexts.localizedTitle : article.name;
            $scope.cart.push(article);
        }
        if ($scope.cart.length > 0) {
            $scope.skipAllowed = false;
        }

        var cartSize = 0;
        $scope.cart.forEach(function (item) {
            cartSize = cartSize + item.unitCnt;
        });
        $scope.cartSize = cartSize;
    };

    $scope.orderCallback = function (cart) {
        console.log('Order Callback with cart ' + cart);
        cart.forEach($scope.addToDetails);
        $scope.switch('loading-data');
        $scope.postCharges();
    };

    $scope.sizeCallback = function (size) {
        $scope.cartSize = size;
    };

    $scope.addToDetails = function (item) {
        var detail = {
            unitPrice: item.unitPrice,
            productCode: item.productCode,
            description: item.description,
            unitCnt: item.unitCnt
        };

        if (item.revenueGroup) {
            detail["revenueGroup"] = item.revenueGroup;
        }

        if (item.revenueCategory) {
            detail["revenueCategory"] = item.revenueCategory;
        }

        if (item.taxRate) {
            detail["taxRate"] = item.taxRate;
        }

        $scope.details.push(detail);
    };

    $scope.showCart = function () {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'minibarModal.html',
            controller: 'MinibarModalCtrl',
            windowClass: 'menuModal',
            resolve: {
                param: function param() {
                    var ret = [];
                    ret.cart = $scope.cart;
                    ret.cartSize = $scope.cartSize;
                    ret.callback = $scope.orderCallback;
                    ret.sizeCallback = $scope.sizeCallback;
                    return ret;
                }
            }
        });

        modalInstance.result.then(function (selectedItem) {
            console.log('Modal closed with result ' + selectedItem);
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
        });
    };

    $scope.initWithSearchParameter = function () {

        $scope.paymentSuccess = $location.search().s;
        if ($scope.paymentSuccess === 't') {
            $scope.switch('loading-data');
            $scope.sharedData.sendLog('checkout-payment-success', 0, $scope.sharedData.hotelID);
            $scope.processToInvoice();
        }

        if ($scope.paymentSuccess === 'f') {
            $scope.switch('loading-data');
            $scope.sharedData.sendLog('checkout-payment-fail', 0, $scope.sharedData.hotelID);
            $scope.sharedData.showYesnoDialog(function () {
                $scope.processToInvoice();
            }, $scope.sharedData.hotel.wirt.group != 5746235935817728 ? 'PAYMENT_FAILED_MESSAGE' : 'PAYMENT_FAILED_MESSAGE_PROVENT');
        }

        if (!$scope.paymentSuccess) {
            var reservationNumber = $location.search().r;
            var hotelNumber = $location.search().h;
            var roomNumber = $location.search().rn;
            var clientId = $location.search().client;
            $scope.loadOptions();
            if (reservationNumber && hotelNumber && roomNumber != null) {
                $scope.sharedData.sendLog('checkout-mail', 0, $scope.sharedData.hotelID);
                $scope.switch('loading-data');
                $scope.reservationNumber = reservationNumber;
                $scope.hotelNumber = hotelNumber;
                $scope.roomNumber = roomNumber;
                $scope.loadMinibar();
            } else if (roomNumber && hotelNumber && clientId) {
                $scope.sharedData.loadAutomaticUser(hotelNumber, roomNumber, clientId, function (user) {

                    if (user.success === "false") {
                        $scope.switch('login');
                        return;
                    }

                    if (!$scope.userdata) {
                        $scope.userdata = {};
                    }

                    $scope.userdata.room = roomNumber;
                    $scope.hotelNumber = hotelNumber;
                    $scope.roomNumber = roomNumber;
                    $scope.userdata.firstname = user.firstname;
                    $scope.userdata.lastname = user.lastname;

                    $scope.reservationNumber = user.mobileCheckin.reservationNumber;
                    $scope.mobileCheckin = user.mobileCheckin;

                    $scope.switch("greeting-view");
                });
            } else {
                $scope.sharedData.sendLog('checkout-kachel', 0, $scope.sharedData.hotelID);
                $scope.switch('login');
            }
        }
    };

    $scope.showCheckoutQuestion = function (callback, $text) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'checkoutModal.html',
            controller: 'YesnoModalController',
            windowClass: 'menuModal',
            resolve: {
                param: function param() {
                    var ret = {};
                    ret.text = $text;
                    return ret;
                }
            }
        });

        modalInstance.result.then(function (selectedItem) {
            if (callback) {
                callback(true);
            }
        }, function () {
            if (callback) {
                callback(false);
            }
        });
    };

    $scope.processToInvoice = function () {
        $scope.reservationNumber = $location.search().r;
        $scope.hotelNumber = $location.search().h;
        $scope.payedAmount = $location.search().a;
        $scope.roomNumber = $location.search().rn;
        $location.search('a', null);
        $location.search('s', null);
        $scope.loadInvoice();
    };

    $scope.loadInvoice = function () {
        var waitCounter = -1;
        var paymentWasSucessful = $scope.paymentSuccess === 't';

        if (paymentWasSucessful) {
            waitCounter = 2;
        }

        var continueAfterInvoiceIsLoaded = function continueAfterInvoiceIsLoaded() {
            waitCounter--;
            if (waitCounter === 0) {
                console.log('Invoice loaded, continue with checkout');
                $scope.showInvoiceAddressOverview();
            }
        };

        var reservationNumber = $scope.reservationNumber.replace('/', '%2F');
        var restUrl = '/rest/folio/' + $scope.hotelNumber + '/' + reservationNumber + '?lang=' + TranslationFactory.getLanguage() + '&rn=' + $scope.roomNumber;

        var requestData = {
            hid: $scope.sharedData.hotelID,
            lang: TranslationFactory.getLanguage(),
            action: 'read'
        };

        $http({
            method: 'POST',
            url: urlPrefix + '/minibar',
            headers: {
                'Content-Type': 'application/json'
            },
            data: requestData
        }).success(function (data) {
            if (data != null && data.length > 1) {
                $scope.articles = data;
            }
            continueAfterInvoiceIsLoaded();
        });

        $http({
            method: 'GET',
            url: urlPrefix + restUrl,
            headers: {
                'Content-Type': 'application/json'
            }
        }).success(function (data) {
            if (data && data.success === true) {
                $scope.fillFolioData(data);
                $scope.switch('checkoutform');
                continueAfterInvoiceIsLoaded();
            } else {
                $scope.showErrorModal();
            }
        });
    };

    $scope.toggleCollapse = function (item) {
        item.expanded = !item.expanded;
    };

    $scope.minibarExists = function () {
        return $scope.articles && $scope.articles.length > 0;
    };

    $scope.formatDate = function (date) {
        return new Date(date);
    };

    $scope.formatDisplayedDate = function (date) {
        return date.replaceAll("-", ".");
    }


    $scope.showErrorModal = function (message) {
        message = message || 'NOT_FOUND_NAME_ROOM_DIALOG';
        $scope.sharedData.showYesnoDialog(function (response) {
            $scope.switch('default');
        }, message);
    };

    $scope.showExternalPage = function (url) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'externalPageModal.html',
            controller: 'externalPageModalController',
            windowClass: 'externalPageModal',
            size: 'lg',
            resolve: {
                param: function param() {
                    var ret = {};
                    ret.url = url;
                    return ret;
                }
            }
        });
    };
    externalPageLoader = $scope.showExternalPage;

    $scope.getInvoiceAddress = function () {
        if ($scope.mobileCheckin == null) {
            return;
        }

        if ($scope.mobileCheckin.reservationType === 'HMS') {
            return $scope.mobileCheckin.invoiceAddress ? $scope.mobileCheckin.invoiceAddress : $scope.mobileCheckin.privateAddress;
        } else {
            return $scope.mobileCheckin.hasPayerProfile ? $scope.mobileCheckin.invoiceAddress : $scope.mobileCheckin.privateAddress;
        }
    };

    $scope.getTaxAsString = function (transaction) {
        return transaction.taxRate ? transaction.taxRate * 100 + "%" : null;
    };

    $scope.goToBmineWebsite = function () {
        return 'https://bmine.de';
    };

    $scope.goBackHome = function () {
        $scope.loadClient();
    };

    $scope.getLinkToIBE = function () {
        return "https://bmine.direct-reservation.net/en/accommodation";
    };

    $scope.refreshAgb = function() {
        $http({
            method: 'POST',
            url: urlPrefix + '/wirtData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                action: 'client', id: $scope.sharedData.hotelID, lang: TranslationFactory.getLanguage()
            }
        }).then(function onSuccess(response) {
            var data = response.data;

            $scope.gtcText = $sce.trustAsHtml(KeyWordService.replaceLinks(data.wirt.gtcText));
        });
    }

    $scope.$on('lang-changed', function(event, lang) {
        $scope.refreshAgb();
    });
});
