/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

myApp.controller('MenuCtrl', function ($rootScope, $timeout, $uibModal, $scope, $location, $translate, $http, $sce, $filter, SharedData, TranslationFactory) {
    $scope.sharedData = SharedData.data;

    $scope.items = [];
    $scope.senddata = {};

    $scope.sendAllowed = true;


    $scope.orderCallback = function (comment) {
        console.log("Order Callback with comment " + comment);
        $scope.sendOrder(comment);


    };

    $scope.toggleCollapse = function (item) {
        if (!item.expanded) {
            item.expanded = true;
        } else
            item.expanded = false;

        console.log("item:" + item.localizedName + " expanded:" + item.expanded);
    };
    $scope.sendOrder = function (comment) {

        $scope.sharedData.showLogonDialog(function (success) {
            if (success === true) {

                if ($scope.sharedData.globals.orderpay) {
                    console.log("You got to pay!");
                }

                if ($scope.sendAllowed) {
                    $scope.sharedData.sendLog("sendOrder", 0, $scope.sharedData.hotelID);

                    $scope.senddata.message = comment;
                    $scope.senddata.hid = $scope.sharedData.hotelID;
                    $scope.senddata.id = $scope.sharedData.user.userID;

                    $scope.senddata.room = $scope.sharedData.user.lastroom;

                    $scope.senddata.name = $scope.sharedData.user.lastname;

                    $scope.senddata.lang = TranslationFactory.getLanguage();

                    $scope.senddata.items = $scope.items;

                    if ($scope.sharedData.gpsPos.latitude && $scope.sharedData.gpsPos.longitude) {
                        $scope.senddata.lat = $scope.sharedData.gpsPos.latitude;
                        $scope.senddata.lon = $scope.sharedData.gpsPos.longitude;
                    }


                    $scope.sendAllowed = false;
                    theTimer = $timeout(function () {
                        $scope.sendAllowed = true;
                        console.log("Send Timeout");
                    }, 2500);

                    $http({
                        method: 'POST',
                        url: urlPrefix + '/roomService',
                        headers: {'Content-Type': 'application/json; charset=UTF-8'},
                        data: $scope.senddata
                    }).success(function (data) {

                        $scope.sendAllowed = true;
                        $timeout.cancel(theTimer);
                        $scope.senddata.message = "";
                        console.log("Send of Room Service done " + JSON.stringify(data));
                        $scope.sharedData.loadHistory($scope.sharedData.hotelID);
                        // $location.path("/send");
                        $scope.sharedData.showMessages();
                    });
                }
            }
        }, true, true, 0);
    };

    $scope.changeTotalColor = function () {

    };
    // $scope.goHome = function () {
    //     $location.path($scope.sharedData.globals.homePath);
    //     $scope.sharedData.trackScreen("home", $scope.hotel.shortcut, $scope.tracker);
//
    //   };
    $scope.showCart = function () {

        $scope.sharedData.sendLog("showCart", 0, $scope.sharedData.hotelID);

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'menuModal',
            controller: 'MenuModalController',
            windowClass: 'menuModal',
            resolve: {
                param: function () {
                    var ret = [];
                    ret.items = $scope.items;
                    ret.callback = $scope.orderCallback;
                    return ret;
                }
            }
        });

        modalInstance.result.then(function (selectedItem) {
            console.log('Modal closed with result ' + selectedItem);

        }, function () {
            console.log('Modal dismissed at: ' + new Date());
        });
    };
    $scope.addToCart = function (item) {
        console.log("added item " + item.localizedName + " with price " + item.price);
        $scope.sharedData.sendLog("addToCart", item.id, $scope.sharedData.hotelID);

        var itemFound = false;
        for (index = 0; index < $scope.items.length; index++) {
            if ($scope.items[index].id == item.id) {
                $scope.items[index].count++;
                itemFound = true;
            }
        }
        if (!itemFound) {
            item.count = 1;
            $scope.items.push(item);
        }


    };

    $scope.totalPrice = function () {
        var total = 0;
        for (index = 0; index < $scope.items.length; index++) {
            total += $scope.items[index].price * $scope.items[index].count;
        }
        if (total <= $scope.sharedData.globals.chargeMin) {
            total += $scope.sharedData.globals.charge;
        }
        return total;
    };
    $scope.totalItems = function () {
        var total = 0;
        for (index = 0; index < $scope.items.length; index++) {
            total += $scope.items[index].count;
        }
        return total;
    };

    $scope.historyClass = function (item) {
        if (item.me)
            return "chatMe";
        else
            return "chatOther";
    };
    $scope.loadMenu = function () {

        $scope.sharedData.trackScreen("menu", $scope.hotel.shortcut, $scope.tracker);

        $scope.sharedData.sendLog("menu", 0, $scope.sharedData.hotelID);


        // should be replaced by Angular's $http call


    };
});
