myApp.controller('ServiceCtrl', function ($rootScope, $timeout, $scope, $location, $translate, $http, $sce, $filter, SharedData, TranslationFactory) {
    $scope.sharedData = SharedData.data;
    $scope.inputs = [];
    $scope.sendAllowed = true;
    $scope.senddata = {};
    $scope.wirtPlace = "at";    // Phone input: Default flag is Austria
    $scope.datePickerOptions = {
        format: 'DD.MM.YYYY',
        widgetPositioning: {
            horizontal: 'right'
        }
    };
    $scope.timePickerOptions = {
        format: 'HH:mm',
        widgetPositioning: {
            horizontal: 'right'
        },
        stepping: $scope.sharedData.hotel.wirt.dateTimeInputStepSize
    };
    $scope.isPhone;


    function isPhone(x) {
        if (x.matches) {
            $scope.isPhone = true;
        } else {
            $scope.isPhone = false;
        }
    }

    var x = window.matchMedia("(max-width: 768px)");
    isPhone(x); // Call listener function at run time


    $scope.getHtml = function (html) {
        return $sce.trustAsHtml(html);
    };

    $scope.goHome = function () {
        $location.path("#/?" + $scope.hid);
        sendTrackingHit();
    };

    function sendTrackingHit() {
        if ($scope.sharedData.currentParent !== 0) {
            $scope.parentItem = $filter('filter')($scope.sharedData.hotel.mainMenu, {
                id: $scope.sharedData.currentParent
            })[ 0 ];
            if ($scope.parentItem.trackingcode !== undefined) {
                $scope.sharedData.trackScreen($scope.parentItem.trackingcode, $scope.hotel.shortcut, $scope.tracker);
            } else {
                $scope.sharedData.trackScreen("service", $scope.hotel.shortcut, $scope.tracker);
            }
        } else {
            $scope.sharedData.trackScreen("home", $scope.hotel.shortcut, $scope.tracker);
        }
    }

    $scope.send = function () {
        if (formFilled()) {
            $scope.sharedData.showLogonDialog(function (success) {
                $scope.sharedData.sendLog("sendService", $scope.sharedData.services.currentService.id, $scope.sharedData.hotelID);

                if (success === true) {
                    if ($scope.sendAllowed) {
                        console.log("allowed");

                        $scope.senddata.hid = $scope.sharedData.hotelID;
                        $scope.senddata.id = $scope.sharedData.user.userID;
                        $scope.senddata.room = $scope.sharedData.user.lastroom;
                        $scope.senddata.name = $scope.sharedData.user.lastname;
                        $scope.senddata.lang = TranslationFactory.getLanguage();
                        $scope.senddata.event = $scope.sharedData.services.currentService.id;
                        $scope.senddata.message = $scope.comment;
                        $scope.senddata.additionalValues = $scope.inputs;

                        if ($scope.sharedData.gpsPos.latitude && $scope.sharedData.gpsPos.longitude) {
                            $scope.senddata.lat = $scope.sharedData.gpsPos.latitude;
                            $scope.senddata.lon = $scope.sharedData.gpsPos.longitude;
                        }

                        $scope.sendAllowed = false;
                        theTimer = $timeout(function () {
                            $scope.sendAllowed = true;
                            console.log("Send Timeout");
                        }, 2500);

                        $http({
                            method: 'POST',
                            url: urlPrefix + '/sendClientMessage',
                            headers: {'Content-Type': 'application/json; charset=UTF-8'},
                            data: $scope.senddata
                        }).success(function (data) {
                            $scope.sendAllowed = true;
                            $timeout.cancel(theTimer);
                            $scope.senddata.message = "";
                            console.log("Send done " + JSON.stringify(data));
                            if (data.chat) {
                                $scope.sharedData.clientHistory.chat = data.chat;
                            }
                            $scope.sharedData.loadHistory($scope.sharedData.hotelID);
                            // $location.path("/send");
                            $scope.sharedData.showMessages();
                        });
                    } else {
                        console.log("not allowed");
                    }
                }
            }, true, true, 0);
        }
    };

    $scope.inputStyle = function (item) {
        if (item.error) {
            return {'background-color': '#FFDDDD'};
        } else
            return {};
    };

    function getToday() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;  // January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = dd + '.' + mm + '.' + yyyy;
        return today;
    }

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function formFilled() {
        var ret = true;
        if ($scope.inputs) {
            containsDateToday = false;
            containsDay = false;
            for (var i = 0; i < $scope.inputs.length; i++) {
                console.log($scope.inputs[ i ].name + " - " + $scope.inputs[ i ].value);

                if ($scope.inputs[ i ].name === 'DAY') {
                    containsDay = true;
                    console.log($scope.inputs[ i ].value + " vs " + getToday());
                    if ($scope.inputs[ i ].value === getToday()) {
                        console.log("This is today");
                        containsDateToday = true;
                    }
                }

                if ($scope.inputs[ i ].type === 'date') {
                    containsDay = true;
                }

                if ($scope.inputs[ i ].type === 'invoiceaddress') {
                    var invoiceAddress = $scope.inputs[ i ].value;
                    if (!invoiceAddress || !invoiceAddress.address1 || !invoiceAddress.zipCode || !invoiceAddress.domicile || !invoiceAddress.country) {
                        $scope.inputs[ i ].error = "INVOICE_ADDRESS_INCOMPLETE";
                        ret = false;
                    } else {
                        $scope.inputs[ i ].error = undefined;
                    }
                } else {
                    if ($scope.inputs[ i ].value == null || $scope.inputs[ i ].value === undefined) {
                        ret = false;

                        if ($scope.inputs[ i ].type === 'time')
                            $scope.inputs[ i ].error = "TIME_FORMAT";
                        else
                            $scope.inputs[ i ].error = "VALID_VALUE";
                    } else {
                        $scope.inputs[ i ].error = null;
                        if (($scope.inputs[ i ].inputType === 'number' && $scope.inputs[ i ].value < 0) ||
                            ($scope.inputs[ i ].type === 'email' && !validateEmail($scope.inputs[ i ].value))) {
                            $scope.inputs[ i ].error = "VALID_VALUE";
                            ret = false;
                        }
                    }
                }
            }
        }

        if (!containsDay)
            containsDateToday = true;

        if (containsDateToday) {
            for (var i = 0; i < $scope.inputs.length; i++) {
                if ($scope.inputs[ i ].type === 'time' && !$scope.inputs[ i ].error) {
                    var dat = $scope.inputs[ i ].value;
                    var minOfDay = dat.getUTCMinutes() + dat.getUTCHours() * 60;
                    var day = new Date();
                    var minOfNow = day.getMinutes() + day.getHours() * 60;

                    console.log("minOfDay is " + minOfDay + " minOfNew " + minOfNow);

                    if (minOfDay < minOfNow) {
                        $scope.inputs[ i ].error = "TIME_FUTURE";
                        ret = false;
                    }
                }
            }
        }

        return ret;
    }

    $scope.loadService = function () {
        $scope.sharedData.trackScreen("service/" + $scope.sharedData.services.currentService.trackingcode, $scope.hotel.shortcut, $scope.tracker);
        $scope.sharedData.sendLog("service", $scope.sharedData.services.currentService.id, $scope.sharedData.hotelID);

        if ($scope.sharedData.services.currentService.parsedInput) {
            $scope.inputs = $scope.sharedData.services.currentService.parsedInput;

            if (SharedData.data.hotel.wirt.place) {
                $scope.wirtPlace = SharedData.data.hotel.wirt.place.toLowerCase();
            }
        }
    };

    $scope.updateDateTime = function (dateTime, input) {
        // Add timezone difference manually because date is send as object
        var temp = new Date(dateTime.full);
        temp.setMinutes(temp.getMinutes() - temp.getTimezoneOffset());
        input.value = temp;
    };

    $scope.isGeneralInput = function (input) {
        var tmp = (input.type !== 'list' && input.type !== 'radio' && input.type !== 'dropdown' && input.type != null && input.type !== 'number'
            && input.type !== 'phone' && input.type !== 'date' && input.type !== 'time' && input.type !== 'callreception' && input.type !== 'invoiceaddress') ||
            ($scope.isPhone && (input.type === 'date' || input.type === 'time'));
        return tmp;
    };
});
