myApp.config(function ($translateProvider) {
    $translateProvider.translations('ru', {
        ADDRESS_HEADER: 'Адрес',
        ARRIVAL_LABEL: 'Заезд',
        ASK_FOR_CHECK_OUT_LABEL: 'Вы можете заранее подготовить свои платежные данные.',
        BACK: 'назад',
        CHANNELS_LABEL: 'канал',
        CHARGE: 'плата за услуги',
        CHECK_IN_BUTTON: 'Выполнить регистрацию',
        CHECK_IN_CARD_INFO_LABEL: 'Просим Вас заполнить всю форму',
        CHECK_IN_CARD_LABEL: 'Персональные данные',
        CHECK_IN_FINISH_THANK_YOU_LABEL: 'Спасибо! Ваши данные сохранены. Вы можете забрать свой ключ на ресепшн по приезду. Желаем безопасной поездки',
        CHECK_IN_THANK_YOU_LABEL: 'Спасибо за онлайн-регистрацию.',
        CHECK_OUT_BUTTON: 'Сохранить адрес для выставления счета',
        CHECK_OUT_CARD_INFO_LABEL: 'Желаете использовать другой адрес для выставления счета?',
        CHECK_OUT_CARD_LABEL: 'Адрес для выставления счета',
        CHECK_OUT_THANK_YOU_LABEL: 'Спасибо! Ваши данные сохранены. Вы можете забрать свой ключ на ресепшн по приезду. Желаем безопасной поездки.',
        CITY_LABEL: 'Город',
        COMMENT: 'комментарий...',
        COMPANY_NAME1_LABEL: 'Наименование компании',
        COMPANY_NAME2_LABEL: 'Наименование компании',
        COUNTRY_LABEL: 'Страна',
        DATE_OF_BIRTH_LABEL: 'Дата рождения',
        DEPARTURE_LABEL: 'Выезд',
        DO_CHECK_OUT_BUTTON: 'Подготовьте платежную информацию',
        EMAIL_LABEL: 'e-mail',
        ERROR_MESSAGE: 'Сообщение об ошибке',
        FINISH_CHECK_IN_BUTTON: 'Не сейчас',
        FIRST_NAME_LABEL: 'Имя',
        INPUT_PROMPT_COMMENT: 'комментарий',
        LANGUAGE_LABEL: 'язык',
        LAST_NAME_LABEL: 'Фамилия',
        LOADING_LABEL: 'Загрузка',
        LOGOFFDIALOG: 'Вы действительно желаете выйти?',
        MANDATORY_FIELD: '* Обязательное поле',
        MESSAGE_TO_RECEPTION: 'Свяжитесь с ресепшн',
        MONTH_APRIL: 'Апрель',
        MONTH_AUGUST: 'Август',
        MONTH_DECEMBER: 'Декабрь',
        MONTH_FEBRUARY: 'Февраль',
        MONTH_JANUARY: 'Январь',
        MONTH_JULY: 'Июль',
        MONTH_JUNE: 'Июнь',
        MONTH_MARCH: 'Март',
        MONTH_MAY: 'Май',
        MONTH_NOVEMBER: 'Ноябрь',
        MONTH_OCTOBER: 'Октябрь',
        MONTH_SEPTEMBER: 'Сентябрь',
        NATIONALITY_LABEL: 'Гражданство',
        NAV_HOME: 'Главная',
        NAV_MOBILE_CHECK_IN: 'Мобильное заселение',
        NO_DATA_FOUND: 'Данные о бронировании не найдены. Проверьте введенную информацию.',
        NO_ROOM: 'Комната не найдена',
        NO: 'Нет',
        ORDER: 'заказ',
        PASSPORT_LABEL: 'Номер паспорта',
        PLEASE_ENTER: 'Пожалуйста, введите Ваш номер комнаты и имя',
        PRICE_LABEL: 'цена',
        PROCEED: 'продолжить',
        REPLY: 'Ответ',
        RESERVATION_COMMENT: 'Примечание',
        ROOM_NAME: 'имя',
        ROOM_NUMBER: 'Комната',
        SALUTATION_LABEL: 'название',
        SEARCH: 'Поиск',
        SEND_MESSAGE: 'Отправить',
        STREET_LABEL: 'Улица',
        STREET_LINE1_LABEL: 'Улица (ряд 1)',
        STREET_LINE2_LABEL: 'Улица (ряд 2)',
        USE_CREDIT_CARD_LABEL: 'Использовать эту кредитную карту',
        USE_PRIVATE_ADDRESS_LABEL: 'Использовать личный адрес?',
        VALUE_LABEL: 'значение',
        YES: 'Да',
        YOUR_MESSAGE: 'Ваше сообщение',
        YOUR_ORDER: 'заказ',
        ZIP_CODE_LABEL: 'Индекс',
        VALID_VALUE: 'Пожалуйста, введите корректное значение',
        NOT_AVAILABLE_WIFI: 'Чтобы использовать эту функцию необходимо подключиться к сети Wi-Fi!',
        NOT_AVAILABLE_LOCATION: 'Чтобы использовать эту функцию Вам необходимо быть в отеле!',
        NOT_AVAILABLE: 'Функция недоступна',
        CHECK_LOCATION: 'Проверка расположения…',
        DESCRIPTION_LABEL: 'Описание',
        CONTACT_RECEPTION_PERMANENT: 'Остались вопросы? Ресепшн с радостью ответит',
        CHECK_NEARBY_HOTELS_LABEL: 'Ищем отели поблизости, пожалуйста, подождите',
        NO_GEO_LOCATION_FOUND: 'Не удалось определить расположение',
        NO_GEO_LOCATION_SERVICE_FOUND: 'Пожалуйста, включите службу геолокации на Вашем устройстве',
        //checkout
        ACCEPT_AGB: '*<a class="a_orig" href=\'{{agb}}\' target=\'_blank\'>Общие условия</a> прочитаны и приняты.&nbsp;&nbsp;',
        CANCEL_LABEL: 'Отменить',
        CHECK_OUT_VALIDATION: 'Пожалуйста, подтвердите свою личность',
        CHECKOUT: 'Выезд',
        CHECKOUT_DONE_MESSAGE: 'Почти готово! Пожалуйста, оставьте ключи на стойке регистрации гостей.',
        CHECKOUT_DONE_MESSAGE_WITH_INVOICE: 'Почти готово! Ваш счет будет отправлен по электронной почте. Пожалуйста, оставьте ключи на стойке регистрации гостей.',

        CHECKINOUT_ERROR_MESSAGE: 'Ой! Что-то пошло не так. Пожалуйста, обратитесь к администратору на стойке регистрации, и наш персонал обо всем позаботится.',
        CONSUMATE_QUESTION: 'Использовали что-либо из минибара? Пожалуйста, добавьте позиции.',
        EDIT_LABEL: 'Редактировать',
        FOLIO_DETAILS: 'Информация для выставления счета',
        FOLIO_SUM: 'Сумма',
        INVOICE_ADDRESS: 'Адрес выставления счета',
        INVOICE_INFORMATION: 'Информация для выставления счета. Услуга регистрации выезда будет доступна только в день отъезда.',
        INVOICE_MESSAGE_ON_CONDITION: 'Все сборы включены. Возможны изменения.',
        ITEM_DESCRIPTION_LABEL: 'Позиция',
        ITEM_QUANTITY_LABEL: 'Количество',
        ITEM_TRANSACTION_DATE_LABEL: 'Дата транзакции',
        MANDATORY_MAIL_MESSAGE: 'Просто укажите ваш адрес эл. почты для выставления счета.',
        NOT_FOUND_NAME_ROOM_DIALOG: 'Ой! Что-то пошло не так. Пожалуйста, повторите попытку или обратитесь к администратору на стойке регистрации.',
        PAY_DIFFERENCE: 'Оплатить',
        PAYED_AMOUNT: 'Уплаченная сумма',
        PAYMENT_FAILED_MESSAGE: 'Ой! Что-то пошло не так. Пожалуйста, повторите процесс оплаты.',
        PAYMENT_SUCCESS_MESSAGE: 'Готово! Следующий этап — окончательная регистрация выезда!',
        PROCESS_CART: 'Забронировать',
        REMAINING_AMOUNT: 'Осталось к оплате',
        REQUIRED_LABEL: 'Это поле обязательно для заполнения!',
        ROOM_NUMBER_CHECKOUT: 'Номер в отеле',
        SAVE_LABEL: 'Сохранить',
        SKIP_CART: 'Пропустить проверку минибара',
        SUBMIT_CONFIRMATION_MESSAGE: 'Вы действительно хотите отправить форму?',
        SUBMIT_LABEL: 'Забронировать',
        SUM_AMOUNT: 'Общая сумма',
        YOUR_ORDER_CHECKOUT: 'Употребленные продукты'
    });
});
