myApp.config(function ($translateProvider) {
    $translateProvider.translations('fr', {
        ADDRESS_HEADER: 'Adresse',
        ARRIVAL_LABEL: 'Arrivée',
        ASK_FOR_CHECK_OUT_LABEL: 'Vous pouvez d\'ores et déjà préparer les informations de facturation',
        BACK: 'Retour',
        CHANNELS_LABEL: 'canal',
        CHARGE: 'Frais de service',
        CHECK_IN_BUTTON: 'Enregistrement en cours',
        CHECK_IN_CARD_INFO_LABEL: 'Merci de renseigner l\'ensemble du document',
        CHECK_IN_CARD_LABEL: 'Information personnelle',
        CHECK_IN_FINISH_THANK_YOU_LABEL: 'Merci ! Vos informations de facturation ont été enregistrées. Il ne vous reste plus qu\'à récupérer votre clé de chambre à votre arrivée à l\'hôtel. Bon voyage',
        CHECK_IN_THANK_YOU_LABEL: 'Merci pour votre entregistrement en ligne',
        CHECK_OUT_BUTTON: 'Enregistrez l\'adresse de facturation',
        CHECK_OUT_CARD_INFO_LABEL: 'Souhaitez-vous une adresse de facturation différente?',
        CHECK_OUT_CARD_LABEL: 'Adresse de facturation',
        CHECK_OUT_THANK_YOU_LABEL: 'Merci ! Vos informations de facturation ont été engegistrées. Il ne vous reste plus qu\'à récupérer votre clé de chambre à votre arrivée à l\'hôtel. Bon voyage. ',
        CITY_LABEL: 'Ville',
        COMMENT: 'Commentaire...',
        COMPANY_NAME1_LABEL: 'Nom de la société',
        COMPANY_NAME2_LABEL: 'Nom de la société',
        COUNTRY_LABEL: 'Pays',
        DATE_OF_BIRTH_LABEL: 'Date de naissance',
        DEPARTURE_LABEL: 'Départ',
        DO_CHECK_OUT_BUTTON: 'Facturation en préparation',
        EMAIL_LABEL: 'e-mail',
        ERROR_MESSAGE: 'Message d\'erreur',
        FINISH_CHECK_IN_BUTTON: 'Pas maintenant',
        FIRST_NAME_LABEL: 'Prénom',
        INPUT_PROMPT_COMMENT: 'Commentaire',
        LANGUAGE_LABEL: 'langue',
        LAST_NAME_LABEL: 'Nom de famille',
        LOADING_LABEL: 'Chargement',
        LOGOFFDIALOG: 'Voulez-vous vraiment vous déconnecter ? ',
        MANDATORY_FIELD: '* Information obligatoire',
        MESSAGE_TO_RECEPTION: 'Contacter la Réception',
        MONTH_APRIL: 'Avril',
        MONTH_AUGUST: 'Août',
        MONTH_DECEMBER: 'Décembre',
        MONTH_FEBRUARY: 'Février',
        MONTH_JANUARY: 'Janvier',
        MONTH_JULY: 'Juillet',
        MONTH_JUNE: 'Juin',
        MONTH_MARCH: 'Mars',
        MONTH_MAY: 'Mai',
        MONTH_NOVEMBER: 'Novembre',
        MONTH_OCTOBER: 'Octobre',
        MONTH_SEPTEMBER: 'Septembre',
        NATIONALITY_LABEL: 'Nationalité',
        NAV_HOME: 'Accueil',
        NAV_MOBILE_CHECK_IN: 'Enregistrement en ligne',
        NO_DATA_FOUND: 'Aucune réservation est disponible. Mercie de vérifier les informations communiquées',
        NO_ROOM: 'Chambre nom identifiée',
        NO: 'Non',
        ORDER: 'Ordre',
        PASSPORT_LABEL: 'Numero de passeport',
        PLEASE_ENTER: 'Merci d\'entrer votre numéro de chambre et votre nom',
        PRICE_LABEL: 'prix',
        PROCEED: 'Continuer',
        REPLY: 'Répondre',
        RESERVATION_COMMENT: 'Commentaire',
        ROOM_NAME: 'Nom',
        ROOM_NUMBER: 'Chambre',
        SALUTATION_LABEL: 'titre',
        SEARCH: 'Recherche',
        SEND_MESSAGE: 'Envoyer',
        STREET_LABEL: 'Rue',
        STREET_LINE1_LABEL: 'Rue (Ligne 1)',
        STREET_LINE2_LABEL: 'Rue (Ligne 2)',
        USE_CREDIT_CARD_LABEL: 'Utilisez la carte de crédit suivante',
        USE_PRIVATE_ADDRESS_LABEL: 'Souhaitez-vous utiliser une adresse personnelle ?',
        VALUE_LABEL: 'valeur',
        YES: 'Oui',
        YOUR_MESSAGE: 'Votre message',
        YOUR_ORDER: 'votre commande',
        ZIP_CODE_LABEL: 'Code postal',
        VALID_VALUE: 'Veuillez entrer une valeur valide',
        NOT_AVAILABLE_WIFI: 'Pour utiliser cette fonctionnalité, vous devez être connecté au réseau WiFi',
        NOT_AVAILABLE_LOCATION: 'Pour utiliser cette fonctionnalité, vous devez être  dans l\'hôtel',
        NOT_AVAILABLE: 'Fonctionnalité non disponible',
        CHECK_LOCATION: 'Vérification de l\'emplacement',
        DESCRIPTION_LABEL: 'Description',
        CONTACT_RECEPTION_PERMANENT: 'Des questions ? La réception sera ravie de vous aider',
        CHECK_NEARBY_HOTELS_LABEL: 'Merci de patienter, nous recherchons des hôtels dans votre région',
        NO_GEO_LOCATION_FOUND: 'Impossible de définir votre localisation',
        NO_GEO_LOCATION_SERVICE_FOUND: 'Veuillez activer les services de localisation sur votre appareil',
        PASSWORD_PROTECT: 'Cette zone est uniquement accessible avec un mot de passe.',
        ROOM_NUMBER_CHECKOUT: 'N° de chambre',
        CHECK_OUT_VALIDATION: 'Merci de valider votre identité',
        REQUIRED_LABEL: 'Ce champ ne peut rester vide !',
        CONSUMATE_QUESTION: 'Avez-vous utilisé le minibar ? Veuillez ajouter les articles.',
        PROCESS_CART: 'Réserver des articles',
        SKIP_CART: 'Ignorer les articles du minibar',
        YOUR_ORDER_CHECKOUT: 'Votre consommation',
        SUBMIT_LABEL: 'Réserver des articles',
        SUBMIT_CONFIRMATION_MESSAGE: 'Confirmez-vous ces articles ?',
        INVOICE_ADDRESS: 'Adresse de facturation',
        EDIT_LABEL: 'Modifier',
        CANCEL_LABEL: 'Annuler',
        SAVE_LABEL: 'Enregistrer',
        FOLIO_DETAILS: 'Détails de la facture',
        INVOICE_MESSAGE_ON_CONDITION: 'Toutes les consommations à ce jour. Sous réserve de modification.',
        ITEM_DESCRIPTION_LABEL: 'Article',
        ITEM_QUANTITY_LABEL: 'Quantité',
        ITEM_TRANSACTION_DATE_LABEL: 'Date de la transaction',
        FOLIO_SUM: 'Montant',
        SUM_AMOUNT: 'Montant total',
        PAYED_AMOUNT: 'Montant réglé',
        REMAINING_AMOUNT: 'Montant restant dû',
        ACCEPT_AGB: '*<a class="a_orig" href=\'{{agb}}\' target=\'_blank\'>CGV</a> lues et acceptées.&nbsp;&nbsp;',
        PAY_DIFFERENCE: 'Payer maintenant',
        PAYMENT_SUCCESS_MESSAGE: 'Paiement effectué ! La prochaine étape est le check-out !',
        CHECKOUT: 'Check-out',
        CHECKOUT_DONE_MESSAGE: 'Vous y êtes presque ! Veuillez déposer votre clef à la réception.',
        CHECKOUT_DONE_MESSAGE_WITH_INVOICE: 'Vous y êtes presque ! La facture vous sera envoyée par e-mail. Veuillez déposer votre clef à la réception.',

        PAYMENT_FAILED_MESSAGE: 'Oups. Quelque chose n\'a pas fonctionné. Veuillez recommencer la procédure de règlement.',
        MANDATORY_MAIL_MESSAGE: 'Indiquez simplement votre e-mail pour recevoir la facture.',
        CHECKINOUT_ERROR_MESSAGE: 'Oups. Quelque chose n\'a pas fonctionné. Veuillez vous adresser au personnel de la réception.',
        INVOICE_INFORMATION: 'Informations concernant les détails de la facture. Le service chargé du check-out sera disponible le jour de votre départ.',
        NOT_FOUND_NAME_ROOM_DIALOG: 'Oups. Quelque chose n\'a pas fonctionné. Veuillez réessayer ou contactez la réception.'
    });
});
