function getFCMToken()
{
    messaging.getToken()
            .then(function (currentToken) {
                if (currentToken) {
                    console.log("FCM: Token is " + currentToken);
                    angular.element(document.getElementById('mainBody')).scope().sharedData.sendPushRegister(currentToken);

                    //sendTokenToServer(currentToken);
                    //updateUIForPushEnabled(currentToken);
                } else {
                    // Show permission request.
                    console.log('FCM: No Instance ID token available. Request permission to generate one.');
                    // Show permission UI.
                    //updateUIForPushPermissionRequired();
                    //setTokenSentToServer(false);
                }
            })
            .catch(function (err) {
                console.log('FCM: An error occurred while retrieving token. ', err);
                // showToken('Error retrieving Instance ID token. ', err);
                //   setTokenSentToServer(false);
            });
}

function checkFCM()
{
    messaging.requestPermission()
            .then(function () {
                console.log('FCM: Notification permission granted.');
                getFCMToken();
            })
            .catch(function (err) {
                console.log(' FCM:Unable to get permission to notify.', err);
            });
}
