(function () {
    var uid, hid, urlBase;

    if (testLocalStorage()) {
        uid = localStorage.getItem('ngStorage-userID');
        hid = localStorage.getItem('ngStorage-hotelID');
    }

    if (window.location) {
        var pathArray = window.location.href.split('/');
        urlBase = pathArray[ 0 ] + '//' + pathArray[ 2 ];
    }

    function testLocalStorage() {
        try {
            return window.localStorage;
        } catch (e) {
            return false;
        }
    }
})();
