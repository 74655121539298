(function () {

    angular
        .module('Order4meBase')
        .config([ '$translateProvider', 'translationHelperProvider', function ($translateProvider, translationHelperProvider) {

            $translateProvider.registerAvailableLanguageKeys([ 'en', 'de', 'es', 'it', 'cz', 'ru', 'sk', 'pl', 'fr', 'ro', 'az' ], {
                'en_*': 'en',
                'de_*': 'de',
                'es_*': 'es',
                'it_*': 'it',
                'cz_*': 'cz',
                'pl_*': 'pl',
                'ru_*': 'ru',
                'sk_*': 'sk',
                'fr_*': 'fr',
                'ro_*': 'ro',
                'az_*': 'az'
            }).fallbackLanguage('en').determinePreferredLanguage();

            translationHelperProvider.translateProvider = $translateProvider;
        } ]);

    /*
     * This is a hack! Read below for more information
     */
    angular
        .module('Order4meBase')
        .provider('translationHelper', function () {
            this.translateProvider = {};
            this.$get = function () {
                return {
                    translateProvider: this.translateProvider
                };
            };
        });

    angular
        .module('Order4meBase')
        .factory('TranslationFactory', function ($translate, $http, $q, LocalStorage, translationHelper) {

            return {
                use: useLanguage,
                getLanguage: getLanguage
            };

            function useLanguage(lang, hotelId) {
                console.log('Attempt language change for hotel ' + hotelId + ' and language ' + lang);
                lang = fixLanguageCode(lang);

                setLanguage(lang, null);    // set general lang first to prevent
                                            // waiting for async call

                if (hotelId) {
                    var hotelLangKey = lang + '-' + hotelId;
                    load(hotelLangKey, lang, hotelId).then(function (dl) {
                        console.log('Translation lang gotten is ' + dl);
                        setLanguage(dl, lang);
                    }).catch(function () {
                        console.log('No custom language-file found');
                    });
                }
            }


            function getLanguage() {
                return fixLanguageCode($translate.use());
            }

            function setLanguage(lang, fallbackLang) {
                console.log('Changing Language to ' + lang);
                $translate.use(lang);


                if (fallbackLang) {
                    setFallback([ fallbackLang, 'en' ]);
                } else {
                    setFallback([ 'en' ]);
                }
            }

            function fixLanguageCode(lang) {
                // To fix language abbreviations like 'en_US'
                if (lang) {
                    return lang.substring(0, 2);
                }

                return 'en';
            }

            function load(translationKey, lang, hotelId) {
                let def = $q.defer();

                fetchTranslation(hotelId, lang).then(function (translation) {
                    console.log('Translation is ' + translation);
                    if (translation && translation.length > 0) {
                        uploadTranslation(translationKey, translation);
                        def.resolve(translationKey);
                    }
                    else {
                        console.log('Translation is empty');
                        def.resolve(lang);
                    }
                }).catch(function () {
                    def.reject(lang);
                });

                return def.promise;
            }

            function fetchTranslation(hotelId, lang) {
                let def = $q.defer();
                let mainUrl = urlPrefix + '/translate?hid=' + hotelId + '&lang=' + lang + '&action=getTranslations';

                $http({
                    method: 'GET',
                    url: mainUrl,
                    headers: {'Content-Type': 'application/json'}
                }).then(function (response) {
                    def.resolve(response.data);
                }, function (error) {
                    console.log('Translation failed ', error);
                    def.reject();
                });

                return def.promise;
            }

            function uploadTranslation(key, translation) {
                translationHelper.translateProvider.translations(key, translation);
                console.log('Translation uploaded for key', key);
            }

            function setFallback(language) {
                translationHelper.translateProvider.fallbackLanguage(language);
            }
        });

    /*
     * This is a custom angular-translate loader that should be used in a case
     * such as ours. Simply configure where and how it gets the translations and
     * you are good to go and add the following line to app configurations.
     *
     * $translateProvider.useLoader('translationUse');
     *
     *
     * However, for some reason it does not work correctly with our application -
     * the loaded translation is always reverted to a previous state. Further
     * research is needed, but in the mean time the above hack with
     * 'translationHelperProvider' should suffice. For more information read: -
     * https://angular-translate.github.io/docs/#/guide/13_custom-loaders
     */
    /*
     * myApp.factory('translationUse', function ($q, $translate) {
     *
     * return function (options) {
     *
     * var languageKey = options.key; var hotelId = "?"; // Fetch or create a
     * translation table var translations =
     * translationFactory.fetchTranslation(languageKey, hotelId);
     *
     * let promise = $q.resolve(translations); return promise; }; });
     */
})
();
