'use strict';
'use strict';

var slideOut = false;

function slid() {
    slide(slideOut);
    slideOut = !slideOut;
}

function slide(selected) {
    console.log('slide back ' + selected);
    $('#slidemenu')
        .stop()
        .animate({
            left: selected ? '-100%' : '0px'
        });

    $('#navbar-height-col')
        .stop()
        .animate({
            left: selected ? '-80%' : '0px'
        });

    $('.navbar-header')
        .stop()
        .animate({
            left: selected ? '0px' : '60%'
        });
    $('#slidemenu')
        .toggleClass('slide-active');
    $('#page-content, #slide-nav, .navbar, body, .navbar-header')
        .toggleClass('slide-active');
}

$(document)
    .ready(function () {
        //stick in the fixed 100% height behind the navbar but don't wrap it
        $('#slide-nav.navbar .container')
            .append($('<div id="navbar-height-col"></div>'));

        // Enter your ids or classes
        var toggler = '.navbar-toggle';
        var pagewrapper = '#page-content';
        var navigationwrapper = '.navbar-header';
        var menuwidth = '100%'; // the menu inside the slide menu itself
        var slidewidth = '80%';
        var menuneg = '-100%';
        var slideneg = '-80%';
        var selected = '#slidemenu, #page-content, body, .navbar, .navbar-header';

        $(window)
            .on('resize', function () {
                if ($(window)
                        .width() > 767 && $('.navbar-toggle')
                        .is(':hidden')) {
                    $(selected)
                        .removeClass('slide-active');
                }
            });
    });

var externalPageLoader;
myApp.controller('CheckinCtrl', function ($rootScope, $scope, $location, $translate, $http, $uibModal, $timeout, SharedData, TranslationFactory, KeyWordService, EventDispatcher, $sce) {
    var pRn = $location.search().rn;
    var pGn = $location.search().gn;
    var hotelID = $scope.sharedData.hotelID;

    $scope.showDiv = '';
    $scope.usePrivateAddress = false;
    $scope.maxDate = new Date();

    $scope.firstNamePlaceHolder = 'FIRST_NAME_LABEL';
    $scope.lastNamePlaceHolder = 'LAST_NAME_LABEL';
    $scope.roomPlaceHolder = 'ROOM_NUMBER';

    $scope.open = function ($event, object) {
        object.datePickerOpened = true;
    };

    $scope.dateOptions = {
        'starting-day': 1,
        formatYear: 'yy'
    };

    $scope.status = {
        opened: false
    };

    $scope.salutations;
    $scope.countries;
    $scope.form = {};
    $scope.acceptAgb = false;

    $scope.hotelLoaded = false;
    // $scope.gtcgtcText = '';

    EventDispatcher.addEventListener('hotel-loaded', function () {
        $scope.hotelLoaded = true;
    });

    // Workaround to activate angular event loop
    $scope.$watch('hotelLoaded', function () {
        if ($scope.hotelLoaded) {
            // $scope.gtcText = KeyWordService.replaceLinks($scope.sharedData.hotel.wirt.gtcText);
            $scope.init();
        }
    });

    $scope.$on('lang-changed', function(event, lang) {
        $scope.refreshCountries();
        $scope.refreshSalutations();
        $scope.refreshServices();
        $scope.refreshAgb();
    });

    console.log('Reading info. hotelID: ' + hotelID + ' pRn: ' + pRn + ' pGn: ' + pGn);
    console.log('sharedData: ' + JSON.stringify($scope.sharedData));

    $scope.init = function () {
        loadCountryList();
        if (pRn === undefined && pGn === undefined && hotelID !== undefined) {
            loadClientCheckInForm();
        } else {
            loadMobileCheckInData();
        }
    };

    function pad2(number) {
        return (number < 10 ? '0' : '') + number;
    }

    $scope.pad2 = function (number) {
        return pad2(number);
    };

    function loadClientCheckInForm() {

        if (angular.isUndefined($scope.checkindata)) {
            $scope.checkindata = {};
            $scope.checkindata.firstname = '';
            $scope.checkindata.lastname = '';
            $scope.checkindata.room = '';
        }
        $scope.showDiv = 'client-checkin';
    }

    $scope.clientLoadMobileCheckin = function () {
        $scope.sharedData.sendLog('checkin', 0, $scope.sharedData.hotelID);
        if (!$scope.form.clientCheckinForm.$valid) {
            var visibleInvalids = angular.element.find('.ng-invalid:visible');
            if (angular.isDefined(visibleInvalids) && angular.isDefined(visibleInvalids[1])) {
                visibleInvalids[1].focus();
            }
        } else {

            $scope.showDiv = 'loading-data';
            var firstname = $scope.checkindata.firstname;
            var lastname = $scope.checkindata.lastname;
            var room = $scope.checkindata.room;
            console.log('hid: ' + hotelID + '. firstname: ' + firstname + '. lastname: ' + lastname + '. room: ' + room);

            //Do post finding the details of the client searching by firstname, lastname & room number
            var requestData = {
                hid: hotelID,
                firstname: firstname,
                lastname: lastname,
                room: room,
                checkinDate: {
                    day: $scope.checkindata.calendar.selectedDay,
                    month: $scope.calendar.months.indexOf($scope.checkindata.calendar.selectedMonth),
                    year: $scope.checkindata.calendar.selectedYear
                },
            };
            $.ajax({
                type: 'POST',
                url: urlPrefix + '/clientMobileCheckInData',
                data: JSON.stringify(requestData),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                success: function success(data) {
                    $scope.$apply(function () {
                        console.log('loading customer info done');
                        $scope.checkindata = data;

                        if (data.error !== undefined) {
                            console.log('error: ' + data.error);
                            if (data.error === 'NOT_ALLOWED') {
                                $scope.showDiv = 'no-allowed';
                            } else {
                                $scope.showDiv = 'no-data-found';
                            }
                        } else {
                            if (data.dateOfBirth !== undefined) {
                                var dateOfBirth = data.dateOfBirth.split('-');

                                $scope.calendar.selectedYear = parseInt(dateOfBirth[2]);
                                $scope.changedYear($scope.calendar.selectedYear);

                                $scope.calendar.selectedMonth = $scope.calendar.months[parseInt(dateOfBirth[1]) - 1];
                                $scope.changedMonth($scope.calendar.selectedMonth);

                                $scope.calendar.selectedDay = parseInt(dateOfBirth[0]);
                            }

                            if (data.salutations !== undefined) {
                                $scope.salutations = data.salutations;
                            }

                            if (data.addressForm !== undefined) {
                                if ($scope.salutations.indexOf(data.addressForm) < 0) {
                                    $scope.salutations.push(data.addressForm);
                                }
                            }

                            if ($scope.checkindata.hasOwnProperty('invoiceAddress')) {
                                $scope.checkindata.billingAddress = true;
                                $scope.checkindata.showBillingAddressCheckbox = false;
                            } else {
                                $scope.checkindata.billingAddress = false;
                                $scope.checkindata.showBillingAddressCheckbox = true;
                            }
                            if (data.languageCode !== undefined) {
                                TranslationFactory.use(data.languageCode, hotelID);
                            }
                            $scope.displayPassport();

                            $scope.refreshServices();
                            $scope.refreshAgb();

                            $scope.showDiv = 'checkinform';
                            console.log('MobileCheckin checkindata: ' + $scope.checkindata);
                        }
                    });
                }
            });
        }
    };

    $scope.setAgb = function () {
        $scope.acceptAgb = !$scope.acceptAgb;
    };

    $scope.checkin = function () {
        var navigate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        var callback = arguments[1];
        var source = arguments[2];

        if ($scope.form.checkinInputForm && !$scope.form.checkinInputForm.$valid) {
            var visibleInvalids = angular.element.find('.ng-invalid:visible');
            if (angular.isDefined(visibleInvalids) && angular.isDefined(visibleInvalids[1])) {
                visibleInvalids[1].focus();
            }
        } else {
            if (!$scope.acceptAgb) {
                return console.error('No consent was given!');
            }

            $scope.checkindata.dateOfBirth = pad2($scope.calendar.selectedDay) + '-' + pad2($scope.calendar.months.indexOf($scope.calendar.selectedMonth) + 1) + '-' + $scope.calendar.selectedYear;

            if (!$scope.checkindata.billingAddress) {
                resetCheckoutFields();
            }

            $scope.checkindata.hmsInvoiceAddress = $scope.checkindata.invoiceAddress;
            // should be replaced by Angular's $http call
            $http({
                method: 'POST',
                url: urlPrefix + '/checkin',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    reservation: $scope.checkindata,
                    source: source ? source : ''
                }
            }).then(function (response) {
                if (response.data && response.data.success) {
                    if (navigate) {
                        if ($scope.sharedData.hotel.wirt.group === 6029646313291776) {
                            $scope.goToRegistrationCardPreview();
                            return;
                        }

                        $scope.showDiv = 'checkinfinished';
                        $scope.sharedData.sendLog('checkinSuccess', 0, $scope.sharedData.hotelID);

                        $scope.goToProventSpecificLandingPage()
                    } else if (callback) {
                        callback();
                    }
                } else {
                    $scope.showDiv = 'checkinerror';
                }
            });

            $scope.showDiv = ''; // show loading screen
        }
    };

    $scope.displayPassport = function () {
        if (!$scope.sharedData.hotel.wirt.place
            || $scope.sharedData.hotel.wirt.place !== $scope.checkindata.nationality
            || $scope.sharedData.hotel.wirt.group === 6029646313291776) {
            $scope.checkindata.showIdCardNumberField = true;
        } else {
            $scope.checkindata.showIdCardNumberField = false;
        }
    };

    //This function is not reached anymore as its button in the front end has been deactivated
    $scope.usePrivateAddressForInvoice = function () {
        $scope.usePrivateAddress = !$scope.usePrivateAddress;

        if ($scope.usePrivateAddress) {
            $scope.checkindata.invoiceAddress = JSON.parse(JSON.stringify($scope.checkindata.privateAddress));
        } else {
            resetCheckoutFields();
        }
    };

    function loadCountryList(lang) {
        var requestData = {
            lang: TranslationFactory.getLanguage()
        };

        if (lang) {
            requestData.lang = lang;
        }

        // TODO: should be replaced by Angular's $http call to get rid of $scope.$apply
        $.ajax({
            type: 'POST',
            url: urlPrefix + '/retrieveCountries',
            data: JSON.stringify(requestData),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: function success(data) {
                $scope.$apply(function () {
                    console.log('loading country list done');
                    $scope.countries = data;
                });
            }
        });
    }

    function resetCheckoutFields() {
        $scope.checkindata.invoiceAddress = '';
        $scope.checkindata.companyName1 = '';
        $scope.checkindata.companyName2 = '';
        $scope.checkindata.invoiceAddress.address1 = '';
        $scope.checkindata.invoiceAddress.address2 = '';
        $scope.checkindata.invoiceAddress.zipCode = '';
        $scope.checkindata.invoiceAddress.domicile = '';
        $scope.checkindata.invoiceAddress.country = '';
        $scope.checkindata.useGivenCreditCard = '';
    }

    $scope.switch = function (div) {
        $scope.showDiv = div;
    };

    $scope.calendar = {
        days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
        months: ['MONTH_JANUARY', 'MONTH_FEBRUARY', 'MONTH_MARCH', 'MONTH_APRIL', 'MONTH_MAY', 'MONTH_JUNE', 'MONTH_JULY', 'MONTH_AUGUST', 'MONTH_SEPTEMBER', 'MONTH_OCTOBER', 'MONTH_NOVEMBER', 'MONTH_DECEMBER'],
        years: [1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1949, 1950, 1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
        selectedDay: 1,
        selectedMonth: 'MONTH_JANUARY',
        selectedYear: 1980
    };

    $scope.changedMonth = function (item) {
        if (item !== undefined && $scope.calendar.selectedYear !== undefined) {
            if (item === 'MONTH_JANUARY') {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
            } else if (item === 'MONTH_FEBRUARY') {
                // check if it is a leapyear
                if ($scope.calendar.selectedYear % 4 === 0 && $scope.calendar.selectedYear % 100 !== 0 || $scope.calendar.selectedYear % 400 === 0) {
                    $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];
                } else {
                    $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
                }
            } else if (item === 'MONTH_MARCH') {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
            } else if (item === 'MONTH_APRIL') {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
            } else if (item === 'MONTH_MAY') {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
            } else if (item === 'MONTH_JUNE') {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
            } else if (item === 'MONTH_JULY') {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
            } else if (item === 'MONTH_AUGUST') {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
            } else if (item === 'MONTH_SEPTEMBER') {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
            } else if (item === 'MONTH_OCTOBER') {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
            } else if (item === 'MONTH_NOVEMBER') {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
            } else {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
            }
        }
    };

    $scope.changedYear = function (item) {
        if ($scope.selectedMonth === 'MONTH_FEBRUARY') {
            if (item % 4 === 0 && item % 100 !== 0 || item % 400 === 0) {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];
            } else {
                $scope.calendar.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
            }
        }
    };

    function loadMobileCheckInData() {
        $scope.sharedData.sendLog('checkin', 0, $scope.sharedData.hotelID);
        var requestData = {
            rn: pRn,
            gn: pGn
        };

        // TODO: should be replaced by Angular's $http call to get rid of $scope.$apply
        $.ajax({
            type: 'POST',
            url: urlPrefix + '/mobileCheckInData',
            data: JSON.stringify(requestData),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: function success(data) {
                $scope.$apply(function () {
                    console.log('loading customer info done');
                    $scope.checkindata = data;

                    if (data.error !== undefined) {
                        $scope.showDiv = 'no-data-found';
                        console.log('error: ' + data.error);
                    } else {
                        if (data.dateOfBirth !== undefined) {
                            var dateOfBirth = data.dateOfBirth.split('-');

                            $scope.calendar.selectedYear = parseInt(dateOfBirth[2]);
                            $scope.changedYear($scope.calendar.selectedYear);

                            $scope.calendar.selectedMonth = $scope.calendar.months[parseInt(dateOfBirth[1]) - 1];
                            $scope.changedMonth($scope.calendar.selectedMonth);

                            $scope.calendar.selectedDay = parseInt(dateOfBirth[0]);
                        }

                        if (data.salutations !== undefined) {
                            $scope.salutations = data.salutations;
                        }

                        if (data.addressForm !== undefined) {
                            if ($scope.salutations.indexOf(data.addressForm) < 0) {
                                $scope.salutations.push(data.addressForm);
                            }
                        }

                        if ($scope.checkindata.hasOwnProperty('invoiceAddress')) {
                            $scope.checkindata.billingAddress = true;
                            $scope.checkindata.showBillingAddressCheckbox = false;
                        } else {
                            $scope.checkindata.billingAddress = false;
                            $scope.checkindata.showBillingAddressCheckbox = true;
                        }

                        if (data.languageCode !== undefined) {
                            TranslationFactory.use(data.languageCode, hotelID);
                        }
                        loadCountryList(data.languageCode);
                        $scope.refreshServices();
                        $scope.refreshAgb();

                        $scope.showDiv = 'checkinform';
                        console.log('checkindata: ' + $scope.checkindata);
                    }
                });
            }
        });
    }

    $scope.calcInputLimit = function () {
        if ($scope.checkindata.reservationType === 'HMS') {
            return 100;
        } else {
            return 26;
        }
    };

    $scope.showExternalPage = function (url) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'externalPageModal.html',
            controller: 'externalPageModalController',
            windowClass: 'externalPageModal',
            size: 'lg',
            resolve: {
                param: function param() {
                    var ret = {};
                    ret.url = url;
                    return ret;
                }
            }
        });
    };
    externalPageLoader = $scope.showExternalPage;

    $scope.getHotelArguments = function () {

        if ($scope.hotel.id == 6201157261197312) {
            return {
                CITY_IN_LANGUAGE: $translate.instant("COLOGNE_CITY_NAME"),
                TAX_TYPE: 'Bettensteuer',
                FINE_PERCENTAGE: '5%'
            };
        } else if ($scope.hotel.id == 5368223815958528) {
            return {
                CITY_IN_LANGUAGE: "Dresden",
                TAX_TYPE: 'Kulturförderabgabe',
                FINE_PERCENTAGE: '6%'
            };
        }

        return {};
    };

    $scope.getUrlForFine = function () {

        if ($scope.hotel.id == 6201157261197312) {
            return "https://koeln.bettensteuer.de/";
        } else if ($scope.hotel.id == 5368223815958528) {
            return "https://dresden.bettensteuer.de/";
        }

        return "";
    };

    $scope.servicesAvailableOnCheckin = function () {
        for (var i = 0; i < $scope.checkindata.hotel_services.length; i++) {
            if ($scope.checkindata.hotel_services[i].showOnWebCheckin) {
                return true;
            }
        }

        return false;
    };

    $scope.initSignature = function() {
        var canvas = document.querySelector("signature");

        var signaturePad = new SignaturePad(canvas);

        // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
        //signaturePad.toDataURL(); // save image as PNG
        //signaturePad.toDataURL("image/jpeg"); // save image as JPEG
        signaturePad.toDataURL("image/svg+xml"); // save image as SVG

        // Returns signature image as an array of point groups
        //const data = signaturePad.toData();

        // Draws signature image from an array of point groups
        //signaturePad.fromData(data);

        // Returns true if canvas is empty, otherwise returns false
        //signaturePad.isEmpty();

        // Unbinds all event handlers
        //signaturePad.off();

        // Rebinds all event handlers
        signaturePad.on();
    }

    $scope.clearSignatureCanvas = function() {
        // Clears the canvas
        signaturePad.clear();
    }

    $scope.canDisplayService = function (service) {
        var b = service.showOnWebCheckin;

        if (service.twoNightsStay) {
            var d1 = new Date($scope.normalizeDate($scope.checkindata.arrival));
            var d2 = new Date($scope.normalizeDate($scope.checkindata.departure));
            var diffTimestamp = Math.abs(d2 - d1);
            var diffInDays = Math.ceil(diffTimestamp / (1000 * 60 * 60 * 24));

            if (diffInDays > 1) {
                return b && true;
            }
        }

        return b;
    };

    $scope.normalizeDate = function (d) {
        if (!d) {
            return d;
        }
        var dateParts = d.split("-");
        return dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2];
    };

    $scope.getAsHtml = function (s) {
        return $sce.trustAsHtml(s);
    };

    $scope.openHostedGuaranteeForm = function () {
        // In cases where openHostedGuaranteeForm should be called,
        // the later check for a valid form in checkin() does no longer work, because the form.checkinform is no longer in $scope
        // Therefore, we have to check for the valid form here already
        if ($scope.form.checkinInputForm && !$scope.form.checkinInputForm.$valid) {
            var visibleInvalids = angular.element.find('.ng-invalid:visible');
            if (angular.isDefined(visibleInvalids) && angular.isDefined(visibleInvalids[1])) {
                visibleInvalids[1].focus();
            }
        } else {
            if ($scope.checkindata.customData.is_guaranteed != 'true') {
                $scope.showDiv = 'hosted-guarantee-form';
                $scope.guaranteeUrl = "";
                if ($scope.sharedData.hotelID === "6722864168304640") {
                    $scope.guaranteeUrl = $sce.trustAsResourceUrl("https://sky-eu1.clock-software.com/63481/10461/self_service/bookings/" + $scope.checkindata.reservationNumber.split("/")[0] + "/booking_guarantee?key=" + $scope.checkindata.customData.self_service_key);
                }
                if ($scope.sharedData.hotelID === "6734243755458560") {
                    $scope.guaranteeUrl = $sce.trustAsResourceUrl("https://sky-eu1.clock-software.com/63481/12444/self_service/bookings/" + $scope.checkindata.reservationNumber.split("/")[0] + "/booking_guarantee?key=" + $scope.checkindata.customData.self_service_key);
                }
                var interval = setInterval(function () {
                    var requestData = {
                        rn: pRn,
                        gn: pGn,
                        lang: TranslationFactory.getLanguage()
                    };

                    if (!pRn && !pGn) {
                        requestData = {
                            rn: $scope.checkindata.reservationNumber,
                            gn: $scope.checkindata.guestNumber
                        }
                    }

                    $.ajax({
                        type: 'POST',
                        url: urlPrefix + '/mobileCheckInData',
                        data: JSON.stringify(requestData),
                        contentType: 'application/json; charset=utf-8',
                        dataType: 'json',
                        success: function success(data) {
                            if (data.customData.is_guaranteed === 'true') {
                                $('.statusicon').removeClass("fa-spin").removeClass("fa-circle-o-notch").addClass("fa-check");
                                clearInterval(interval);
                                setTimeout(function () {
                                    $scope.$apply(function () {
                                        $scope.goToRegistrationCardPreview();
                                    });
                                }, 1000);
                            }
                        }
                    });
                }, 3000);
            } else {
                $scope.goToRegistrationCardPreview();
            }
        }
    };

    $scope.goToProventSpecificLandingPage = function() {
        if ($scope.sharedData.hotelID == "6201157261197312") {
            // For provent hotels
            $window.location.href = "https://parkinn-hotel-koeln-city-west.suitepad.io/?js=pre";
        } else if ($scope.sharedData.hotelID == "5684932489773056") {
            // For provent hotels
            $window.location.href = "https://parkinn-hotel-neumarkt.suitepad.io/?js=pre";
        } else if ($scope.sharedData.hotelID == "4852783666692096") {
            // For provent hotels
            $window.location.href = "https://parkinn-hotel-goettingen.suitepad.io/?js=pre";
        } else if ($scope.sharedData.hotelID == "5368223815958528") {
            // For provent hotels
            $window.location.href = "https://parkinn-hotel-dresden.suitepad.io/?js=pre";
        } else if ($scope.sharedData.hotelID == "4861292718325760") {
            // For provent hotels
            $window.location.href = "https://parkinn-hotel-papenburg.suitepad.io/?js=pre";
        } else {
            $scope.loadClient();
        }
    }

    $scope.goBackToCheckinData = function () {
        $scope.showDiv = 'checkinform';
    };

    $scope.goToRegistrationCardPreview = function () {
        $scope.checkindata.dateOfBirth = pad2($scope.calendar.selectedDay) + '-' + pad2($scope.calendar.months.indexOf($scope.calendar.selectedMonth) + 1) + '-' + $scope.calendar.selectedYear;
        $scope.showDiv = 'registration-form-preview';
        $scope.sharedData.sendLog('checkinSuccess', 0, $scope.sharedData.hotelID);
    };

    $scope.confirmRegistrationForm = function () {
        $scope.checkin(false, function() {
            $scope.showDiv = 'checkinfinished';
            $scope.sharedData.sendLog('checkinSuccess', 0, $scope.sharedData.hotelID);
        }, "precheckin");
    };

    $scope.formatDate = function (date) {
        return date.replaceAll("-", ".");
    }

    $scope.refreshServices = function() {
        $http({
            method: 'POST',
            url: urlPrefix + '/serviceData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                action: 'client',
                lang: TranslationFactory.getLanguage(),
                hid: $scope.sharedData.hotelID
            }
        }).success(function (data) {
            $scope.checkindata.hotel_services = data.sort((a, b) => a.serviceOrder < b.serviceOrder ? a.serviceOrder > b.serviceOrder ? 1 : 0 : 0 );
        });
    }

    $scope.refreshAgb = function() {
        $http({
            method: 'POST',
            url: urlPrefix + '/wirtData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                action: 'client', id: $scope.sharedData.hotelID, lang: TranslationFactory.getLanguage()
            }
        }).then(function onSuccess(response) {
            var data = response.data;

            $scope.gtcText = $sce.trustAsHtml(KeyWordService.replaceLinks(data.wirt.gtcText));
        });
    }

    $scope.refreshCountries = function() {
        $http({
            method: 'POST',
            url: urlPrefix + '/retrieveCountries',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                lang: TranslationFactory.getLanguage()
            }
        }).then(function onSuccess(response) {
            $scope.countries = response.data;
        });
    }

    $scope.refreshSalutations = function() {
        $http({
            method: 'POST',
            url: urlPrefix + '/retrieveSalutations',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                hid: $scope.sharedData.hotelID,
                lang: TranslationFactory.getLanguage()
            }
        }).then(function onSuccess(response) {
            var salutations = response.data.salutations;

            if ($scope.checkindata.addressForm !== undefined) {
                if (salutations.indexOf($scope.checkindata.addressForm) < 0) {
                    salutations.push($scope.checkindata.addressForm);
                }
            }

            $scope.salutations = salutations;
        });
    }
});
