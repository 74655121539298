var voyatInit = function (contentid) {
    // (function() {
    //     var v = document.createElement('script');
    //     var s = document.getElementsByTagName('script')[0];
    //     v.src = '//io.voyat.com/pl/' + contentid + '.js';
    //     v.async = true;
    //     s.parentNode.insertBefore(v, s);
    // })();

    (function () {
        var v = document.createElement('script');
        var s = document.getElementsByTagName('script')[0];
        v.src = '//io.voyat.com/v.js';
        v.async = true;
        s.parentNode.insertBefore(v, s);
    })();
};
