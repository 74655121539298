function remove(arr, item) {
    for (var i = arr.length; i--; ) {
        if (arr[i] === item) {
            arr.splice(i, 1);
        }
    }
}

myApp.controller('MenuModalController', function($scope, $uibModalInstance, $http, param, SharedData) {

    $scope.sharedData = SharedData.data;
    $scope.roomServiceFee = $scope.sharedData.hotel.wirt.roomServiceFee;
    $scope.roomServiceFeeMin = $scope.sharedData.hotel.wirt.roomServiceFeeMin;

    $scope.totalPrice = function() {
        var total = 0;
        for (index = 0; index < $scope.items.length; index++) {
            total += $scope.items[index].price * $scope.items[index].count;
        }
        if(total <= $scope.roomServiceFeeMin){
        	total += $scope.roomServiceFee;
        }
        return total;
    };
    $scope.isChargeFree = function(){
    	var total = 0;
        for (index = 0; index < $scope.items.length; index++) {
            total += $scope.items[index].price * $scope.items[index].count;
        }
    	if(total <= $scope.roomServiceFeeMin){
    		return false;
    	}else return true;
    }
    $scope.fullPrice = function(item)
    {
        return item.count * item.price;
    };

    $scope.items = param.items;
    $scope.callback=param.callback;
    $scope.comment="";

    $scope.plus = function(item, increase)
    {
        item.count = item.count + increase;
        if (item.count <= 0)
            remove($scope.items, item);
    }
    $scope.ok = function() {
        console.log("ok, comment is" + $scope.comment);
        $scope.callback($scope.comment);
        $uibModalInstance.dismiss('ok');

    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
});
