myApp.config(function ($translateProvider) {
    $translateProvider.translations('es', {
        SEND_MESSAGE: 'Enviar  ',
        MESSAGE_TO_RECEPTION: 'Mensaje a la recepción',
        YOUR_MESSAGE: 'Su mensaje',
        ROOM_NAME: 'Apellido',
        ROOM_NUMBER: 'Habitación',
        PROCEED: 'Continúa',
        ORDER: 'Pedido',
        PLEASE_ENTER: 'Favor de entrar el número de su habitación y su apellido ',
        NO_ROOM: 'Combinación desconocida de habitación/apellido',
        YOUR_ORDER: 'Su pedido',
        COMMENT: 'Comentario',
        CHARGE: 'Cargo por servicio',
        YES: 'Sí',
        NO: 'No',
        ERROR_MESSAGE: 'Mensaje de error',
        NO_DATA_FOUND: 'No se encontró ninguna reservación. Favor de verificar su información.',
        NAV_HOME: 'Inicio',
        NAV_MOBILE_CHECK_IN: 'Registro electrónico',
        CHECK_IN_CARD_LABEL: 'Información personal',
        CHECK_IN_CARD_INFO_LABEL: 'Favor de llenar el formuario completamente.',
        MANDATORY_FIELD: '* Campo obligatorio',
        ARRIVAL_LABEL: 'Llegada',
        DEPARTURE_LABEL: 'Salida',
        SALUTATION_LABEL: 'Título',
        FIRST_NAME_LABEL: 'Nombre',
        LAST_NAME_LABEL: 'Apellido',
        DATE_OF_BIRTH_LABEL: 'Fecha de nacimiento',
        EMAIL_LABEL: 'Correo electrónico',
        NATIONALITY_LABEL: 'Nacionalidad',
        COUNTRY_LABEL: 'País',
        STREET_LABEL: 'Calle',
        STREET_LINE1_LABEL: 'Calle (1)',
        STREET_LINE2_LABEL: 'Calle (2)',
        ZIP_CODE_LABEL: 'Código postal',
        CITY_LABEL: 'Ciudad',
        PASSPORT_LABEL: 'Número de pasaporte',
        RESERVATION_COMMENT: 'Comentario',
        CHECK_IN_BUTTON: 'Seguir con el registro',
        CHECK_IN_THANK_YOU_LABEL: 'Gracias por registrarse en línea',
        CHECK_IN_FINISH_THANK_YOU_LABEL: 'Gracias! Su llave le espera en la recepción ',
        ASK_FOR_CHECK_OUT_LABEL: 'Puede adelantar los detalles de su facturación.',
        FINISH_CHECK_IN_BUTTON: 'Ahora no',
        DO_CHECK_OUT_BUTTON: 'Proporcionar detalles (de facturación)',
        CHECK_OUT_CARD_LABEL: 'Dirección de facturación.',
        CHECK_OUT_CARD_INFO_LABEL: '¿Prefiere otra dirección de facturación?',
        COMPANY_NAME1_LABEL: 'Nombre de la empresa',
        COMPANY_NAME2_LABEL: 'Nombre de la empresa (2)',
        USE_PRIVATE_ADDRESS_LABEL: '¿Utilizar dirección privada?',
        INVOICE_STREET_LINE1_LABEL: 'Calle',
        INVOICE_STREET_LINE2_LABEL: 'Calle (2)',
        INVOICE_COUNTRY_LABEL: 'País',
        INVOICE_ZIPCODE_LABEL: 'Código postal',
        INVOICE_CITY_LABEL: 'Ciudad',
        USE_CREDIT_CARD_LABEL: 'Tarjeta de crédito',
        CHECK_OUT_BUTTON: 'Guardar dirección de facturación.',
        CHECK_OUT_THANK_YOU_LABEL: 'Gracias! Se guardaron los detalles de su facturación. Su llave le espera en la recepción.',
        LOADING_LABEL: 'Cargando…',
        SEARCH: 'Buscar',
        ADDRESS_HEADER: 'Dirección',
        MONTH_JANUARY: 'Enero',
        MONTH_FEBRUARY: 'Febrero',
        MONTH_MARCH: 'Marzo',
        MONTH_APRIL: 'Abril',
        MONTH_MAY: 'Mayo',
        MONTH_JUNE: 'Junio',
        MONTH_JULY: 'Julio',
        MONTH_AUGUST: 'Agosto',
        MONTH_SEPTEMBER: 'Septiembre',
        MONTH_OCTOBER: 'Octubre',
        MONTH_NOVEMBER: 'Noviembre',
        MONTH_DECEMBER: 'Diciembre',
        BACK: 'Regresar',
        REPLY: 'Contestar',
        INPUT_PROMPT_COMMENT: 'Comentario',
        LOGOFFDIALOG: '¿Realmente desea finalizar la sesión?',
        TITEL_LABEL: 'Título',
        PRICE_LABEL: 'Precio',
        VALUE_LABEL: 'Valor',
        POSITION_LABEL: ' #',
        CHANNELS_LABEL: 'Canal',
        LANGUAGE_LABEL: 'Idioma',
        TIME_FORMAT: 'Introduzca la hora en el formato hh: mm',
        TIME_FUTURE: 'Está en el pasado',
        FIRST_INFORMATION_LABEL: 'Etiqueta',
        SECOND_INFORMATION_LABEL: 'Etiqueta (2)',
        LINK_LABEL: 'Enlace',
        NO_GEO_HOTELS_FOUND: 'No se encontraron hoteles en la zona.',
        EMAIL_NAME: 'Correo electrónico',
        OPTIONAL: 'opcional',
        NO_VALID_EMAIL: 'Introduzca un correo electrónico válido',
        NO_NAME: 'Favor de entrar un nombre',
        ITEM_DESCRIPTION_LABEL: 'Posición',
        CHECKOUT: 'Salida',
        PAY_DIFFERENCE: 'Pagar monto abierto',
        FOLIO_SUM: 'Monto total',
        FOLIO_DETAILS: 'Detalles',
        PASSWORD: 'Contraseña',
        WRONG_PASSWORD: 'Contraseña equivocada',
        VALID_VALUE: 'Favor de ingresar un valor válido.',
        NOT_AVAILABLE_WIFI: 'Para usar esa función necesita estar conectado con el internet.',
        NOT_AVAILABLE_LOCATION: 'Para usar esa función necesita encontrarse en el hotel.',
        NOT_AVAILABLE: 'Función no disponible',
        CHECK_LOCATION: 'Determinar ubicación.',
        DESCRIPTION_LABEL: 'Descripción',
        CONTACT_RECEPTION_PERMANENT: '¿Preguntas? Contácte la recepción.',
        CHECK_NEARBY_HOTELS_LABEL: 'Buscando hoteles en la zona. Espera…',
        NO_GEO_LOCATION_FOUND: 'No se pudo determinar la ubicación',
        NO_GEO_LOCATION_SERVICE_FOUND: 'Favor de activar determinación de la ubicación',
        PASSWORD_PROTECT: 'Esta zona está protegida por contraseña',
        SKIP_CART: 'Omitir',
        PROCESS_CART: 'Comprar',
        CONSUMATE_QUESTION: 'Consumo del minibar',
        CHECK_OUT_VALIDATION: 'Favor de verificar su identidad',
        ITEM_QUANTITY_LABEL: 'Cantidad',
        ITEM_TRANSACTION_DATE_LABEL: 'Fecha de transacción',
        SUM_AMOUNT: 'Monto de pago total',
        PAYED_AMOUNT: 'Monto ya pagado',
        REMAINING_AMOUNT: 'Cantidad abierta'
    });
});
