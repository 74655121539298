(function () {
    angular
        .module('Order4meClient')
        .directive('elavonPaymentButton', ElavonPaymentButton);

    ElavonPaymentButton.$inject = [ '$http', 'TranslationFactory' ];

    function ElavonPaymentButton($http, TranslationFactory) {
        var directive = {
            restrict: 'E',
            link: init,
            scope: false,
            templateUrl: 'templates/directive/elavonPaymentButtonDirective.html'
        };

        function init($scope, element, attributes) {
            $scope.enabled = ($scope.enabled !== undefined) ? $scope.enabled : true;

            let hppUrl = 'https://pay.sandbox.elavonpaymentgateway.com/pay';
            if ($scope.adminData.paymentConfiguration.elavonOptions.environment == "live") {
                hppUrl = 'https://pay.elavonpaymentgateway.com/pay';
            }

            $scope.loadComponent = function() {
                $http({
                    method: 'POST',
                    url: urlPrefix + '/payment',
                    headers: {'Content-Type': 'application/json; charset=UTF-8'},
                    data: {
                        openAmount: $scope.invoicesDto.openAmount,
                        currency: $scope.incomingCurrency,
                        description: 'Payment of invoice ' + $scope.invoicesDto.identifier,
                        orderId: $scope.invoicesDto.identifier + (new Date().getTime()),
                        lang: TranslationFactory.getLanguage(),
                        requestId: 'REQ_' + $scope.randomId(),
                        reservationNumber: $scope.reservationNumber,
                        hotelId: $scope.hotelNumber,
                        source: 'checkout'
                    }
                }).success(function (response) {
                    // Decode each key
                    for(var key in response) {
                        response[key] = atob(response[key]);
                    }

                    // get the HPP JSON from the server-side SDK
                    RealexHpp.setHppUrl(hppUrl);
                    RealexHpp.init("elavonPaymentButton", urlPrefix + "/paymentResult", response);
                });
            }

            $scope.loadComponent();
        }

        return directive;
    }
})();
