myApp.controller('MinibarModalCtrl', function($scope, $uibModalInstance, $http, param, SharedData) {

    $scope.sharedData = SharedData.data;
    $scope.currency = $scope.sharedData.currency + ' ';

    $scope.totalPrice = function() {
        var total = 0;
        for (index = 0; index < $scope.cart.length; index++) {
            total += $scope.cart[index].unitPrice * $scope.cart[index].unitCnt;
        }
        return total;
    };

    $scope.fullPrice = function(article) {
        return article.unitCnt * (article.columns ? article.columns.PRICE_LABEL : article.value);
    };

    $scope.cart = param.cart;
    $scope.callback = param.callback;
    $scope.sizeCallback = param.sizeCallback;
    $scope.cartSize = param.cartSize;
    $scope.comment = "";
    $scope.text = 'SUBMIT_CONFIRMATION_MESSAGE';
    $scope.showQuestion = function() {
        $scope.question = true;
    }
    $scope.hideQuestion = function() {
        $scope.question = false;
    }
    $scope.plus = function(article, increase) {
        article.unitCnt = article.unitCnt + increase;
        $scope.cartSize = $scope.cartSize + increase;
        $scope.sizeCallback($scope.cartSize);
        if (article.unitCnt <= 0)
            remove($scope.cart, article);
        if ($scope.cartSize == 0) {
            $scope.cancel();
        }
    }

    $scope.ok = function() {
        console.log("ok, commence chargepost");
        $scope.callback($scope.cart);
        $uibModalInstance.dismiss('ok');
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
});
