/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

myApp.directive('starRating', starRating);

function starRating() {
    return {
        restrict: 'EA',
        template:
        '<ul class="star-rating" ng-class="{readonly: readonly}">' +
        '  <li ng-repeat="star in stars" class="star" ng-class="{filled: star.filled}" ng-click="toggle($index)">' +
        '    <i class="fa fa-star"></i>' + // or &#9733
        '  </li>' +
        '</ul>',
        scope: {
            ratingValue: '=ngModel',
            max: '=?', // optional (default is 5)
            onRatingSelect: '&?',
            readonly: '=?'
        },
        link: function (scope, element, attributes) {
            scope.max = scope.max || 5;
            scope.max = scope.max > 0 ? scope.max : 0;

            function updateStars() {
                scope.stars = [];
                for (var i = 0; i < scope.max; i++) {
                    scope.stars.push({
                        filled: i < scope.ratingValue
                    });
                }
            }

            scope.toggle = function (index) {
                if (scope.readonly == undefined || scope.readonly === false) {
                    scope.ratingValue = index + 1;
                    if (scope.onRatingSelect) {
                        scope.onRatingSelect({
                            rating: index + 1
                        });
                    }
                }
            };

            scope.$watch('ratingValue', function (oldValue, newValue) {
                if (newValue || newValue === 0) {
                    updateStars();
                }
            });
        }
    };
}

myApp.controller('FeedbackCtrl', function ($rootScope, $scope, $location, $translate, TranslationFactory, $http, $sce, $filter, SharedData) {


    $scope.currentForm = {};
    //  {id: 1, localizedName: "Feedback", localizedDescription: "We really appreciate you taking some time for giving us your valuable feedback!", resultEmail: "office@order4.me"}
    $scope.currentFormQuestionList = [];

    //  {id: 1, localizedName: 'Room', localizedDescription: 'How did you like your Room?', questionType: 2, maxRating: 5, rating: 0},
    //  {id: 2, localizedName: 'Staff', localizedDescription: 'How happy are you with our hotel Staff?', questionType: 2, maxRating: 5, rating: 0},
    //  {id: 3, localizedName: 'Hotel', localizedDescription: 'How likely will you recommed our hotel to a friend?', questionType: 2, maxRating: 5, rating: 0},

    //   {id: 2, localizedName: 'Optionen - eine Option', localizedDescription: 'Was war der Grund für Ihren Aufenthalt bei uns?', questionType: 4, optiontype: 1,
    //      options: [{id: 1, localizedName: "Geschäftlich"}, {id: 2, localizedName: "Städteurlaub", defaultOption: true}, {id: 3, localizedName: "Sonstiges"}]},
    // {id: 3, localizedName: 'Ja-Nein Frage', localizedDescription: 'Würden Sie wieder in einem unserer Hotels übernachten?', questionType: 3},
    //    {id: 4, localizedName: 'Optionen - mehrere Optionen', localizedDescription: 'Welche Services haben Ihnen bei uns besonders gut gefallen?', questionType: 4, optiontype: 2,
    //       options: [{id: 1, localizedName: "Mobile Check-In"}, {id: 2, localizedName: "Digitale Gästemappe", defaultOption: true}, {id: 3, localizedName: "Order4me"}]},
    //   {id: 5, localizedName: 'Comments', localizedDescription: 'Give us some additional comments ...', questionType: 1}
    //];

    /*
       $scope.currentFormQuestionList = [
        {id: 1, localizedName: 'Room', localizedDescription: 'Wie gut haben Sie sich am Flughafen zu recht gefunden? (Beschilderung)?', questionType: 2, maxRating: 5, rating: 0},
        {id: 2, localizedName: 'Staff', localizedDescription: 'Sind Sie mit der Gastronomie (Auswahl) zufrieden?', questionType: 2, maxRating: 5, rating: 0},
        {id: 3, localizedName: 'Hotel', localizedDescription: 'Haben Sie die WC Anlagen als sauber empfunden?', questionType: 2, maxRating: 5, rating: 0},

        {id: 4, localizedName: 'Fback1', localizedDescription: 'Sind Sie mit dem Angebot an Geschäften zufrieden?', questionType: 2, maxRating: 5, rating: 0},

        {id: 5, localizedName: 'Fback2', localizedDescription: 'War unser Personal bei der Sicherheitskontrolle freundlich?', questionType: 2, maxRating: 5, rating: 0},

        {id: 6, localizedName: 'Fback3', localizedDescription: 'War die Handhabung der Self Service Devices einfach? ', questionType: 2, maxRating: 5, rating: 0},

     //   {id: 2, localizedName: 'Optionen - eine Option', localizedDescription: 'Was war der Grund für Ihren Aufenthalt bei uns?', questionType: 4, optiontype: 1,
      //      options: [{id: 1, localizedName: "Geschäftlich"}, {id: 2, localizedName: "Städteurlaub", defaultOption: true}, {id: 3, localizedName: "Sonstiges"}]},
       // {id: 3, localizedName: 'Ja-Nein Frage', localizedDescription: 'Würden Sie wieder in einem unserer Hotels übernachten?', questionType: 3},
    //    {id: 4, localizedName: 'Optionen - mehrere Optionen', localizedDescription: 'Welche Services haben Ihnen bei uns besonders gut gefallen?', questionType: 4, optiontype: 2,
     //       options: [{id: 1, localizedName: "Mobile Check-In"}, {id: 2, localizedName: "Digitale Gästemappe", defaultOption: true}, {id: 3, localizedName: "Order4me"}]},
        {id: 7, localizedName: 'Comments', localizedDescription: 'Haben Sie Wünsche oder Anregungen an uns?', questionType: 1}
    ];
    */


    $scope.refreshData = function () {
        console.log("refreshing...");
        $scope.sharedData.trackScreen("feedback", $scope.hotel.shortcut, $scope.tracker);

        // Processing feedback-id embedded in path
        let path = $scope.sharedData.embedPath.split(":");
        let fid = undefined;
        if (path.length > 1 && !isNaN(path[1]))
            fid = path[1];

        $http({
            method: 'POST',
            url: urlPrefix + "/feedbackFormData",
            headers: {'Content-Type': 'application/json'},
            data: {action: "client", hid: $scope.sharedData.hotelID, lang: TranslationFactory.getLanguage()}
        }).success(function (data) {
            console.log(data);
            if (data.length > 0) {
                $scope.currentForm = undefined;
                // Get specific feedback by id if was set in menu-item
                if (fid !== undefined) {
                    for (let i = 0; i < data.length; i++) {
                        let feedback = data[i];
                        if (feedback.id == fid) {
                            $scope.currentForm = feedback;
                            console.log("Found feedback with id=" + fid);
                            break;
                        }
                    }
                }

                // Take first feedback if nothing could be found with a feedback-id
                if ($scope.currentForm === undefined) {
                    console.log("No valid feedback-id defined - taking first available feedback.");
                    $scope.currentForm = data[0];
                }

                $scope.currentFormQuestionList = $scope.currentForm.questions;
                $scope.switchDiv("loaded");
            }
            // nothing to to yet at log success
        });

    };

    $scope.switchDiv = function (div) {
        $scope.showdiv = div;
    };

    $scope.range = function (min, max, step) {
        step = step || 1;
        var input = [];
        for (var i = min; i <= max; i += step)
            input.push(i);
        return input;
    };

    $scope.submitFeedback = function () {
        console.log($scope.currentFormQuestionList);

        let requestData = {
            action: "write",
            answers: $scope.currentFormQuestionList,
            user: $scope.sharedData.user.userID,
            lang: TranslationFactory.getLanguage()
        };

        $http({
            method: 'POST',
            url: urlPrefix + "/feedbackQuestionAnswerData",
            headers: {'Content-Type': 'application/json; charset=UTF-8'},
            data: requestData
        }).success(function (data) {
            console.log(data);
            $scope.switchDiv("thankyou");
        });
    };

    $scope.checkSingleChoice = function (allOptions, selectedOption) {
        allOptions.forEach(function (option) {
            if (option.id != selectedOption.id)
                option.selected = false;
        }, this);
    };

    $scope.showFeedbackRating = function () {
        if (!$scope.currentForm.enableRating || !$scope.currentForm.ratingThreshold)
            return false;

        let ratingSum = 0;
        let ratingMax = 0;
        $scope.currentForm.questions.forEach(function (question) {
            // Takes only star ratings into account
            if (question.questionType == 2) {
                ratingSum += question.rating;
                ratingMax += question.maxRating;
            }
        });

        if (ratingSum / ratingMax >= ($scope.currentForm.ratingThreshold / 100))
            return true;
        else
            return false;
    };

    $scope.forward = function (link) {
        if (!link.startsWith('http://') && !link.startsWith('https://'))
            link = "http://" + link;

        window.location.href = link;
    };
});

