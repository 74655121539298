(function () {
    angular
        .module('Order4meClient')
        .directive('invoiceAddress', InvoiceAddress);

    InvoiceAddress.$inject = [ '$http', 'TranslationFactory' ];

    function InvoiceAddress($http, TranslationFactory) {
        var directive = {
            restrict: 'E',
            link: link,
            scope: {
                enabled: '=?',
                data: '='
            },
            templateUrl: 'templates/directive/invoiceAddressDirective.html'
        };
        return directive;

        function link($scope, $element) {
            $scope.enabled = ($scope.enabled !== undefined) ? $scope.enabled : true;


            function loadCountries() {
                $http({
                    method: 'POST',
                    url: urlPrefix + '/retrieveCountries',
                    headers: {'Content-Type': 'application/json; charset=UTF-8'},
                    data: {lang: TranslationFactory.getLanguage()}
                }).success(function (data) {
                    $scope.countries = data;
                });
            }

            $scope.$watch('data', function (newData) {
                if (!$scope.enabled || angular.equals({}, newData))
                    return;

                loadCountries();
            });
        }
    }
})();
