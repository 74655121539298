/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

myApp.controller('GuestmapCtrl', function ($rootScope, $scope, $location, $translate, $http, $sce, $filter, SharedData,
        $anchorScroll) {
    $scope.sharedData = SharedData.data;

    $scope.currentID = 0;

    $scope.sendTrackingHit = function () {
        if ($scope.sharedData.currentParent != 0) {
            $scope.parentItem = $filter('filter')($scope.sharedData.hotel.mainMenu, {
                id: $scope.sharedData.currentParent
            })[0];
            if ($scope.parentItem.trackingcode != undefined) {
                $scope.sharedData.trackScreen($scope.parentItem.trackingcode, $scope.hotel.shortcut, $scope.tracker);
            } else {
                $scope.sharedData.trackScreen("guestmap", $scope.hotel.shortcut, $scope.tracker);
            }
        } else {
            $scope.sharedData.trackScreen("home", $scope.hotel.shortcut, $scope.tracker);
        }
    };

    $scope.refreshData = function () {
        console.log("Guestmaps found: " + JSON.stringify($scope.sharedData.guestmaps));

        $scope.sendTrackingHit();

        $scope.sharedData.sendLog("guestmap", 0, $scope.sharedData.hotelID);

        $scope.showdiv = 'default';

        if ($scope.sharedData.menu.currentGuestmap)
            $scope.showItem($scope.sharedData.menu.currentGuestmap);

    };

    $scope.loadList = function (id) {
        $scope.currentID = id;
        // $scope.currentList = $scope.sharedData.lists[id];

    };

    $scope.goHome = function () {
        $location.path("#/?" + $scope.hid);
        $scope.sendTrackingHit();
    };

    $scope.isBig = function (item) {
        if ($scope.sharedData.guestmaps.length % 2 === 0) {
            return false;
        } else if ($scope.sharedData.guestmaps[0] === item) {
            return true;
        }

        return false;

    };

    $scope.getHtml = function (html) {
        return $sce.trustAsHtml(html);
    };

    $scope.switchDiv = function (div) {
        $scope.showdiv = div;
    };

    $scope.phoneItem = function (item) {
        if (item.callPhoneLink) {

            window.open(item.callPhoneLink);

        }

    };

    $scope.returnTrueIfExists = function (label, array) {
        if (array != undefined && array != null) {
            var ret = array[label]
            if (ret != null && ret != undefined) {
                return true;
            }
            return false;
        }
    }
    $scope.checkForPriceCurrency = function (title) {
        if (title == "PRICE_LABEL") {
            return $scope.sharedData.currency;
        } else {
            return null;
        }
    }

    $scope.showItem = function (item) {
        $scope.sharedData.trackScreen("guestmapItem/" + item.trackingcode, $scope.hotel.shortcut, $scope.tracker);

        $scope.sharedData.sendLog("guestmapItem", item.id, $scope.sharedData.hotelID);

        if (item.link) {
            console.log("link!" + item.link);

            window.open(item.link);
        } else {
            $scope.switchDiv('currentItem');
            $scope.currentItem = item;
            // $scope.currentList = [];

            console.log("calling loadList");

            $scope.loadList(item.id);

            console.log("returned from loadList");
        }
    };

    $scope.gotoAnchor = function (anchor) {
        $location.hash(anchor);
        $anchorScroll();
    };

    $scope.removeSpecialChars = function (text) {
        return text.replace(new RegExp('[ \\(\\)?!,+/\\.,’]', 'g'), '');
    };

    $scope.toggleCollapse = function (item)
    {
        if (!item.expanded)
        {
            item.expanded = true;
        } else
            item.expanded = false;

        console.log("item:" + item.localizedName + " expanded:" + item.expanded);
    }
});