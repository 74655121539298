myApp.controller('GuestmapCtrlDummy', function($rootScope, $scope, $location, $translate, $http, $sce, $filter, $anchorScroll) {


    $scope.maps = [
        {id: 1, localizedName: "HERZLICH WILLKOMMEN", localizedDescription: "Sehr geehrte Dame, sehr geehrter Herr!<br/><br/>Ich heiße Sie herzlich im andel’s Hotel Berlin willkommen und freue mich  - hier an der Grenze von Lichtenberg, Prenzlauer Berg und Friedrichshain – Ihr Gastgeber zu sein.<br/><br/>Nachfolgend erhalten Sie eine Zusammenstellung der wichtigsten Hotelinformationen für Ihren Aufenthalt. Für weitergehende Informationen, Fragen oder Wünsche stehen Ihnen unsere Mitarbeiter an der Rezeption (DW 2000) und die Concierges (DW 2033) jederzeit gern zur Verfügung.<br/><br/>Ihr Feedback liegt mir und meinem Team sehr am Herzen. Nehmen Sie sich nur ein paar Minuten Zeit und hinterlassen Sie uns Ihre persönlichen Eindrücke auf dem Gästefragebogen, den Sie zwei Tage nach Abreise per E-Mail erhalten.  Ihre Bewertungen auf Online-Plattformen wie holidaycheck, tripadvisor & Co verfolgen wir gerne.<br/><br/>Ich wünsche Ihnen eine tolle Zeit in Berlin und dem andel’s Hotel Berlin und freue mich, Sie bald wieder bei uns begrüßen zu dürfen.<br/><br/><br/>Ralph Alsdorf,<br/>General Manager <br/>", faicon: "fa-hotel", type: 1, position: 1},
        {id: 2, localizedName: "HOTEL INFORMATIONEN VON A-Z", faicon: "fa-hotel", type: 2, position: 2},
        {id: 3, localizedName: "VORWAHLEN", faicon: "fa-phone", type: 3, position: 3},
        {id: 4, localizedName: "UNSERE ÖFFNUNGSZEITEN", faicon: "fa-clock-o", type: 3, position: 4}
    ];

    $scope.currentList = [];

    $scope.mapDetails =
            [{id: 3, list:
                            [{id: 1, localizedName: "DEUTSCHE ORTSVORWAHLEN",
                                    tbl: {
                                        rows: [
                                            {cols: ['Aachen', '0241']},
                                            {cols: ['Aschaffenburg', '06021']},
                                            {cols: ['Augsburg', '0821']},
                                            {cols: ['Baden-Baden', '07221']},
                                            {cols: ['Bayreuth', '0921']},
                                            {cols: ['Berlin', '030']},
                                            {cols: ['Bielefeld', '0521']},
                                            {cols: ['Bochum', '0234']},
                                            {cols: ['Bonn', '0228']},
                                            {cols: ['Braunschweig', '0531']},
                                            {cols: ['Bremen', '0421']},
                                            {cols: ['Chemnitz', '0371']},
                                            {cols: ['Cottbus', '0355']},
                                            {cols: ['Darmstadt', '06151']},
                                            {cols: ['Dessau', '0340']},
                                            {cols: ['Dortmund', '0231']},
                                            {cols: ['Dresden', '0351']},
                                            {cols: ['Duisburg', '0203']},
                                            {cols: ['Düsseldorf', '0211']},
                                            {cols: ['Erfurt', '0361']},
                                            {cols: ['Essen', '0201']},
                                            {cols: ['Flensburg', '0461']},
                                            {cols: ['Frankfurt / Main', '069']},
                                            {cols: ['Frankfurt / Oder', '0335']},
                                            {cols: ['Freiburg / Breisgau', '0761']},
                                            {cols: ['Gera', '0365']},
                                            {cols: ['Göttingen', '0551']},
                                            {cols: ['Halle / Saar', '0345']},
                                            {cols: ['Hamburg', '040']},
                                            {cols: ['Hannover', '0511']},
                                            {cols: ['Heidelberg', '06221']},
                                            {cols: ['Heilbronn', '07131']},
                                            {cols: ['Ingolstadt / Donau', '0841']},
                                            {cols: ['Jena', '03641']},
                                            {cols: ['Kaiserslautern', '0631']},
                                            {cols: ['Karlsruhe', '0721']},
                                            {cols: ['Kassel', '0561']},
                                            {cols: ['Kiel', '0431']},
                                            {cols: ['Koblenz', '0261']},
                                            {cols: ['Köln', '0221']},
                                            {cols: ['Konstanz', '07531']},
                                            {cols: ['Leipzig', '0341']},
                                            {cols: ['Leverkusen', '0214']},
                                            {cols: ['Lörrach', '07621']},
                                            {cols: ['Ludwigshafen am Rhein', '0621']},
                                            {cols: ['Lübeck', '0451']},
                                            {cols: ['Magdeburg', '0391']},
                                            {cols: ['Mainz', '06131']},
                                            {cols: ['Mannheim', '0621']},
                                            {cols: ['München', '089']},
                                            {cols: ['Münster', '0251']},
                                            {cols: ['Nürnberg', '0911']},
                                            {cols: ['Offenbach / Main', '069']},
                                            {cols: ['Osnabrück', '0541']},
                                            {cols: ['Potsdam', '0331']},
                                            {cols: ['Regensburg', '0941']},
                                            {cols: ['Remscheid', '02191']},
                                            {cols: ['Rostock', '0381']},
                                            {cols: ['Saarbrücken', '0681']},
                                            {cols: ['Schwerin', '0385']},
                                            {cols: ['Singen', '07731']},
                                            {cols: ['Stuttgart', '0711']},
                                            {cols: ['Trier', '0651']},
                                            {cols: ['Ulm', '0731']},
                                            {cols: ['Wiesbaden', '0611']},
                                            {cols: ['Wittenberg', '03877']},
                                            {cols: ['Würzburg', '0931']},
                                            {cols: ['Wuppertal', '0202']}
                                        ]
                                    }
                                },
                                {id: 2, localizedName: "INTERNATIONALE LANDESVORWAHLEN",
                                    tbl: {
                                        rows: [
                                            {cols: ['Ägypten', '0020']},
                                            {cols: ['Argentinien', '0054']},
                                            {cols: ['Australien', '0061']},
                                            {cols: ['Belgien', '0032']},
                                            {cols: ['China', '0086']},
                                            {cols: ['Dänemark', '0045']},
                                            {cols: ['Deutschland', '0049']},
                                            {cols: ['Finnland', '00358']},
                                            {cols: ['Frankreich', '0033']},
                                            {cols: ['Griechenland', '0030']},
                                            {cols: ['Großbritannien', '0044']},
                                            {cols: ['Hong Kong', '00852']},
                                            {cols: ['Indien', '0091']},
                                            {cols: ['Irland', '00353']},
                                            {cols: ['Israel', '00972']},
                                            {cols: ['Italien', '0039']},
                                            {cols: ['Japan', '0081']},
                                            {cols: ['Kanada', '001']},
                                            {cols: ['Korea (Süd)', '0082']},
                                            {cols: ['Kuwait', '00965']},
                                            {cols: ['Liechtenstein', '00423']},
                                            {cols: ['Luxemburg', '00352']},
                                            {cols: ['Monaco', '00377']},
                                            {cols: ['Mexiko', '0052']},
                                            {cols: ['Niederlande', '0031']},
                                            {cols: ['Neuseeland', '0064']},
                                            {cols: ['Nigeria', '00234']},
                                            {cols: ['Norwegen', '0047']},
                                            {cols: ['Österreich', '0043']},
                                            {cols: ['Phillipinen', '0063']},
                                            {cols: ['Polen', '0048']},
                                            {cols: ['Portugal', '00351']},
                                            {cols: ['Rumänien', '0040']},
                                            {cols: ['Russland', '007']},
                                            {cols: ['Saudi Arabien', '00966']},
                                            {cols: ['Slowakei', '00421']},
                                            {cols: ['Slowenien', '00386']},
                                            {cols: ['Spanien', '0034']},
                                            {cols: ['Schweden', '0046']},
                                            {cols: ['Schweiz', '0041']},
                                            {cols: ['Taiwan', '00886']},
                                            {cols: ['Thailand', '0066']},
                                            {cols: ['Türkei', '0090']},
                                            {cols: ['Ungarn', '0036']},
                                            {cols: ['USA', '001']},
                                            {cols: ['Zypern', '00357']}
                                        ]
                                    }
                                }
                            ]},
                {id: 4, list: [
                        {id: 1, localizedName: 'A.LOUNGE / SMOKERS LOUNGE', localizedDescription: 'Täglich von 07:00 bis 23:00 Uhr. Exklusiv nutzbar für unsere Gäste der Executive Floors.', phoneNumber: '2053'},
                        {id: 2, localizedName: 'WELLNESS / SPA', localizedDescription: 'Täglich von 09:00 bis 22:00 Uhr', phoneNumber: '2401'},
                        {id: 3, localizedName: 'SOLARIUM', localizedDescription: 'Täglich von 09:00 bis 22:00 Uhr', phoneNumber: '2401'},
                        {id: 4, localizedName: 'FITNESS', localizedDescription: 'Der Fitnessbereich steht Ihnen 24 Stunden zur Verfügung.', phoneNumber: '2401'},
                        {id: 5, localizedName: 'HOTEL SHOP', localizedDescription: 'Montag bis Freitag von 08:00 bis 14:30 Uhr, Samstag bis Sonntag von  08:00 bis 13:00 Uhr', phoneNumber: '2410'},
                        {id: 6, localizedName: 'RISTORANTE,OSCAR’S', localizedDescription: 'Täglich von 08:00 bis 23:00 Uhr', phoneNumber: '2610'},
                        {id: 7, localizedName: 'BAR', localizedDescription: 'Täglich von 18:00 bis 02:00 Uhr', phoneNumber: '2614'},
                        {id: 8, localizedName: 'RESTAURANT SKYKITCHEN', localizedDescription: 'Dienstag bis Samstag von 18:00 bis 23:00 Uhr (Küche bis 22:30 Uhr) ', phoneNumber: '2620'},
                        {id: 9, localizedName: 'RESTAURANT DELIGHT', localizedDescription: 'Frühstück von Montag bis Samstag von 06:30 bis 10:30 Uhr und an Sonn- und Feiertag von 06:30 bis 13:00 Uhr', phoneNumber: '2625'},
                        {id: 10, localizedName: 'ROOM-SERVICE', localizedDescription: 'Täglich von 00:00 bis 24:00 Uhr. Die Room-Service Karte finden Sie im vorderen Bereich dieser Hotel Information. Der Aufpreis für das Frühstück auf dem Zimmer beträgt EUR 10,-. Kostenfreies „Early Bird“-Frühstücksangebot täglich von 04:30 bis 06:30 Uhr in unserer Lobby.', phoneNumber: '2650'}
                    ]},
                {id: 2, list: [
                        {id: 1, localizedName: 'ABREISE', localizedDescription: 'Am Tag Ihrer Abreise steht Ihnen Ihr Zimmer bis 12:00 Uhr zur Verfügung. Bitte kontaktieren Sie die Rezeption, falls  Sie Ihr Zimmer länger benötigen. Nutzen Sie gern die Möglichkeit der Gepäcklagerung und bei Bedarf auch den Express Check-out. Das Rezeptionsteam hilft Ihnen gern in allen Fragen weiter.', phoneNumber: '2000'},
                        {id: 2, localizedName: 'ADAPTER', localizedDescription: 'Adapter, die nach europäischem Standard – 220 Volt –  funktionieren sowie internationale Adapter und Ladegeräte erhalten Sie von unserem Concierge.', phoneNumber: '2033'},
                        {id: 3, localizedName: 'ADRESSE', localizedDescription: 'andel’s Hotel Berlin<br>Landsberger Allee 106, 10369 Berlin, Germany<br>Tel.: +49 30 453 053 0 Fax: +49 30 453 053 2099<br>E-Mail: info@andelsberlin.com<br>Internet: www.andelsberlin.com', phoneNumber: ''},
                        {id: 4, localizedName: 'ALLERGIE', localizedDescription: 'Für Allergiker stehen in unserem Hotel 19 speziell ausgestattete Allergiker Zimmer zur Verfügung. Darüber hinaus sind alle Zimmer mit antiallergischen Kopfkissen und Bettdecken ausgestattet.', phoneNumber: ''},
                        {id: 5, localizedName: 'ANREISE', localizedDescription: 'Am Anreisetag ist Ihr Zimmer ab 15:00 Uhr für Sie bezugsfertig. Sollten Sie früher anreisen, lagern wir bei Bedarf gern Ihr Gepäck. Sollten Sie weitere Fragen haben, wenden Sie sich bitte an die Rezeption.', phoneNumber: '2000'},
                        {id: 6, localizedName: 'APOTHEKE', localizedDescription: 'Für Informationen zu Apotheken und Notdienstapotheken fragen Sie bitte den Concierge. Die nächstgelegene Apotheke ist in ca. 5 Minuten Fußweg zu erreichen und liegt auf der gegenüber liegenden Straßenseite: Haus-Apotheke, Landsberger Allee 117, 10407 Berlin, Tel.: +49 30 42 02 49 80.', phoneNumber: ''},
                        {id: 7, localizedName: 'ARZT / ZAHNARZT', localizedDescription: 'Sollten Sie einen Arzt, Zahnarzt oder Notarzt benötigen, wenden Sie sich bitte an den Concierge oder Ihre Ansprechpartner an der Rezeption.', phoneNumber: '2033 / 2000'},
                        {id: 8, localizedName: 'AUFZÜGE', localizedDescription: 'Die Sicherheit unserer Gäste ist uns wichtig. Aus diesem Grund ist es notwendig, in den Räumlichkeiten des Hotels und in den Aufzügen die Schlüsselkarten zu verwenden. Diese ermöglichen den Zugang ausschließlich für unsere Hotelgäste sowie berechtigtes Personal.', phoneNumber: ''},
                        {id: 9, localizedName: 'AUSFLÜGE', localizedDescription: 'Unser Concierge berät Sie gern mit Tipps zu Ausflügen in und um Berlin. Erkunden Sie die Hauptstadt doch einfach mal mit dem Trabi,  tauchen Sie mit dem Fahrstuhl durch das Meer oder kuscheln Sie sich gemütlich an die Schultern des Hollywood-Frauenschwarms Brad Pitt.  „Mr. Fix-It-All“ erfüllt Ihnen nahezu alle Wünsche.', phoneNumber: '2033'},
                        {id: 10, localizedName: 'AUSKUNFT', localizedDescription: 'Auskunft innerhalb Deutschlands: 11833<br>Internationale Auskunft: 11883<br>Auskunft der Deutschen Bahn: +49 800 150 7090<br>Für weitere Auskünfte ist Ihnen der Concierge gern behilflich.', phoneNumber: '2033'},
                        {id: 11, localizedName: 'AUTOVERMIETUNG', localizedDescription: 'Gern ist Ihnen unser Concierge bei der Anmietung eines Mietwagens behilflich. <br>Als ökologisch nachhaltige Alternative zu Taxi & Co stehen Ihnen exklusiv auch zwei elektrisch betriebene Kleinwagen zur Verfügung.', phoneNumber: '2033'},
                        {id: 12, localizedName: 'BABYBETTEN', localizedDescription: 'Auf Wunsch stellen wir Ihnen gern ein Babybett kostenfrei zur Verfügung. Bitte wenden Sie sich an unsere Hausdame.', phoneNumber: '2900'},
                        {id: 13, localizedName: 'BABYSITTER', localizedDescription: 'Unser Concierge organisiert Ihnen gern einen Babysitter. Für eine vorherige Anmeldung sind wir Ihnen sehr dankbar.', phoneNumber: '2033'},
                        {id: 14, localizedName: 'BADEMÄNTEL', localizedDescription: 'Bademäntel und Slipper liegenfür Sie im Zimmer (Kleiderschrank) bereit.', phoneNumber: '2900'},
                        {id: 15, localizedName: 'BAHNHOF', localizedDescription: 'Der S-Bahnhof „Landsberger Allee“ befindet sich direkt neben dem Hotel und ist in ca. 2 Minuten zu Fuß erreichbar. Hier halten die S-Bahnlinien S41, S42, S8 und S9 sowie die Straßenbahnlinien M5, M6 und M8.<br>Zum Hauptbahnhof fahren Sie ungefähr 20 Minuten mit dem Auto bzw. 27 Minuten mit der Straßenbahnlinie M5.<br>Für eine genaue Wegbeschreibung oder Auskünfte zu Bus und Bahn wenden Sie sich bitte an unseren Concierge.', phoneNumber: '2033'},
                        {id: 16, localizedName: 'BANK', localizedDescription: 'Commerzbank, Frankfurter Allee 13 – 15, Tel.: +49 30 453 0685-0<br>Sparda Bank, Storkower Straße 101A, Tel.: +49 30 428 300<br>Für weitere Auskünfte kontaktieren Sie bitte unseren Concierge.', phoneNumber: '2033'},
                        {id: 17, localizedName: 'BAR', localizedDescription: 'Die sky.bar auf der 14. Etage bietet zur blauen Stunde und bei Nacht einen einzigartigen Blick über die Dächer von Berlin. Neben fruchtigen Cocktailspezialitäten aus frischem Obst erwartet Sie eine Auswahl an 300 auserwählten Spirituosen, Weinen, Bieren und Champagner. <br>Die Öffnungszeiten entnehmen Sie bitte der letzten Seite.', phoneNumber: '2614'},
                        {id: 18, localizedName: 'BARRIEREFREIE ZIMMER', localizedDescription: 'Das Hotel verfügt über 14 barrierefreie Zimmer, die rollstuhlgerecht ausgestattet sind. Für diese Zimmer besteht ein direkter Zugang zu einem eventuell benötigten Betreuerzimmer.', phoneNumber: ''},
                        {id: 19, localizedName: 'BCC', localizedDescription: 'Das Berliner Congress Center befindet sich am Alexanderplatz in der Alexanderstraße 11, 10178 Berlin. Vom andel’s Hotel Berlin ist das BCC mit den Straßenbahnen M5 und M6 in ca. 10 Minuten zu erreichen.', phoneNumber: '2033'},
                        {id: 20, localizedName: 'BESUCHER', localizedDescription: 'Wir bitten unsere Hotelgäste, Ihre Zimmerbesucher an der Rezeption anzumelden. Diese Maßnahme soll vor allem den Schutz und die Sicherheit der Gäste selbst gewährleisten. Ein Besuch, der sich über Nacht aufhält, wird als zweiter Zimmerbewohner registriert und erhöht dementsprechend den Zimmerpreis. Weitere Informationen und Preisauskünfte erhalten Sie bei unseren Mitarbeitern der Rezeption.', phoneNumber: '2000'},
                        {id: 21, localizedName: 'BETTDECKEN', localizedDescription: 'Sollten Sie zusätzliche Bettdecken wünschen, ist Ihnen unsere Hausdame gern behilflich.', phoneNumber: '2900'},
                        {id: 22, localizedName: 'BETTWÄSCHE', localizedDescription: 'Sollten Sie einen täglichen Bettwäschewechsel wünschen, so geben Sie uns bitte Bescheid.', phoneNumber: '2900'},
                        {id: 23, localizedName: 'BITTE NICHT STÖREN', localizedDescription: 'Falls Sie in Ihrem Zimmer nicht gestört werden möchten, hängen Sie bitte das Schild „Bitte nicht stören“ außen an Ihre Zimmertür. Zusätzlich kann Ihr Telefon von der Rezeption gesperrt werden.', phoneNumber: '2000'},
                        {id: 24, localizedName: 'BOTSCHAFT', localizedDescription: 'Bei Fragen steht Ihnen unser Concierge Team jederzeit gern zur Verfügung.', phoneNumber: '2033'},
                        {id: 25, localizedName: 'BRIEFMARKEN', localizedDescription: 'Briefmarken erhalten Sie in unserem Hotel Shop in der Lobby. ', phoneNumber: '2030'},
                        {id: 26, localizedName: 'BÜGELN', localizedDescription: 'Wenn Sie Ihre Wäsche bügeln lassen möchten, wenden Sie sich bitte an unsere Hausdame. Möchten Sie Ihre Wäsche selber bügeln, bringen wir Ihnen gern kostenfrei ein Bügeleisen und ein Bügelbrett auf Ihr Zimmer.', phoneNumber: '2900'},
                        {id: 27, localizedName: 'BUSINESS CENTER', localizedDescription: 'Unser Business Center befindet sich auf der 1. Etage und bietet Ihnen verschiedene Dienstleistungen an. Hier stehen Ihnen PC-Arbeitsplätze, Drucker mit Kopier- und Scannermöglichkeit und Internetzugang zur Verfügung. Zusätzlich haben Sie die Möglichkeit, sich auf Ihrem Zimmer in das hoteleigene WLAN mit einer Geschwindigkeit von bis zu 2 MB/s und kostenpflichtige Highspeed-Internet einzuloggen. Für Fragen oder Preisauskünfte kontaktieren Sie bitte unsere Rezeption.', phoneNumber: '2000'},
                        {id: 28, localizedName: 'CITYCUBE BERLIN ', localizedDescription: 'Der CityCube Berlin befindet sich im Berliner Stadtteil Charlottenburg und ist vom andel’s Hotel Berlin mit der S-Bahn (Haltestelle: Messe Süd) in ca. 20 Minuten zu erreichen. Sollten Sie weitere Fragen haben, ist Ihnen unser Concierge gern behilflich.', phoneNumber: '2033'},
                        {id: 29, localizedName: 'CONCIERGE', localizedDescription: 'Unser Concierge steht Ihnen täglich von 08:30 bis 19:00 Uhr mit Informationen zur Bundeshauptstadt aus erster Hand zur Verfügung. Hierzu gehören Sightseeing-Touren, Restaurantreservierungen, Museumsempfehlungen, und Ticketbestellungen.  Auch Inhouse kennt sich der Concierge bestens aus und unterstützt Sie mit Regenschirmen und dem Hinweis zum nächst gelegenen Schuhputzgerät.  ', phoneNumber: '2033'},
                        {id: 30, localizedName: 'DEVISEN- UND WÄHRUNGSWECHSEL', localizedDescription: 'Devisen- und Währungswechsel können Sie gern an unserer Rezeption vornehmen.', phoneNumber: '2000'},
                        {id: 31, localizedName: 'DIREKTION', localizedDescription: 'Wenden Sie sich bitte an unsere Rezeption, die Sie gern weiterverbindet.', phoneNumber: '2000'},
                        {id: 32, localizedName: 'DRUCKER', localizedDescription: 'Ein PC-Drucker mit Kopier- und Scannerfunktion steht Ihnen im Business Center auf der 1. Etage sowie an unserer Internet Station in der Lobby nahe des Concierge Desks zur Verfügung.', phoneNumber: '2033'},
                        {id: 33, localizedName: 'DVD-SPIELER', localizedDescription: 'Alle Hotelzimmer und Suiten sind mit einem DVD-Spieler ausgestattet. Unsere Rezeption freut sich, Ihnen eine Auswahl an DVDs anbieten zu dürfen. ', phoneNumber: '2000'},
                        {id: 34, localizedName: 'E-MAIL', localizedDescription: 'Selbstverständlich können Sie Ihre E-Mails an unsere Hotel E-Mail Adresse senden lassen.<br>Unsere E-Mail Adresse lautet: info@andelsberlin.com<br>Tagsüber werden wir Ihnen Ihre E-Mails umgehend auf Ihr Zimmer bringen.', phoneNumber: ''},
                        {id: 35, localizedName: 'ERSTE HILFE', localizedDescription: 'Unsere hauseigene 24 Stunden Security organisiert Ihnen in Notfällen gern ärztliche Unterstützung. In lebensbedrohlichen Situationen kontaktieren Sie bitte unsere Rezeption.', phoneNumber: '2000'},
                        {id: 36, localizedName: 'EXECUTIVE FLOORS', localizedDescription: 'Unsere Executive Floors befinden sich auf der 5. und 6. Etage und bieten erstklassigen Service speziell für Reisende mit besonderen Ansprüchen. Gäste der Executive Floors erhalten einen exklusiven Zugang zu unserer a.lounge und Smokers Lounge mit 110 m² Sonnenterrasse, in die Sie auch gern außerhalb der Buffetzeiten einen Kaffee, Tee oder einem Softgetränk genießen können.<br>„FEEL LIKE A VIP“ und genießen Sie ein reichhaltiges Frühstücksbuffet, einen süßen Afternoon Break mit Kreationen aus der hauseigenen Pâtisserie, ein Snackbuffet mit warmen und kalten Köstlichkeiten sowie Tee, Kaffee und Erfrischungsgetränke zur ganztätigen Verfügung. Kostenfreies WLAN und High-Speed-Internet in diesem Bereich ist selbstverständlich.<br>Für weitere Informationen wenden Sie sich bitte an unser Guest Relation Team.', phoneNumber: '2053'},
                        {id: 37, localizedName: 'EXPRESS CHECK-OUT', localizedDescription: 'Für eine frühere Abreise kontaktieren Sie bitte gern die Rezeption.', phoneNumber: '2000'},
                        {id: 38, localizedName: 'FAHRRADVERLEIH', localizedDescription: 'Wussten Sie, dass Berlin aufgrund seiner zahlreichen Parks und Waldflächen zu den „grünsten“ Städten Europas gehört? Sollten Sie „Spree-Athen“ auf dem Fahrrad erkunden wollen, ist Ihnen der Concierge nicht nur beim Ausleihen eines geeigneten Fahrrades behilflich, sondern zeigt Ihnen auch gern interessante Touren durch die Hauptstadt und Umgebung.', phoneNumber: '2033'},
                        {id: 39, localizedName: 'FAX', localizedDescription: 'Sehr gern können Sie Ihre Faxe an unser Hotel senden lassen. <br>Unsere Faxnummer lautet:+ 49 30 453 053 2099<br>Tagsüber erhaltene Faxe werden umgehend von uns auf Ihr Zimmer gebracht.', phoneNumber: '2000'},
                        {id: 40, localizedName: 'FEIERTAGE', localizedDescription: 'Offizielle Feiertage in Berlin:<br>1. Januar Neujahrstag<br>Variabel  Karfreitag<br>Variabel Ostermontag<br>1. Mai Maifeiertag<br>Variabel Christi Himmelfahrt<br>Variabel Pfingstmontag<br>3. Oktober Tag der Deutschen Einheit<br>25. Dezember Erster Weihnachtsfeiertag<br>26. Dezember Zweiter Weihnachtsfeiertag', phoneNumber: ''},
                        {id: 41, localizedName: 'FERNSEHEN', localizedDescription: 'Eine Übersicht der angebotenen Fernsehkanäle finden Sie im TV-Programm.', phoneNumber: '2000'},
                        {id: 42, localizedName: 'FEUERALARM', localizedDescription: 'Falls in Ihrem Zimmer Rauch oder Feuer ausbricht, verlassen Sie bitte umgehend das Zimmer und schließen Sie die Tür hinter sich, um eine weitere Ausbreitung zu vermeiden. Bedienen Sie den nächstgelegenen Feuermelder und informieren Sie die Rezeption. Sollte im Hotel ein Brand ausbrechen, hören Sie einen Alarm. Im Fall einer Evakuierung werden Sie in deutscher, englischer und französischer Sprache informiert. Unsere kontinuierlich geschulten Mitarbeiter werden Sie zu der vorgesehenen Evakuierungsstelle leiten. Bitte benutzen Sie auf keinen Fall den Fahrstuhl. Den detaillierten Fluchtweg von Ihrem Zimmer aus können Sie dem Fluchtplan an der Zimmertür entnehmen.', phoneNumber: '2000'},
                        {id: 43, localizedName: 'FEUERSICHERHEITSMASSNAHMEN', localizedDescription: 'Das Hotel besitzt ein modernes Feuerwarnsystem und ist mit Rauchmeldern in allen Zimmern und Korridoren ausgestattet. Das Hotelpersonal wird regelmäßig für Notfälle geschult. Weitere Informationen finden Sie auf dem Lageplan an Ihrer Zimmertür.', phoneNumber: ''},
                        {id: 44, localizedName: 'FITNESS', localizedDescription: 'Unser Fitness-Studio befindet sich in unserem Spa- und Wellnessbereich auf der 2. Etage und ist 24 Stunden für Sie geöffnet.', phoneNumber: '2401'},
                        {id: 45, localizedName: 'FLUGHAFEN', localizedDescription: 'Flughafen Tegel (TXL):              13,5 km; Erreichbarkeit in ca. 30 Minuten mit dem Auto<br>Flughafen Schönefeld (SXF):    21,3 km; Erreichbarkeit in ca. 40 Minuten mit dem Auto<br>Zum Flughafen Schönefeld (SXF) gibt es eine Direktanbindung von der Landsberger Allee mit der S 9 (die Fahrtzeit beträgt 30 Minuten).<br>Beide Flughäfen sind unter der Nummer + 49 1805 000 186 zu erreichen. <br>Für Informationen zu den Flughäfen kontaktieren Sie bitte den Concierge.', phoneNumber: '2033'},
                        {id: 46, localizedName: 'FLUGTICKETS', localizedDescription: 'Der Concierge ist Ihnen bei Ihren Reisevorbereitungen gern behilflich.', phoneNumber: '2033'},
                        {id: 47, localizedName: 'FÖHN', localizedDescription: 'Alle Hotelzimmer und Suiten sind mit einem Föhn ausgestattet.', phoneNumber: ''},
                        {id: 48, localizedName: 'FOTOKOPIEN', localizedDescription: 'Benötigen Sie Fotokopien, so wenden Sie sich bitte an unser Business Center in der 1. Etage oder an unseren Concierge.', phoneNumber: '2033'},
                        {id: 49, localizedName: 'FRAGEBOGEN FÜR GÄSTE', localizedDescription: 'Ihre Meinung ist uns wichtig. Umso mehr freuen wir uns, wenn Sie den Gästefragebogen, den Sie zwei Tage nach Abreise per E-Mail von uns erhalten, ausfüllen. Wir bedanken uns schon heute für Ihre Anregungen, die uns helfen werden, das Serviceangebot in unserem Hotel stetig zu verbessern.', phoneNumber: ''},
                        {id: 50, localizedName: 'FRISEUR', localizedDescription: 'Gern sind der Concierge und das a.spa Team bei der Auswahl des richtigen Hairstylisten in der unmittelbaren Umgebung behilflich.', phoneNumber: '2033 / 2401'},
                        {id: 51, localizedName: 'FRÜHSTÜCK', localizedDescription: 'Genießen Sie Ihren Start in den Tag mit einem reichhaltigen Frühstücksbuffet im Restaurant delight auf der 1. Etage. Das „early bird“ Frühstücksangebot und die Öffnungszeiten im Restaurant entnehmen Sie bitte der letzten Seite.', phoneNumber: '2625'},
                        {id: 52, localizedName: 'FRÜHSTÜCK AUF IHREM ZIMMER', localizedDescription: 'Sie haben die Möglichkeit, ganz bequem auf Ihrem Zimmer zu frühstücken. Bitte hängen Sie dazu den Bestellzettel bis 02:00 Uhr von außen an Ihre Zimmertür. Das Frühstück im Zimmer wird Ihnen nicht extra berechnet. Den Room-Service Aufpreis entnehmen Sie bitte der Frühstückskarte. ', phoneNumber: '2650'},
                        {id: 53, localizedName: 'FUNDSACHEN', localizedDescription: 'Sollten Sie etwas vermissen oder finden, melden Sie sich bitte an der Rezeption.', phoneNumber: '2000'},
                        {id: 54, localizedName: 'GELDAUTOMAT', localizedDescription: 'Der nächste Geldautomat befindet sich in der Volksbank, Forum Landsberger Allee gegenüber des Hotels in der Storkower Straße 139, ca. 400 Meter.', phoneNumber: ''},
                        {id: 55, localizedName: 'GEPÄCK', localizedDescription: 'Gern sind unsere Portiers beim Transport Ihres Gepäcks behilflich. Zusätzlich bieten wir Ihnen Gepäckwagen an. Bitte wenden Sie sich an unseren Concierge.', phoneNumber: '2033'},
                        {id: 56, localizedName: 'GUEST RELATION', localizedDescription: 'Unser Guest Relation Team steht Ihnen gern für Fragen und Wünsche jederzeit zur Verfügung.', phoneNumber: '2050'},
                        {id: 57, localizedName: 'HANDTÜCHER', localizedDescription: 'Wünschen Sie einen Wechsel der Hand- oder Badetücher, so lassen Sie diese bitte auf dem Boden liegen. Handtücher auf dem Handtuchhalter bedeuten, dass Sie keinen Wechsel wünschen. Mit dieser Maßnahme tragen Sie mit uns gemeinsam zum Umweltschutz bei. Benötigen Sie zusätzliche Handtücher, benachrichtigen Sie bitte unsere Hausdame.', phoneNumber: '2900'},
                        {id: 58, localizedName: 'HAUSTIERE', localizedDescription: 'Haustiere sind herzlich willkommen. Bitte haben Sie Verständnis, dass wir eine Reinigungsgebühr von EUR 15,- pro Tag berechnen.', phoneNumber: ''},
                        {id: 59, localizedName: 'HOTEL SHOP', localizedDescription: 'Unser Hotel Shop im Erdgeschoss, gegenüber des Concierge Desks, bietet Ihnen unter anderem eine Auswahl an internationalen Zeitungen und Zeitschriften sowie Souvenirs an. Die Öffnungszeiten entnehmen Sie bitte der letzten Seite.', phoneNumber: '2410'},
                        {id: 60, localizedName: 'HOTELRECHNUNG', localizedDescription: 'Die Hotelrechnung kann mit internationalen Kreditkarten, EC-Cash oder in Bar bezahlt werden. Fragen zu Ihrer Hotelrechnung beantwortet Ihnen gern unser Rezeptionsteam.', phoneNumber: '2000'},
                        {id: 61, localizedName: 'HOUSEKEEPING', localizedDescription: 'Für zusätzliche Wäsche und andere Zimmerutensilien wie z.B. ein Badezimmerhocker  kontaktieren Sie bitte unsere Hausdame.', phoneNumber: '2900'},
                        {id: 62, localizedName: 'INTERNET ', localizedDescription: 'Alle Zimmer im andel’s Hotel Berlin verfügen über einen Highspeed- und einen WLAN-Internetzugang. Auf Anfrage stellen wir Ihnen gern ein mobiles Endgerät zur Verfügung. Bei Verbindungsproblemen kontaktieren Sie gern unser Rezeptionsteam .<br><br>Der Highspeed-Internetzugang wird Ihnen mit EUR 2,- pro Stunde und maximal EUR 14,- pro Tag berechnet. Gästen unserer Executive Floors steht der Highspeed-Internetzugang rund um die Uhr kostenlos zur Verfügung. Für die Nutzung benötigen Sie das Netzwerkkabel auf Ihrem Schreibtisch. Der Log-In setzt sich aus Ihrem Namen und Ihrer Zimmernummer zusammen. <br><br>Kostenloses WLAN steht Ihnen mit einer Geschwindigkeit von bis zu 2 MB/s im gesamten Hotel zur Verfügung.', phoneNumber: '2000'},
                        {id: 63, localizedName: 'JOGGING', localizedDescription: 'Möchten Sie den nahe gelegenen Volkspark Friedrichshain laufend erkunden? Gern ist  Ihnen unser Concierge behilflich, die schönste Laufroute zu entdecken.', phoneNumber: '2033'},
                        {id: 64, localizedName: 'KIRCHEN', localizedDescription: 'Für Informationen zu Kirchen und Gottesdiensten kontaktieren Sie bitte den Concierge.', phoneNumber: '2033'},
                        {id: 65, localizedName: 'KLIMAANLAGE UND HEIZUNG', localizedDescription: 'Alle Zimmer und Suiten verfügen über eine individuell regulierbare Klimaanlage und Heizung und sind über die Temperaturregler an der Wand zu bedienen.', phoneNumber: ''},
                        {id: 66, localizedName: 'KONFERENZ UND TAGUNGEN', localizedDescription: 'Das andel’s Hotel Berlin zählt mit einer Konferenz- und Eventkapazität von 3.800 m² und Platz für bis zu 3.000 Personen zu einem der größten Tagungs- und Kongresshotels der Bundeshauptstadt. Die großzügigen Eventflächen, Foyers und der Ballsaal eignen sich sowohl für Großveranstaltungen, Messen, Konzerte, Bälle, Auto- und Firmenpräsentationen als auch für Fachtagungen und internationale Kongresse. Zusätzlich stehen Ihnen 21 multiflexible Tagungsräume mit Tageslicht zur Verfügung. Für detaillierte Auskünfte steht Ihnen unser Convention Team jederzeit gern beratend zur Seite.', phoneNumber: '2320'},
                        {id: 67, localizedName: 'KONSULATE', localizedDescription: 'Bei Fragen und Auskünften steht Ihnen unser Concierge jederzeit gern zur Verfügung.', phoneNumber: '2033'},
                        {id: 68, localizedName: 'KOPFKISSEN', localizedDescription: 'Sollten Sie für eine erholsame Nacht Ihr Lieblingskissen vermissen, so kontaktieren Sie bitte unser Rezeptionsteam. Treffen Sie Ihre Auswahl im Kissenmenü,  welches in der Hotelinformation hinterlegt ist. ', phoneNumber: '2000'},
                        {id: 69, localizedName: 'KREDITKARTEN', localizedDescription: 'Unser Hotel akzeptiert folgende Kreditkarten: American Express, VISA, MasterCard, Diners Club und JCB sowie EC-Cash.', phoneNumber: ''},
                        {id: 70, localizedName: 'LIMOUSINE', localizedDescription: 'Für Limousinen- und Chauffeurdienste wenden Sie sich bitte an unseren Concierge.', phoneNumber: '2033'},
                        {id: 71, localizedName: 'MANAGER ON DUTY', localizedDescription: 'In Abwesenheit unseres General Managers ist der Manager on Duty (MOD) der diensthabende Manager und jederzeit für Wünsche, Fragen oder Anregungen für Sie zu sprechen.', phoneNumber: '2222'},
                        {id: 72, localizedName: 'MASSAGE', localizedDescription: 'Wünschen Sie eine Gesicht-, Kopf-, Nacken- oder Ganzkörpermassage, so wenden Sie sich bitte an das a.spa Team.', phoneNumber: '2401'},
                        {id: 73, localizedName: 'MESSE BERLIN', localizedDescription: 'Die Messe Berlin befindet sich im Berliner Stadtteil Charlottenburg und ist vom andel’s Hotel Berlin mit der S-Bahn Linie 42 (Haltestelle: Messe Nord ICC) in ca. 20 Minuten zu erreichen. Sollten Sie weitere Fragen haben, ist Ihnen unser Concierge gern behilflich.', phoneNumber: '2033'},
                        {id: 74, localizedName: 'MINIBAR', localizedDescription: 'Alle Zimmer und Suiten sind mit einer Minibar ausgestattet. Für spezielle Wünsche kontaktieren Sie bitte unseren Room-Service.', phoneNumber: '2650'},
                        {id: 75, localizedName: 'MITTEILUNGEN / NACHRICHTEN', localizedDescription: 'Nachrichten oder Faxe bringen wir Ihnen umgehend auf Ihr Zimmer. Bei Sprachnachrichten leuchtet die Nachrichtenlampe Ihres Telefons auf.', phoneNumber: '2000'},
                        {id: 76, localizedName: 'NÄHSERVICE', localizedDescription: 'Unser Housekeeping Team freut sich, Ihnen bei Näharbeiten behilflich zu sein.', phoneNumber: '2900'},
                        {id: 77, localizedName: 'NOTRUF', localizedDescription: 'In Notfällen drücken Sie bitte den Knopf „Emergency“ auf dem Telefon.', phoneNumber: '2000'},
                        {id: 78, localizedName: 'NOTRUFNUMMERN', localizedDescription: 'Amtsleitung     0 <br>Polizei 110 <br>Feuerwehr 112', phoneNumber: ''},
                        {id: 79, localizedName: 'ÖFFENTLICHE  VERKEHRSMITTEL', localizedDescription: 'In den öffentlichen Verkehrsmitteln benötigen Sie Fahrkarten, die vor dem Einstieg zu entwerten sind. Die Fahrkarten sind bei unserem Concierge oder an den Automaten der Haltestellen zu erwerben. Fahrpläne liegen beim Concierge für Sie bereit.', phoneNumber: '2033'},
                        {id: 80, localizedName: 'PARKPLÄTZE', localizedDescription: 'Das Hotel bietet Ihnen 550 Stellplätze in der hauseigenen Tiefgarage. Bitte beachten Sie, dass das Hotel für Diebstahl oder Beschädigungen keine Haftung übernehmen kann. <br>Parkgebühren können an der Rezeption oder am Automaten entrichtet werden. Die Parkgebühren betragen EUR 2,- pro Stunde und EUR 15,- pro Tag.', phoneNumber: '2000'},
                        {id: 81, localizedName: 'POST', localizedDescription: 'Ihre Post wird direkt auf Ihr Zimmer gebracht. Zu versendende Post nimmt der Concierge jederzeit sehr gern entgegen.', phoneNumber: '2033'},
                        {id: 82, localizedName: 'RASIERSETS', localizedDescription: 'Sollten Sie Ihren Rasierer vergessen haben, ist Ihnen das Housekeeping Team gern mit einem Rasierset behilflich. ', phoneNumber: '2900'},
                        {id: 83, localizedName: 'RAUCHEN', localizedDescription: 'Das Rauchen ist in allen öffentlichen Bereichen sowie auf den Nichtraucherzimmern untersagt. Bitte haben Sie Verständnis, dass wir für das Rauchen in Nichtraucherzimmern eine Reinigungsgebühr von EUR 150,- erheben.', phoneNumber: '2000'},
                        {id: 84, localizedName: 'REINIGUNG', localizedDescription: 'Bitte geben Sie Ihre zu reinigende Wäsche (von Montag bis Samstag) mit der ausgefüllten Wäscheliste bis 09:00 Uhr an der Rezeption ab. Die Wäsche wird bis spätestens 18:00 Uhr zurück auf Ihr Zimmer geliefert. Wäsche, welche nach 09:00 Uhr abgegeben wird, können wir erst am nächsten Tag retournieren.', phoneNumber: '2900'},
                        {id: 85, localizedName: 'RESERVIERUNGEN', localizedDescription: 'Reservierungen in anderen Hotels der VI Hotels & Resorts nehmen die Mitarbeiter der Rezeption sehr gern entgegen.', phoneNumber: '2000'},
                        {id: 86, localizedName: 'RESTAURANT DELIGHT', localizedDescription: 'Genießen Sie Ihren Start in den Tag mit einem reichhaltigen Frühstücksbuffet im Restaurant delight auf der 1. Etage. Das „early bird“ Frühstücksangebot und die Öffnungszeiten im Restaurant entnehmen Sie bitte der letzten Seite.', phoneNumber: '2625'},
                        {id: 87, localizedName: 'RESTAURANT SKYKITCHEN', localizedDescription: 'In der SKYKITCHEN auf der 12. Etage bringen Sternekoch Alexander Koppe und sein Team moderne Neuinterpretationen deutscher Küche mit einem Touch Internationalität mit Blick über ganz Berlin auf den Teller. <br>Die Öffnungszeiten entnehmen Sie bitte der letzten Seite.', phoneNumber: '2620'},
                        {id: 88, localizedName: 'REZEPTION', localizedDescription: 'Unsere Rezeption steht Ihnen 24 Stunden zur Verfügung.', phoneNumber: '2000'},
                        {id: 89, localizedName: 'RISTORANTE OSCAR‘S', localizedDescription: 'Das Ristorante oscar’s befindet sich im Erdgeschoss und bietet eine Auswahl an italienischen Klassikern und Highlights der spanischen und südfranzösischen Küche zubereitet in der offenen Showküche.<br>Die Öffnungszeiten entnehmen Sie bitte der letzten Seite.', phoneNumber: '2900'},
                        {id: 90, localizedName: 'ROOM-SERVICE', localizedDescription: 'Unser Room-Service bietet Ihnen 24 Stunden ein klassisches Speisenangebot. <br>Die Room-Service Karte finden Sie im vorderen Teil dieser Hotelinformation. ', phoneNumber: '2650'},
                        {id: 91, localizedName: 'SAFE', localizedDescription: 'In Ihrem Hotelzimmer steht Ihnen ein Safe für Wertgegenstände gebührenfrei zur Verfügung. Der elektrische Anschluss im Safe kann zum Laden Ihres Laptops oder Mobiltelefons genutzt werden.<br>Zusätzlich haben Sie die Möglichkeit, Wertgegenstände kostenfrei in einem eigenen Safe an der Rezeption aufzubewahren. Bei Fragen wenden Sie sich bitte an die Rezeption.', phoneNumber: '2000'},
                        {id: 92, localizedName: 'SAUNA', localizedDescription: 'Die Saunen befinden sich in unserem Spa- und Wellnessbereich auf der 2. Etage.<br>Die Öffnungszeiten entnehmen Sie bitte der letzten Seite.', phoneNumber: '2401'},
                        {id: 93, localizedName: 'SCHLÜSSELKARTEN', localizedDescription: 'Unsere Zimmer und Suiten sind mit einem Chipkartensystem ausgestattet. Einen Verlust der Schlüsselkarten melden Sie bitte sofort der Rezeption. Bitte beachten Sie, dass am Abreisetag Ihr Schlüssel das Zimmer nur bis 12:00 Uhr öffnet. Um Strom in Ihrem Zimmer zu erhalten, geben Sie bitte die Schlüsselkarte in den dafür vorgesehenen Kartenköcher neben der Zimmertür.', phoneNumber: '2000'},
                        {id: 94, localizedName: 'SHOPPING', localizedDescription: 'Das Concierge Team weist Ihnen gern den Weg zu den nächsten Einkaufszentren, Wochenmärkten und sonstigen Shopping-Highlights in und um Berlin.', phoneNumber: '2033'},
                        {id: 95, localizedName: 'SMOKERS LOUNGE', localizedDescription: 'Auf der 6. Etage befindet sich die Smokers Lounge mit vollklimatisiertem Schrankhumidor, gefüllt mit einer anspruchsvollen Auswahl an Zigarren. Genießen Sie als Gast der Executive Lounge edlen Tabak mit einem unverstellten Blick auf den Fernsehturm.', phoneNumber: '2053'},
                        {id: 96, localizedName: 'SOLARIUM', localizedDescription: 'Das Solarium befindet sich in unserem Spa- und Wellnessbereich auf der 2. Etage. <br>Die Öffnungszeiten entnehmen Sie bitte der letzten Seite.', phoneNumber: '2401'},
                        {id: 97, localizedName: 'SOUVENIRS', localizedDescription: 'Eine breite Auswahl an Souvenirs erhalten Sie im Hotel Shop im Erdgeschoss. <br>Die Öffnungszeiten entnehmen Sie bitte der letzten Seite.', phoneNumber: '2410'},
                        {id: 98, localizedName: 'STADTRUNDFAHRTEN', localizedDescription: 'Der Concierge wird Sie gern beraten und Ihnen Empfehlungen für außergewöhnliche Entdeckungen der Bundeshauptstadt und des Umlands aussprechen.', phoneNumber: '2033'},
                        {id: 99, localizedName: 'STROMSPANNUNG', localizedDescription: 'Die Stromspannung in unserem Hotel beträgt 230 Volt.', phoneNumber: ''},
                        {id: 100, localizedName: 'TAXI', localizedDescription: 'Ein Taxistand befindet sich vor dem Hotel in der Landsberger Allee. Für Taxi-, Shuttle- oder Limousinen-Vorbestellungen kontaktieren Sie bitte dasConcierge Team.', phoneNumber: '2033'},
                        {id: 101, localizedName: 'TELEFONIEREN', localizedDescription: 'Innerhalb Berlin:  0 + Telefonnummer<br>Deutschlandweit:  0 + Vorwahl + Telefonnummer<br>International:   0 + Landesnummer + Vorwahl (ohne 0) + Telefonnummer <br>Ein Verzeichnis aller Landesvorwahlen finden Sie am Ende dieser Hotel Information. ', phoneNumber: ''},
                        {id: 102, localizedName: 'TELEFONIEREN VON ZIMMER ZU ZIMMER', localizedDescription: 'Bitte wählen Sie die 1 vor der Zimmernummer auf den Etagen 2 - 9.<br>Für die Etagen 10 - 11 benötigen Sie keine Ziffer vor der Zimmernummer.', phoneNumber: ''},
                        {id: 103, localizedName: 'TELEFONKOSTEN', localizedDescription: 'DEUTSCHLAND <br>Deutschland, Berlin EUR 0,35/min<br>Nat. Teilnehmer mit Zugangskennzahl 032 EUR 0,50/min<br>T-Mobile und Vodafone D2 EUR 0,75/min<br>E-Plus, 02 Germany, Vistream, Ring EUR 0,80/min<br><br>EUROPA<br>Europa A EUR 1,00/min<br>Europa B EUR 2,00/min<br><br>USA & KANADA<br>USA & Kanada EUR 2,00/min<br><br>WELT<br>Welt A EUR 2,00/min<br>Welt B EUR 3,00/min<br>Welt C EUR 3,00/min<br><br>SONDERRUFNUMMERN<br>110 & 112  EUR 0,00/min<br>0137 & 0180 & 0700 EUR 1,50/min<br>0800 EUR 5,00/min???', phoneNumber: ''},
                        {id: 104, localizedName: 'TELEFONNUMMERN INTERN', localizedDescription: 'Concierge 2033  Rezeption 2000<br>Gepäckservice 2033  Room-Service 2650<br>Guest Relation 2050  sky.bar  2614<br>Housekeeping 2900  Restaurant SKYKITCHEN 2620 <br>Telefonzentrale 2000  Restaurant delight 2625 <br>Wäscheservice 2900  Ristorante oscar’s 2610', phoneNumber: ''},
                        {id: 105, localizedName: 'THEATERKARTEN', localizedDescription: 'Berlin hat ein fantastisches Theaterangebot. Der Concierge freut sich, Ihnen Ihren perfekten Abend zu organisieren. ', phoneNumber: '2033'},
                        {id: 106, localizedName: 'TÜRSCHLOSS', localizedDescription: 'Das Schloss Ihrer Zimmertür ist mit einer Sicherheitsvorkehrung ausgestattet.<br>Auch bei geschlossener Tür können Sie im Notfall das Zimmer verlassen. Während Ihres Aufenthaltes achten Sie bitte darauf, dass Ihre Zimmertür gut verschlossen ist.<br>Blau  – Der Eintritt ist möglich.<br>Rot  – Die Karte ist nicht in Ordnung, bitte kontaktieren Sie die Rezeption.', phoneNumber: '2000'},
                        {id: 107, localizedName: 'VOUCHER / HOTELGUTSCHEIN', localizedDescription: 'Falls Sie Ihren Hotelaufenthalt mittels Gutschein bezahlen, geben Sie diesen bitte bei Anreise an der Rezeption ab. Bei der Abreise bezahlen Sie bitte alle sonstigen Dienstleistungen, die in der Gutscheinleistung nicht inbegriffen waren.', phoneNumber: ''},
                        {id: 108, localizedName: 'WÄSCHEREI', localizedDescription: 'Bitte geben Sie Ihre Wäsche (von Montag bis Sonntag) mit der ausgefüllten Wäscheliste bis 09:00 Uhr an der Rezeption ab. Die Wäsche wird Ihnen bis spätestens 18:00 Uhr zurück auf Ihr Zimmer geliefert. Wäsche, welche nach 09:00 Uhr abgegeben wird, können wir erst am nächsten Tag retournieren.', phoneNumber: '2900'},
                        {id: 109, localizedName: 'WECKRUFE', localizedDescription: 'Ihren persönlichen Weckruf nehmen wir gern an der Rezeption entgegen. Sie haben ebenfalls die Möglichkeit, diesen über Ihr Zimmertelefon einzustellen. ', phoneNumber: '2000'},
                        {id: 110, localizedName: 'WELLNESS / SPA', localizedDescription: 'Das weitläufige a.spa auf der 2. Etage begrüßt Sie mit einem 24 Stunden geöffneten Fitnessbereich. Verschiedene Saunen, ein Dampfbad, Whirlpool und Solarium versprechen Erholung vom Alltag. Vereinbaren Sie gern einen Termin für Ihr persönlich abgestimmtes Treatments nach Beratung des hochqualifizierten a.spa Therapeutenteams und wählen Sie aus einem breiten Massagen- und Kosmetikangebot. Tief durchatmen können Sie auf den angrenzenden Ruheterrassen mit Blick über Berlin. Die Öffnungszeiten entnehmen Sie bitte der letzten Seite.', phoneNumber: '2401'},
                        {id: 111, localizedName: 'WERTSACHEN', localizedDescription: 'Für Ihre persönlichen Wertsachen steht Ihnen der Zimmersafe im Kleiderschrank zu Verfügung. Weitere Safes befinden sich an der Rezeption.', phoneNumber: '2000'},
                        {id: 112, localizedName: 'ZAHNPUTZSET', localizedDescription: 'Sollten Sie Ihr Zahnputzset vergessen haben, wenden Sie sich bitte an unsere Hausdame.', phoneNumber: '2900'},
                        {id: 113, localizedName: 'ZEITUNGEN', localizedDescription: 'Lokale und internationale Zeitungen sowie Zeitschriften erhalten Sie in unserem Hotel Shop. <br>Der Hotel Shop befindet sich im Erdgeschoss unseres Hauses gegenüber des Concierge Desks.', phoneNumber: '2410'},
                        {id: 114, localizedName: 'ZEITZONE', localizedDescription: 'Berlin befindet sich in der mitteleuropäischen Zeitzone GMT +1.', phoneNumber: ''}
                    ]}];

    $scope.refreshData = function()
    {
        console.log("refreshing...");
    };

    $scope.isBig = function(item)
    {
        if ($scope.maps.length % 2 == 0)
        {
            return false;
        }
        else if ($scope.maps[0] == item)
        {
            return true;
        }

        return false;

    };

    $scope.getHtml = function(html) {
        return $sce.trustAsHtml(html)
    };

    $scope.switchDiv = function(div)
    {
        $scope.showdiv = div;
    };

    $scope.showItem = function(item)
    {
        $scope.switchDiv('currentItem');
        $scope.currentItem = item;

        var mp = $filter('filter')($scope.mapDetails, {id: item.id})[0];

        if (mp && mp.list)
            $scope.currentList = mp.list;
        else
            $scope.currentList = [];

        console.log("Entries:" + list.size);
    };

    $scope.gotoAnchor = function(anchor)
    {
        $location.hash(anchor);
        $anchorScroll();
    };
    
    $scope.removeSpecialChars = function(text){
        return text.replace(new RegExp('[ \\(\\)?!,+/\\.,’]', 'g'), '');
    }    
});
