myApp.controller('PayCtrl', function ($rootScope, $scope, $location, $translate, $http, SharedData, $sce, LocalStorage, TranslationFactory) {

    $scope.paymentLoaded = false;

    $scope.paymentSuccess = false;

    $scope.noPayment = false;

    $scope.resultURL = "";

    $scope.reservationNumber = $location.search().r;

    $scope.hotelNumber = $location.search().h;

    $scope.roomNumber = $location.search().rn;

    $scope.sharedData = SharedData.data;

    $scope.test = function () {
        $location.path("/checkout").search({
            "s": "t",
            "r": $scope.reservationNumber,
            "rn": $scope.roomNumber,
            "h": $scope.hotelNumber
        });
    };
    $scope.callBack = function (success) {
        $location.path("/checkout").search({
            "s": "t",
            "r": $scope.reservationNumber,
            "h": $scope.hotelNumber,
            "a": $scope.lastAmount,
            "rn": $scope.roomNumber
        });
    };

    $scope.$watch(function () {
        return LocalStorage.read('language');
    }, function (newVal, oldVal) {
        if (oldVal !== newVal) {
            console.log(newVal);
            $scope.loadPaymentID();
        }
    });

    $scope.openPayment = function () {
        var requestData = {
            value: 22200,
            currency: "EUR",
            description: "TEST3",
            orderId: "ORDERSpec",
            lang: "de",
            requestId: "REQUESTSpec",
            reservationNumber: $scope.reservationNumber,
            hotelNumber: $scope.hotelNumber
        };

        $http({
            method: 'POST',
            url: urlPrefix + '/paymentNew',
            headers: {
                'Content-Type': 'application/json'
            },
            data: requestData
        }).success(function (data) {
            window.open(data.redirectUrl, "_self");
        });
    };

    $scope.assertPayment = function () {
        $http({
            method: 'POST',
            url: urlPrefix + '/paymentResultNew',
            headers: {
                'Content-Type': 'application/json'
            }
        }).success(function (data) {
            window.open(data.redirectUrl, "_self");
        });
    };

    $scope.openPayment = function () {
        var requestData = {
            value: 22200,
            currency: "EUR",
            description: "TEST3",
            orderId: "ORDERSpec",
            lang: "de",
            requestId: "REQUESTSpec",
            reservationNumber: $scope.reservationNumber,
            hotelNumber: $scope.hotelNumber
        };

        $http({
            method: 'POST',
            url: urlPrefix + '/paymentNew',
            headers: {
                'Content-Type': 'application/json'
            },
            data: requestData
        }).success(function (data) {
            window.open(data.redirectUrl, "_self");
        });
    };

    $scope.assertPayment = function () {
        $http({
            method: 'POST',
            url: urlPrefix + '/paymentResultNew',
            headers: {
                'Content-Type': 'application/json'
            }
        }).success(function (data) {
            window.open(data.redirectUrl, "_self");
        });
    };

    function createPaymentScript(src) {
        var oldlangScript = document.getElementById('lang-script');
        if (oldlangScript)
            oldlangScript.parentNode.removeChild(oldlangScript);

        var langScript = document.createElement("script");
        langScript.id = 'lang-script';
        langScript.text = "var wpwlOptions = {locale: '" + TranslationFactory.getLanguage() + "'}";

        var oldScript = document.getElementById('pay-script-src');
        if (oldScript)
            oldScript.parentNode.removeChild(oldScript);
        var script = document.createElement("script"),
            tag = document.getElementById('payment-script');
        script.id = 'pay-script-src';
        script.src = src;
        tag.parentNode.insertBefore(langScript, tag);
        tag.parentNode.insertBefore(script, tag);
        $scope.recreateWidged();
        $scope.paymentLoaded = true;
    }

    $scope.recreateWidged = function () {
        var oldPaywidget = document.getElementById('paywidget');
        if (oldPaywidget) {
            oldPaywidget.parentNode.removeChild(oldPaywidget);
        }
        var paywidget = document.createElement("div");
        var belowDiv = document.getElementById('success');
        paywidget.setAttribute("ng-show", "paymentLoaded");
        paywidget.setAttribute("style", "color:black;");
        paywidget.id = "paywidget";
        belowDiv.parentNode.insertBefore(paywidget, belowDiv);

        var widget = document.createElement("form");
        widget.action = $scope.resultURL;
        widget.id = "widget";
        widget.setAttribute("class", "paymentWidgets");
        widget.setAttribute("data-brands", "VISA MASTER AMEX");
        paywidget.appendChild(widget);
    };

    $scope.loadPaymentID = function () {

        var requestData = {
            action: "check",
            reservationNumber: $scope.reservationNumber,
            hotelNumber: $scope.hotelNumber
        };

        $http({
            method: 'POST',
            url: urlPrefix + '/payment',
            headers: {
                'Content-Type': 'application/json'
            },
            data: requestData
        }).success(function (data) {
            if (data.status === 'open') {

                $scope.resultURL = data.resultURL;

                console.log($scope.resultURL);
                $scope.payid = data.payid;
                createPaymentScript(data.script);
            } else {
                // ToDo: Validate lastAmount!

                if (data.lastAmount) {
                    $scope.lastAmount = data.lastAmount;
                    $scope.paymentSuccess = true;
                    $scope.callBack(true);
                } else
                    $scope.noPayment = true;
            }
        });

    }
    //$scope.loadPaymentID();
});
