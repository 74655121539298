myApp.config(function ($translateProvider) {
    $translateProvider.translations('en', {
        TEST: 'USER',
        SEND_MESSAGE: 'Send',
        MESSAGE_TO_RECEPTION: 'Message to Reception',
        YOUR_MESSAGE: 'Your message',
        ROOM_NAME: 'Name',
        ROOM_NUMBER: 'Room',
        ROOM_NUMBER_CHECKOUT: 'Room No',
        PROCEED: 'Proceed',
        ORDER: 'Order',
        PLEASE_ENTER: 'Please enter your room number and name',
        NO_ROOM: 'Room not found!',
        YOUR_ORDER: 'Your order',
        YOUR_ORDER_CHECKOUT: 'Your consumption',
        COMMENT: 'Comment...',
        CHARGE: 'Service Charges',
        YES: 'Yes',
        NO: 'No',
        FEEDBACK_THANKYOU_TEXT: 'Thank you for your feedback!',
        ERROR_MESSAGE: 'Error message',
        NO_DATA_FOUND: 'There are no reservation details available or you already checked in. Please check your information.',
        CHECKIN_NOT_ALLOWED: 'The online check-in is not available for this reservation. Please contact the reception.',
        CHECKOUT_NOT_ALLOWED: 'The online check-out is not available for this reservation. Please contact the reception.',
        NAV_HOME: 'Home',
        NAV_MOBILE_CHECK_IN: 'Mobile check in',
        CHECK_IN_CARD_LABEL: 'Personal information',
        CHECK_IN_CARD_INFO_LABEL: 'We kindly ask you to fill out the entire form.',
        MANDATORY_FIELD: '* Mandatory field',
        ARRIVAL_LABEL: 'Arrival',
        DEPARTURE_LABEL: 'Departure',
        ADULTS_LABEL: 'Adults',
        ADULT_LABEL: 'Adult',
        CHILD_LABEL: 'Child',
        CHILDREN_LABEL: 'Children',
        BALANCE_LABEL: 'Balance',
        CALL_HOTEL_FOR_EMERGENCIES: 'For changes or questions, please contact us at ',
        SALUTATION_LABEL: 'Title',
        FIRST_NAME_LABEL: 'First Name',
        LAST_NAME_LABEL: 'Last Name',
        LOGIN_CHECKIN_DATE: "Check In date",
        RESERVATION_NUMBER_LABEL: 'Reservation Number',
        DATE_OF_BIRTH_LABEL: 'Date of birth',
        EMAIL_LABEL: 'E-mail',
        EMAIL_NOTICE: 'On this email we will send the invoice',
        NATIONALITY_LABEL: 'Nationality',
        DOCUMENT_TYPE: 'ID type',
        COUNTRY_LABEL: 'Country',
        STREET_LABEL: 'Street',
        STREET_LINE1_LABEL: 'Street (row 1)',
        STREET_LINE2_LABEL: 'Street (row 2)',
        ZIP_CODE_LABEL: 'Postcode',
        CITY_LABEL: 'City',
        PASSPORT_LABEL: 'Passport number / ID Card',
        PASSPORT: 'Passport',
        ID_CARD: 'ID Card',
        RESERVATION_COMMENT: 'Note',
        CHECK_IN_BUTTON: 'Process check-in!',
        CHECK_IN_THANK_YOU_LABEL: 'Thank you for checking-in online, ',
        CHECK_IN_FINISH_THANK_YOU_LABEL: 'Thank you! You can collect your key from reception upon arrival. Have a safe trip',
        ASK_FOR_CHECK_OUT_LABEL: 'You can prepare your billing details in advance.',
        BMINE_SUCCESSFUL_CHECKIN_HEADER: 'Thank you, {{firstname}} {{lastname}},',
        BMINE_CHECKIN_COMPLETE_MESSAGE: 'you have been successfully registered for your <b>stay from {{arrival}}</b>. Your data is now saved and stored for your arrival.<br />\n' +
            'You will receive your <b>digital room key</b> by e-mail to {{email}} as soon as your room is available from 3.00 p.m. at the earliest.',
        BMINE_CHECKIN_COMPLETE_FOOTER: 'Best regards and until soon,<br />\n' +
            'Team of the {{hotelName}}',
        BMINE_CHECKIN_COMPLETE_FRA_FOOTER: 'Best regards and until soon,<br />\n' +
            'Team of the b’mine hotel Frankfurt',
        PROVENT_START_CHECK_IN_FINISH_LABEL: 'Note Culture Tax {{ CITY_IN_LANGUAGE }}: If your stay is professionally conditioned, the city administration {{ CITY_IN_LANGUAGE }} requires a separate and signed by the employer certificate for every traveler. The certificate as well as further information about the bed tax can be found on',
        PROVENT_END_CHECK_IN_FINISH_LABEL: '. If the certificate is not completed, an additional {{ FINE_PERCENTAGE }} of the room rate will be charged by the hotel and paid directly to the City of {{ CITY_IN_LANGUAGE }}',
        PROVENT_INVOICE_ISSUES_NOTICE: 'Have we forgotten something or even overcharged? Please contact the reception on the ground floor, where any discrepancies can be resolved.',
        COLOGNE_CITY_NAME: 'Cologne',
        AFTER_CHECKOUT_GREETINGS_PROVENT: 'Done! Please leave the keycard in your room. <br> We hope that you enjoyed your stay at {{HOTEL_NAME}} and wish you a pleasant journey.',
        FINISH_CHECK_IN_BUTTON: 'Not now',
        DO_CHECK_OUT_BUTTON: 'Prepare billing details',
        CHECK_OUT_CARD_LABEL: 'Billing address',
        CHECK_OUT_CARD_INFO_LABEL: 'Would you like a different billing address?',
        COMPANY_NAME_LABEL: 'Company name',
        ELEVATOR_BOOKING_TITLE: 'Book your CarLift',
        ELEVATOR_BOOKING_OK: 'Thanks for booking the CarLift! CarLift will be at your loft {{ DAY }} at {{ HOUR }}:{{ MINUTE }}. <br />You will get an alert 10 minutes upfront! <br />' +
            'Invitation to {{ EMAIL_ADDRESS }} has been sent.',
        ELEVATOR_BOOKING_ERROR: 'There was an issue while booking the time slot for the CarLift.',
        ELEVATOR_BOOKING_NOT_AVAILABLE: 'Booking the CarLift is not yet available for your reservation.',
        ELEVATOR_TIMESLOT_BOOKED: '(Occupied)',
        ELEVATOR_BOOKING_QUESTION: 'Greetings! I would like to book the CarLift for ',
        ELEVATOR_BOOKING_QUESTION_TIME_PREP: ' at ',
        ELEVATOR_BOOKING_QUESTION_CLOSING: '.',
        ELEVATOR_SLOT_ALREADY_BOOKED: 'You have already booked the CarLift for this time slot. Do you need to cancel it?',
        CANCEL_ELEVATOR_BOOKING: 'Cancel time slot',
        ELEVATOR_SLOT_NOT_AVAILABLE: 'The selected slot is not available. Please try with another one.',
        TODAY: 'today',
        TOMORROW: 'tomorrow',
        COMPANY_NAME1_LABEL: 'Company name (row 1)',
        COMPANY_NAME2_LABEL: 'Company name (row 2)',
        USE_PRIVATE_ADDRESS_LABEL: 'Use private address?',
        INVOICE_STREET_LINE1_LABEL: 'Street',
        INVOICE_STREET_LINE2_LABEL: 'Street',
        INVOICE_COUNTRY_LABEL: 'Country',
        INVOICE_ZIPCODE_LABEL: 'Postcode',
        INVOICE_CITY_LABEL: 'City',
        USE_CREDIT_CARD_LABEL: 'Use following credit cards: ',
        CHECK_OUT_BUTTON: 'Save billing address',
        CHECK_OUT_THANK_YOU_LABEL: 'Thank you! Your billing details have been saved. You can collect your key from reception upon arrival. Have a save trip.',
        LOADING_LABEL: 'Loading ...',
        SEARCH: 'Search...',
        ADDRESS_HEADER: 'Address',
        MONTH_JANUARY: 'January',
        MONTH_FEBRUARY: 'February',
        MONTH_MARCH: 'March',
        MONTH_APRIL: 'April',
        MONTH_MAY: 'May',
        MONTH_JUNE: 'June',
        MONTH_JULY: 'July',
        MONTH_AUGUST: 'August',
        MONTH_SEPTEMBER: 'September',
        MONTH_OCTOBER: 'October',
        MONTH_NOVEMBER: 'November',
        MONTH_DECEMBER: 'December',
        BACK: 'Back',
        REPLY: 'Reply',
        SELECT: 'Select',
        DATETIMEPICKER_POINT_TO_RIGHT_ICON: 'with icon on the right',
        INPUT_PROMPT_COMMENT: 'Comment',
        LOGOFFDIALOG: 'Do you really want to log out?',
        TITEL_LABEL: 'Title',
        PRICE_LABEL: 'Price',
        PRICE_LABEL_INCL_VAT: 'Price (incl. VAT)',
        VALUE_LABEL: 'Value',
        POSITION_LABEL: '#',
        CHANNELS_LABEL: 'Channel',
        LANGUAGE_LABEL: 'Language',
        TIME_FORMAT: 'Please enter time in format hh:mm',
        INVOICE_ADDRESS_INCOMPLETE: 'Invoice Address is not complete',
        TIME_FUTURE: 'This is in the past!',
        FIRST_INFORMATION_LABEL: '',
        SECOND_INFORMATION_LABEL: '',
        LINK_LABEL: 'Link',
        NO_GEO_HOTELS_FOUND: 'No hotels have been found nearby!',
        EMAIL_NAME: 'E-Mail',
        OPTIONAL: 'optional',
        NO_VALID_EMAIL: 'Please enter a valid E-Mail',
        NO_NAME: 'Please enter a Name',
        ITEM_DESCRIPTION_LABEL: 'Item',
        CHECKOUT: 'Checkout',
        CONTINUE: 'Continue',
        PAY_DIFFERENCE: 'Pay now',
        FOLIO_SUM: 'Sum',
        FOLIO_DETAILS: 'Invoice details',
        PASSWORD: 'Password',
        WRONG_PASSWORD: 'Wrong Password!',
        VALID_VALUE: 'Please enter a valid value',
        NOT_AVAILABLE_WIFI: 'To use this feature, you have to be connected to  the WiFi network!',
        NOT_AVAILABLE_LOCATION: 'To use this feature, you have to be in the hotel!',
        NOT_AVAILABLE: 'Feature not available',
        CHECK_LOCATION: 'Checking location ...',
        DESCRIPTION_LABEL: 'Description',
        CONTACT_RECEPTION_PERMANENT: 'Any questions? Reception is glad to help ...',
        CHECK_NEARBY_HOTELS_LABEL: 'Searching for hotels nearby, please wait ...',
        NO_GEO_LOCATION_FOUND: 'Could not determine location',
        NO_GEO_LOCATION_SERVICE_FOUND: 'Please enable location services on your device!',
        PASSWORD_PROTECT: 'This area is password protected',
        SKIP_CART: 'Skip minibar articles',
        PROCESS_CART: 'Book items',
        CONSUMATE_QUESTION: 'Something from the Minibar? Please add the articles.',
        CHECK_OUT_VALIDATION: 'Please validate yourself',
        CHECK_OUT_VALIDATION_PROVENT: 'Proceed',
        ITEM_QUANTITY_LABEL: 'Quantity',
        ITEM_TRANSACTION_DATE_LABEL: 'Date of transaction',
        SUM_AMOUNT: 'Total amount',
        PAYED_AMOUNT: 'Payed amount',
        REMAINING_AMOUNT: 'Open amount',
        SUM_AMOUNT_INCL_VAT: 'Total amount (incl. VAT)',
        PAYED_AMOUNT_INCL_VAT: 'Payed amount (incl. VAT)',
        REMAINING_AMOUNT_INCL_VAT: 'Open amount (incl. VAT)',
        LOADING_CHAT: 'Loading chat history...',
        CHAT_DATE_TODAY: 'Today',
        SPLASHSCREEN_BACK: 'Proceed to Gustaffo',
        REQUIRED_LABEL: 'This field cannot be empty!',
        NOT_FOUND_NAME_ROOM_DIALOG: 'Oops. Something went wrong. Please try again or contact the reception.',
        INVOICE_ADDRESS: 'Invoice address',
        INVOICE: 'Invoice',
        NAME_LABEL: 'Name',
        COMPANY_LABEL: 'Company',
        PHONE_LABEL: 'Phone',
        EDIT_LABEL: 'Edit',
        SAVE_LABEL: 'Save',
        CANCEL_LABEL: 'Cancel',
        SUBMIT_LABEL: 'Book',
        MOVE_LABEL: 'Move',
        SUBMIT_CONFIRMATION_MESSAGE: 'Do you really want to submit this?',
        PERSONAL_DETAILS_LABEL: 'Personal details',
        ADDRESS_DETAILS_LABEL: 'Address details',
        COMPANY_DETAILS_LABEL: 'Company details',
        CHECKOUT_DONE_MESSAGE: 'Almost there! Please drop the key at the reception.',
        CHECKOUT_DONE_MESSAGE_PROVENT: 'Done! Please leave the keycard in your room. <br> We hope that you enjoyed your stay at {{ HOTEL_NAME }} and wish you a pleasant journey.',
        CHECKOUT_DONE_MESSAGE_WITH_INVOICE: 'Almost there! Your invoice will be sent via email. Please drop the key at the reception.',
        CHECKOUT_DONE_MESSAGE_WITH_INVOICE_PROVENT: 'Done! Your invoice will be sent via email. Please drop the key at the desk in your room. We hope that you enjoyed your stay at {{ HOTEL_NAME }} and wish you a pleasant journey.',
        CHECKINOUT_ERROR_MESSAGE: 'Oops. Something went wrong. Please go to the reception and the team will take care.',
        CHECKINOUT_ERROR_MESSAGE_PROVENT: 'Oops. Something went wrong. Please go to the reception and the team will take care.',
        SUBMIT_MESSAGE: 'Submit data?',
        SELECT_TIME_SLOT: 'Select a time slot',
        TIME_SLOT_ALREADY_BLOCKED: 'CarLift is already blocked for',
        ELEVATOR_OTHER_RESERVATIONS: 'Other reservations',
        ELEVATOR_RESERVATION_FOR: 'Reserved for ',
        ELEVATOR_MY_BOOKINGS: 'My CarLift bookings',
        PAYMENT_SUCCESS_MESSAGE: 'Success! Next step is the final check-out!',
        PAYMENT_FAILED_MESSAGE: 'Oops. Something went wrong. Please repeat the payment process.',
        PAYMENT_FAILED_MESSAGE_PROVENT: 'Oops. Something went wrong. Please repeat the payment process.',
        ACCEPT_AGB: '*<a class="a_orig" href=\'{{agb}}\' target=\'_blank\'>GTC</a> read and accepted. &nbsp;',
        INVOICE_INFORMATION: 'Information on invoice details. The check-out service will be available on day of departure.',
        MANDATORY_MAIL_MESSAGE: 'Just specify your email address for invoicing.',
        INVOICE_MESSAGE_ON_CONDITION: 'All charges so far included. Subject to change.',
        COOKIE_TEXT: 'Our digital concierge uses cookies in order to provide optimal service',
        COOKIE_BUTTON: 'Got it!',
        COOKIE_DISABLE: 'Disable',
        COOKIE_PRIVACY_TITLE: 'Read more',
        CONSENT: 'I accept the <a href=\'https://gustaffo.com/privacy-policy-concierge/?lang=en\' target=\'_new\'>Privacy Policy</a>',
        CONSENT_ACCEPT: 'Please accept Privacy Policy',
        SELFCHECKIN_ERROR_DISABLED: 'The Self-Checkin feature is disabled.',
        CHECKOUT_GREETING: 'Greetings! Below you can either checkout or just view your invoice.',
        ONECHECKOUT_GREETING: 'Greetings! View your invoice or Check-Out if you leave today',
        PROVENT_CHECKOUT_GREETING: 'Greetings! Below you can either checkout or just view your invoice.',
        VIEW_INVOICE_BUTTON: 'View invoice',
        TAX_LABEL: 'VAT',
        TRANSACTION_MOVEMENT_TOOLTIP: 'Move transaction',
        MANDATORY_FIELDS_MESSAGE: 'Please complete all required fields labelled with *.',
        TRANSACTION_LABEL: 'Invoice Line Item',
        CREATE_PRIVATE_INVOICE_LABEL: 'Create private invoice',
        CREATE_CORPORATE_INVOICE_LABEL: 'Create corporate invoice',

        /* SELFCHECKIN */
        SELFCHECKIN_HEADER: 'Self Check-in',
        SELFCHECKIN_ERROR_DESC: 'Opps...<br>Something went wrong with the reservation.<br>Please ask a member of staff at the reception desk.',
        SELFCHECKIN_ERROR_BTN_NEXT: 'Restart',
        SELFCHECKIN_WELCOME_DESC: 'Welcome! Here you can easily and comfortably check-in.<br>Please choose your preferred language',
        SELFCHECKIN_LOGIN_BTN_NEXT: 'Continue',
        SELFCHECKIN_LOGIN_DESC: 'Please authenticate yourself with your name.',
        SELFCHECKIN_PERSINFO_DESC_HEADER: 'Personal information',
        SELFCHECKIN_PERSINFO_DESC: 'We kindly ask you to fill out the entire form.',
        SELFCHECKIN_PERSINFO_BTN_NEXT: 'Continue',
        SELFCHECKIN_ADDRINFO_ADDRESS_HEADER: 'Address',
        SELFCHECKIN_ADDRINFO_COMPANYNAME: 'Company name',
        SELFCHECKIN_ADDRINFO_STREET: 'Street',
        SELFCHECKIN_ADDRINFO_BILLING_TEXT: 'Would you like a different billing address?',
        SELFCHECKIN_ADDRINFO_BTN_NEXT: 'Process check-in!',
        SELFCHECKIN_PRINT_DESC1: 'Printing your registration card...',
        SELFCHECKIN_PRINT_DESC2: 'Please sign and drop-off in the box aside.',
        SELFCHECKIN_PRINT_BTN_NEXT: 'Continue',
        SELFCHECKIN_INVOICE_DESC_HEADER: 'Invoice details',
        SELFCHECKIN_INVOICE_BTN_NEXT: 'Continue',
        SELFCHECKIN_INVOICE_BTN_SKIP: 'Skip payment',
        SELFCHECKIN_INVOICE_BTN_PAY: 'Pay open amount.',
        SELFCHECKIN_KEY_BTN_PAYED_INFO: 'Payment was successful!',
        SELFCHECKIN_KEY_BTN_DESC: 'Drum-roll... your Room Number is',
        SELFCHECKIN_KEY_BTN_GETKEY_HEADER: 'The best for last: your room key.',
        SELFCHECKIN_KEY_BTN_GETKEY_STEPS: '<li><span class="selfCheckinListItem">Take a key card from the box to your side.</span></li><li><span class="selfCheckinListItem">Insert your key in the device.</span></li><li><span class="selfCheckinListItem">Click on "Continue".</span></li><li><span class="selfCheckinListItem">Wait until it is initialised.</span></li>',
        SELFCHECKIN_KEY_BTN_NEXT: 'Continue',
        SELFCHECKIN_FINISH_BTN_DESC: 'Thanks for using our Self Check-In Service<br>Enjoy your stay!',
        SELFCHECKIN_FINISH_BTN_ANOTHER_KEY: 'Initialize another key card',
        SELFCHECKIN_FINISH_BTN_NEXT: 'Finish',
        OK: 'OK',
        REG_FORM: 'Registration form',
        REG_FORM_ADDRESS: 'Home address*',
        REG_FORM_ARRIVAL: 'Date of arrival*',
        REG_FORM_DEPARTURE: 'Date of departure*',
        REG_FORM_NAME: 'Last name, First name',
        REG_FORM_STREET: 'Street',
        REG_FORM_CITY: 'Postal Code, City',
        REG_FORM_LAND: 'Country',
        REG_FORM_NATION: 'Nationality',
        REG_FORM_ID: 'Passport number',
        REG_FORM_DOB: 'Date of birth',
        REG_FORM_NO_PEOPLE: 'Number of accompanying persons (Adults + Children)'
    });
});
