myApp.config(function ($translateProvider) {
    $translateProvider.translations('pl', {
        ADDRESS_HEADER: 'Adres',
        ARRIVAL_LABEL: 'Przyjazd',
        ASK_FOR_CHECK_OUT_LABEL: 'Możesz przygotować dane do faktury z wyprzedzeniem',
        BACK: 'z powrotem',
        CHANNELS_LABEL: 'kanał',
        CHARGE: 'Opłata za usługę',
        CHECK_IN_BUTTON: 'Kontynuj check-in',
        CHECK_IN_CARD_INFO_LABEL: 'Proszę o wypełnienie całęgo formularza',
        CHECK_IN_CARD_LABEL: 'Dane osobowe',
        CHECK_IN_FINISH_THANK_YOU_LABEL: 'Dziękuję! Dane do faktury zostały zapisane. Możesz odebrać kartę do pokoju z recepcji po przybyciu do hotelu. Zyczymy bezpiecznej podróży',
        CHECK_IN_THANK_YOU_LABEL: 'Dziękujemy za korzystanie z mobilnego chek-in ',
        CHECK_OUT_BUTTON: 'Zapisz adres do faktury',
        CHECK_OUT_CARD_INFO_LABEL: '',
        CHECK_OUT_CARD_LABEL: 'Adres do faktury',
        CHECK_OUT_THANK_YOU_LABEL: 'Dziękuję! Dane do faktury zostały zapisane. Możesz odebrać kartę do pokoju z recepcji po przybyciu do hotelu. Zyczymy bezpiecznej podróży.',
        CITY_LABEL: 'Miasto',
        COMMENT: 'komentarz...',
        COMPANY_NAME1_LABEL: 'Nazwa firmy',
        COMPANY_NAME2_LABEL: 'Nazwa firmy',
        COUNTRY_LABEL: 'Kraj ',
        DATE_OF_BIRTH_LABEL: 'Data urodzenia',
        DEPARTURE_LABEL: 'Wyjazd',
        DO_CHECK_OUT_BUTTON: 'Przygotować dane rozliczeniowe',
        EMAIL_LABEL: 'Adres e-mail',
        ERROR_MESSAGE: 'Komunikat o błędzie',
        FINISH_CHECK_IN_BUTTON: 'Nie teraz',
        FIRST_NAME_LABEL: 'Imię',
        INPUT_PROMPT_COMMENT: 'komentarz',
        LANGUAGE_LABEL: 'język',
        LAST_NAME_LABEL: 'Nazwisko',
        LOADING_LABEL: 'Ładowanie',
        LOGOFFDIALOG: 'Czy chcesz się wylogować?',
        MANDATORY_FIELD: '* Pole obowiązkowe',
        MESSAGE_TO_RECEPTION: 'Kontakt z Recepcją ',
        MONTH_APRIL: 'Kwiecień',
        MONTH_AUGUST: 'Sierpień',
        MONTH_DECEMBER: 'Grudzień',
        MONTH_FEBRUARY: 'Luty',
        MONTH_JANUARY: 'Styczeń',
        MONTH_JULY: 'Lipiec',
        MONTH_JUNE: 'CZerwiec',
        MONTH_MARCH: 'Marzec',
        MONTH_MAY: 'Maj',
        MONTH_NOVEMBER: 'Listopad',
        MONTH_OCTOBER: 'Październik',
        MONTH_SEPTEMBER: 'Wrzesień',
        NATIONALITY_LABEL: 'Narodowość',
        NAV_HOME: 'Dom',
        NAV_MOBILE_CHECK_IN: 'Mobilny check-in',
        NO_DATA_FOUND: 'Brak szczegółów dostępnej rezerwacji. Proszę sprawdzić swoje dane',
        NO_ROOM: 'Pokoju nie znaleziono',
        NO: 'Nie',
        ORDER: 'Zamówienie',
        PASSPORT_LABEL: 'Numer paszportu',
        PLEASE_ENTER: 'Podaj swój numer pokoju i nazwisko',
        PRICE_LABEL: 'walor',
        PROCEED: 'Kontynuować',
        REPLY: 'Odpowiedz',
        RESERVATION_COMMENT: 'Komentarz',
        ROOM_NAME: 'Nazwa',
        ROOM_NUMBER: 'Pokój',
        SALUTATION_LABEL: 'tytuł',
        SEARCH: 'Szukanie',
        SEND_MESSAGE: 'Wysłać',
        STREET_LABEL: 'Ulica',
        STREET_LINE1_LABEL: 'Ulica (Rząd 1)',
        STREET_LINE2_LABEL: 'Ulica (Rząd 2)',
        USE_CREDIT_CARD_LABEL: 'Proszę o użycie następującej karty kredytowej',
        USE_PRIVATE_ADDRESS_LABEL: 'Użyj adresu prywatnego?',
        VALUE_LABEL: 'wartość',
        YES: 'Tak',
        YOUR_MESSAGE: 'Twoja wiadomość',
        YOUR_ORDER: 'rezerwacja',
        ZIP_CODE_LABEL: 'Kod pocztowy',
        VALID_VALUE: 'Wprowadź prawidłową wartość',
        NOT_AVAILABLE_WIFI: 'Aby korzystać z tej funkcji, musisz podłączyć się do sieci WiFi!',
        NOT_AVAILABLE_LOCATION: 'Aby korzystać z tej funkcji, musisz być w hotelu!',
        NOT_AVAILABLE: 'Funkcja niedostępna',
        CHECK_LOCATION: 'Sprawdzanie lokalizacji ...',
        DESCRIPTION_LABEL: 'Opis',
        CONTACT_RECEPTION_PERMANENT: 'Jakieś pytania? Recepcja chętnie pomoże ...',
        CHECK_NEARBY_HOTELS_LABEL: 'Szukanie hoteli w pobliżu, czekaj ...',
        NO_GEO_LOCATION_FOUND: 'Nie udało się określić lokalizacji',
        NO_GEO_LOCATION_SERVICE_FOUND: 'Włącz usługi lokalizacyjne w urządzeniu!',
        PASSWORD_PROTECT: 'Ta część jest zabiezpieczona hasłem.',
        //checkout
        ACCEPT_AGB: '*Przeczytano i zaakceptowano <a class="a_orig" href=\'{{agb}}\' target=\'_blank\'>ogólne warunki i postanowienia</a>.&nbsp;&nbsp;',
        CANCEL_LABEL: 'Anuluj',
        CHECK_OUT_VALIDATION: 'Proszę, potwierdź tożsamość',
        CHECKOUT: 'Wymeldowanie',
        CHECKOUT_DONE_MESSAGE: 'Już prawie koniec! Proszę, zostaw klucze w recepcji.',
        CHECKOUT_DONE_MESSAGE_WITH_INVOICE: 'Już prawie koniec! Twoja faktura zostanie wysłana e-mailem. Proszę, zostaw klucze w recepcji.',

        CHECKINOUT_ERROR_MESSAGE: 'Ups. Coś poszło nie tak. Skontaktuj się z recepcją. Personel zajmie się tą sprawą.',
        CONSUMATE_QUESTION: 'Potrzebujesz czegoś z minibaru? Dodaj artykuły.',
        EDIT_LABEL: 'Edytuj',
        FOLIO_DETAILS: 'Szczegóły faktury',
        FOLIO_SUM: 'Suma',
        INVOICE_ADDRESS: 'Adres na fakturze',
        INVOICE_INFORMATION: 'Informacje dot. szczegółów faktury. Wymeldowanie będzie dostępne w dniu wyjazdu.',
        INVOICE_MESSAGE_ON_CONDITION: 'Wszelkie bieżące opłaty zostały doliczone. Podlega zmianie.',
        ITEM_DESCRIPTION_LABEL: 'Pozycja',
        ITEM_QUANTITY_LABEL: 'Ilość',
        ITEM_TRANSACTION_DATE_LABEL: 'Data transakcji',
        MANDATORY_MAIL_MESSAGE: 'Podaj adres e-mail do przesłania faktury.',
        NOT_FOUND_NAME_ROOM_DIALOG: 'Ups. Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z recepcją.',
        PAY_DIFFERENCE: 'Zapłać teraz',
        PAYED_AMOUNT: 'Kwota opłacona',
        PAYMENT_FAILED_MESSAGE: 'Ups. Coś poszło nie tak. Powtórz proces płatności.',
        PAYMENT_SUCCESS_MESSAGE: 'Zapisano pomyślnie! Następnym krokiem jest ostateczne wymeldowanie!',
        PRICE_LABEL: 'Cena',
        PROCESS_CART: 'Zarezerwuj produkty',
        REMAINING_AMOUNT: 'Pozostała kwota do zapłaty',
        REQUIRED_LABEL: 'To pole nie może być puste!',
        ROOM_NUMBER_CHECKOUT: 'Numer pokoju',
        SAVE_LABEL: 'Zapisz',
        SKIP_CART: 'Pomiń artykuły z minibaru',
        SUBMIT_CONFIRMATION_MESSAGE: 'Czy na pewno chcesz przesłać rezerwację?',
        SUBMIT_LABEL: 'Zarezerwuj produkty',
        SUM_AMOUNT: 'Łączna kwota do zapłaty',
        YOUR_ORDER_CHECKOUT: 'Wykorzystane produkty',

        /* SELFCHECKIN */
        SELFCHECKIN_HEADER: 'Self Check-in',
        SELFCHECKIN_ERROR_DESC: 'Opps...<br>Coś poszło nie tak podczas rezerwacji.<br>Proszę zapytać pracownika recepcji.',
        SELFCHECKIN_ERROR_BTN_NEXT: 'Restart',
        SELFCHECKIN_WELCOME_DESC: 'Witaj! Tutaj możesz łatwo i wygodnie się zameldować.<br>Proszę wybrać preferowany język',
        SELFCHECKIN_LOGIN_BTN_NEXT: 'Dalej',
        SELFCHECKIN_LOGIN_DESC: 'Proszę uwierzytelnić się swoim imieniem.',
        SELFCHECKIN_PERSINFO_DESC_HEADER: 'Informacje osobiste.',
        SELFCHECKIN_PERSINFO_DESC: 'Uprzejmie prosimy o wypełnienie całego formularza.',
        SELFCHECKIN_PERSINFO_BTN_NEXT: 'Dalej',
        SELFCHECKIN_ADDRINFO_ADDRESS_HEADER: 'Adres',
        SELFCHECKIN_ADDRINFO_COMPANYNAME: 'Nazwa firmy',
        SELFCHECKIN_ADDRINFO_STREET: 'Ulica',
        SELFCHECKIN_ADDRINFO_BILLING_TEXT: 'Czy chcesz podać inny adres rozliczeniowy do faktury?',
        SELFCHECKIN_ADDRINFO_BTN_NEXT: 'Proces zameldowania!',
        SELFCHECKIN_PRINT_DESC1: 'Drukowanie karty meldunkowej...',
        SELFCHECKIN_PRINT_DESC2: 'Proszę podpisać i odłożyć w pudełku obok.',
        SELFCHECKIN_PRINT_BTN_NEXT: 'Dalej',
        SELFCHECKIN_INVOICE_DESC_HEADER: 'Dane do faktury',
        SELFCHECKIN_INVOICE_BTN_NEXT: 'Dalej',
        SELFCHECKIN_INVOICE_BTN_SKIP: 'Pomiń płatność',
        SELFCHECKIN_INVOICE_BTN_PAY: 'Dokonaj płatności',
        SELFCHECKIN_KEY_BTN_PAYED_INFO: 'Płatność powiodła się!',
        SELFCHECKIN_KEY_BTN_DESC: 'Werble... Twój numer pokoju to',
        SELFCHECKIN_KEY_BTN_GETKEY_HEADER: 'Najlepsze na koniec…Twój klucz do pokoju.',
        SELFCHECKIN_KEY_BTN_GETKEY_STEPS: '<li><span class="selfCheckinListItem">Proszę wyjąć klucz z pudełka.</span></li><li><span class="selfCheckinListItem">Proszę włożyć klucz do urządzenia.</span></li><li><span class="selfCheckinListItem">Naciśnij "Dalej".</span></li><li><span class="selfCheckinListItem">Poczekaj na zapisanie klucza do pokojuj</span></li>',
        SELFCHECKIN_KEY_BTN_NEXT: 'Dalej',
        SELFCHECKIN_FINISH_BTN_DESC: 'Dziękujemy za skorzystanie z naszej usługi samodzielnego zameldowania f<br>Życzymy miłego pobytu!',
        SELFCHECKIN_FINISH_BTN_ANOTHER_KEY: 'Czy chcesz przygotować kolejny klucz?',
        SELFCHECKIN_FINISH_BTN_NEXT: 'Gotowe'
    });
});
