myApp.controller('LogonModalController', function ($scope, $uibModalInstance, $http, $timeout, param, location, logon, password, SharedData, $translate, TranslationFactory, LocalStorage, ChatWidget) {

    $scope.sharedData = SharedData.data;

    $scope.senddata = {room: SharedData.data.globals.defaultRoom, name: SharedData.data.globals.defaultName};

    console.log("Password: " + password + ", location: " + location + ", logon: " + logon + ", IPOK: " + $scope.sharedData.globals.ipok + ", Proximity Allowed: " + $scope.sharedData.globals.proximityAllowed);
    $scope.logonNeeded = false;
    $scope.passwordNeeded = false;
    $scope.locationNeeded = false;
    $scope.proximityError = false;

    if (logon) {
        $scope.logonNeeded = true;
        console.log("Logon needed!");
    }
    if (location) {
        $scope.locationNeeded = true;
        console.log("Location needed!");
    }
    if (password > 0) {
        $scope.passwordNeeded = true;
        console.log("Password needed!");
    }

    $scope.shallShowProximity = function () {
        return !$scope.sharedData.globals.ipok && $scope.sharedData.globals.proximityAllowed && $scope.locationNeeded;
    };

    $scope.shallShowLogon = function () {
        let lastname = LocalStorage.read('lastname');
        return !$scope.shallShowProximity() && $scope.logonNeeded && !(lastname && lastname.length > 0);
    };

    $scope.shallShowPassword = function () {
        return !$scope.shallShowProximity() && !$scope.shallShowLogon() && $scope.passwordNeeded;
    };


    $scope.checkStatus = function () {
        if ($scope.shallShowProximity()) {
            console.log("Need to check Proximity");
            $scope.sharedData.getPosition($scope.sharedData.hotelID, function (success, lat, long) {
                if (success) {
                    console.log("Position OK, it is " + lat + " x " + long);
                    $scope.locationNeeded = false;

                    $scope.checkStatus();
                } else {
                    console.log("Position NOT OK, it is " + lat + " x " + long);
                    $scope.proximityError = true;
                }
            });
        } else if ($scope.shallShowLogon()) {
            // Close modal to login over chat widget if enabled
            // Otherwise, do nothing and wait for user Input
            if (ChatWidget.isEnabled()) {
                $timeout(function () {
                    $uibModalInstance.close("chatWidgetLogin");
                });
            }
        } else if ($scope.shallShowPassword()) {
            // do nothing, wait for user Input
        } else {
            $timeout(function () {
                $uibModalInstance.close("close");
            });
        }
    };

    $scope.checkStatus();


    $scope.setPlaceholders = function () {
        var optional = $translate.instant('OPTIONAL');
        $scope.placeholderEmail = $translate.instant('EMAIL_NAME');
        $scope.placeholderRoom = $translate.instant('ROOM_NUMBER');

        if (!$scope.sharedData.globals.wirt.authRoomMandatory) {
            $scope.placeholderRoom = $scope.placeholderRoom + " (" + optional + ")";
        }

        if (!$scope.sharedData.globals.wirt.authEmailMandatory) {
            $scope.placeholderEmail = $scope.placeholderEmail + " (" + optional + ")";
        }

        $scope.placeholderRoom = $scope.placeholderRoom + "...";
        $scope.placeholderEmail = $scope.placeholderEmail + "...";
    };

    $scope.setPlaceholders();

    $scope.checkRoom = function () {
        $scope.senddata.hid = $scope.sharedData.hotelID;
        $scope.senddata.id = $scope.sharedData.user.userID;
        $scope.senddata.action = "";
        $scope.senddata.lang = TranslationFactory.getLanguage();
        $scope.senddata.checkpass = password;

        $http({
            method: 'POST',
            url: urlPrefix + '/selectRoom',
            headers: {'Content-Type': 'application/json'},
            data: $scope.senddata
        }).success(function (data) {
            if (data.success) {
                $scope.sharedData.user.lastname = $scope.senddata.name;
                LocalStorage.write('lastname', $scope.sharedData.user.lastname);
                $scope.sharedData.user.lastroom = $scope.senddata.room;
                LocalStorage.write('lastroom', $scope.sharedData.user.lastroom);
                $scope.logonNeeded = false;
                $scope.checkStatus();
            } else
                $scope.errorcode = data.code;
        });
        return true;
    };

    $scope.ok = function () {
        $scope.checkRoom();
    };

    $scope.checkPass = function () {

        $scope.passdata.hid = $scope.sharedData.hotelID;
        $scope.passdata.id = $scope.sharedData.user.userID;
        $scope.passdata.checkpass = password;

        $http({
            method: 'POST',
            url: urlPrefix + '/checkPass',
            headers: {'Content-Type': 'application/json'},
            data: $scope.passdata
        }).success(function (data) {
            if (data.success) {
                $scope.passwordNeeded = false;
                $scope.checkStatus();

            } else
                $scope.errorcode = data.code;
        });
        return true;
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});
