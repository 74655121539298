myApp.filter('reverse', ReverseFilter);
myApp.controller('HotelChatCtrl', HotelChatCtrl);

function ReverseFilter() {
    return function (items) {
        return items.slice().reverse();
    };
}

HotelChatCtrl.$inject = ['$rootScope', '$scope', '$translate', '$http', '$q', '$timeout', 'SharedData', 'PubNub', 'TranslationFactory'];

function HotelChatCtrl($rootScope, $scope, $translate, $http, $q, $timeout, SharedData, PubNub, TranslationFactory) {
    $scope.chat = [];
    $scope.nextChatPage = 0;
    $scope.hasNextPages = true;
    $scope.reloadLock = false;
    $scope.resetScroll = false;
    $scope.newMessage = {
        text: ""
    };
    $scope.sharedData = SharedData.data;

    function registratePubnub() {
        var channel = "CHATHISTORY-" + $scope.sharedData.hotelID;
        console.log("Registrating Pubnub Channel " + channel);
        PubNub.ngSubscribe({channel: channel});

        $rootScope.$on(PubNub.ngMsgEv(channel), function (event, payload) {
            // payload contains message, channel, env...
            console.log(payload.message);

            $timeout(function () {
                processMessage(payload.message);
                $scope.chat.unshift(payload.message);
            }, 0);
        });
    }

    function processMessage(msg) {
        msg.date = optimizeMessageDateTime(msg.timestamp);
        msg.color = "rgba(" + randomColorById(msg.user) + ",0.75)";
    }

    function optimizeMessageDateTime(timestamp) {
        let res = "";

        let now = new Date();
        let msg = new Date(timestamp);

        // Date
        let diffDays = now.getDate() - msg.getDate();
        if (diffDays == 0)
            res += $translate.instant('CHAT_DATE_TODAY') + " ";
        else
            res += msg.getDate() + "/" + toDoubleDigitNumber(msg.getMonth() + 1) + " ";

        // Time
        res += msg.getHours() + ":" + toDoubleDigitNumber(msg.getMinutes());

        return res;
    }

    function toDoubleDigitNumber(number) {
        if (number < 10)
            number = "0" + number;

        return number;
    }

    function randomColorById(id) {
        if ($scope.colorPalette === undefined) {
            $scope.colorPalette = [
                {color: "40,10,10"}, {color: "10,40,10"}, {color: "10,10,40"}, {color: "40,10,40"},
                {color: "10,40,40"}, {color: "40,40,40"}, {color: "80,10,10"}, {color: "10,80,10"}, {color: "10,10,80"},
                {color: "80,10,80"}, {color: "10,80,80"}, {color: "80,80,80"}, {color: "80,10,40"}, {color: "10,80,40"},
                {color: "40,80,10"}, {color: "40,10,80"}
            ];
            $scope.colorPaletteDefault = "10,10,10";
            console.log("Created color-palette with " + $scope.colorPalette.length + " colors.");
        }

        // Find color if already assigned and index of first unassigned color
        let index = undefined;
        let firstAvailable = undefined;
        for (let i = 0; i < $scope.colorPalette.length; i++) {
            let color = $scope.colorPalette[i];

            if (color.hasOwnProperty("id") && color.id == id && !index)
                index = i;
            else if (!color.hasOwnProperty("id") && !firstAvailable)
                firstAvailable = i;

            if (index && firstAvailable)
                break;
        }

        if (index)
        // A color is already assigned to this id
            return $scope.colorPalette[index].color;
        else {
            // No color is assigned to this id -> try random color
            let index = getRandomInt(0, $scope.colorPalette.length - 1);
            if (!$scope.colorPalette[index].hasOwnProperty("id")) {
                // Color at random index is available
                $scope.colorPalette[index].id = id;
                return $scope.colorPalette[index].color;
            } else {
                // Color at ranodm index is already taken -> try first available color
                if (firstAvailable) {
                    $scope.colorPalette[firstAvailable].id = id;
                    return $scope.colorPalette[firstAvailable].color;
                }
            }

        }

        // Id is not assigned to any color and no more colors are available -> return default color.
        return $scope.colorPaletteDefault;
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


    $scope.init = function () {
        registratePubnub();
        $scope.loadChat().then(function () {
            $rootScope.$broadcast('o4mResetScroll');
        });
    };

    $scope.loadChat = function () {
        if ($scope.reloadLock)
            return;

        $scope.reloadLock = true;
        console.log("Loading hotel chat-page " + $scope.nextChatPage + "...");

        let requestData = {
            action: "read",
            hid: $scope.sharedData.hotelID,
            lang: TranslationFactory.getLanguage(),
            page: $scope.nextChatPage
        };

        var deferred = $q.defer();
        $http({
            method: 'POST',
            url: urlPrefix + "/chatData",
            headers: {'Content-Type': 'application/json'},
            data: requestData
        }).success(function (data) {
            console.log(data);
            if (data.hasOwnProperty("chat")) {
                //$scope.chat = $scope.chat.concat(data.chat);
                data.chat.forEach(function (message) {
                    processMessage(message);
                    $scope.chat.push(message);
                }, this);
            }
            if (data.hasOwnProperty("nextPage"))
                $scope.nextChatPage = data.nextPage;
            else
            // no more pages available
                $scope.hasNextPages = false;

            $scope.reloadLock = false;
            $scope.switchDiv('loaded');
            deferred.resolve();
        });

        return deferred.promise;
    };

    $scope.sendMessage = function () {
        if ($scope.newMessage.text.length == 0)
            return;

        let requestData = {
            action: "write",
            hid: $scope.sharedData.hotelID,
            user: $scope.sharedData.user.userID,
            text: $scope.newMessage.text,
            lang: TranslationFactory.getLanguage()
        };

        $http({
            method: 'POST',
            url: urlPrefix + "/chatData",
            headers: {'Content-Type': 'application/json; charset=UTF-8'},
            data: requestData
        }).success(function (data) {
            console.log(data);
            if (data.success)
                $scope.newMessage.text = "";
        });
    };

    $scope.switchDiv = function (div) {
        $scope.showdiv = div;
    };

    $scope.getBubbleClass = function (uid) {
        if ($scope.sharedData.user.userID == uid)
            return /*'itemMe'*/'chatMe';
        else
            return /*'itemOther'*/'chatOther';
    };

    $scope.inputKeys = function (event) {
        // Enter-key
        if (event.keyCode == 13) {
            if (event.shiftKey) {
                // Insert linebreak
            } else {
                $scope.sendMessage();
                event.preventDefault();
            }
        }
    };
}
