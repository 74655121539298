myApp.controller('ClockInvoiceCtrl', function ($uibModal, $scope, $location, $http, SharedData, $filter, $window, EmailProviderService, EventDispatcher, TranslationFactory, KeyWordService) {
    $scope.invoices = [];
    $scope.currentInvoice = {};
    $scope.currentInvoiceCustomer = {};
    $scope.currentTransactions = [];
    $scope.currentBilling = {};
    $scope.currentBillingAddress = {};

    $scope.sharedData = SharedData.data;
    $scope.updateInProgress = false;

    $scope.invoiceAddress = {
        expanded: true
    };

    $scope.invoiceDetails = {
        expanded: false
    };

    $scope.invoiceSummary = {
        expanded: true
    };

    $scope.setCurrentInvoice = function(invoice) {
        $scope.currentInvoice = invoice;

        if ($scope.currentInvoice.transactionDtos) {
            flattenTransactions();
        } else {
            $scope.transactions = [];
            $scope.totalItems = 0;
        }

        if ($scope.currentInvoice.customerDto) {
            $scope.currentInvoiceCustomer = $scope.currentInvoice.customerDto;
        }

        if ($scope.currentInvoice.billingDto) {
            $scope.currentBilling = $scope.currentInvoice.billingDto;
            if ($scope.currentBilling.addresses && $scope.currentBilling.addresses.length > 0) {
                $scope.currentBillingAddress = $scope.currentBilling.addresses[0];
            }
        } else {
            $scope.currentBilling = {};
            $scope.currentBillingAddress = {};
        }

        $scope.invoiceAddress.expanded = true;
        $scope.invoiceDetails.expanded = false;
        $scope.invoiceSummary.expanded = true;
    };

    $scope.isCurrentInvoice = function(invoice) {
        return $scope.currentInvoice.identifier == invoice.identifier;
    };

    $scope.isNotCurrentInvoice = function(invoice) {
        return $scope.currentInvoice.identifier != invoice.identifier;
    };

    $scope.isCurrentInvoicePrivate = function() {
        return $scope.isInvoicePrivate($scope.currentInvoice);
    };

    $scope.isCurrentInvoiceCompany = function() {
        return $scope.isInvoiceCompany($scope.currentInvoice);
    };

    $scope.isInvoicePrivate = function(invoice) {
        if (!invoice) {
            return false;
        }

        return invoice.billingDto && invoice.billingDto.profileType === "1";
    };

    $scope.isInvoiceCompany = function(invoice) {
        if (!invoice) {
            return false;
        }

        return invoice.billingDto && invoice.billingDto.profileType  === "3";
    };

    $scope.isInvoiceEmpty = function(invoice) {
        if (!invoice) {
            return false;
        }

        return invoice.transactionDtos.length == 0;
    };

    $scope.isInvoiceOpen = function(invoice) {
        if (!invoice) {
            return false;
        }

        return invoice.openAmount > 0;
    };

    $scope.isInvoicePaid = function(invoice) {
        if (!invoice) {
            return true;
        }

        return !$scope.isInvoiceOpen(invoice);
    };

    $scope.invoiceHeader = function(invoice) {
        let invoiceNumber = invoice.invoiceNumber;
        let invoiceRecipient = invoice.billingDto.name2 ? invoice.billingDto.name1 + " " + invoice.billingDto.name2 : invoice.billingDto.name1;
        if (invoiceNumber && invoiceRecipient) {
            return "#" + invoiceNumber + " [" + invoiceRecipient + "]";
        } else if (invoiceNumber) {
            return "#" + invoiceNumber;
        } else if (invoiceRecipient) {
            return "[" + invoiceRecipient + "]";
        }

        return "";
    };

    $scope.isTransactionMovementAllowed = function() {
        let sourceInvoice = $scope.currentInvoice;
        let targetInvoice = findMovementTargetInvoice();
        if (($scope.isInvoicePaid(sourceInvoice) && !$scope.isInvoiceEmpty(sourceInvoice))||
            (targetInvoice && $scope.isInvoicePaid(targetInvoice) && !$scope.isInvoiceEmpty(targetInvoice))) {
            return false;
        }

        let payedTransactions = $scope.currentInvoice.transactionDtos.filter(t => $scope.isTransactionPayment(t));
        return payedTransactions.length == 0;
    };

    $scope.isTransactionPayment = function(transaction) {
        let transactionDto = transaction;
        let transactionDetailsDto = transactionDto.transactionDetailsDtos[0];
        return transactionDto.paymentType === "PAYMENT" || transactionDetailsDto.amount < 0;
    };

    $scope.transactionTaxRate = function(transaction) {
        return transaction.taxRate ? transaction.taxRate * 100 : null;
    };

    $scope.moveTransaction = function(transaction, targetInvoice) {
        let sourceInvoiceDto = JSON.parse(JSON.stringify($scope.currentInvoice));
        let sourceTransactionDto = findTransaction(sourceInvoiceDto, transaction.reference);
        let sourceTransactionDetailsDto = sourceTransactionDto.transactionDetailsDtos[0];

        let targetInvoiceDto = targetInvoice;
        if (!targetInvoiceDto) {
            targetInvoiceDto = findMovementTargetInvoice();
            if (targetInvoiceDto) {
                targetInvoiceDto = JSON.parse(JSON.stringify(targetInvoiceDto));
            } else if ($scope.invoices.length < 2) {
                targetInvoiceDto = {
                    identifier: null,
                    transactionDtos: [sourceTransactionDto],
                    billingDto: {
                        profileType: $scope.isInvoiceCompany(sourceInvoiceDto) ? "1" : $scope.isInvoicePrivate(sourceInvoiceDto) ? "3" : null,
                        addresses: [{}]
                    }
                };
                openProfilePopup(targetInvoiceDto, function(invoiceDto) {
                    let transactionDetailsDto = invoiceDto.transactionDtos[0].transactionDetailsDtos[0];
                    invoiceDto.transactionDtos = [];
                    $scope.moveTransaction(transactionDetailsDto, invoiceDto);
                });
                return;
            } else {
                $scope.showErrorModal();
            }
        }

        sourceTransactionDto.action = "MOVED";
        sourceTransactionDetailsDto.amount = sourceTransactionDetailsDto.unitPrice;
        targetInvoiceDto.transactionDtos.push(sourceTransactionDto);

        let invoicesDto = JSON.parse(JSON.stringify($scope.invoicesDto));
        invoicesDto.invoiceDtos = [targetInvoiceDto];
        $scope.updateClockInvoices(invoicesDto, targetInvoiceDto, function() {
            $scope.invoiceDetails.expanded = true;
        });
    };

    $scope.paginate = function (value) {
        let begin,
            end,
            index;
        begin = ($scope.pagination.currentPage - 1) * $scope.pagination.numPerPage;
        end = begin + $scope.pagination.numPerPage;
        index = $scope.transactions.indexOf(value);
        return (begin <= index && index < end);
    };

    $scope.preparePaymentForInvoice = function (currentInvoice, openAmount, reservationNumber) {
        if ($scope.acceptAgb) {
            let nominalOrderId = currentInvoice.invoiceNumber ? currentInvoice.invoiceNumber.replace(/[^0-9]*/gi, '') : currentInvoice.identifier
            let invoiceNumber = currentInvoice.invoiceNumber ? currentInvoice.invoiceNumber.replace(/[^0-9]*/gi, '') : currentInvoice.identifier

            const requestData = {
                openAmount: openAmount,
                currency: 'EUR',
                description: 'Payment of invoice ' + invoiceNumber,
                nominalOrderId: nominalOrderId,
                orderId: currentInvoice.identifier,
                lang: 'de',
                requestId: 'REQ_' + invoiceNumber +'_' + $scope.randomId(),
                reservationNumber: reservationNumber,
                hotelId: $scope.mobileCheckin.hotelNumber,
                source: 'checkout'
            };

            $http({
                method: 'POST',
                url: urlPrefix + '/payment',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: requestData
            }).success(function (data) {
                window.open(data.RedirectUrl, '_self');
            });
        }
    };

    $scope.openInvoices = function() {
        let openInvoices = $scope.invoices.filter(el => el.openAmount > 0);
        return openInvoices.length > 0;
    };

    $scope.editBillingProfile = function() {
        let invoice = JSON.parse(JSON.stringify($scope.currentInvoice));
        openProfilePopup(invoice, $scope.updateBillingProfile);
        document.activeElement.blur();
    };

    $scope.updateBillingProfile = function(invoiceDto) {
        let invoicesDto = JSON.parse(JSON.stringify($scope.invoicesDto));
        invoicesDto.invoiceDtos = [invoiceDto];

        $scope.updateClockInvoices(invoicesDto, invoiceDto);
    };

    $scope.updateClockInvoices = function(invoicesDto, targetInvoiceDto, callback) {
        $scope.updateInProgress = true;
        $scope.updateFolioData(invoicesDto, function() {
            $scope.updateInProgress = false;
            $scope.initClockInvoices();

            let activeInvoice = findInvoice($scope.invoices, targetInvoiceDto.identifier);
            if (activeInvoice) {
                $scope.setCurrentInvoice(activeInvoice);
            }

            callback && callback();
        });
    };

    $scope.initClockInvoices = function() {
        if ($scope.invoicesDto && $scope.invoicesDto.invoiceDtos) {
            $scope.invoices = $scope.invoicesDto.invoiceDtos;
            if ($scope.invoices.length > 0) {
                $scope.setCurrentInvoice($scope.invoices[0]);
                $scope.toggleCollapse($scope.currentInvoice);
            }
        }
    }

    function flattenTransactions() {
        $scope.transactions = [];
        $scope.totalItems = 0;

        $scope.currentInvoice.transactionDtos.forEach(function (transactionDto, i) {
            transactionDto.transactionDetailsDtos.forEach(function (transactionDetailsDto, j) {
                transactionDetailsDto.date = transactionDto.date;
                transactionDetailsDto.index = i + j;
                transactionDetailsDto.reference = transactionDto.id;
                transactionDetailsDto.checked= false;
                $scope.transactions.push(transactionDetailsDto);
            });
            transactionDto.invoiceSource = $scope.currentInvoice.identifier;

            $scope.totalItems = $scope.totalItems + transactionDto.transactionDetailsDtos.length;
        });

        $scope.transactions.sort(function (a, b) {
            a = new Date(a.date);
            b = new Date(b.date);
            return a > b ? -1 : a < b ? 1 : 0;
        });
    }

    function findInvoice(invoices, identifier) {
        for (let i = 0; i < invoices.length; i++) {
            let invoice = invoices[i];
            if (invoice.identifier == identifier) {
                return invoice;
            }
        }

        return null;
    }

    function findMovementTargetInvoice() {
        for (let i = 0; i < $scope.invoices.length; i++) {
            let invoice = $scope.invoices[i];
            if (invoice.identifier != $scope.currentInvoice.identifier &&
                ($scope.isInvoiceOpen(invoice) || $scope.isInvoiceEmpty(invoice))) {
                return invoice;
            }
        }

        return null;
    }

    function findTransaction(invoice, reference) {
        for (let i = 0; i < invoice.transactionDtos.length; i++) {
            let transaction = invoice.transactionDtos[i];
            if (transaction.id == reference) {
                return transaction;
            }
        }

        return null;
    }

    function openProfilePopup(invoice, callback) {
        $uibModal.open({
            animation: true,
            templateUrl: 'clockCustomerModal.html',
            controller: 'ClockCustomerModalCtrl',
            windowClass: 'customerModal',
            resolve: {
                param: function () {
                    const ret = [];
                    ret.countries = $scope.countries;
                    ret.salutations = $scope.salutations;
                    ret.invoiceDto = invoice;
                    ret.isInvoiceDtoCompany = $scope.isInvoiceCompany(invoice);
                    ret.currency = $scope.currency;
                    ret.callback = callback;
                    return ret;
                }
            }
        });
    }

    $scope.initClockInvoices();
});
