(function () {
    angular
        .module('Order4meClient')
        .controller('SelfCheckinController', SelfCheckinController);

    SelfCheckinController.$inject = [ '$scope', '$q', '$http', '$timeout', '$location', '$window', '$translate', '$uibModal', 'TranslationFactory', 'SharedData', 'EventDispatcher', 'Calendar', 'KeyWordService' ];

    function SelfCheckinController($scope, $q, $http, $timeout, $location, $window, $translate, $uibModal, TranslationFactory, SharedData, EventDispatcher, Calendar, KeyWordService) {
        const vm = this;

        // public members
        vm.states = {
            current: {},
            lastPageNumber: '8',
            progressBarMax: '6',
            /* Available states */
            errorFeatureDisabled: {
                name: 'errorFeatureDisabled'
            },
            error: {
                name: 'error',
                next: 'welcome',
                btn: 'SELFCHECKIN_ERROR_BTN_NEXT',
                btnBlock: 'next',
                banner: 'errorAsset',
                action: function () {
                    processThen(resetData, nextState);
                }
            },
            errorLogin: {
                name: 'errorLogin',
                next: 'login',
                btn: 'SELFCHECKIN_ERROR_BTN_NEXT',
                btnBlock: 'next',
                action: function () {
                    processThen(resetData, nextState);
                }
            },
            errorPayment: {
                name: 'errorPayment',
                next: 'login',
                btn: 'SELFCHECKIN_ERROR_BTN_NEXT',
                btnBlock: 'next',
                action: function () {
                    processThen(resetData, nextState);
                }
            },
            welcome: {
                page: '1',
                name: 'welcome',
                next: 'login',
                banner: 'welcomeAsset'
            },
            login: {
                page: '2',
                name: 'login',
                next: 'personalInfo',
                prev: 'welcome',
                btn: 'SELFCHECKIN_LOGIN_BTN_NEXT',
                btnBlock: 'next',
                banner: 'loginAsset',
                action: function () {
                    validateForm(vm.forms.login, nextStateAsync.bind(vm, loadReservation));
                },
                prevAction: function () {
                    navigateBack();
                }
            },
            personalInfo: {
                page: '3',
                name: 'personalInfo',
                next: 'addressInfo',
                prev: 'login',
                btn: 'SELFCHECKIN_PERSINFO_BTN_NEXT',
                btnBlock: 'next',
                progressBarNumber: 1,
                banner: 'personalInfoAsset',
                action: function () {
                    validateForm(vm.forms.personalInfo, nextState);
                },
                prevAction: function () {
                    navigateBack();
                }
            },
            addressInfo: {
                page: '4',
                name: 'addressInfo',
                next: 'print',
                prev: 'personalInfo',
                btn: 'SELFCHECKIN_ADDRINFO_BTN_NEXT',
                btnBlock: 'next',
                progressBarNumber: 2,
                banner: 'addressInfoAsset',
                action: function () {
                    validateForm(vm.forms.addressInfo, processThen.bind(vm, print, nextStateAsync.bind(vm, checkin)));
                },
                prevAction: function () {
                    navigateBack();
                }
            },
            print: {
                page: '5',
                name: 'print',
                next: 'invoiceDetails',
                prev: 'addressInfo',
                btn: 'SELFCHECKIN_PRINT_BTN_NEXT',
                btnBlock: 'next',
                progressBarNumber: 3,
                banner: 'printAsset',
                action: function () {
                    validateForm(vm.forms.print, nextStateAsync.bind(vm, loadTransactions));
                },
                prevAction: function () {
                    navigateBack();
                }
            },
            invoiceDetails: {
                page: '6',
                name: 'invoiceDetails',
                next: 'key',
                prev: 'print',
                btn: 'SELFCHECKIN_INVOICE_BTN_NEXT',
                btnBlock: 'invoice',
                progressBarNumber: 4,
                banner: 'invoiceDetailsAsset',
                action: function () {
                    nextState();
                },
                altAction: function () {
                    payInvoice();
                },
                prevAction: function () {
                    navigateBack();
                }
            },
            key: {
                page: '7',
                name: 'key',
                next: 'finish',
                prev: 'invoiceDetails',
                btn: 'SELFCHECKIN_KEY_BTN_NEXT',
                btnBlock: 'next',
                progressBarNumber: 5,
                banner: 'keyAsset',
                action: function () {
                    nextStateAsync(createHotelKey, processThen(autoNext, nextState));
                },
                prevAction: function () {
                    if (Number(vm.invoicesDto.totalAmount) === 0) {
                        // Navigate back and skip invoice details
                        navigateBack(vm.states[ vm.states.key.prev ].prev);
                    } else {
                        // Navigate back
                        navigateBack();
                    }
                }
            },
            finish: {
                page: '8',
                name: 'finish',
                next: 'welcome',
                prev: 'key',
                btn: 'SELFCHECKIN_FINISH_BTN_NEXT',
                btnBlock: 'finish',
                progressBarNumber: 6,
                banner: 'finishAsset',
                action: function () {
                    processThen(stopAutoNext, processThen.bind(vm, resetData, nextState));
                },
                altAction: function () {
                    processThen(stopAutoNext, prevState);
                },
                prevAction: function () {
                    processThen(stopAutoNext, navigateBack);
                }
            }
        };
        vm.forms = {};
        vm.calendar = Calendar;
        vm.countries = [];
        vm.hotelName;
        vm.salutations;
        vm.reservation;
        vm.transactions;
        vm.totalTransactionItems;
        vm.pagination;
        vm.currency = '';
        vm.invoicesDto;
        vm.hasPayed;
        vm.isPrinting = false;
        vm.welcomeTextInAllLanugages = '';
        vm.init = init;
        vm.nextState = nextState;
        vm.setLanguage = setLanguage;
        vm.restart = restart;
        vm.isReservationNumberRequired = isReservationNumberRequired;
        vm.isNameRequired = isNameRequired;
        vm.isNextBtnVisible = isNextBtnVisible;
        vm.isInvoiceBtnVisible = isInvoiceBtnVisible;
        vm.isFinishBtnVisible = isFinishBtnVisible;
        vm.displayPassport = displayPassport;
        vm.formatDate = formatDate;
        vm.paginate = paginate;
        vm.showPagination = showPagination;
        vm.payInvoice = payInvoice;
        vm.buildAddressForPrint = buildAddressForPrint;
        vm.isCompanyNameInputVisible = isCompanyNameInputVisible;
        vm.calcInputLimit = calcInputLimit;
        vm.buildBannerUrl = buildBannerUrl;
        vm.positionVerticallyCentered = positionVerticallyCentered;
        vm.getGtcText = getGtcText;

        // private members
        const sharedData = SharedData.data;
        const itemsPerPaginationPage = 8;
        const autoNextTime = 15000;
        let autoNextRef;

        function init() {
            loadCountryList();

            EventDispatcher.addEventListener('hotel-loaded', function () {
                // Check if self-checkin feature is enabled for this hotel
                if (!sharedData.hotel.allowSelfCheckin) {
                    changeState('errorFeatureDisabled');
                    return;
                }

                vm.hotelName = sharedData.hotel.wirt.name;
                vm.currency = sharedData.currency + ' ';
                initWithSearchParameter();
                createWelcomeTextInAllLanguages();
            });

            // Add event listeners
            $window.addEventListener('beforeprint', function () {
                vm.isPrinting = true;
            });
            $window.addEventListener('afterprint', function () {
                vm.isPrinting = false;
            });
        }

        function initWithSearchParameter() {
            const paymentSuccess = $location.search().s;

            if (paymentSuccess === 't') {
                sendLog('selfcheckin-payment-success');
                const reservationNumber = $location.search().r;
                processThen(loadReservationData.bind(vm, reservationNumber), changeState.bind(vm, vm.states.key.name));
                $location.url($location.path());    // Remove all get parameters
            } else if (paymentSuccess === 'f') {
                sendLog('selfcheckin-payment-fail');
                resetData();
                $location.url($location.path());    // Remove all get parameters
                changeState(vm.states.errorPayment.name);
            } else {
                restart();
            }
        }

        function restart() {
            resetData();
            changeState(vm.states.welcome.name);
        }

        function createWelcomeTextInAllLanguages() {
            const tranlationCode = 'SELFCHECKIN_WELCOME_DESC';
            //const langs = sharedData.hotel.wirt.languages;
            const langs = [ 'en', 'de', 'pl' ];

            const currentLang = $translate.use();
            vm.welcomeTextInAllLanugages = '';

            langs.forEach(function (lang, index) {
                $translate.use(lang);
                vm.welcomeTextInAllLanugages += $translate.instant(tranlationCode);

                if (index !== langs.length) {
                    vm.welcomeTextInAllLanugages += '<br/><br/>';
                }
            });

            $translate.use(currentLang);
        }

        /**
         * Resets all user specific data.
         */
        function resetData() {
            const def = $q.defer();

            vm.forms = {
                current: {},
                /* Available forms */
                login: {},
                personalInfo: {},
                addressInfo: {},
                print: {
                    consent: false
                }
            };
            vm.salutations = [];
            vm.reservation = {};
            vm.transactions = [];
            vm.totalTransactionItems = 0;
            vm.pagination = {
                currentPage: 1,
                numPerPage: itemsPerPaginationPage
            };
            vm.invoicesDto = {
                'openAmount': 0,
                'paidAmount': 0,
                'totalAmount': 0
            };
            vm.hasPayed = false;

            def.resolve();
            return def.promise;
        }

        function nextStateAsync(asyncCallable) {
            const def = $q.defer();
            let nextState = vm.states.current.next;
            changeState();    // Show loading screen

            asyncCallable().then(function (redirectState) {
                nextState = redirectState || nextState;
                changeState(nextState);
                def.resolve();
            }, function () {
                changeState('error');
                def.reject();
            });

            return def.promise;
        }

        function nextState() {
            changeState(vm.states.current.next);
        }

        function prevState(altPrevState) {
            const state = altPrevState || vm.states.current.prev;
            changeState(state);
        }

        function changeState(name) {
            if (name) {
                vm.states.current = vm.states[ name ];
            } else {
                // Show loading screen
                vm.states.current = {};
            }
        }

        function processThen(asyncCallable, callable) {
            asyncCallable().then(callable);
        }

        function validateForm(form, callable) {
            // Removes :active state from button
            angular.element(document.querySelector('#selfCheckinBtn'))[ 0 ].blur();

            if (form) {
                // Forces validation of all form fields
                form.$setSubmitted();

                if (form.$valid) {
                    callable();
                } else {
                    form.error = true;
                }
            }
        }

        function navigateBack(altPrevState) {
            // Removes :active state from button
            angular.element(document.querySelector('#selfCheckinBtnBack'))[ 0 ].blur();

            prevState(altPrevState);
        }

        function setLanguage(abbr) {
            TranslationFactory.use(abbr, sharedData.hotel.wirt.id);
        }

        $scope.isLanguageAvailable = function(abbr) {
            if (sharedData.hotel.wirt.languages.indexOf(abbr) < 0) {
                return false;
            } else {
                return true;
            }
        }

        function isReservationNumberRequired(loginData) {
            return !loginData ||
                (isStringEmpty(loginData.reservationNumber) && isStringEmpty(loginData.lastname) && isStringEmpty(loginData.firstname));
        }

        function isNameRequired(loginData) {
            return isReservationNumberRequired(loginData) ||
                (isStringEmpty(loginData.reservationNumber) && (!isStringEmpty(loginData.lastname) || !isStringEmpty(loginData.firstname)));
        }

        function isStringEmpty(string) {
            return string === undefined || string === null || string.length === 0;
        }

        function isNextBtnVisible() {
            return vm.states.current && vm.states.current.btn && vm.states.current.hasOwnProperty('action') && (vm.states.current.btnBlock === 'next'
                || (vm.states.current.btnBlock === 'invoice' && vm.invoicesDto.openAmount === 0));
        }

        function isInvoiceBtnVisible() {
            return vm.states.current.btnBlock === 'invoice' && vm.invoicesDto.openAmount > 0;
        }

        function isFinishBtnVisible() {
            return vm.states.current && vm.states.current.btnBlock === 'finish';

        }

        function makeParamUrlConform(param) {
            if (param) {
                return param.replace('/', '%2F');
            } else {
                return param;
            }
        }

        function loadCountryList() {
            const requestData = {
                lang: TranslationFactory.getLanguage()
            };

            $http({
                method: 'POST',
                url: urlPrefix + '/retrieveCountries',
                headers: {'Content-Type': 'application/json'},
                data: requestData
            }).then(function successCallback(response) {
                vm.countries = response.data;
            });

        }

        function loadReservation() {
            const def = $q.defer();

            const input = vm.forms.login.data;
            loadReservationData(input.reservationNumber, input.firstname, input.lastname).then(function () {
                def.resolve();
            }, function () {
                def.reject();
            });

            return def.promise;
        }

        function loadReservationData(reservationNumber, firstName, lastName) {
            const def = $q.defer();

            sendLog('selfcheckin-login');
            const requestData = {
                hid: sharedData.hotel.wirt.id,
                firstname: firstName,
                lastname: lastName,
                reservation: reservationNumber,
                lang: TranslationFactory.getLanguage()
            };

            $http({
                method: 'POST',
                url: urlPrefix + '/clientMobileCheckInData',
                headers: {'Content-Type': 'application/json'},
                data: requestData
            }).then(function successCallback(response) {
                const data = response.data;

                if (data.hasOwnProperty('error')) {
                    def.reject(vm.states.errorLogin.name);
                } else {
                    vm.reservation = data;

                    // Set calendar selection to reservation guest birth day
                    vm.calendar.selectDateByReservationDate(data.dateOfBirth);

                    // Load salutations
                    vm.salutations = data.salutations || [];

                    // Add reservation salution if not already available
                    if (data.addressForm) {
                        if (vm.salutations.indexOf(data.addressForm) < 0) {
                            vm.salutations.push(data.addressForm);
                        }
                    }

                    // Checks if billing address should be shown or not
                    if (vm.reservation.hasOwnProperty('invoiceAddress')) {
                        vm.reservation.billingAddress = true;
                        vm.reservation.showBillingAddressCheckbox = false;
                    } else {
                        vm.reservation.billingAddress = false;
                        vm.reservation.showBillingAddressCheckbox = true;
                    }

                    def.resolve();
                }
            }, function errorCallback() {
                def.reject();
            });

            return def.promise;
        }

        function sendLog(text) {
            sharedData.sendLog(text, 0, sharedData.hotelID);
        }

        function displayPassport() {
            vm.reservation.showIdCardNumberField = (sharedData.hotel.wirt.place && sharedData.hotel.wirt.place !== vm.reservation.nationality);
        }

        function checkin() {
            const def = $q.defer();

            vm.reservation.dateOfBirth = vm.calendar.getInReservationDateForm();

            if (!vm.reservation.billingAddress) {
                console.log('Reset billing address fields');
                resetCheckoutFields();
            }

            $http({
                method: 'POST',
                url: urlPrefix + '/checkin',
                headers: {'Content-Type': 'application/json'},
                timeout: 3 * 60 * 1000,
                data: {
                    reservation: vm.reservation,
                    source: 'selfcheckin'
                }
            }).then(function successCallback(response) {
                if (response.data.success) {
                    sendLog('selfcheckin-success');

                    // Update room number - maybe updated during self checkin
                    if (response.data.room) {
                        vm.reservation.room = response.data.room;
                    }

                    def.resolve();
                } else {
                    def.reject();
                }
            }, function errorCallback() {
                def.reject();
            });

            return def.promise;
        }

        function resetCheckoutFields() {
            vm.reservation.invoiceAddress = '';
            vm.reservation.companyName1 = '';
            vm.reservation.companyName2 = '';
            vm.reservation.invoiceAddress.address1 = '';
            vm.reservation.invoiceAddress.address2 = '';
            vm.reservation.invoiceAddress.zipCode = '';
            vm.reservation.invoiceAddress.domicile = '';
            vm.reservation.invoiceAddress.country = '';
            vm.reservation.useGivenCreditCard = '';
        }

        function print() {
            const def = $q.defer();
            $window.print();
            def.resolve();
            return def.promise;
        }

        function loadTransactions() {
            const def = $q.defer();

            const restUrl = '/rest/folio/' + vm.reservation.hotelNumber + '/' + makeParamUrlConform(vm.reservation.reservationNumber) + '?lang=' + TranslationFactory.getLanguage() + '&rn=' + vm.reservation.room;

            $http({
                method: 'GET',
                url: urlPrefix + restUrl,
                headers: {'Content-Type': 'application/json'}
            }).then(function successCallback(response) {
                const data = response.data;
                if (data && data.success === true) {
                    fillFolioData(data);

                    if (Number(vm.invoicesDto.totalAmount) === 0) {
                        // Skip invoice page
                        def.resolve(vm.states.invoiceDetails.next);
                    } else {
                        // Show invoice page
                        def.resolve();
                    }
                } else {
                    def.reject();
                }
            }, function errorCallback() {
                def.reject();
            });

            return def.promise;
        }

        function fillFolioData(data) {
            if (data && data !== '') {
                processInvoicesData(data.invoicesDto);
                flattenTransactions();
            }
        }

        function processInvoicesData(invoicesDto) {
            vm.invoicesDto.invoiceDtos = invoicesDto.invoiceDtos;
            vm.invoicesDto.identifier = convertInvoiceIdentifier(invoicesDto.invoiceDtos[ 0 ].identifier);

            invoicesDto.invoiceDtos.forEach(function (invoiceDto) {
                vm.invoicesDto.openAmount += (invoiceDto.openAmount) ? invoiceDto.openAmount : 0;
                vm.invoicesDto.paidAmount += (invoiceDto.paidAmount) ? invoiceDto.paidAmount : 0;
                vm.invoicesDto.totalAmount += (invoiceDto.totalAmount) ? invoiceDto.totalAmount : 0;
            });
        }

        function convertInvoiceIdentifier(identifier) {
            const separator = identifier.indexOf('|');

            if (separator >= 0) {
                return identifier.substr(0, separator);
            }

            return identifier;
        }

        function flattenTransactions() {
            vm.transactions = [];
            vm.totalTransactionItems = 0;

            vm.invoicesDto.invoiceDtos.forEach(function (invoiceDto) {
                invoiceDto.transactionDtos.forEach(function (transactionDto, i) {
                    transactionDto.transactionDetailsDtos.forEach(function (transactionDetailsDto, j) {
                        transactionDetailsDto.date = transactionDto.date;
                        transactionDetailsDto.index = i + j;
                        vm.transactions.push(transactionDetailsDto);
                    });
                    vm.totalTransactionItems += transactionDto.transactionDetailsDtos.length;
                });
            });

            vm.transactions.sort(function (a, b) {
                a = new Date(a.date);
                b = new Date(b.date);
                return a > b ? -1 : a < b ? 1 : 0;
            });
        }

        function formatDate(date) {
            return new Date(date);
        }

        function paginate(value) {
            let begin = (vm.pagination.currentPage - 1) * vm.pagination.numPerPage;
            let end = begin + vm.pagination.numPerPage;
            let index = vm.transactions.indexOf(value);
            return (begin <= index && index < end);
        }

        function showPagination() {
            return vm.totalTransactionItems > vm.pagination.numPerPage;
        }

        function payInvoice() {
            const requestData = {
                openAmount: vm.invoicesDto.openAmount,
                currency: vm.currency.substring(0, vm.currency.length - 1),
                description: 'Payment of invoice ' + vm.invoicesDto.identifier,
                orderId: vm.invoicesDto.identifier,
                lang: 'de',
                requestId: 'REQ_' + sharedData.getRandomId(),
                reservationNumber: vm.reservation.reservationNumber,
                hotelId: vm.reservation.hotelNumber,
                source: 'selfcheckin'
            };

            $http({
                method: 'POST',
                url: urlPrefix + '/payment',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: requestData
            }).success(function (data) {
                window.open(data.RedirectUrl, '_self');
            });
        }

        function createHotelKey() {
            const def = $q.defer();

            const requestData = {
                hid: sharedData.hotel.wirt.id,
                rn: vm.reservation.reservationNumber
            };

            $http({
                method: 'POST',
                url: urlPrefix + '/hotelKey',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: requestData
            }).success(function successCallback(response) {
                const data = response;
                if (data && data.hasOwnProperty('success') && data.success) {
                    def.resolve();
                } else {
                    def.reject();
                }
            }, function errorCallback() {
                def.reject();
            });

            return def.promise;
        }

        function autoNext() {
            const def = $q.defer();

            autoNextRef = $timeout(function () {
                autoNextRef = undefined;
                nextState();
            }, autoNextTime);

            def.resolve();
            return def.promise;
        }

        function stopAutoNext() {
            const def = $q.defer();

            if (autoNextRef) {
                $timeout.cancel(autoNextRef);
            }

            def.resolve();
            return def.promise;
        }

        function buildAddressForPrint(addressJson) {
            let html = '';

            if (addressJson) {
                html = addressJson.address1 != undefined ? addressJson.address1 : '';
                html = html + (addressJson.address2 != undefined ? ', ' + addressJson.address2 : '');
                html = html + (addressJson.zipCode != undefined ? ' - ' + addressJson.zipCode : '');
                html = html + (addressJson.domicile != undefined ? ' - ' + addressJson.domicile : '');
                html = html + (addressJson.country != undefined ? ' - ' + addressJson.country : '');
            }

            return html;
        }

        function isCompanyNameInputVisible() {
            return vm.reservation.billingAddress;
        }

        function calcInputLimit() {
            if (vm.reservation.reservationType === 'HMS') {
                return 100;
            } else {
                return 26;
            }
        }

        function buildBannerUrl(bannerName) {
            if (bannerName && sharedData && sharedData.hotel && sharedData.hotel.wirt && sharedData.hotel.wirt.selfCheckinSettings) {
                const assetId = sharedData.hotel.wirt.selfCheckinSettings[ bannerName ];
                if (assetId) {
                    return urlPrefix + '/serveImageAsset?id=' + assetId;
                }
            }

            return undefined;
        }

        function positionVerticallyCentered() {
            const navBarHeight = 70;
            const body = angular.element(document.getElementsByTagName('body'));
            const banner = angular.element(document.getElementById('selfCheckinBannerImage'));

            let offset = (body[ 0 ].clientHeight - banner[ 0 ].clientHeight) / 2 - navBarHeight;
            offset = (offset < 0) ? 0 : offset;
            banner.css('margin-top', offset + 'px');
        }

        function getGtcText() {
            if (sharedData && sharedData.hotel && sharedData.hotel.wirt) {
                return KeyWordService.replaceLinks(sharedData.hotel.wirt.gtcText);
            } else {
                return '';
            }
        }

        $scope.showExternalPage = function (url) {
            const modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'externalPageModal.html',
                controller: 'externalPageModalController',
                windowClass: 'externalPageModal',
                size: 'lg',
                resolve: {
                    param: function () {
                        const ret = {};
                        ret.url = url;
                        return ret;
                    }
                }
            });
        };
    }
})();
