myApp.config(function ($translateProvider) {
    $translateProvider.translations('de', {
        SEND_MESSAGE: 'Senden',
        MESSAGE_TO_RECEPTION: 'Nachricht an Rezeption',
        YOUR_MESSAGE: 'Nachricht',
        ROOM_NAME: 'Name',
        ROOM_NUMBER: 'Zimmer',
        ROOM_NUMBER_CHECKOUT: 'Zimmer Nr',
        PROCEED: 'Fortfahren',
        ORDER: 'Bestellen',
        PLEASE_ENTER: 'Bitte tragen Sie Ihre Zimmernummer und Namen ein',
        NO_ROOM: 'Zimmer nicht gefunden!',
        YOUR_ORDER: 'Ihre Bestellung',
        YOUR_ORDER_CHECKOUT: 'Konsumation',
        COMMENT: 'Kommentar...',
        CHARGE: 'Servicegebühr',
        YES: 'Ja',
        NO: 'Nein',
        FEEDBACK_THANKYOU_TEXT: 'Vielen Dank für Ihr Feedback!',
        ERROR_MESSAGE: 'Fehlermeldung',
        NO_DATA_FOUND: 'Es sind keine Reservierungsdetails vorhanden oder Sie sind bereits eingecheckt. Bitte überprüfen Sie Ihre Angaben.',
        CHECKIN_NOT_ALLOWED: 'Der online Check-in ist für diese Reservierung nicht verfügbar. Bitte wenden Sie sich an die Rezeption.',
        CHECKOUT_NOT_ALLOWED: 'Der online Check-out ist für diese Reservierung nicht verfügbar. Bitte wenden Sie sich an die Rezeption.',
        NAV_HOME: 'Start',
        NAV_MOBILE_CHECK_IN: 'Mobile check in',
        CHECK_IN_CARD_LABEL: 'Persönliche Angaben',
        CHECK_IN_CARD_INFO_LABEL: 'Bitte füllen Sie das Formular vollständig aus.',
        MANDATORY_FIELD: '* Pflichtfeld',
        ARRIVAL_LABEL: 'Ankunft',
        DEPARTURE_LABEL: 'Abreise',
        ADULTS_LABEL: 'Erwachsene',
        ADULT_LABEL: 'Erwachsener',
        CHILDREN_LABEL: 'Kinder',
        CHILD_LABEL: 'Kind',
        BALANCE_LABEL: 'Offener Betrag',
        CALL_HOTEL_FOR_EMERGENCIES: 'Bei Änderungen oder Fragen kontaktieren Sie uns bitte unter ',
        SALUTATION_LABEL: 'Anrede',
        FIRST_NAME_LABEL: 'Vorname',
        LAST_NAME_LABEL: 'Nachname',
        LOGIN_CHECKIN_DATE: "Check In Datum",
        RESERVATION_NUMBER_LABEL: 'Reservierungsnummer',
        DATE_OF_BIRTH_LABEL: 'Geburtstag',
        EMAIL_LABEL: 'E-Mail',
        EMAIL_NOTICE: 'An diese Email erhalten Sie die email',
        NATIONALITY_LABEL: 'Nationalität',
        DOCUMENT_TYPE: 'Ausweis oder Pass',
        COUNTRY_LABEL: 'Land',
        STREET_LABEL: 'Straße',
        STREET_LINE1_LABEL: 'Straße (Zeile 1)',
        STREET_LINE2_LABEL: 'Straße (Zeile 2)',
        ZIP_CODE_LABEL: 'PLZ',
        CITY_LABEL: 'Stadt',
        PASSPORT_LABEL: 'Reisepass Nummer / Personalausweis',
        PASSPORT: 'Pass',
        ID_CARD: 'Ausweis',
        ACCOMPANYING_ADULTS_LABEL: 'Begleitpersonen (Erwachsen)',
        ACCOMPANYING_CHILDREN_LABEL: 'Begleitpersonen (Kinder)',
        NUMBER_OF: 'Anzahl',
        RESERVATION_COMMENT: 'Anmerkung',
        CHECK_IN_BUTTON: 'Check-In durchführen!',
        CHECK_IN_THANK_YOU_LABEL: 'Vielen Dank für Ihren Online Check-in, ',
        CHECK_IN_FINISH_THANK_YOU_LABEL: 'Vielen Dank! Ihren Zimmerschlüssel erhalten Sie bei Anreise an der Rezeption. Gute Reise ',
        PROVENT_START_CHECK_IN_FINISH_LABEL: 'Hinweis Kulturförderabgabe {{ CITY_IN_LANGUAGE }} ({{TAX_TYPE}}): Ist Ihr Aufenthalt beruflich bedingt, verlangt die Stadtverwaltung {{ CITY_IN_LANGUAGE }} für jeden Reisenden eine eigene und vom Arbeitgeber unterschriebene Bescheinigung. Die Bescheinigung sowie weitere Informationen zur Bettensteuer erhalten Sie auf',
        PROVENT_END_CHECK_IN_FINISH_LABEL: '. Liegt die Bescheinigung nicht ausgefüllt vor, werden zusätzliche {{ FINE_PERCENTAGE }} des Übernachtungspreises durch das Hotel berechnet und direkt an die Stadtverwaltung {{ CITY_IN_LANGUAGE }} abgeführt.',
        PROVENT_INVOICE_ISSUES_NOTICE: 'Haben wir etwas vergessen oder gar zu viel berechnet? Wenden Sie sich bitte an die Rezeption im Erdgeschoss, hier können sämtliche Unstimmigkeiten geklärt werden.',
        COLOGNE_CITY_NAME: 'Köln',
        BMINE_SUCCESSFUL_CHECKIN_HEADER: 'Vielen Dank, {{firstname}} {{lastname}},',
        BMINE_CHECKIN_COMPLETE_MESSAGE: 'Sie haben sich für Ihren <b>Aufenthalt ab {{arrival}}</b> bei uns erfolgreich registriert. Ihre Daten sind nun gespeichert und für Ihre Anreise hinterlegt<br />\n' +
            'Sobald Ihr Zimmer zur Verfügung steht, <b>fühestens ab 15:00 Uhr</b>, erhalten Sie Ihren digitalen <b>Zimmerschlüssel</b> per E-Mail an {{email}} zugesandt.',
        BMINE_CHECKIN_COMPLETE_FOOTER: 'Herzliche Grüße und bis bald,<br />\n' +
            'ihr {{hotelName}} Team',
        AFTER_CHECKOUT_GREETINGS_PROVENT: 'Geschafft! Bitte die Zimmerkarte auf dem Schreibtisch liegen lassen. <br>Wir hoffen, Sie hatten einen angenehmen Aufenthalt im {{HOTEL_NAME}} und wünschen eine angenehme Heimreise.' ,
        ASK_FOR_CHECK_OUT_LABEL: 'Einfach und bequem schon heute den Check-out vorbereiten.',
        FINISH_CHECK_IN_BUTTON: 'Nicht jetzt',
        DO_CHECK_OUT_BUTTON: 'Check-Out vorbereiten',
        CHECK_OUT_CARD_LABEL: 'Rechnungsadresse',
        CHECK_OUT_CARD_INFO_LABEL: 'Wünschen Sie eine andere Rechnungsadresse?',
        COMPANY_NAME_LABEL: 'Firmenname',
        COMPANY_NAME1_LABEL: 'Firmenname (Zeile 1)',
        COMPANY_NAME2_LABEL: 'Firmenname (Zeile 2)',
        USE_PRIVATE_ADDRESS_LABEL: 'Private Adresse verwenden?',
        INVOICE_STREET_LINE1_LABEL: 'Straße',
        INVOICE_STREET_LINE2_LABEL: 'Straße',
        INVOICE_COUNTRY_LABEL: 'Land',
        INVOICE_ZIPCODE_LABEL: 'PLZ',
        INVOICE_CITY_LABEL: 'Stadt',
        USE_CREDIT_CARD_LABEL: 'Folgende Kreditkarte verwenden: ',
        CHECK_OUT_BUTTON: 'Rechnungsadresse speichern',
        CHECK_OUT_THANK_YOU_LABEL: 'Vielen Dank! Ihre Rechnungsdetails sind nun gespeichert. Ihren Schlüssel erhalten Sie an der Rezeption.',
        LOADING_LABEL: 'Daten werden geladen ...',
        SEARCH: 'Suchen...',
        ADDRESS_HEADER: 'Adresse',
        MONTH_JANUARY: 'Januar',
        MONTH_FEBRUARY: 'Februar',
        MONTH_MARCH: 'März',
        MONTH_APRIL: 'April',
        MONTH_MAY: 'Mai',
        MONTH_JUNE: 'Juni',
        MONTH_JULY: 'Juli',
        MONTH_AUGUST: 'August',
        MONTH_SEPTEMBER: 'September',
        MONTH_OCTOBER: 'Oktober',
        MONTH_NOVEMBER: 'November',
        MONTH_DECEMBER: 'Dezember',
        BACK: 'Zurück',
        REPLY: 'Antworten',
        SELECT: 'Wählen Sie',
        DATETIMEPICKER_POINT_TO_RIGHT_ICON: 'mit Symbol auf der rechten Seite',
        INPUT_PROMPT_COMMENT: 'Kommentar',
        LOGOFFDIALOG: 'Wollen sie sich wirklich abmelden?',
        TITEL_LABEL: 'Titel',
        PRICE_LABEL: 'Preis',
        PRICE_LABEL_INCL_VAT: 'Preis (inkl. MwSt.)',
        VALUE_LABEL: 'Wert',
        POSITION_LABEL: '#',
        CHANNELS_LABEL: 'Kanal',
        LANGUAGE_LABEL: 'Sprache',
        TIME_FORMAT: 'Bitte Uhrzeit im Format hh:mm eingeben',
        INVOICE_ADDRESS_INCOMPLETE: 'Rechnungsadresse ist unvollständig',
        TIME_FUTURE: 'Zeitpunkt liegt in der Vergangenheit',
        FIRST_INFORMATION_LABEL: '',
        SECOND_INFORMATION_LABEL: '',
        LINK_LABEL: 'Link',
        NO_GEO_HOTELS_FOUND: 'Keine Hotels in der Nähe gefunden',
        EMAIL_NAME: 'E-Mail',
        OPTIONAL: 'optional',
        NO_VALID_EMAIL: 'Bitte gültige E-Mail eingeben',
        NO_NAME: 'Bitte Namen eingeben',
        ITEM_DESCRIPTION_LABEL: 'Position',
        CHECKOUT: 'Check Out',
        CONTINUE: 'Weiter',
        PAY_DIFFERENCE: 'Jetzt bezahlen',
        FOLIO_SUM: 'Gesamtrechnung',
        FOLIO_DETAILS: 'Rechnungsdetails',
        PASSWORD: 'Passwort',
        WRONG_PASSWORD: 'Falsches Passwort!',
        VALID_VALUE: 'Bitte einen gültigen Wert eintragen',
        NOT_AVAILABLE_WIFI: 'Für diese Funktion müssen Sie mit dem Hotel WLAN verbunden sein',
        NOT_AVAILABLE_LOCATION: 'Diese Funkion ist aktiv, sobald Sie im Hotel sind',
        NOT_AVAILABLE: 'Die Funktion ist nicht verfügbar',
        CHECK_LOCATION: 'Ort wird überprüft…',
        DESCRIPTION_LABEL: 'Beschreibung',
        CONTACT_RECEPTION_PERMANENT: 'Weitere Fragen? Das Rezeptions-Team hilft gerne weiter...',
        CHECK_NEARBY_HOTELS_LABEL: 'Einen Moment bitte, es wird nach Hotels in Ihrer Umgebung gesucht',
        NO_GEO_LOCATION_FOUND: 'Ort konnten nicht vom Gerät abgerufen werden',
        NO_GEO_LOCATION_SERVICE_FOUND: 'Bitte aktivieren Sie den Ortungsdienst auf Ihren Gerät.',
        PASSWORD_PROTECT: 'Dieser Bereich ist passwortgeschützt',
        LOADING_CHAT: 'Lade Chatverlauf...',
        CHAT_DATE_TODAY: 'Heute',
        SPLASHSCREEN_BACK: 'Weiter zu Gustaffo',
        SKIP_CART: 'Keine Minibarartikel auswählen',
        PROCESS_CART: 'Artikel buchen',
        CONSUMATE_QUESTION: 'Etwas aus der Minibar? Bitte Artikel nennen',
        CHECK_OUT_VALIDATION: 'Bitte Daten eingeben',
        CHECK_OUT_VALIDATION_PROVENT: 'Absenden',
        ITEM_QUANTITY_LABEL: 'Menge',
        ITEM_TRANSACTION_DATE_LABEL: 'Transaktions&#8203;datum',
        SUM_AMOUNT: 'Gesamtbetrag',
        PAYED_AMOUNT: 'Bezahlter Betrag',
        REMAINING_AMOUNT: 'Offener Betrag',
        SUM_AMOUNT_INCL_VAT: 'Gesamtbetrag (inkl. MwSt.)',
        PAYED_AMOUNT_INCL_VAT: 'Bezahlter Betrag (inkl. MwSt.)',
        REMAINING_AMOUNT_INCL_VAT: 'Offener Betrag (inkl. MwSt.)',
        REQUIRED_LABEL: 'Dieses Feld darf nicht leer sein!',
        NOT_FOUND_NAME_ROOM_DIALOG: 'Upps. Da stimmt etwas nicht. Bitte noch einmal probieren oder die Rezeption kontaktieren.',
        INVOICE_ADDRESS: 'Rechnungsadresse',
        INVOICE: 'Rechnung',
        NAME_LABEL: 'Name',
        COMPANY_LABEL: 'Firma',
        PHONE_LABEL: 'Telefon',
        EDIT_LABEL: 'Bearbeiten',
        SAVE_LABEL: 'Speichern',
        CANCEL_LABEL: 'Abbrechen',
        SUBMIT_LABEL: 'Buchen',
        MOVE_LABEL: 'Verschieben',
        SUBMIT_CONFIRMATION_MESSAGE: 'Sind Sie sicher, dass Sie die Buchung abschicken möchten?',
        PERSONAL_DETAILS_LABEL: 'Persönliche Daten',
        ADDRESS_DETAILS_LABEL: 'Adresse',
        COMPANY_DETAILS_LABEL: 'Firma',
        CHECKOUT_DONE_MESSAGE: 'Fast geschafft! Bitte den Zimmerschlüssel an der Rezeption abgeben.',
        CHECKOUT_DONE_MESSAGE_PROVENT: 'Geschafft! Bitte die Zimmerkarte auf dem Schreibtisch liegen lassen. <br>Wir hoffen, Sie hatten einen angenehmen Aufenthalt im {{HOTEL_NAME}} und wünschen eine angenehme Heimreise.',
        CHECKOUT_DONE_MESSAGE_WITH_INVOICE: 'Fast geschafft! Die Rechnung wird per E-Mail zugeschickt. Bitte den Zimmerschlüssel an der Rezeption abgeben.',
        CHECKOUT_DONE_MESSAGE_WITH_INVOICE_PROVENT: 'Geschafft! Bitte die Zimmerkarte auf dem Schreibtisch liegen lassen. <br>Wir hoffen, Sie hatten einen angenehmen Aufenthalt im {{HOTEL_NAME}} und wünschen eine angenehme Heimreise. <br> Die Rechnung wird in Kürze an die von Ihnen angegebene Mailadresse gesandt.',
        CHECKINOUT_ERROR_MESSAGE: 'Upps. Da hat etwas nicht geklappt! Bitte gehen Sie zur Rezeption. Das Team wird Ihnen weiterhelfen.',
        CHECKINOUT_ERROR_MESSAGE_PROVENT: ' Verzeihung. Da hat etwas nicht geklappt! Bitte wenden Sie sich an die Rezeption. Unser Team wird Ihnen weiterhelfen.',
        SUBMIT_MESSAGE: 'Diese Daten absenden?',
        ELEVATOR_OTHER_RESERVATIONS: 'Andere Reservierungen',
        ELEVATOR_RESERVATION_FOR: 'Reserviert für ',
        ELEVATOR_MY_BOOKINGS: 'Bereits gebuchte Uhrzeiten',
        SELECT_TIME_SLOT: 'Wählen Sie eine Uhrzeit',
        TIME_SLOT_ALREADY_BLOCKED: 'CarLift ist für diese Uhrzeit bereits gebucht',
        PAYMENT_SUCCESS_MESSAGE: 'Es hat geklappt! Gleich geht es weiter mit dem Check Out.',
        PAYMENT_FAILED_MESSAGE: 'Upps. Da hat etwas nicht geklappt! Bitte den Zahlungsvorgang wiederholen.',
        PAYMENT_FAILED_MESSAGE_PROVENT: ' Verzeihung. Da hat etwas nicht geklappt! Bitte den Zahlungsvorgang wiederholen.',
        ACCEPT_AGB: '*<a class="a_orig" href=\'{{agb}}\' target=\'_blank\'>AGB</a> gelesen und akzeptiert.&nbsp;&nbsp;',
        INVOICE_INFORMATION: 'Informationen zur Rechnung. Der Service steht am Tag des Check-out zur Verfügung.',
        ELEVATOR_BOOKING_TITLE: 'Buchen Sie Ihren CarLift',
        ELEVATOR_BOOKING_OK: 'Die Reservierung für den CarLift für {{DAY}}, {{HOUR}}:{{MINUTE}} war erfolgreich.' +
            '<br /> Sie erhalten eine Nachricht hier 10 Minuten vor dem Termin.' +
            '<br /> Zusätzlich wurde eine Termineinladung an {{EMAIL_ADDRESS}} versandt.',
        ELEVATOR_BOOKING_QUESTION: 'Guten Tag! Ich möchte den CarLift für ',
        ELEVATOR_BOOKING_QUESTION_TIME_PREP: ' um ',
        ELEVATOR_BOOKING_QUESTION_CLOSING: ' Uhr buchen. ',
        ELEVATOR_BOOKING_ERROR: 'Bei der Buchung des Zeitfensters für den CarLift ist ein Problem aufgetreten.',
        ELEVATOR_BOOKING_NOT_AVAILABLE: 'Die Buchung der CarLift ist für Ihre Reservierung noch nicht verfügbar.',
        ELEVATOR_TIMESLOT_BOOKED: '(belegt)',
        TODAY: 'heute',
        TOMORROW: 'morgen',
        MANDATORY_MAIL_MESSAGE: 'Jetzt noch die E-Mailadresse für den Rechnungsversand angeben.',
        INVOICE_MESSAGE_ON_CONDITION: 'Hier sind alle bis jetzt angefallenen Leistungen enthalten. Änderungen vorbehalten',
        COOKIE_TEXT: 'Unser digitaler Concierge verwendet Cookies um Ihnen optimal behilflich zu sein',
        COOKIE_BUTTON: 'Einverstanden!',
        COOKIE_DISABLE: 'Deaktivieren',
        COOKIE_PRIVACY_TITLE: 'Mehr Infos',
        CONSENT: 'Ich akzeptiere die <a href=\'https://gustaffo.com/datenschutzerklaerung-concierge\' target=\'_new\'> Datenschutzerklärung </a>',
        CONSENT_ACCEPT: 'Bitte akzeptieren',
        SELFCHECKIN_ERROR_DISABLED: 'Das Self-Checkin Feature ist deaktiviert.',
        CHECKOUT_GREETING: 'Hallo! Du kannst entweder die Rechnungsübersicht ansehen oder direkt den Web Checkout durchführen.',
        ONECHECKOUT_GREETING: 'Hallo! Hier können Sie ihre Rechnung einsehen oder den Check-Out durchführen',
        PROVENT_CHECKOUT_GREETING: 'Sehr geehrter Gast, Sie haben hier die Möglichkeit, direkt online auszuchecken, die Rechnungsanschrift zu ändern und sich die Rechnung per Mail zusenden zu lassen. <br>' +
            'Benötigen Sie noch ein Ausfahrticket für den Parkplatz oder haben Sie Fragen zu den bereits aufgebuchten Leistungen? Unsere Kollegen am Empfang sind Ihnen gerne behilflich',
        VIEW_INVOICE_BUTTON: 'Rechnung ansehen',
        TAX_LABEL: 'MwSt.',
        TRANSACTION_MOVEMENT_TOOLTIP: 'Rechnungsposition verschieben',
        MANDATORY_FIELDS_MESSAGE: 'Bitte füllen Sie die mit * gekennzeichneten Pflichtfelder aus.',
        TRANSACTION_LABEL: 'Rechnungsposition',
        CREATE_PRIVATE_INVOICE_LABEL: 'Private Rechnung anlegen',
        CREATE_CORPORATE_INVOICE_LABEL: 'Firmenrechnung anlegen',

        /* SELFCHECKIN */
        SELFCHECKIN_HEADER: 'Self Check-in',
        SELFCHECKIN_ERROR_DESC: 'Opps...<br>Irgendetwas ist hier schief gelaufen.<br>Bitte fragen Sie einen Mitarbeiter an der Rezeption.',
        SELFCHECKIN_ERROR_BTN_NEXT: 'Neustart',
        SELFCHECKIN_WELCOME_DESC: 'Herzlich willkommen! Hier können Sie einfach und bequem selber einchecken!<br>Bitte wählen Sie Ihre Sprache.',
        SELFCHECKIN_LOGIN_BTN_NEXT: 'Weiter',
        SELFCHECKIN_LOGIN_DESC: 'Bitte geben Sie Ihren Namen ein.',
        SELFCHECKIN_PERSINFO_DESC_HEADER: 'Persönliche Angaben',
        SELFCHECKIN_PERSINFO_DESC: 'Bitte füllen Sie das Formular vollständig aus.',
        SELFCHECKIN_PERSINFO_BTN_NEXT: 'Weiter',
        SELFCHECKIN_ADDRINFO_ADDRESS_HEADER: 'Adresse',
        SELFCHECKIN_ADDRINFO_COMPANYNAME: 'Firmenname',
        SELFCHECKIN_ADDRINFO_STREET: 'Straße',
        SELFCHECKIN_ADDRINFO_BILLING_TEXT: 'Möchten Sie Ihre Rechnungsadresse ändern?',
        SELFCHECKIN_ADDRINFO_BTN_NEXT: 'Weiter',
        SELFCHECKIN_PRINT_DESC1: 'Ihr Meldeschein wird nun gedruckt...',
        SELFCHECKIN_PRINT_DESC2: 'Bitte unterschreiben und in die Box einwerfen.',
        SELFCHECKIN_PRINT_BTN_NEXT: 'Weiter',
        SELFCHECKIN_INVOICE_DESC_HEADER: 'Rechnungsdetails',
        SELFCHECKIN_INVOICE_BTN_NEXT: 'Weiter',
        SELFCHECKIN_INVOICE_BTN_SKIP: 'Zahlung überspringen',
        SELFCHECKIN_INVOICE_BTN_PAY: 'Offenen Betrag bezahlen',
        SELFCHECKIN_KEY_BTN_PAYED_INFO: 'Zahlung war erfolgreich.',
        SELFCHECKIN_KEY_BTN_DESC: 'Trommelwirbel... Ihre Zimmernummer lautet:',
        SELFCHECKIN_KEY_BTN_GETKEY_HEADER: 'Das Beste zum Schluss... die Schlüsselkarte:',
        SELFCHECKIN_KEY_BTN_GETKEY_STEPS: '<li><span class="selfCheckinListItem">Nehmen Sie eine Schlüsselkarte aus der Box.</span></li><li><span class="selfCheckinListItem">Führen Sie die Karte in das Lesegerät.</span></li><li><span class="selfCheckinListItem">Klicken Sie auf "Weiter".</span></li><li><span class="selfCheckinListItem">Bitte warten, bis es initialisiert ist.</span></li>',
        SELFCHECKIN_KEY_BTN_NEXT: 'Weiter',
        SELFCHECKIN_FINISH_BTN_DESC: 'Danke, dass Sie unseren Self Check-in Service benutzt haben.<br>Genießen Sie Ihren Aufenthalt.',
        SELFCHECKIN_FINISH_BTN_ANOTHER_KEY: 'Eine weitere Karte initialisieren',
        SELFCHECKIN_FINISH_BTN_NEXT: 'Fertig',
        OK: 'OK',
        REG_FORM: 'Meldeschein',
        REG_FORM_ADDRESS: 'Privatanschrift*',
        REG_FORM_ARRIVAL: 'Datum Anreise*',
        REG_FORM_DEPARTURE: 'Datum Abreise*',
        REG_FORM_NAME: 'Name, Vorname',
        REG_FORM_STREET: 'Straße, Nr.',
        REG_FORM_CITY: 'PLZ, Ort',
        REG_FORM_LAND: 'Land',
        REG_FORM_NATION: 'Staatsangehörigkeit',
        REG_FORM_ID: 'Passnummer',
        REG_FORM_DOB: 'Geburtsdatum',
        REG_FORM_NO_PEOPLE: 'Anzahl der Mitreisenden (Erwachsene + Kinder)'
    });
});
