(function () {
    'use strict';

    myApp.directive('o4mImgOnLoad', ImgOnLoad);

    function ImgOnLoad() {
        const directive = {
            restrict: 'A',
            link: link,
            scope: {
                imageOnLoad: '&'
            }
        };
        return directive;


        function link($scope, $element) {
            $element.bind('load', function () {
                $scope.$apply($scope.imageOnLoad);
            });
        }
    }
})();
