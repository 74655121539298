/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

myApp.controller('EmbedCtrl', function ($rootScope, $scope, $sce, SharedData, $location, $timeout) {
    $scope.sharedData = SharedData.data;
    $scope.controlFlow = 'loading';

    $scope.goHome = function () {
        $location.path('#/?' + $scope.hid);
        $scope.sharedData.trackScreen('home', $scope.hotel.shortcut, $scope.tracker);
    };

    $scope.loadEmbed = function () {
        if ($scope.sharedData.embedPath.lastIndexOf('twitter', 0) === 0) {
            $scope.sharedData.trackScreen('embed/twitter', $scope.hotel.shortcut, $scope.tracker);

            var parts = $scope.sharedData.embedPath.split(':');
            if (parts.length === 2) {
                createTwitter();
                $scope.controlFlow = "twitter";
            } else {
                console.error("Invalid embedded-url for twitter defined!");
            }
        }

        if ($scope.sharedData.embedPath.lastIndexOf('ubimet', 0) === 0) {
            $scope.sharedData.trackScreen('embed/weather', $scope.hotel.shortcut, $scope.tracker);
            var cid = $scope.sharedData.embedPath.substring(7);

            createUbimet(cid);

            $scope.controlFlow = "ubiwidget";
            if ($scope.$$phase) {
                $timeout(function () {
                    $scope.$apply();
                }, 10);
            } else {
                $scope.$apply();
            }
        }

        if ($scope.sharedData.embedPath.lastIndexOf('iframe://', 0) === 0) {
            var href = $sce.trustAsResourceUrl($scope.sharedData.embedPath.replace("iframe://", ""));
            $scope.controlFlow = "iframecontent";
            $scope.iframe = {
                href: href
            };
        }
    };
});
