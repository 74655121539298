myApp.config(function ($translateProvider) {
    $translateProvider.translations('cz', {
        ADDRESS_HEADER: 'adressa',
        ARRIVAL_LABEL: 'Příjezd',
        ASK_FOR_CHECK_OUT_LABEL: 'Můžete připravit Vaše fakturační údaje předem.',
        BACK: 'zpět',
        CHANNELS_LABEL: 'channel',
        CHARGE: 'Poplatek za službu',
        CHECK_IN_BUTTON: 'Zahájit Check-in',
        CHECK_IN_CARD_INFO_LABEL: 'Žádáme Vás o vyplnění celého formuláře',
        CHECK_IN_CARD_LABEL: 'Osobní informace',
        CHECK_IN_FINISH_THANK_YOU_LABEL: 'Děkujeme Vám! Fakturační údaje byly uloženy. Klíč od pokoje si můžete po příjezdu vyzvednout na recepci. Šťastnou cestu',
        CHECK_IN_THANK_YOU_LABEL: 'Děkujeme za Vaše přihlášení online',
        CHECK_OUT_BUTTON: 'uložit fakturační adresu',
        CHECK_OUT_CARD_INFO_LABEL: 'Přejete si jinou fakturační adresu?',
        CHECK_OUT_CARD_LABEL: 'fakturační adresa',
        CHECK_OUT_THANK_YOU_LABEL: 'Děkujeme Vám! Fakturační údaje byly uloženy. Klíč od pokoje si můžete po příjezdu vyzvednout na recepci. Šťastnou cestu.',
        CITY_LABEL: 'Město',
        COMMENT: 'Komentář...',
        COMPANY_NAME1_LABEL: 'Jméno firmy (číslo domu 1)',
        COMPANY_NAME2_LABEL: 'Jméno firmy (číslo domu 2)',
        COUNTRY_LABEL: 'Stát',
        DATE_OF_BIRTH_LABEL: 'Datum narození',
        DEPARTURE_LABEL: 'Odjezd',
        DO_CHECK_OUT_BUTTON: 'připravit fakturační údaje',
        EMAIL_LABEL: 'e-mail',
        ERROR_MESSAGE: 'Chybná zpráva',
        FINISH_CHECK_IN_BUTTON: 'nyní ne',
        FIRST_NAME_LABEL: 'Jméno',
        INPUT_PROMPT_COMMENT: 'Komentář',
        LANGUAGE_LABEL: 'jazyk',
        LAST_NAME_LABEL: 'Příjmení',
        LOADING_LABEL: 'Nahrávání',
        LOGOFFDIALOG: 'Opravdu se chcete odhlásit?',
        MANDATORY_FIELD: '* Povinné pole',
        MESSAGE_TO_RECEPTION: 'Kontaktujte recepci',
        MONTH_APRIL: 'duben',
        MONTH_AUGUST: 'srpen',
        MONTH_DECEMBER: 'prosinec',
        MONTH_FEBRUARY: 'únor ',
        MONTH_JANUARY: 'leden',
        MONTH_JULY: 'červenec',
        MONTH_JUNE: 'červen',
        MONTH_MARCH: 'březen',
        MONTH_MAY: 'květen',
        MONTH_NOVEMBER: 'listopad',
        MONTH_OCTOBER: 'říjen',
        MONTH_SEPTEMBER: 'září ',
        NATIONALITY_LABEL: 'Národnost',
        NAV_HOME: 'Domů',
        NAV_MOBILE_CHECK_IN: 'Mobilní Check-in',
        NO_DATA_FOUND: 'Žádné detaily rezervace nejsou dostupné. Prosím ověřte Vaše informace.',
        NO_ROOM: 'Pokoj nenalezen',
        NO: 'Ne',
        ORDER: 'Objednávka',
        PASSPORT_LABEL: 'číslo pasu',
        PLEASE_ENTER: 'Prosím, zadejte své číslo pokoje a jméno',
        PRICE_LABEL: 'cena',
        PROCEED: 'Pokračovat',
        REPLY: 'Odpovědět',
        RESERVATION_COMMENT: 'poznámka',
        ROOM_NAME: 'Jméno',
        ROOM_NUMBER: 'Pokoj ',
        SALUTATION_LABEL: 'nadpis',
        SEARCH: 'hledání',
        SEND_MESSAGE: 'Odeslat',
        STREET_LABEL: 'Ulice',
        STREET_LINE1_LABEL: 'Ulice (číslo domu 1)',
        STREET_LINE2_LABEL: 'Ulice (číslo domu 2)',
        USE_CREDIT_CARD_LABEL: 'Použít následující kreditní kartu',
        USE_PRIVATE_ADDRESS_LABEL: 'Použít soukromou adresu?',
        VALUE_LABEL: 'hodnota',
        YES: 'Ano',
        YOUR_MESSAGE: 'Vaše zpráva',
        YOUR_ORDER: 'objednávka',
        ZIP_CODE_LABEL: 'PSČ',
        VALID_VALUE: 'Prosím zadejte platnout hodnotu',
        NOT_AVAILABLE_WIFI: 'Pro použití této funkce musíte být připojeni na WiFi',
        NOT_AVAILABLE_LOCATION: 'Pro použití této funkce musíte být v hotelu',
        NOT_AVAILABLE: 'Funkce není přístupná',
        CHECK_LOCATION: 'Kontroluji polohu …',
        DESCRIPTION_LABEL: 'Popis',
        CONTACT_RECEPTION_PERMANENT: 'Nějaké otázky? Recepce Vám ráda pomůže',
        CHECK_NEARBY_HOTELS_LABEL: 'Hledám hotely v okolí, prosím počkejte',
        NO_GEO_LOCATION_FOUND: 'Nelze zjistit poloha',
        NO_GEO_LOCATION_SERVICE_FOUND: 'Prosím zapněte polohové funkce na vašem přístroji',
        PASSWORD_PROTECT: 'Vstup do této sekce je chráněn heslem',
        //checkout
        ACCEPT_AGB: '*<a class="a_orig" href=\'{{agb}}\' target=\'_blank\'>Obecné podmínky a zásady</a> přečteny a odsouhlaseny.&nbsp;&nbsp;',
        CANCEL_LABEL: 'Zrušit',
        CHECK_OUT_VALIDATION: 'Prokažte se prosím',
        CHECKOUT: 'Check-out',
        CHECKOUT_DONE_MESSAGE: 'A jsme skoro hotovi. Klíče prosím nechte na recepci.',
        CHECKOUT_DONE_MESSAGE_WITH_INVOICE: 'A jsme skoro hotovi. Fakturu Vám zašleme e-mailem. Klíče prosím nechte na recepci.',

        CHECKINOUT_ERROR_MESSAGE: 'Ajaj. Něco se nepovedlo. Běžte prosím na recepci. Tým se o to postará.',
        CONSUMATE_QUESTION: 'Něco z Minibaru? Přidejte prosím položky.',
        EDIT_LABEL: 'Upravit',
        FOLIO_DETAILS: 'Fakturační údaje',
        FOLIO_SUM: 'Celkem',
        INVOICE_ADDRESS: 'Fakturační adresa',
        INVOICE_INFORMATION: 'Informace o fakturačních údajích. Služba pro check-out bude k dispozici v den odjezdu.',
        INVOICE_MESSAGE_ON_CONDITION: 'Veškeré poplatky jsou započítány. Mohou se však změnit.',
        ITEM_DESCRIPTION_LABEL: 'Položka',
        ITEM_QUANTITY_LABEL: 'Množství',
        ITEM_TRANSACTION_DATE_LABEL: 'Datum transakce',
        MANDATORY_MAIL_MESSAGE: 'Nyní upřesněte e-mailovou adresu pro účely fakturace.',
        NOT_FOUND_NAME_ROOM_DIALOG: 'Ajaj. Něco se nepovedlo. Zkuste to prosím znovu nebo kontaktujte recepci.',
        PAY_DIFFERENCE: 'Zaplatit nyní',
        PAYED_AMOUNT: 'Uhrazená částka',
        PAYMENT_FAILED_MESSAGE: 'Ajaj. Něco se nepovedlo. Zopakujte prosím proces platby.',
        PAYMENT_SUCCESS_MESSAGE: 'Povedlo se. Následuje závěrečný check-out.',
        PROCESS_CART: 'Rezervovat položky',
        REMAINING_AMOUNT: 'Zbývá uhradit',
        REQUIRED_LABEL: 'Toto pole nesmí zůstat prázdné!',
        ROOM_NUMBER_CHECKOUT: 'Pokoj č.',
        SAVE_LABEL: 'Uložit',
        SKIP_CART: 'Přeskočit položky minibaru',
        SUBMIT_CONFIRMATION_MESSAGE: 'Opravdu chcete tuto rezervaci potvrdit?',
        SUBMIT_LABEL: 'Rezervovat položky',
        SUM_AMOUNT: 'Celková částka',
        YOUR_ORDER_CHECKOUT: 'Vaše konzumace'
    });
});
