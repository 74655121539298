(function() {
    'use strict';

    myApp.directive('o4mInfiniteScroll', InfiniteScroll);
    
    InfiniteScroll.$inject = ['$window','$timeout'];

    function InfiniteScroll($window,$timeout) {
        var directive = {
            restrict: 'A',
            link: link,
            scope: {
                targetId : '@',
                cbTrigger : '&',
                isEnabled : '=?'
            }
        };
        return directive;


        function link($scope, $element, $attrs) {
            
            $scope.isEnabled = ($scope.isEnabled !== undefined) ? $scope.isEnabled : true;

            let target = angular.element(document.querySelector('#' + $scope.targetId));
            let lastScrollPos = 0;

            if (target) {
                angular.element(target[0]).bind("scroll", function() {
                    lastScrollPos = target[0].scrollTop;
                    let ratio = (target[0].scrollHeight - target[0].offsetHeight) / 20;
                    
                    //if (target[0].scrollTop > (target[0].scrollHeight - ratio) && $scope.isEnabled)    // Top to btm
                    if (target[0].scrollTop < ratio && $scope.isEnabled)    // Btm to top
                        $scope.cbTrigger();
                });

                // When the element size increases, the scroll-position should remain the same
                $scope.$watch( function() {
                    return target[0].scrollHeight;
                }, function(newValue, oldValue) {
                    let diff = Math.abs(newValue - oldValue);
                    diff += lastScrollPos;

                    target[0].scrollTop = diff;
                    lastScrollPos = target[0].scrollTop;
                });
            }

            function resetScroll() {
                if (!target)
                    return;

                $timeout(function() {
                    target[0].scrollTop = target[0].scrollHeight;
                    lastScrollPos = target[0].scrollTop;
                }, 0);
            };
            resetScroll();

            $scope.$on('o4mResetScroll', function(event) {
                resetScroll();
            });
        }
    }
})();