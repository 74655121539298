(function () {
    try {
        window.GustaffoPushNotifications = (function () {
            if ('serviceWorker' in navigator) {
                console.log('Service Worker is supported');
                navigator.serviceWorker.register('assets/workers/service-worker.js')
                        .then(function (r) {
                            console.log('registered service worker', r);
                            r.update();
                            swRegistration = r;
                            messaging.useServiceWorker(swRegistration);
                            //  initialiseUI(); // COMMENT IN TO ACTIVATE OLD PUSH WORKFLOW
                            checkFCM();
                            //getFCMToken();
                        })
                        .catch(function (err) {
                            console.error('error with service worker ', err);
                        });
            } else {
                console.warn('Service worker is not supported');
            }

            window.addEventListener('beforeinstallprompt', function (e) {
                console.log("Before Install Prompt");
                reportAngular('beforeinstallprompt');

                // e.userChoice will return a Promise. For more details read: http://www.html5rocks.com/en/tutorials/es6/promises/
                e.userChoice.then(function (choiceResult) {
                    console.log(choiceResult.outcome);
                    if (choiceResult.outcome == 'dismissed') {
                        console.log('User cancelled homescreen install');
                        reportAngular('installdismiss');
                    } else {
                        console.log('User added to homescreen');
                        reportAngular('installhome');
                    }
                });
            });
        });
    } catch (e) {
        console.error(e);
    }
})();
