myApp.service('EmailProviderService', function ($filter) {
    var blackListedDomains = [ {
        'domain': "guest.booking.com"
    },
        {
            'domain': "fake.gg.at"
        }
    ];

    this.isBlacklisted = function (email) {
        if (isUndefined(email)) {
            return true;
        }
        if (isNoMail(email)) {
            return true;
        }

        return isInBlacklist(email);
    };

    function isUndefined(email) {
        if (email == "" || email == null || email == undefined) {
            return true;
        }
    }

    function isNoMail(email) {
        if (email.indexOf("@") < 0) {
            return true;
        }
    }

    function isInBlacklist(email) {
        var index = email.indexOf("@") + 1;
        var domain = email.substring(index);

        var blacklisted = $filter('filter')(blackListedDomains, {
            domain: domain
        })[ 0 ];

        return (blacklisted && blacklisted.domain == domain);
    }
});
